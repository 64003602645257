import React, { useEffect, useState } from "react";
import { ConvertFeetToMeters } from "../../util";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DownloadIcon from "@mui/icons-material/Download";

// Component requires 4 props
// - downloadWaypointDialogOpen and setDownloadWaypointDialogOpen - controls visibility of the dialog
// - downloadWaypointOperation and setDownloadWaypointMenuOperation - controls operation being exported

const ExportFlightWaypointsDialog = (props) => {
    const [fileName, setFileName] = useState("");
    const [fileNameHelperText, setFileNameHelperText] = useState("");

    useEffect(() => {
        if (props.downloadWaypointOperation) {
            setFileName(props.downloadWaypointOperation.name);
        }
    }, [props.downloadWaypointOperation]);

    const getWaypointsForMissionPlanner = () => {
        let waypoints = "QGC WPL 110\n";
        props.downloadWaypointOperation.waypoints.forEach(({ lat, lng, alt_agl_ft }, index) => {
            const alt_agl_m = ConvertFeetToMeters(alt_agl_ft);
            waypoints += `${index + 1} 0 3 16 0.000000 0.000000 0.000000 0.000000 ${lat} ${lng} ${alt_agl_m} 1\n`;
        });
        return waypoints;
    };
    const getWaypointsForUgCS = () => {
        let waypoints = "Latitude,Longitude,AltitudeAGL\n";
        props.downloadWaypointOperation.waypoints.forEach(({ lat, lng, alt_agl_ft }) => {
            const alt_agl_m = ConvertFeetToMeters(alt_agl_ft);
            waypoints += `${lat},${lng},${alt_agl_m}\n`;
        });
        return waypoints;
    };
    const handleDownloadWaypoints = (type) => {
        try {
            if (!fileName || fileName.length === 0) {
                setFileNameHelperText("Please enter a name for your file.");
                return;
            }
            let waypoints = "";
            if (type === "txt") {
                waypoints = getWaypointsForMissionPlanner();
            } else {
                waypoints = getWaypointsForUgCS();
            }
            const blob = new Blob([waypoints], { type: "text/plain" });
            const file = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = file;
            link.download = `${fileName}.${type}`;

            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );
            document.body.removeChild(link);
        } catch (err) {
            alert("This operation could not be downloaded at this time. Please try again later.");
        }
        handleCloseDownloadWaypointDialog();
    };
    const handleCloseDownloadWaypointDialog = () => {
        props.setDownloadWaypointDialogOpen(false);
        props.setDownloadWaypointOperation(null);
    };
    return props.downloadWaypointOperation !== null ? (
        <Dialog open={props.downloadWaypointDialogOpen} onClose={handleCloseDownloadWaypointDialog}>
            <DialogTitle>Export to GCS</DialogTitle>
            <DialogContent>
                <DialogContentText>{"Before exporting this operation, please enter a name for your file."}</DialogContentText>
                <TextField
                    autoFocus
                    label="File Name"
                    fullWidth
                    variant="standard"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                    error={fileNameHelperText.length > 0}
                    helperText={fileNameHelperText}
                    sx={{ mt: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDownloadWaypointDialog} sx={{ mr: "auto" }} id="cancel">
                    Cancel
                </Button>
                <Button onClick={() => handleDownloadWaypoints("txt")} startIcon={<DownloadIcon />}>
                    Mission Planner
                </Button>
                <Button onClick={() => handleDownloadWaypoints("csv")} startIcon={<DownloadIcon />}>
                    UgCS
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <></>
    );
};

export default ExportFlightWaypointsDialog;
