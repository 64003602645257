import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";

import AddIcon from "@mui/icons-material/Add";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import CircleIcon from "@mui/icons-material/Circle";
import SquareIcon from "@mui/icons-material/Square";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SellIcon from "@mui/icons-material/Sell";

import { useUserAuth } from "../contexts/authContext";
import { Paper, SvgIcon } from "@mui/material";
import { useMap } from "../contexts/mapContext";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomPagination } from "../customPagination";
import { getSourceName } from "../map/mapUtil";
import { ConvertISOToDate } from "../util";

export function SourceSettings() {
    const [sources, setSources] = useState([]);
    const [sourceDialogOpen, setSourceDialogOpen] = useState(false);
    const [sourceTypeDialogOpen, setSourceTypeDialogOpen] = useState(false);
    const [sourceTypeMenuOpen, setSourceTypeMenuOpen] = useState(new Map());
    const [sourceTypeMenuAnchorEls, setSourceTypeMenuAnchorEls] = useState(new Map());

    const [editRow, setEditRow] = useState({});

    const { userMapSettings, updateMapSettings } = useUserAuth();
    const { sourceData, uniqueSources, getUniqueSources } = useMap();

    const sourceTypeColors = [
        { value: "#FF0000", label: "Red", predicate: (source_type) => source_type.includes("RADAR") },
        { value: "#FF4500", label: "Orange", predicate: (source_type) => !source_type.includes("RADAR") },
        { value: "#FFEA00", label: "Yellow" },
        { value: "#0000FF", label: "Blue" },
        { value: "#800080", label: "Purple" },
        { value: "#FFFFFF", label: "White" }
    ];

    const SourceTypeIcon = ({ icon, color = "white", size = 32 }) => {
        if (icon === "icon") {
            return <AirplanemodeActiveIcon sx={{ width: size, height: size, color: color }} />;
        } else if (icon === "circle") {
            return <CircleIcon sx={{ width: size, height: size, color: color }} />;
        } else if (icon === "square") {
            return <SquareIcon sx={{ width: size, height: size, color: color }} />;
        } else if (icon === "triangle") {
            return (
                <SvgIcon sx={{ width: size, height: size, color: color }}>
                    <polygon points="12,2 22,22 2,22" />
                </SvgIcon>
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        getUniqueSources();
    }, []);

    useEffect(() => {
        const sources = [...uniqueSources];
        for (const key of Object.keys(userMapSettings.source_mapping)) {
            if (!sources.find((s) => s.source_id === key)) {
                sources.push({ source_id: key });
            }
        }
        setSources(sources);
    }, [uniqueSources]);

    const handleVisibilityChange = (source_id, visibility) => {
        const updatedMapSettings = { ...userMapSettings };
        const source = updatedMapSettings.source_mapping[source_id];
        if (!source) {
            updatedMapSettings.source_mapping[source_id] = { visibility: visibility };
        } else {
            source.visibility = visibility;
        }
        updateMapSettings(updatedMapSettings);
    };

    const columns = [
        {
            field: "visibility",
            headerName: "Visibility",
            width: 75,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            display: "flex",
            valueGetter: (value, row) => {
                const source = userMapSettings.source_mapping[row.source_id];
                return !source || source.visibility;
            },
            sortComparator: (v1, v2) => {
                if (v1 === v2) return 0;
                else if (v1) return -1;
                else return 1;
            },
            renderCell: (value) => {
                const source = userMapSettings.source_mapping[value.row.source_id];
                return !source || source.visibility ? (
                    <IconButton onClick={() => handleVisibilityChange(value.row.source_id, false)}>
                        <CheckCircleIcon color="success" fontSize="medium" />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => handleVisibilityChange(value.row.source_id, true)}>
                        <RadioButtonUncheckedIcon color="disabled" fontSize="medium" />
                    </IconButton>
                );
            }
        },
        {
            field: "source_id",
            headerName: "Source ID",
            width: 290,
            editable: false,
            valueGetter: (value, row) => {
                const sourceID = row.source_id;
                const sensorName = sourceData ? sourceData.get(sourceID) : undefined;
                return sensorName ? `${sourceID} (${sensorName})` : sourceID;
            }
        },
        {
            field: "source_tag",
            headerName: "Source Tag",
            width: 150,
            editable: false,
            valueGetter: (value, row) => {
                const source = userMapSettings.source_mapping[row.source_id];
                return source && source.identifier ? source.identifier : "";
            }
        },
        {
            field: "timestamp",
            headerName: "Last ASD Received",
            width: 185,
            editable: false,
            renderCell: (cell) => {
                if (cell.row.timestamp) {
                    return ConvertISOToDate(cell.row.timestamp);
                } else {
                    return (
                        <Typography variant="body2" color="text.secondary" sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                            No new ASD for 24+ hours
                        </Typography>
                    );
                }
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 70,
            flex: 1,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        key={params.id}
                        icon={<SellIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            setEditRow(params.row);
                            setSourceDialogOpen(true);
                        }}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, gap: 2 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", flexGrow: 1, flexShrink: 1, maxWidth: "805px", minWidth: 0, gap: 2 }}>
                <Typography variant="h6" noWrap>
                    Surveillance Sources
                </Typography>
                <DataGrid
                    rows={sources}
                    getRowId={(row) => row.source_id}
                    columns={columns}
                    disableRowSeletionOnClick
                    disableVirtualization
                    autoHeight
                    pagination
                    slots={{ pagination: CustomPagination }}
                    initialState={{ pagination: { paginationModel: { pageSize: 8 } }, sorting: { sortModel: [{ field: "timestamp", sort: "desc" }] } }}
                    sx={{
                        border: "none",
                        // "& .MuiDataGrid-cell": { borderBottom: "none" },
                        "& .MuiDataGrid-columnHeaders": { borderBottom: "none" },
                        "& .MuiDataGrid-footerContainer": { borderTop: "none" }
                    }}
                />
                {sourceDialogOpen ? <SourceDialog row={editRow} sourceDialogOpen={sourceDialogOpen} setSourceDialogOpen={setSourceDialogOpen} /> : <></>}
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", flexGrow: 0, flexShrink: 0, width: "400px", maxWidth: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h6" noWrap>
                        Source Types
                    </Typography>

                    <Tooltip title="Add">
                        <IconButton onClick={() => setSourceTypeDialogOpen(true)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <List dense>
                    {userMapSettings.source_type_settings.map(({ id, source_type, icon, color }) => {
                        const menuOpen = sourceTypeMenuOpen.get(id) || false;
                        const menuAnchorEl = sourceTypeMenuAnchorEls.get(id);

                        const handleOpenColorMenu = (e) => {
                            const updatedMenu = new Map(sourceTypeMenuOpen);
                            updatedMenu.set(id, true);
                            setSourceTypeMenuOpen(updatedMenu);

                            const updatedAnchorEls = new Map(sourceTypeMenuAnchorEls);
                            updatedAnchorEls.set(id, e.currentTarget);
                            setSourceTypeMenuAnchorEls(updatedAnchorEls);
                        };
                        const handleCloseColorMenu = () => {
                            const updatedMenu = new Map(sourceTypeMenuOpen);
                            updatedMenu.set(id, false);
                            setSourceTypeMenuOpen(updatedMenu);

                            const updatedAnchorEls = new Map(sourceTypeMenuAnchorEls);
                            updatedAnchorEls.set(id, null);
                            setSourceTypeMenuAnchorEls(updatedAnchorEls);
                        };
                        const handleSelectIcon = (icon_type) => {
                            const updatedSourceTypeSettings = userMapSettings.source_type_settings.map((setting) => {
                                if (setting.id === id) {
                                    return {
                                        ...setting,
                                        icon: icon_type
                                    };
                                } else {
                                    return setting;
                                }
                            });
                            const updatedMapSettings = {
                                ...userMapSettings,
                                source_type_settings: updatedSourceTypeSettings
                            };
                            updateMapSettings(updatedMapSettings);
                        };
                        const handleSelectColor = (color_hex) => {
                            const updatedSourceTypeSettings = userMapSettings.source_type_settings.map((setting) => {
                                if (setting.id === id) {
                                    return {
                                        ...setting,
                                        color: color_hex
                                    };
                                } else {
                                    return setting;
                                }
                            });
                            const updatedMapSettings = {
                                ...userMapSettings,
                                source_type_settings: updatedSourceTypeSettings
                            };
                            updateMapSettings(updatedMapSettings);
                        };
                        return (
                            <React.Fragment key={id}>
                                <ListItem>
                                    <ListItemAvatar sx={{ cursor: "pointer" }} onClick={handleOpenColorMenu} data-testid={`source-type-list-item-${id}`}>
                                        <SourceTypeIcon icon={icon} color={color} size={40} />
                                    </ListItemAvatar>
                                    <ListItemText primary={source_type} />
                                </ListItem>
                                <Popover
                                    onClose={handleCloseColorMenu}
                                    open={menuOpen}
                                    anchorEl={menuAnchorEl}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "center",
                                        horizontal: "right"
                                    }}
                                >
                                    <Typography variant="body2" color="text.secondary" sx={{ m: 1, mb: 0, pb: 0 }}>
                                        Icon:
                                    </Typography>
                                    <Box sx={{ m: 1, mb: 0, pb: 0, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                        {source_type === "ADS_B" || source_type === "MULTISENSOR_TRACKER" || source_type === "TELEMETRY" ? (
                                            <Tooltip title="Icon">
                                                <IconButton
                                                    onClick={() => handleSelectIcon("icon")}
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                        bgcolor: icon === "icon" ? "rgba(0, 0, 0, 0.5)" : "transparent",
                                                        borderRadius: "0%"
                                                    }}
                                                >
                                                    <SourceTypeIcon icon="icon" />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <></>
                                        )}
                                        <Tooltip title="Circle">
                                            <IconButton
                                                onClick={() => handleSelectIcon("circle")}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    bgcolor: icon === "circle" ? "rgba(0, 0, 0, 0.5)" : "transparent",
                                                    borderRadius: "0%"
                                                }}
                                            >
                                                <SourceTypeIcon icon="circle" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Square">
                                            <IconButton
                                                onClick={() => handleSelectIcon("square")}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    bgcolor: icon === "square" ? "rgba(0, 0, 0, 0.5)" : "transparent",
                                                    borderRadius: "0%"
                                                }}
                                            >
                                                <SourceTypeIcon icon="square" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Triangle">
                                            <IconButton
                                                onClick={() => handleSelectIcon("triangle")}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    bgcolor: icon === "triangle" ? "rgba(0, 0, 0, 0.5)" : "transparent",
                                                    borderRadius: "0%"
                                                }}
                                            >
                                                <SourceTypeIcon icon="triangle" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary" sx={{ m: 1, mb: 0, pb: 0 }}>
                                        Color:
                                    </Typography>
                                    <List dense>
                                        {sourceTypeColors.map(({ value, label, predicate }, i) => {
                                            if (predicate && !predicate(source_type)) {
                                                return <React.Fragment key={i}></React.Fragment>;
                                            }
                                            return (
                                                <ListItemButton
                                                    key={i}
                                                    onClick={() => handleSelectColor(value)}
                                                    sx={{ bgcolor: color === value ? "rgba(0, 0, 0, 0.3)" : "transparent" }}
                                                >
                                                    <ListItemAvatar>
                                                        <SourceTypeIcon icon={icon} color={value} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={label} />
                                                </ListItemButton>
                                            );
                                        })}
                                    </List>
                                </Popover>
                            </React.Fragment>
                        );
                    })}
                </List>

                {sourceTypeDialogOpen ? (
                    <AddSourceTypeDialog sourceTypeDialogOpen={sourceTypeDialogOpen} setSourceTypeDialogOpen={setSourceTypeDialogOpen} />
                ) : (
                    <></>
                )}
            </Paper>
        </Box>
    );
}

const SourceDialog = (props) => {
    const { userMapSettings, updateMapSettings } = useUserAuth();
    const { sourceData } = useMap();

    const row = props.row;
    const [sourceID] = useState(row.source_id);
    const [sourceName] = useState(getSourceName(row.source_id, sourceData));
    const [identifier, setIdentifier] = useState(userMapSettings.source_mapping[row.source_id]?.identifier || "");

    const handleSubmit = async () => {
        const updatedMapSettings = { ...userMapSettings };
        const source = updatedMapSettings.source_mapping[sourceID];
        if (!source) {
            updatedMapSettings.source_mapping[sourceID] = { identifier: identifier, visibility: true };
        } else {
            source.identifier = identifier === "" ? undefined : identifier;
        }

        updateMapSettings(updatedMapSettings);
        handleClose();
    };

    const handleClose = () => {
        props.setSourceDialogOpen(false);
    };

    return (
        <Dialog onClose={handleClose} open={props.sourceDialogOpen} maxWidth="lg" width="300px">
            <DialogTitle>Tag Source</DialogTitle>
            <DialogContent sx={{ pb: 0, pt: 0, width: "300px" }}>
                <TextField disabled={true} value={sourceID} margin="normal" id="sourceID" label="Source ID" fullWidth />
                {sourceID !== sourceName ? (
                    <TextField disabled={true} value={sourceName} margin="normal" id="sourceName" label="HMS Sensor Name" fullWidth />
                ) : (
                    <></>
                )}
                <TextField value={identifier} onChange={(e) => setIdentifier(e.target.value)} margin="normal" id="identifier" label="Identifier" fullWidth />
            </DialogContent>
            <DialogActions>
                <Button sx={{ mr: "auto" }} onClick={handleClose} data-testid="close" id="close">
                    Close
                </Button>
                <Button onClick={handleSubmit} data-testid="save" id="save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AddSourceTypeDialog = (props) => {
    const [newSourceType, setNewSourceType] = useState("");
    const [setSourceTypeErrorText, setNewSourceTypeErrorText] = useState("");
    const { userMapSettings, updateMapSettings } = useUserAuth();

    const handleSubmit = () => {
        if (!newSourceType) {
            setNewSourceTypeErrorText("Please enter a valid source type.");
            return;
        }
        const updatedSourceTypeSettings = [
            ...userMapSettings.source_type_settings,
            {
                id: userMapSettings.source_type_settings.length,
                source_type: newSourceType,
                icon: "square",
                color: "#FFFFFF"
            }
        ];
        const updatedMapSettings = {
            ...userMapSettings,
            source_type_settings: updatedSourceTypeSettings
        };
        updateMapSettings(updatedMapSettings);
        props.setSourceTypeDialogOpen(false);
    };
    return (
        <Dialog open={props.sourceTypeDialogOpen} onClose={() => props.setSourceTypeDialogOpen(false)}>
            <DialogTitle>Add Source Type</DialogTitle>
            <DialogContent>
                <DialogContentText>Please enter the name of your new source type here. You can modify the icon and color later.</DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="Name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    value={newSourceType}
                    onChange={(e) => setNewSourceType(e.target.value)}
                    error={setSourceTypeErrorText !== ""}
                    helperText={setSourceTypeErrorText}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setSourceTypeDialogOpen(false)}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SourceSettings;
