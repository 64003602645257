import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToLogin: false
        };
    }
    state = {
        hasError: false
    };
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        console.error(error, info);
    }
    handleLoginClick = () => {
        const base_url = window.location.origin;
        window.location.href = `${base_url}/login`;
    };
    handleEmailClick = (e) => {
        window.location = "mailto:info@calanalytics.com";
        e.preventDefault();
    };
    handleFeedbackClick = () => {
        const feedbackUrl = "https://forms.office.com/r/YBkaSna3H6";
        window.open(feedbackUrl, "_blank");
    };
    render() {
        if (this.state.hasError) {
            return (
                <Paper sx={{ height: "100vh" }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Container
                                component="main"
                                maxWidth="xl"
                                sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                            >
                                <Typography component="h1" variant="h6" color="inherit">
                                    {"CAL Analytics LLC"}
                                </Typography>

                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <Button variant="text" startIcon={<EmailIcon />} onClick={this.handleEmailClick}>
                                        {"Email Us"}
                                    </Button>
                                    <Button variant="outlined" startIcon={<PersonIcon />} onClick={this.handleLoginClick}>
                                        {"Login"}
                                    </Button>
                                </Box>
                            </Container>
                        </Toolbar>
                    </AppBar>

                    <Container component="main" maxWidth="lg" sx={{ mt: 12 }}>
                        <Grid container spacing={6}>
                            <Grid item md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography variant="h2" component="h1" gutterBottom>
                                    {"Whoops,"}
                                </Typography>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    {"Something went wrong. "}
                                    {"Please click below if you would like to report an issue, otherwise you can log back in and try again."}
                                </Typography>

                                <Box sx={{ pt: 2 }}>
                                    <Button variant="contained" onClick={this.handleFeedbackClick}>
                                        {"Feedback Form"}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
                                <Box
                                    component="img"
                                    alt="error.png"
                                    src="../images/error.png"
                                    sx={{ maxWidth: "100%", height: "auto", padding: 0, margin: 0 }}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
