import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useCallback } from "react";
import { Avatar } from "@mui/material";
import { ReceiverMessage, SenderMessage } from "mui-chat-box";
import { useUserAuth } from "../contexts/authContext";

export const Chatbox = ({ messages }) => {
    const { user } = useUserAuth();

    const setLastMessageRef = useCallback((node) => {
        if (node) node.scrollIntoView({ behavior: "instant" });
    }, []);

    return (
        <Grid sx={{ flexWrap: "nowrap", display: "flex", flexDirection: "column", gap: "10px" }}>
            {messages.map((message, i) => {
                const lastMessage = messages.length - 1 === i;
                const prevMessage = messages[i - 1] || messages[i];
                const prevMessageDate = new Date(prevMessage.date_sent).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric"
                });
                const newMessageDate = new Date(message.date_sent).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric"
                });

                return message.sent_user_id !== user.id ? (
                    <Box key={i}>
                        {prevMessageDate !== newMessageDate || i === 0 ? <Divider sx={{ color: "#6b778c", p: "20px 0" }}> {newMessageDate} </Divider> : <></>}
                        <div className="cmp-ui-chat-message" ref={lastMessage ? setLastMessageRef : null}>
                            <Typography variant="caption" display="block" sx={{ pb: "8px", color: "#6b778c" }}>
                                {message.sent_user_email.split("@")[0]}{" "}
                                {new Date(message.date_sent).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })}
                            </Typography>
                            <ReceiverMessage avatar={<Avatar>{message.sent_user_email[0].toUpperCase()}</Avatar>}>
                                <Box sx={{ p: "8px" }}>{message.content}</Box>
                            </ReceiverMessage>
                        </div>
                    </Box>
                ) : (
                    <Box key={i}>
                        {prevMessageDate !== newMessageDate || i === 0 ? <Divider sx={{ color: "#6b778c", p: "20px 0" }}> {newMessageDate} </Divider> : <></>}
                        <div className="cmp-ui-chat-message" ref={lastMessage ? setLastMessageRef : null}>
                            <Typography variant="caption" display="block" sx={{ display: "flex", justifyContent: "flex-end", color: "#6b778c" }}>
                                {new Date(message.date_sent).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })}
                            </Typography>
                            <SenderMessage avatar={<Avatar>{message.sent_user_email[0].toUpperCase()}</Avatar>}>
                                <Box sx={{ p: "8px" }}>{message.content}</Box>
                            </SenderMessage>
                        </div>
                    </Box>
                );
            })}
        </Grid>
    );
};
