import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const Button = (props) => {
  const [debounce, setDebounce] = useState(false);

  useEffect(() => {
    return () => {
      setDebounce(false);
    };
  }, []);

  const handleClick = () => {
    if (debounce === true) {
      return;
    }
    props.onClick();
    setDebounce(true);

    setTimeout(() => {
      setDebounce(false);
    }, 500);
  };

  return (
    <LoadingButton {...props} loading={debounce} onClick={handleClick}>
      {props.children}
    </LoadingButton>
  );
};

export default Button;
