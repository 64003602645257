import React, { useEffect, useState } from "react";

import ModeIcon from "@mui/icons-material/Mode";
import StyleIcon from "@mui/icons-material/Style";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import useOpenLayersMap from "../openlayers/hooks/useMap";
import OpenLayersMap from "../openlayers/olMap";
import AssetCards from "./assetCards";
import AssetCreator from "./assetCreator";

import { useMap } from "../contexts/mapContext";

export default function AssetMap() {
    const [canCreateAsset, setCanCreateAsset] = useState(true);
    const [assetCreatorOpen, setAssetCreatorOpen] = useState(false);
    const [assetCardsOpen, setAssetCardsOpen] = useState(false);
    const [editFeature, setEditFeature] = useState(null);

    const map = useOpenLayersMap(false);
    const { stopAssetMonitoring, stopAllAssetMonitoring } = useMap();

    useEffect(() => {
        if (map) {
            stopAllAssetMonitoring(map);
        }
    }, [map]);

    useEffect(() => {
        if (assetCreatorOpen) {
            setCanCreateAsset(false);
        } else {
            setCanCreateAsset(true);
            setEditFeature(null);
        }
    }, [assetCreatorOpen]);

    useEffect(() => {
        if (editFeature) {
            stopAssetMonitoring(map, editFeature.getId());
            setAssetCreatorOpen(true);
        }
    }, [editFeature]);

    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            {/* Asset creation menu. */}
            {assetCreatorOpen ? (
                <AssetCreator map={map} editFeature={editFeature} assetCreatorOpen={assetCreatorOpen} setAssetCreatorOpen={setAssetCreatorOpen} />
            ) : (
                <></>
            )}

            {/* Map being rendered. */}
            <OpenLayersMap
                map={map}
                buttons={[{ name: "Asset Cards", icon: <StyleIcon />, onClick: () => setAssetCardsOpen(!assetCardsOpen), visible: true }]}
            />

            {/* Add asset button. */}
            {canCreateAsset ? (
                <Fab
                    sx={{
                        position: "absolute",
                        bottom: "1em",
                        right: "1em",
                        zIndex: 1,
                        color: "#666666",
                        bgcolor: "white"
                    }}
                    onClick={() => setAssetCreatorOpen(true)}
                >
                    <ModeIcon />
                </Fab>
            ) : (
                <></>
            )}

            {/* Asset cards menu. */}
            {assetCardsOpen ? <AssetCards map={map} editFeature={editFeature} setEditFeature={setEditFeature} assetCardsOpen={assetCardsOpen} /> : <></>}
        </Box>
    );
}
