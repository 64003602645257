export const PASSWORD_ERROR =
    "Password must contain at least 8 characters, and an uppercase, lowercase, numeric, and one of the following special characters: @ $ ! % * ? & # _ - ";

export function FormatPhoneNumber(input) {
    input = input.replace(/\D/g, "");
    const size = input.length;
    if (size > 0) input = "(" + input;
    if (size > 3) input = input.slice(0, 4) + ") " + input.slice(4, 11);
    if (size > 6) input = input.slice(0, 9) + "-" + input.slice(9);
    return input;
}

export function IsInvalidPhoneNumber(phoneNumber) {
    const phoneNumberRegex = /^[1-9]\d{9}$/;
    return !phoneNumber || !phoneNumberRegex.test(phoneNumber.replace(/\D/g, ""));
}

export function IsInvalidEmail(email) {
    const emailRegex = /.+@.+\..+/;
    return !email || !emailRegex.test(email);
}

export function IsInvalidPassword(password) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#_-])[A-Za-z\d@$!%*?&#_-]{8,}$/;
    return !password || !passwordRegex.test(password);
}
