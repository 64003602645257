import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import Button from "../../components/button";

import { useUserAuth } from "../../contexts/authContext";
import { ConvertFeetToMeters, getConstraintDurationFromInput } from "../../util";

export const ConstraintCircleCreateDialog = (props) => {
    const [createRadius, setCreateRadius] = useState(0);
    const [createDuration, setCreateDuration] = useState(60);

    const { user, handleFailedFetch } = useUserAuth();

    //Handle publish for constraint entities
    const handlePublish = async () => {
        const isBadRadius = isNaN(parseFloat(createRadius)) || parseFloat(createRadius) <= 0;
        const isBadDuration = isNaN(parseFloat(createDuration)) || parseFloat(createDuration) <= 0;
        if (isBadRadius || isBadDuration) {
            props.setSnackbar({ children: "Please fill out the required fields.", severity: "error" });
            return false;
        }
        const currentTime = new Date();
        const startTime = currentTime.getTime() + 10 * 60 * 1000;
        const endTime = currentTime.getTime() + (parseInt(10) + parseInt(createDuration)) * 60 * 1000;
        const startIsoString = new Date(startTime).toISOString();
        const endIsoString = !Number.isNaN(new Date(endTime).getTime()) ? new Date(endTime).toISOString() : new Date(startTime).toISOString();

        const longitude = props.constraintCircleCenterPoint.lon_deg;
        const latitude = props.constraintCircleCenterPoint.lat_deg;

        // Create constraint and publish it
        const constraint = {
            name: "First Responder Constraint",
            type: "PUBLIC_SAFETY",
            altitude: ConvertFeetToMeters(10000),
            organization: user.organization,
            organization_id: user.organization_id,
            created_user_id: user.id,
            priority: 30,
            time_start: startIsoString,
            time_end: endIsoString,
            volumes: [
                {
                    lng: longitude,
                    lat: latitude,
                    radius: ConvertFeetToMeters(createRadius)
                }
            ],
            state: "ACCEPTED",
            version: 1
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        await fetch("api/constraint/planning", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                props.setSnackbar({ children: "Constraint successfully created", severity: "success" });
                props.setConstraintCircleCreateDialogOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };
    //Handle cancel for constraint creation
    const handleCancel = () => {
        props.setConstraintCircleCreateDialogOpen(false);
    };
    const handleDurationChange = (e) => {
        const input = getConstraintDurationFromInput(e.target.value);
        if (input !== undefined) {
            setCreateDuration(input);
        }
    };
    return (
        <Dialog
            open={props.constraintCircleCreateDialogOpen}
            onContextMenu={(e) => e.preventDefault()}
            container={props.mapContainer ? props.mapContainer : undefined}
        >
            <DialogTitle sx={{ width: { sm: "600px" } }}>Create Constraint</DialogTitle>
            <DialogContent sx={{ marginTop: "5px", display: "flex", flexDirection: "column", gap: "20px", maxHeight: "550px", pt: "20px" }}>
                <Box sx={{ paddingTop: "5px", display: "grid", gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, gap: "20px" }}>
                    <TextField
                        error={props.snackbar && (isNaN(parseFloat(createRadius)) || parseFloat(createRadius) <= 0)}
                        inputProps={{ "data-testid": "createRadius" }}
                        onChange={(e) => setCreateRadius(e.target.value)}
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        variant="outlined"
                        id="nameInput"
                        label="Radius"
                        value={createRadius}
                        onWheel={(e) => e.target.blur()}
                    />

                    <TextField
                        error={props.snackbar && (isNaN(parseFloat(createDuration)) || parseFloat(createDuration) <= 0)}
                        InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment> }}
                        variant="outlined"
                        id="durationInput"
                        label="Duration"
                        onChange={handleDurationChange}
                        value={createDuration}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} size="small">
                    Cancel
                </Button>
                <Button onClick={handlePublish} data-testid="accept" size="small" id="submit">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
