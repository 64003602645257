import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { EmailLog } from "./emailLog";
import { ErrorLog } from "./errorLog";
import { AirspaceLog } from "./airspaceLog";
import { PlanningOpLog } from "./planningOpLog";
import { OperationsLog } from "./operationsLog";
import { ConstraintLog } from "./constraintLog";
import { AlertVolumeLog } from "./alertVolumeLog";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { ErrorDashboard } from "./errorDashboard";

export function DataLogs() {
    const [value, setValue] = useLocalStorage("userDatalogTab", "0");
    const [opValue, setOpValue] = useLocalStorage("userDatalogOpTab", "1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpChange = (event, newValue) => {
        setOpValue(newValue);
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", p: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Data Logs
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column" }}>
                <Box sx={{ width: "100%" }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                variant="scrollable"
                                value={value}
                                scrollButtons
                                allowScrollButtonsMobile
                            >
                                <Tab label="Error Dashboard" value="0" />
                                <Tab label="Error Log" value="1" />
                                <Tab label="Email Log" value="2" />
                                <Tab label="Operations Logs" value="3" />
                                <Tab label="Constraint Log" value="4" />
                                <Tab label="Airspace Log" value="5" />
                                <Tab label="Alert Volume Log" value="6" />
                            </Tabs>
                        </Box>
                        <TabPanel value="0">
                            <ErrorDashboard />
                        </TabPanel>
                        <TabPanel value="1">
                            <ErrorLog />
                        </TabPanel>
                        <TabPanel value="2">
                            <EmailLog />
                        </TabPanel>
                        <TabPanel value="3">
                            <TabContext value={opValue}>
                                <Tabs
                                    onChange={handleOpChange}
                                    aria-label="lab API tabs example"
                                    value={opValue}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Archived Planning Data" value="1" />
                                    <Tab label="Archived Published Data" value="2" />
                                </Tabs>
                                <TabPanel sx={{ px: 0 }} value="1">
                                    <PlanningOpLog />
                                </TabPanel>
                                <TabPanel sx={{ px: 0 }} value="2">
                                    <OperationsLog />
                                </TabPanel>
                            </TabContext>
                        </TabPanel>
                        <TabPanel value="4">
                            <ConstraintLog />
                        </TabPanel>
                        <TabPanel value="5">
                            <AirspaceLog />
                        </TabPanel>
                        <TabPanel value="6">
                            <AlertVolumeLog />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
        </Grid>
    );
}
