import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";

import { Chatbox } from "./chatbox";
import { useChat } from "../contexts/chatContext";
import { useUserAuth } from "../contexts/authContext";

export const ConversationDialog = (props) => {
    const [messageInput, setMessageInput] = useState("");

    const { user, socket } = useUserAuth();
    const { sendMessage, selectedConversation, setSelectedConversationUUID } = useChat();

    useEffect(() => {
        const convo = selectedConversation;
        if (!socket || !convo || !convo.unreadMessages || convo.unreadMessages.length === 0) {
            return;
        }

        // Initially read conversation if message comes through and you are on the chat tab.
        if (!document.hidden) {
            socket.emit("readConversation", convo.conversation_uuid, user.id);
        }

        // If already on chat but in a different window, read conversation when swapping back to the CMP.
        const handleVisibilityChange = () => {
            if (convo?.unreadMessages?.length > 0 && !document.hidden) {
                socket.emit("readConversation", selectedConversation.conversation_uuid, user.id);
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [selectedConversation]);

    const handleClose = () => {
        setSelectedConversationUUID(null);
        props.setConversationDialogOpen(false);
    };

    return (
        <Dialog
            open={props.conversationDialogOpen}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            container={props.mapContainer ? props.mapContainer : undefined}
        >
            {selectedConversation ? (
                <>
                    <DialogTitle sx={{ position: "relative", width: "100%" }}>
                        {selectedConversation.recipientEmails.filter((email) => email !== user.email).join(", ")}
                        <IconButton color="inherit" onClick={handleClose} sx={{ position: "absolute", top: "15px", right: "15px" }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Container
                            sx={{
                                height: "650px",
                                overflowY: "scroll",
                                py: "16px",
                                mt: "16px",
                                maxWidth: "100% !important"
                            }}
                        >
                            <Chatbox messages={selectedConversation.messages} />
                        </Container>

                        <TextField
                            label="Message"
                            sx={{ mt: "20px", width: "100%" }}
                            id="outlined-start-adornment"
                            inputProps={{ "data-testid": "messageInput" }}
                            onChange={(e) => setMessageInput(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    sendMessage(messageInput, setMessageInput);
                                }
                            }}
                            value={messageInput}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={() => sendMessage(messageInput, setMessageInput)}>
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </DialogContent>
                </>
            ) : (
                <DialogContent>
                    <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "650px" }}>
                        <CircularProgress />
                    </Container>
                </DialogContent>
            )}
        </Dialog>
    );
};
