import React, { useEffect } from "react";

import HomeIcon from "@mui/icons-material/Home";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import useOpenLayersMap from "../hooks/useOpenLayersMap";
import MapFullScreen from "../map/mapFullScreen";
import MapInfoCard from "../map/mapInfoCard";

import { Box, CircularProgress, FormControl, MenuItem, Paper, Select, Typography } from "@mui/material";
import { Feature } from "ol";
import { circular } from "ol/geom/Polygon";
import { Fill, Icon, Stroke, Style } from "ol/style";
import { useUserAuth } from "../contexts/authContext";
import { allModels, convertHexColorToRgbaColor, GetSource } from "../map/mapUtil";
import { getSensorStateColor } from "./hmsUtil";
import { useMap } from "../contexts/mapContext";
import { useLocalStorage } from "../hooks/useLocalStorage";

export default function HMSMapComponent() {
    const MAP_ID = "hms-map-component";
    const map = useOpenLayersMap(MAP_ID);
    const [sensorFilter, setSensorFilter] = useLocalStorage("componentMapSensorFilter", "All");
    const { userMapSettings } = useUserAuth();
    const { components } = useMap();

    useEffect(() => {
        if (!map) {
            return;
        }

        const vectorLayer = new VectorLayer({
            source: new VectorSource(),
            properties: { name: "hms_components", selectable: true },
            zIndex: 5
        });
        map.addLayer(vectorLayer);
        handleHomeButtonClick();
    }, [map]);

    useEffect(() => {
        if (!map || !GetSource(map, "hms_components") || !components || components.length === 0) {
            return;
        }

        const hmsSource = GetSource(map, "hms_components");
        hmsSource.clear();

        for (const component of components) {
            if (sensorFilter === "ADS-B" && component.sensor_type !== "adsb") {
                continue;
            } else if (sensorFilter === "Radar" && component.sensor_type !== "gbradar") {
                continue;
            }

            const center = [component.lon, component.lat];
            const radius = component.radius;
            const geometry = circular(center, radius, 128);

            const sourceType = component.sensor_type === "adsb" ? "ADS_B" : "GROUND_BASED_RADAR";
            const sourceSettings = userMapSettings.source_type_settings.find(({ source_type }) => source_type === sourceType);

            let src = `../static/2d-models/shapes/${sourceSettings.icon}.png`;
            if (sourceSettings.icon === "icon") {
                src = allModels.get(0);
            }

            const color = getSensorStateColor(component.sensor_state);
            const style = [
                new Style({
                    fill: new Fill({
                        color: convertHexColorToRgbaColor(color, 0.1)
                    }),
                    stroke: new Stroke({
                        color: convertHexColorToRgbaColor(color, 1),
                        width: 3
                    })
                }),
                new Style({
                    image: new Icon({
                        src,
                        scale: 0.6,
                        color: sourceSettings.color
                    }),
                    geometry: (feature) => {
                        const geometry = feature.getGeometry();
                        if (geometry.getType() === "Polygon") {
                            return geometry.getInteriorPoint();
                        }
                    }
                })
            ];

            const feature = new Feature(geometry);
            feature.set("name", component.name);
            feature.set("hms_component", component);
            feature.setStyle(style);
            hmsSource.addFeature(feature);
        }
    }, [map, components, sensorFilter]);

    const handleHomeButtonClick = () => {
        if (map && map.getView()) {
            map.getView().setCenter([userMapSettings.longitude, userMapSettings.latitude]);
            map.getView().setZoom(8);
        }
    };

    return (
        <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ height: "100%", width: "100%", opacity: components.length > 0 ? 1 : 0.5 }}>
                <MapFullScreen map_id={MAP_ID} map={map}>
                    <Box sx={{ position: "absolute", zIndex: 1, right: "8px", top: "8px", display: "flex", flexDirection: "row", gap: "8px" }}>
                        <Paper
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "215px",
                                height: "38px",
                                px: "5px",
                                pl: "10px",
                                gap: "8px"
                            }}
                        >
                            <Typography variant="body1" color="textSecondary" sx={{ width: "100%" }}>
                                Sensor Filter:
                            </Typography>
                            <FormControl size="small" sx={{ width: "100%" }}>
                                <Select value={sensorFilter} onChange={(e) => setSensorFilter(e.target.value)} sx={{ maxHeight: "30px" }}>
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="ADS-B">ADS-B</MenuItem>
                                    <MenuItem value="Radar">Radar</MenuItem>
                                </Select>
                            </FormControl>
                        </Paper>
                        <button
                            title="View Home"
                            type="button"
                            onClick={handleHomeButtonClick}
                            className="control-button-dark"
                            style={{ width: "38px", height: "38px" }}
                        >
                            <HomeIcon />
                        </button>
                    </Box>
                    <Box sx={{ position: "relative", top: "10px" }}>
                        <MapInfoCard map={map} />
                    </Box>
                </MapFullScreen>
            </Box>
            {components.length === 0 && <CircularProgress sx={{ position: "absolute" }} />}
        </Box>
    );
}
