import { useEffect } from "react";

import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";

import { useUserAuth } from "../../contexts/authContext";

const useWeatherLayer = (map) => {
    const { userMapSettings } = useUserAuth();

    useEffect(() => {
        if (map) {
            const source = new TileWMS({
                url: "https://mapservices.weather.noaa.gov:443/eventdriven/services/radar/radar_base_reflectivity_time/ImageServer/WMSServer",
                params: {
                    LAYERS: "0",
                    FORMAT: "image/png",
                    TRANSPARENT: "true"
                },
                serverType: "mapserver",
                crossOrigin: "anonymous"
            });
            const layer = new TileLayer({
                source: source,
                zIndex: 1,
                visible: false,
                opacity: userMapSettings.weather_opacity || 0,
                properties: {
                    name: "weather"
                }
            });
            map.addLayer(layer);
        }
    }, [map]);
};

export default useWeatherLayer;
