import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid } from "@mui/x-data-grid";
import { useUserAuth } from "../contexts/authContext";
import { ConvertISOToDate } from "../util";
import { CustomPagination } from "../customPagination";

export function CreateOrg(props) {
    const [open, setOpen] = React.useState(props.open);
    const [addOrg, setAddOrg] = React.useState(false);
    const [newOrg, setNewOrg] = React.useState("");
    const { organizations, getOrganizations, handleFailedFetch } = useUserAuth();

    const handleClose = () => {
        setOpen(false);
        props.close();
    };

    const handleAddOrg = () => {
        setNewOrg("");
        setAddOrg(true);
    };

    const handleNewOrgChange = (event) => {
        setNewOrg(event.target.value);
    };

    const handleSaveOrg = () => {
        //Save to database
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ name: newOrg })
        };

        fetch("/api/org/insert", requestOptions)
            .then((response) => {
                response.json();
                getOrganizations();
            })
            .catch((err) => handleFailedFetch(err));

        setAddOrg(false);
    };

    const columns = [
        {
            field: "name",
            headerName: "Organization",
            flex: 1,
            minWidth: 200
        },
        {
            field: "date_created",
            headerName: "Date Created",
            minWidth: 200,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        }
    ];

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" data-testid="dialog">
            <DialogTitle>Add/View Organizations</DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: "5px", display: "flex", flexDirection: "column", gap: "5px", minWidth: 410 }}>
                    <Box>
                        <DataGrid
                            disableVirtualization
                            rows={organizations}
                            columns={columns}
                            disableRowSeletionOnClick
                            autoHeight
                            slots={{ pagination: CustomPagination }}
                            initialState={{ pagination: { paginationModel: { pageSize: 6 } } }}
                            sx={{
                                bgcolor: "#121212"
                            }}
                        />
                    </Box>
                    {addOrg ? (
                        <Box sx={{ marginTop: "5px", display: "flex", flexDirection: "column", gap: "5px", minWidth: 400 }}>
                            <TextField autoFocus margin="dense" id="name" label="Organization" fullWidth variant="standard" onChange={handleNewOrgChange} />
                            <Button sx={{ marginRight: "auto" }} variant="contained" color="primary" onClick={handleSaveOrg} data-testid="save">
                                Save
                            </Button>
                        </Box>
                    ) : (
                        <Button sx={{ marginRight: "auto" }} variant="contained" color="primary" onClick={handleAddOrg} data-testid="add">
                            Add Organization
                        </Button>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
