import React, { useEffect, useState } from "react";
import { ConvertISOToDate } from "../util";
import HomeIcon from "@mui/icons-material/Home";

import MapFullScreen from "../map/mapFullScreen";
import MapInfoCard from "../map/mapInfoCard";
import { getSystemColorFromType } from "./hmsUtil";
import { convertHexColorToRgbaColor, JumpToFeatures } from "../map/mapUtil";
import { useUserAuth } from "../contexts/authContext";
import useOpenLayersMap from "../hooks/useOpenLayersMap";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { GeometryCollection } from "ol/geom";

// component takes in 2 props
// - mapSystem - system to display
// - setMapSystem - sets the system to display

function HMSMapComponent({ mapSystem }) {
    const MAP_ID = "hms-map-preview";
    const map = useOpenLayersMap(MAP_ID, false);
    const { userMapSettings } = useUserAuth();

    useEffect(() => {
        if (!map) return;

        const features = [];
        let index = 0;
        mapSystem.status_areas.forEach(({ polygons, status_type }) => {
            const geometries = polygons.map((p) => new Polygon([p.map(({ lng, lat }) => [lng, lat])]));
            const feature = new Feature(new GeometryCollection(geometries));
            feature.setId("HIMS " + (index + 1));
            feature.set("name", mapSystem.name.toUpperCase().replace(/_/g, " "));
            feature.set("hms", { mapSystem: mapSystem, status_type: status_type });

            const color = getSystemColorFromType(status_type);
            const style = new Style({
                fill: new Fill({
                    color: convertHexColorToRgbaColor(color, 0.9)
                }),
                stroke: new Stroke({
                    color: convertHexColorToRgbaColor(color, 1),
                    width: 2
                })
            });
            feature.setStyle(style);
            features.push(feature);

            index += 1;
        });

        const vectorLayer = new VectorLayer({
            source: new VectorSource({ features: features }),
            name: "hims",
            zIndex: 5,
            properties: { selectable: true }
        });
        map.addLayer(vectorLayer);

        JumpToFeatures(map, features);
    }, [mapSystem]);

    const handleHomeButtonClick = () => {
        if (map && map.getView()) {
            map.getView().setCenter([userMapSettings.longitude, userMapSettings.latitude]);
            map.getView().setZoom(12);
        }
    };

    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            <MapFullScreen
                map_id={MAP_ID}
                map={map}
                buttons={[
                    {
                        id: "viewHome",
                        name: "View Home",
                        visible: true,
                        icon: <HomeIcon />,
                        onClick: handleHomeButtonClick
                    }
                ]}
            >
                <MapInfoCard map={map} showButtons={false} />
            </MapFullScreen>
        </Box>
    );
}

export default function HmsMapPreview({ mapSystem, setMapSystem }) {
    const [open, setOpen] = useState(false);
    const [systems, setSystems] = useState(null);

    useEffect(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        if (open) {
            setSystems(mapSystem);
        }
    }, [open]);

    return (
        <Dialog open={true} onClose={() => setMapSystem(null)} maxWidth="md" fullWidth>
            <DialogTitle>{mapSystem.name.toUpperCase().replace(/_/g, " ")}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1, pb: 0 }}>
                <Box sx={{ position: "relative", height: "500px", mb: 1 }}>
                    <HMSMapComponent mapSystem={systems} />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Status: </b>
                    <span style={{ color: getSystemColorFromType(mapSystem.status) }}>{mapSystem.status}</span>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Date Reported: </b>
                    <span>{ConvertISOToDate(mapSystem.date_reported)}</span>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Total Components: </b>
                    <span>{mapSystem.hms_components ? mapSystem.hms_components.length : 0}</span>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setMapSystem(null)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
