import React from "react";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useUserAuth } from "../contexts/authContext";
import { useSocket } from "../contexts/socketContext";

export const ConversationContextMenu = (props) => {
    const { user, handleFailedFetch } = useUserAuth();
    const { setMessages, visibleConversations, setSelectedConversationUUID } = useSocket();

    const handleOpenClicked = () => {
        props.handleSelectedConversationChange(props.conversationContextMenuUUID);
        props.setConversationContextMenuOpen(false);
    };

    const handleDeleteClicked = () => {
        function selectNextConversation() {
            const conversationIndex = visibleConversations.findIndex((convo) => convo.conversation_uuid === props.conversationContextMenuUUID);
            const nextConversation = visibleConversations[conversationIndex + 1];
            const prevConversation = visibleConversations[conversationIndex - 1];

            if (nextConversation) {
                setSelectedConversationUUID(nextConversation.conversation_uuid);
            } else if (prevConversation) {
                setSelectedConversationUUID(prevConversation.conversation_uuid);
            } else {
                setSelectedConversationUUID(null);
            }
        }

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                conversation_uuid: props.conversationContextMenuUUID,
                user_id: user.id
            })
        };
        fetch("/api/messages/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                selectNextConversation();
                setMessages((prev) => {
                    const messages = [...prev];
                    messages.filter((m) => m.conversation_uuid === props.conversationContextMenuUUID).forEach((m) => (m.deleted_message = true));
                    return messages;
                });
                props.setConversationContextMenuOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };

    return (
        <Popper open={props.conversationContextMenuOpen} anchorEl={props.conversationContextMenuAnchorEl} placement={"bottom-end"} style={{ zIndex: 1500 }}>
            {() => (
                <Paper onContextMenu={(e) => e.preventDefault()}>
                    <ClickAwayListener onClickAway={() => props.setConversationContextMenuOpen(false)}>
                        <MenuList dense sx={{ maxHeight: 200, overflow: "auto" }}>
                            <MenuItem onClick={handleOpenClicked}>Open</MenuItem>
                            <MenuItem onClick={handleDeleteClicked}>Delete</MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            )}
        </Popper>
    );
};
