import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { useUserAuth } from "../contexts/authContext";
import { ConvertISOToDate, FormatPhoneNumber, IsInvalidEmail, IsInvalidPhoneNumber } from "../util";
import { useSocket } from "../contexts/socketContext";

export function UserInfo() {
    const { user, updateInfo, handleFailedFetch, snackbar, setSnackbar } = useUserAuth();
    const { socket } = useSocket();
    const [disabled, setDisabled] = useState(true);
    const [showToolbar, setShowToolbar] = useState(false);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [phoneNumber, setPhoneNumber] = useState(user.phone_number || "");
    const [org] = useState(user.organization);
    const [currentPassword, setCurrentPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [pilotUUID, setPilotUUID] = useState(user.pilot_uuid || "");
    const [licenseNumber, setLicenseNumber] = useState(user.license_number || "");
    const [licenseType, setLicenseType] = useState(user.license_type || "");
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #202020 inset" };

    const handleEdit = () => {
        setDisabled(false);
        setShowToolbar(true);
    };

    const handleCancel = () => {
        resetData();
        setDisabled(true);
        setShowToolbar(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const updatedInfo = { ...user };
        updatedInfo.first_name = firstName;
        updatedInfo.last_name = lastName;
        updatedInfo.email = email.trim();
        updatedInfo.phone_number = phoneNumber;
        updatedInfo.pilot_uuid = pilotUUID ? pilotUUID : null;
        updatedInfo.license_number = licenseNumber;
        updatedInfo.license_type = licenseType;
        updatedInfo.organization = org;

        //If email address has changed, make sure its not already in the database.
        let isError = false;
        if (updatedInfo.email != user.email) {
            await fetch("api/user/check/" + updatedInfo.email)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => {
                    if (data.found) {
                        setSnackbar({ children: "The entered email is already associated with another account", severity: "error" });
                        isError = true;
                    }
                })
                .catch((err) => handleFailedFetch(err));
        }

        if (IsInvalidEmail(email)) {
            setSnackbar({ children: "The provided email is invalid", severity: "error" });
            isError = true;
        }
        if (pilotUUID && (!licenseNumber || !licenseType)) {
            setSnackbar({ children: "A license number and type is required for pilots", severity: "error" });
            isError = true;
        }
        if (IsInvalidPhoneNumber(phoneNumber)) {
            setSnackbar({ children: "Please enter a valid phone number", severity: "error" });
            isError = true;
        }
        if (isError) {
            return false;
        }
        setSnackbar({ children: "User successfully updated", severity: "success" });
        updateInfo(updatedInfo);
        socket.emit("storeUserChatSocket", updatedInfo);
        setDisabled(true);
        setShowToolbar(false);
    };

    const handleOpenPasswordDialog = () => {
        setNewPasswordError("");
        setCurrentPasswordError("");
        setOpenPasswordDialog(true);
    };

    const handleSubmitPasswordDialog = async () => {
        setNewPasswordError("");
        setCurrentPasswordError("");

        const updatedUser = { ...user };
        updatedUser.currentPassword = currentPassword;
        updatedUser.newPassword = newPassword;
        updatedUser.confirmPassword = confirmPassword;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };

        // update password
        await fetch("/api/user/update/password", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                if (data.correctPassword === false) setCurrentPasswordError("The provided password is incorrect");
                else if (data.passwordsMatch === false) setNewPasswordError("The two passwords must match.");
                else if (data.passwordValidated === false)
                    setNewPasswordError("New password must contain at least 8 characters, and an uppercase, lowercase, numeric, and special character.");
                else {
                    setSnackbar({ children: data.message, severity: data.severity });
                    setOpenPasswordDialog(false);
                }
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handlePilotChange = () => {
        if (pilotUUID) {
            return;
        }

        setPilotUUID(uuidv4());
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        const phoneNumber = FormatPhoneNumber(value);
        setPhoneNumber(phoneNumber);
    };

    const resetData = () => {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setPhoneNumber(user.phone_number || "");
        setPilotUUID(user.pilot_uuid || "");
        setLicenseNumber(user.license_number || "");
        setLicenseType(user.license_type || "");
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "700px !important", pt: 3, px: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                <AccountCircleIcon /> User Profile
            </Typography>
            <Paper sx={{ p: 2, mt: 2 }}>
                <Box component="form" display="grid" gap="10px" sx={{ mb: 0 }}>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                        <TextField
                            disabled={disabled}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            margin="normal"
                            id="first_name"
                            label="First Name"
                            inputProps={{ style: inputStyle }}
                            fullWidth
                        />
                        <TextField
                            disabled={disabled}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            margin="normal"
                            id="last_name"
                            label="Last Name"
                            inputProps={{ style: inputStyle }}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                        <TextField
                            disabled={disabled}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={snackbar && IsInvalidEmail(email)}
                            margin="normal"
                            id="email"
                            label="Email Address"
                            required
                            inputProps={{ style: inputStyle }}
                            fullWidth
                        />
                        <TextField
                            disabled={disabled}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            error={snackbar && IsInvalidPhoneNumber(phoneNumber)}
                            margin="normal"
                            id="phone_number"
                            label="Phone Number"
                            inputProps={{ style: inputStyle }}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                        <TextField
                            disabled={true}
                            value={"********"}
                            margin="normal"
                            label="Password"
                            type="password"
                            InputProps={
                                disabled
                                    ? {}
                                    : {
                                          endAdornment: (
                                              <Button
                                                  onClick={handleOpenPasswordDialog}
                                                  data-testid="changePassword"
                                                  id="change_password"
                                                  sx={{ fontSize: "small", width: { xs: "350px", sm: "200px" } }}
                                              >
                                                  Change Password
                                              </Button>
                                          )
                                      }
                            }
                            fullWidth
                        />
                        <TextField disabled={true} value={org} margin="normal" id="org" label="Organization" inputProps={{ style: inputStyle }} fullWidth />
                    </Box>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                        <TextField
                            disabled={true}
                            value={user.user_role}
                            margin="normal"
                            id="role"
                            label="User Role"
                            inputProps={{ style: inputStyle }}
                            fullWidth
                        />
                        <TextField
                            disabled={true}
                            value={ConvertISOToDate(user.last_login)}
                            margin="normal"
                            id="last_login"
                            label="Last Login"
                            inputProps={{ style: inputStyle }}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                        <FormControlLabel
                            control={<Checkbox disabled={disabled} checked={pilotUUID !== ""} onChange={handlePilotChange} id="pilot" />}
                            label="Pilot"
                            sx={{ mt: "13px" }}
                        />
                        {pilotUUID ? (
                            <Box sx={{ flex: "auto", display: "flex", gap: "20px" }}>
                                <TextField
                                    disabled={disabled || !pilotUUID}
                                    value={licenseNumber}
                                    onChange={(e) => setLicenseNumber(e.target.value)}
                                    error={snackbar && pilotUUID && !licenseNumber}
                                    margin="normal"
                                    id="license_number"
                                    label="License Number"
                                    inputProps={{ style: inputStyle }}
                                    fullWidth
                                />
                                <TextField
                                    disabled={disabled || !pilotUUID}
                                    value={licenseType}
                                    onChange={(e) => setLicenseType(e.target.value)}
                                    error={snackbar && pilotUUID && !licenseType}
                                    margin="normal"
                                    id="license_type"
                                    label="License Type"
                                    inputProps={{ style: inputStyle }}
                                    fullWidth
                                />
                            </Box>
                        ) : (
                            <Box></Box>
                        )}
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <FormControlLabel control={<Checkbox disabled checked={user.active} />} label="Active" />
                        <Box sx={{ ml: "auto" }}>
                            {!showToolbar ? (
                                <Box display="flex" gap="10px">
                                    <Button onClick={handleEdit} variant="contained" id="edit">
                                        Edit
                                    </Button>
                                </Box>
                            ) : (
                                <Box display="flex" gap="10px">
                                    <Button onClick={handleCancel} variant="contained" data-testid="cancel" id="cancel">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} variant="contained" data-testid="save" id="save">
                                        Save
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Paper>

            <Dialog open={openPasswordDialog}>
                <DialogTitle sx={{ pb: "5px !important" }}>Change Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        label="Current Password"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        helperText={currentPasswordError}
                        error={currentPasswordError.length ? true : false}
                        inputProps={{ "data-testid": "currentPassword" }}
                        type="password"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="current_password"
                    />
                    <TextField
                        label="New Password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        error={newPasswordError.length ? true : false}
                        inputProps={{ "data-testid": "newPassword" }}
                        type="password"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="new_password"
                    />
                    <TextField
                        label="Confirm Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={newPasswordError.length ? true : false}
                        inputProps={{ "data-testid": "confirmPassword" }}
                        helperText={newPasswordError}
                        type="password"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="confirm_password"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPasswordDialog(false)} id="cancel_password">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitPasswordDialog} data-testid="submitPassword" id="submit_password">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
