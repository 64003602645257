import React, { useState, useEffect, useRef } from "react";
import * as turf from "@turf/turf";
import { toJpeg } from "html-to-image";

import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import MapIcon from "@mui/icons-material/Map";
import HomeIcon from "@mui/icons-material/Home";
import ClearIcon from "@mui/icons-material/Clear";
import LayersIcon from "@mui/icons-material/Layers";
import ModeOutlined from "@mui/icons-material/ModeOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";

import Collection from "ol/Collection";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import Translate from "ol/interaction/Translate";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import LineString from "ol/geom/LineString";
import Polygon, { circular } from "ol/geom/Polygon";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";

import MapFullScreen from "./mapFullScreen";
import MapInfoCard from "./mapInfoCard";
import MapLaancLayersDropdown from "./mapLaancLayersDropdown";
import MapTileDropdown from "./mapTileDropdown";

import { ConvertFeetToMeters, ConvertMetersToFeet, GetColorFromState } from "../util";
import { getGeofenceFeatureFromGeometryAndBuffer, GetCoordinatesForSubmissions, ConvertKmlFileToFeatures, ConvertGeometryToGeoJson } from "../map/mapUtil";
import { convertHexColorToRgbaColor, GetAllFeaturesWithId, JumpToFeature, JumpToFeatures, GetLayer, ConvertCmpVolumesToFeatures } from "../map/mapUtil";
import { ConvertLAANCVolumesToFeatures, GetFeature, GetGeofenceStyle, GetEditVolumeStyle } from "../map/mapUtil";

import { getUpdatedWaypointsFromCoordinatesAndDefaultAltitude, getWaypointFeatureStyleFromFeatureAndSettings } from "../map/mapUtil";
import { getGeofenceCoordinatesFromCoordinatesTypeAndBuffer } from "../map/mapUtil";

import useOpenLayersMap from "../hooks/useOpenLayersMap";

import { useMap } from "../contexts/mapContext";
import { useUserAuth } from "../contexts/authContext";

const DrawType = {
    UPLOAD: {
        name: "Upload",
        icon: <FileUploadOutlinedIcon sx={{ width: "100%" }} />
    },
    AUTOMATIC: {
        name: "Automatic",
        icon: <HdrAutoIcon sx={{ width: "100%" }} />
    },
    POLYGON: {
        name: "Polygon",
        icon: <ModeOutlined sx={{ width: "100%" }} />
    },
    WAYPOINTS: {
        name: "Waypoints",
        icon: <PolylineOutlinedIcon sx={{ width: "100%" }} />
    },
    CIRCLE: {
        name: "Circle",
        icon: <PlaceOutlinedIcon sx={{ width: "100%" }} />
    },
    CLEAR: {
        name: "Clear",
        icon: <ClearIcon sx={{ width: "100%" }} />
    }
};

export const VolumeType = {
    FLIGHT: {
        name: "Create Flight",
        description: "Map used to create flights.",
        layers: {
            operations: true,
            constraints: true,
            alerts: false,
            airspaces: true,
            laanc: true
        },
        drawTypes: [DrawType.UPLOAD, DrawType.POLYGON, DrawType.WAYPOINTS, DrawType.CIRCLE, DrawType.CLEAR]
    },
    CONSTRAINT: {
        name: "Create Constraint",
        description: "Map used to create constraints.",
        layers: {
            operations: true,
            constraints: true,
            alerts: false,
            airspaces: true,
            laanc: false
        },
        drawTypes: [DrawType.UPLOAD, DrawType.POLYGON, DrawType.CIRCLE, DrawType.CLEAR]
    },
    ALERT: {
        name: "Create Alert",
        description: "Map used to create alerts.",
        layers: {
            operations: true,
            constraints: false,
            alerts: true,
            airspaces: true,
            laanc: false
        },
        drawTypes: [DrawType.UPLOAD, DrawType.AUTOMATIC, DrawType.POLYGON, DrawType.CIRCLE, DrawType.CLEAR]
    },
    TEST: {
        name: "Map Draw",
        description: "Used for unit testing.",
        layers: {
            operations: false,
            constraints: false,
            alerts: false,
            airspaces: false,
            laanc: false
        },
        drawTypes: [DrawType.UPLOAD, DrawType.AUTOMATIC, DrawType.WAYPOINTS, DrawType.POLYGON, DrawType.CIRCLE, DrawType.CLEAR]
    }
};

export function MapDrawComponent(props) {
    const MAP_ID = "map-draw-map";

    const [volumeType, setVolumeType] = useState(null);
    const [drawType, setDrawType] = useState("Polygon");
    const [alertOperation, setAlertOperation] = useState(null);

    const { userMapSettings, setSnackbar } = useUserAuth();
    const { colors } = useMap();

    const [mapLayersOpen, setMapLayersOpen] = useState(false);
    const [mapLayersAnchorEl, setMapLayersAnchorEl] = useState(null);

    const [mapTileDropdownOpen, setMapTileDropdownOpen] = useState(false);
    const [mapTileDropdownAnchorEl, setMapTileDropdownAnchorEl] = useState(null);

    const [selectedLaancFeature, setSelectedLaancFeature] = useState(null);
    const [clearFeature, setClearFeature] = useState(false);

    const map_location_ref = useRef(null);
    const default_waypoint_altitude_ref = useRef(200);

    const volume_upload_ref = useRef(null);
    const volume_vector_layer_ref = useRef(null);

    const volume_feature_ref = useRef(null); // represents the operational volume that will be submitted
    const volume_automatic_feature_ref = useRef(null); // for automatic buffer features
    const volume_waypoint_feature_ref = useRef(null); // represents the flight path feature
    const volume_geofence_buffer_ref = useRef(100);
    const volume_waypoints_ref = useRef([]); // for the waypoints field in the volume submission

    const draw_interaction_ref = useRef(null);
    const translate_interaction_ref = useRef(null);
    const modify_interaction_ref = useRef(null);
    const snap_interaction_ref = useRef(null);

    const map = useOpenLayersMap(MAP_ID, false);

    useEffect(() => {
        if (!map) return;

        const handlePointerMove = ({ originalEvent }) => {
            const coordinates = map.getEventCoordinate(originalEvent);
            if (map_location_ref.current) {
                map_location_ref.current.innerHTML = `( ${coordinates[1]}, ${coordinates[0]} )`;
            }
        };
        map.on("pointermove", handlePointerMove);

        return () => {
            if (!map) return;
            map.un("pointermove", handlePointerMove);
        };
    }, [map]);

    useEffect(() => {
        if (isNaN(props.defaultWaypointAltitudeAglFt) === false) {
            default_waypoint_altitude_ref.current = props.defaultWaypointAltitudeAglFt;
        }
    }, [props.defaultWaypointAltitudeAglFt]);

    useEffect(() => {
        const volumeType = VolumeType[props.type?.toUpperCase()];
        if (volumeType) {
            setVolumeType(volumeType);
        }
    }, [props.type]);

    useEffect(() => {
        if (props.drawType) {
            setDrawType(props.drawType);
        }
    }, [props.drawType]);

    useEffect(() => {
        if (!map || !props.laancVolumes) return;

        handleResetDrawingData();

        const features = ConvertLAANCVolumesToFeatures(props.laancVolumes);
        const layer = GetLayer(map, "laanc_volumes");
        if (layer) {
            const source = layer.getSource();
            if (source) {
                source.clear();
                source.addFeatures(features);
            }
        } else {
            const vectorLayer = new VectorLayer({
                source: new VectorSource({ features: features }),
                name: "laanc_volumes",
                zIndex: 5,
                properties: { selectable: true }
            });
            map.addLayer(vectorLayer);
        }

        JumpToFeatures(map, features);
    }, [map, props.laancVolumes]);

    useEffect(() => {
        if (!map) return;

        const feature = GetFeature(map, "laanc_volumes", props.selectedLAANCVolume);
        setSelectedLaancFeature(feature);
        JumpToFeature(map, feature);
    }, [map, props.selectedLAANCVolume]);

    useEffect(() => {
        if (!map || !props.clearFeature) return;

        setClearFeature(true);
    }, [map, props.clearFeature]);

    useEffect(() => {
        if (!map) return;

        const layerNames = [
            { name: "flights", volumes: props.publishedFlights },
            { name: "constraints", volumes: props.constraints },
            { name: "alerts", volumes: props.alerts }
        ];
        layerNames.forEach(({ name, volumes }) => {
            if (!name || !volumes) {
                return;
            }

            const layer = GetLayer(map, name);
            const features = ConvertCmpVolumesToFeatures(volumes, userMapSettings);
            if (layer) {
                const source = layer.getSource();
                if (source) {
                    source.clear();
                    source.addFeatures(features);
                }
            } else {
                const volumesLayer = new VectorLayer({
                    source: new VectorSource({ features: features }),
                    properties: { name: name }
                });
                map.addLayer(volumesLayer);
            }
        });

        if (alertOperation) {
            GetAllFeaturesWithId(map, "flights", alertOperation.flight_uuid).forEach((f) => {
                const style = f.getStyle();
                style.getStroke().setColor(convertHexColorToRgbaColor("#FFD700", 1));
                f.setStyle(style);
            });
        }
    }, [map, props.publishedFlights, props.constraints, props.alerts]);

    useEffect(() => {
        if (!map || !props.currentEditVolumes) return;

        drawEditVolumes();
    }, [map, props.currentEditVolumes]);

    useEffect(() => {
        if (!map || !props.radius || !volume_feature_ref.current) {
            return;
        }

        const geometry = volume_feature_ref.current.getGeometry();
        if (!geometry || !(geometry instanceof Polygon)) {
            return;
        }

        const center = geometry.getInteriorPoint().getCoordinates();
        const radius_m = ConvertFeetToMeters(props.radius);

        const circle = circular(center, radius_m, 128);
        circle.set("type", "Circle");
        volume_feature_ref.current.setGeometry(circle);

        const geojson = GetCoordinatesForSubmissions(geometry);
        props.setVolumeGeojson(geojson.coordinates);
    }, [map, props.radius]);

    useEffect(() => {
        if (!map) return;

        if (props.canEdit) {
            const layer = GetLayer(map, "laanc_volumes");
            if (layer) map.removeLayer(layer);

            if (!volume_vector_layer_ref.current) {
                handleDrawTypeChange(undefined, "Polygon");
            } else {
                if (props.currentEditVolumes) drawEditVolumes();
            }
        } else {
            handleDrawOff();
        }
    }, [map, props.canEdit]);

    useEffect(() => {
        if (!map || drawType !== "Waypoints") {
            return;
        }
        volume_geofence_buffer_ref.current = props.buffer;

        if (Array.isArray(props.volumeWaypoints) && props.volumeWaypoints.length > 1) {
            const waypoint_coordinates_geojson = props.volumeWaypoints.map(({ lat, lng }) => {
                return [lng, lat];
            });
            // update operational volume
            if (volume_feature_ref.current) {
                const geofence_coordinates = getGeofenceCoordinatesFromCoordinatesTypeAndBuffer(
                    waypoint_coordinates_geojson,
                    props.geofenceType,
                    volume_geofence_buffer_ref.current
                );
                if (geofence_coordinates) {
                    const geofence_geometry = volume_feature_ref.current.getGeometry();
                    geofence_geometry.setCoordinates(geofence_coordinates);

                    const geojson = GetCoordinatesForSubmissions(geofence_geometry);
                    props.setVolumeGeojson(geojson.coordinates);
                }
            }
            // update waypoint feature coordinates
            if (volume_waypoint_feature_ref.current) {
                const waypoint_feature_geometry = volume_waypoint_feature_ref.current.getGeometry();
                waypoint_feature_geometry.setCoordinates(waypoint_coordinates_geojson);

                const waypoint_style = getWaypointFeatureStyleFromFeatureAndSettings(volume_waypoint_feature_ref.current, userMapSettings);
                volume_waypoint_feature_ref.current.setStyle(waypoint_style);
            }
            // update waypoint reference
            volume_waypoints_ref.current = props.volumeWaypoints.map(({ lng, lat, alt_agl_ft }) => {
                return { lng: lng, lat: lat, alt_agl_ft: alt_agl_ft };
            });
        } else if (volume_vector_layer_ref.current) {
            handleResetDrawingData();
        }
    }, [map, props.volumeWaypoints, props.geofenceType, props.buffer]);

    useEffect(() => {
        if (!map || !volume_feature_ref.current || !volume_automatic_feature_ref.current || !props.automaticBuffer || props.automaticBuffer < 0) {
            return;
        }

        const geoJson = ConvertGeometryToGeoJson(volume_feature_ref.current.getGeometry());
        const buffered = turf.buffer(geoJson, props.automaticBuffer, { units: "feet" });
        const coords = buffered.geometry.coordinates;
        const geometry = volume_automatic_feature_ref.current.getGeometry();
        geometry.setCoordinates(coords);
        props.setVolumeGeojson(GetCoordinatesForSubmissions(geometry).coordinates);
    }, [map, props.automaticBuffer]);

    useEffect(() => {
        const op = props.automaticOperation;
        const prevOp = alertOperation;
        if (!map || !op) {
            return;
        }

        // Reset prev op + add op highlight.
        if (op !== prevOp) {
            if (prevOp) {
                GetAllFeaturesWithId(map, "flights", prevOp.flight_uuid).forEach((f) => {
                    const style = f.getStyle();
                    const color = GetColorFromState(f.getProperties().flight.state, userMapSettings.op_state_settings);
                    style.getStroke().setColor(convertHexColorToRgbaColor(color, 1));
                    f.setStyle(style);
                });
            }
            GetAllFeaturesWithId(map, "flights", op.flight_uuid).forEach((f) => {
                const style = f.getStyle();
                style.getStroke().setColor(convertHexColorToRgbaColor("#FFD700", 1));
                f.setStyle(style);
            });
            setAlertOperation(op);
        }

        if (drawType !== "Automatic" || !props.automaticBuffer || props.automaticBuffer < 0) {
            return;
        }

        props.setMinAltitude(ConvertMetersToFeet(op.volumes[0].altitude_min_agl_m));
        props.setMaxAltitude(ConvertMetersToFeet(op.volumes[0].altitude_max_agl_m));

        const feature = ConvertCmpVolumesToFeatures([op], userMapSettings)[0];
        const geoJson = ConvertGeometryToGeoJson(feature.getGeometry());
        const buffered = turf.buffer(geoJson, props.automaticBuffer, { units: "feet" });
        const bufferedFeature = new Feature({ geometry: new Polygon(buffered.geometry.coordinates) });
        bufferedFeature.setStyle(GetGeofenceStyle(userMapSettings));

        volume_feature_ref.current = feature;
        volume_automatic_feature_ref.current = bufferedFeature;
        props.setVolumeGeojson(GetCoordinatesForSubmissions(bufferedFeature.getGeometry()).coordinates);

        if (volume_vector_layer_ref.current) {
            map.removeLayer(volume_vector_layer_ref.current);
        }
        volume_vector_layer_ref.current = new VectorLayer({
            source: new VectorSource({ features: [volume_automatic_feature_ref.current] }),
            zIndex: 1
        });
        map.addLayer(volume_vector_layer_ref.current);
        JumpToFeature(map, volume_automatic_feature_ref.current);
    }, [props.automaticOperation, drawType]);

    useEffect(() => {
        if (!props.volumeGeojson || !volume_vector_layer_ref.current) {
            return;
        }

        const color = colors.find((color) => color.id === props.colorId);
        const colorHex = color ? color.color_rgb : null;
        const style = GetEditVolumeStyle(userMapSettings, colorHex);
        volume_vector_layer_ref.current.getSource().forEachFeature((f) => f.setStyle(style));
    }, [props.volumeGeojson, props.colorId]);

    useEffect(() => {
        if (!drawType) {
            return;
        }

        // If a volume isn't fully draw yet, we will reset it with the new color.
        if (!props.volumeGeojson || !volume_vector_layer_ref.current) {
            handleDrawTypeChange(null, drawType);
        }
    }, [props.colorId, colors]);

    useEffect(() => {
        if (!map || !props.getScreenshot) return;

        const filter = (node) => {
            const exclusionClasses = ["MuiSvgIcon-root"];
            return !exclusionClasses.some((classname) => node.classList?.contains(classname));
        };

        map.once("rendercomplete", function () {
            toJpeg(map.getTargetElement(), { quality: 0.95, filter: filter, skipFonts: true }).then(function (dataURL) {
                props.setPrintMap(dataURL);
            });
        });
    }, [map, props.getScreenshot]);

    const drawEditVolumes = () => {
        handleResetDrawingData();
        const features = ConvertCmpVolumesToFeatures([props.currentEditVolumes], userMapSettings);
        handleDrawFeatures(features);
    };

    const handleToggleLayersButtonClick = (e) => {
        setMapLayersAnchorEl(e.currentTarget);
        setMapLayersOpen((prev) => !prev);
    };
    const handleDrawTypeChange = (e, value) => {
        if (!map) {
            return;
        } else if (!value) {
            if (drawType === "Upload") {
                volume_upload_ref.current.click();
            }
            return;
        }

        handleResetDrawingData();
        setDrawType(value);
        props.changeDrawType(value);

        if (value === "Upload") {
            volume_upload_ref.current.click();
            return;
        } else if (value === "Clear") {
            return;
        }

        if (value === "Automatic") {
            return;
        }

        draw_interaction_ref.current = new Draw({
            source: new VectorSource({ wrapX: false }),
            type: value === "Waypoints" ? "LineString" : value === "Circle" ? "Point" : value,
            style: GetEditVolumeStyle(userMapSettings, colors.find((color) => color.id === props.colorId)?.color_rgb)
        });
        draw_interaction_ref.current.on("drawend", handleDrawEnd);
        map.addInteraction(draw_interaction_ref.current);
    };

    const handleDrawEnd = ({ feature }) => {
        if (draw_interaction_ref.current) {
            map.removeInteraction(draw_interaction_ref.current);
            draw_interaction_ref.current = null;
        }
        const editable_features = new Collection([feature]);
        const vector_layer_features = new Collection([feature]);

        // extract data from the drawn feature
        let geometry = feature.getGeometry();
        if (geometry instanceof LineString) {
            volume_waypoint_feature_ref.current = feature;

            const waypoint_style = getWaypointFeatureStyleFromFeatureAndSettings(feature, userMapSettings);
            volume_waypoint_feature_ref.current.setStyle(waypoint_style);

            const waypoint_coordinates = geometry.getCoordinates();
            volume_waypoints_ref.current = waypoint_coordinates.map(([lng, lat]) => {
                return { lng: lng, lat: lat, alt_agl_ft: default_waypoint_altitude_ref.current };
            });
            const geofence_feature = getGeofenceFeatureFromGeometryAndBuffer(geometry, props.buffer, userMapSettings);
            vector_layer_features.push(geofence_feature);

            volume_feature_ref.current = geofence_feature;
            geometry = geofence_feature.getGeometry();
        } else {
            const style = GetEditVolumeStyle(userMapSettings);
            feature.setStyle(style);
            volume_feature_ref.current = feature;
        }
        if (geometry instanceof Point) {
            geometry = circular(geometry.getCoordinates(), ConvertFeetToMeters(1000), 128);
            geometry.set("type", "Circle");
            feature.set("type", "Circle");
            feature.setGeometry(geometry);
            props.setRadius(1000);
        }
        JumpToFeature(map, feature);
        if (props.setMobileDrawerOpen) {
            props.setMobileDrawerOpen(true);
        }

        const geojson = GetCoordinatesForSubmissions(geometry);
        props.setVolumeGeojson(geojson.coordinates);
        if (props.setVolumeWaypoints) {
            props.setVolumeWaypoints(volume_waypoints_ref.current);
        }

        // add the feature to the map
        volume_vector_layer_ref.current = new VectorLayer({
            source: new VectorSource({ features: vector_layer_features }),
            zIndex: 1
        });
        map.addLayer(volume_vector_layer_ref.current);

        // add the ability to move the feature
        translate_interaction_ref.current = new Translate({
            filter: (f) => f === feature
        });
        map.addInteraction(translate_interaction_ref.current);
        translate_interaction_ref.current.on("translateend", handleGeometryUpdate);

        // add the ability to edit or add vertices
        if (feature.get("type") !== "Circle") {
            modify_interaction_ref.current = new Modify({
                features: editable_features,
                deleteCondition: (event) => event.originalEvent.button === 2
            });
            map.addInteraction(modify_interaction_ref.current);
            modify_interaction_ref.current.on("modifyend", handleGeometryUpdate);
        }

        // add vertex snapping
        snap_interaction_ref.current = new Snap({
            features: editable_features
        });
        map.addInteraction(snap_interaction_ref.current);
    };

    const handleGeometryUpdate = ({ features }) => {
        if (features.getLength() < 1) {
            return;
        }
        const feature = features.item(0); // this may need to be updated??

        let geometry = feature.getGeometry();
        if (geometry instanceof LineString) {
            // update the waypoint feature style
            const updated_style = getWaypointFeatureStyleFromFeatureAndSettings(feature, userMapSettings);
            feature.setStyle(updated_style);
            volume_waypoint_feature_ref.current = feature;

            // update the geofence feature
            const waypoint_coordinates = geometry.getCoordinates();
            if (volume_feature_ref.current && volume_geofence_buffer_ref.current) {
                const geofence_coordinates = getGeofenceCoordinatesFromCoordinatesTypeAndBuffer(
                    waypoint_coordinates,
                    props.geofenceType,
                    volume_geofence_buffer_ref.current
                );
                if (geofence_coordinates) {
                    const geofence_geometry = volume_feature_ref.current.getGeometry();
                    geofence_geometry.setCoordinates(geofence_coordinates);
                    geometry = geofence_geometry;
                }
            }
            // update the waypoint reference for submission
            const updated_flight_waypoints = getUpdatedWaypointsFromCoordinatesAndDefaultAltitude(
                waypoint_coordinates,
                volume_waypoints_ref.current,
                default_waypoint_altitude_ref.current
            );
            volume_waypoints_ref.current = updated_flight_waypoints;

            if (props.setVolumeWaypoints) {
                props.setVolumeWaypoints(updated_flight_waypoints);
            }
        } else {
            volume_feature_ref.current = feature;
        }
        const geojson = GetCoordinatesForSubmissions(geometry);
        props.setVolumeGeojson(geojson.coordinates);
    };

    const handleGeometriesUpdate = () => {
        const layer = volume_vector_layer_ref.current;
        const source = layer.getSource();
        const features = source.getFeatures();

        const coords = [];
        features.forEach((feature) => {
            coords.push(GetCoordinatesForSubmissions(feature.getGeometry()).coordinates[0]);
        });

        props.setVolumeGeojson(coords);
        if (props.setVolumeWaypoints) {
            props.setVolumeWaypoints([]);
        }
    };

    const handleResetDrawingData = () => {
        if (volume_vector_layer_ref.current) {
            map.removeLayer(volume_vector_layer_ref.current);
            volume_vector_layer_ref.current = null;
        }
        handleDrawOff();
        volume_feature_ref.current = null;
        volume_waypoint_feature_ref.current = null;
        volume_waypoints_ref.current = [];

        props.setVolumeGeojson([]);
        if (props.setVolumeWaypoints) {
            props.setVolumeWaypoints([]);
        }
    };

    const handleDrawOff = () => {
        if (draw_interaction_ref.current) {
            map.removeInteraction(draw_interaction_ref.current);
            draw_interaction_ref.current = null;
        }
        if (modify_interaction_ref.current) {
            map.removeInteraction(modify_interaction_ref.current);
            modify_interaction_ref.current = null;
        }
        if (snap_interaction_ref.current) {
            map.removeInteraction(snap_interaction_ref.current);
            snap_interaction_ref.current = null;
        }
        if (translate_interaction_ref.current) {
            map.removeInteraction(translate_interaction_ref.current);
            translate_interaction_ref.current = null;
        }
    };

    const handleDrawFeatures = (features) => {
        const coords = [];
        features.forEach((feature) => {
            feature.set("edit", true);
            feature.setStyle(GetEditVolumeStyle(userMapSettings));
            const feature_coord = GetCoordinatesForSubmissions(feature.getGeometry());
            coords.push(feature_coord.coordinates[0]);
        });

        props.setVolumeGeojson(coords);

        // add the feature to the map
        const source = new VectorSource({ features: features });
        volume_vector_layer_ref.current = new VectorLayer({
            source: source,
            zIndex: 1
        });
        map.addLayer(volume_vector_layer_ref.current);
        JumpToFeatures(map, features);

        if (props.drawType === "Circle") {
            volume_feature_ref.current = features[0];
        } else {
            modify_interaction_ref.current = new Modify({ source: source });
            map.addInteraction(modify_interaction_ref.current);
            modify_interaction_ref.current.on("modifyend", handleGeometriesUpdate);
        }
    };

    const handleUpload = (files) => {
        ConvertKmlFileToFeatures(files[0])
            .then((features) => {
                props.setName(features[0].get("name"));
                props.setMinAltitude(ConvertMetersToFeet(features[0].get("altitudes").minAltitude));
                props.setMaxAltitude(ConvertMetersToFeet(features[0].get("altitudes").maxAltitude));
                handleDrawFeatures(features);
            })
            .catch((error) => {
                setSnackbar({ children: error, severity: "error" });
            });
    };

    const handleMapTileDropdownButtonClick = (e) => {
        setMapTileDropdownOpen((prev) => !prev);
        setMapTileDropdownAnchorEl(e.currentTarget);
    };

    const handleHomeButtonClick = () => {
        if (map && map.getView()) {
            map.getView().setCenter([userMapSettings.longitude, userMapSettings.latitude]);
            map.getView().setZoom(12);
        }
    };

    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            <MapFullScreen
                map_id={MAP_ID}
                map={map}
                buttons={[
                    {
                        name: "Toggle Layers",
                        visible: true,
                        icon: <LayersIcon />,
                        onClick: handleToggleLayersButtonClick
                    },
                    {
                        id: "toggleMapTiles",
                        name: "Map Tiles",
                        visible: true,
                        icon: <MapIcon />,
                        onClick: handleMapTileDropdownButtonClick
                    },
                    {
                        id: "viewHome",
                        name: "View Home",
                        visible: true,
                        icon: <HomeIcon />,
                        onClick: handleHomeButtonClick
                    }
                ]}
            >
                <MapInfoCard map={map} showButtons={false} featureToShow={selectedLaancFeature} clearFeature={clearFeature} />
            </MapFullScreen>

            <Typography
                ref={map_location_ref}
                variant="caption"
                sx={{ position: "absolute", right: "0.5em", bottom: ".5em", zIndex: 1, textShadow: "0 0 10px #000" }}
            />
            <input
                type="file"
                ref={volume_upload_ref}
                style={{ display: "none" }}
                onChange={(e) => handleUpload(e.target.files)}
                onClick={(e) => (e.target.value = "")}
                id="fileUpload"
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    position: "absolute",
                    left: "15px",
                    top: "15px",
                    zIndex: 100,
                    background: "#121212"
                }}
            >
                {props.canEdit ? (
                    <ToggleButtonGroup orientation="vertical" color="primary" value={drawType} onChange={handleDrawTypeChange} exclusive sx={{ width: "48px" }}>
                        {volumeType?.drawTypes.map((drawType, i) => (
                            <Tooltip key={i} title={drawType.name} placement="right">
                                <ToggleButton value={drawType.name} id={drawType.name.toLowerCase()}>
                                    {drawType.icon}
                                </ToggleButton>
                            </Tooltip>
                        ))}
                    </ToggleButtonGroup>
                ) : (
                    <></>
                )}
            </Box>

            <MapLaancLayersDropdown
                map={map}
                mapLayersOpen={mapLayersOpen}
                setMapLayersOpen={setMapLayersOpen}
                mapLayersAnchorEl={mapLayersAnchorEl}
                setMapLayersAnchorEl={setMapLayersAnchorEl}
                layers={volumeType?.layers}
            />
            <MapTileDropdown
                mapTileDropdownOpen={mapTileDropdownOpen}
                setMapTileDropdownOpen={setMapTileDropdownOpen}
                mapTileDropdownAnchorEl={mapTileDropdownAnchorEl}
            />
        </Box>
    );
}
