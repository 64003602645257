import { Dialog, DialogContent, DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import ReactJson from "@microlink/react-json-view";

export const JsonDialog = (props) => {
    const handleClose = () => {
        props.setJsonOpen(false);
    };
    return (
        <Dialog open={props.jsonOpen} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogContent>
                <ReactJson src={props.selectedJson} theme="bright" style={{ backgroundColor: "inherit" }} displayDataTypes={false} displayObjectSize={false} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} data-testid="close">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
