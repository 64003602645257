import React, { useEffect, useState } from "react";

import BadgeIcon from "@mui/icons-material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useDrawEdit from "../openlayers/hooks/useDrawEdit";

import { Divider, IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { v4 as uuid_v4 } from "uuid";
import { useUserAuth } from "../contexts/authContext";
import { useSocket } from "../contexts/socketContext";
import { GetSource } from "../openlayers/utils/olUtil";

// Regular expression to check if string is a valid UUID.
const validUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export default function AssetCreator({ map, editFeature, assetCreatorOpen, setAssetCreatorOpen }) {
    const [drawType, setDrawType] = useState("Polygon");
    const [id, setId] = useState(0);
    const [uuid, setUuid] = useState(uuid_v4());
    const [name, setName] = useState("");

    const [uuidError, setUuidError] = useState("");
    const [nameError, setNameError] = useState("");

    const [submitting, setSubmitting] = useState(false);
    const [oldEditFeature, setOldEditFeature] = useState(null);

    const assetCoords = useDrawEdit(map, drawType, editFeature);
    const isDesktop = useMediaQuery("(min-width:600px)");

    const { handleFailedFetch } = useUserAuth();
    const { assets } = useSocket();

    useEffect(() => {
        if (!editFeature?.getProperties()?.asset) {
            return;
        }

        // Add back the old edit feature if a new one is selected to be edited.
        if (oldEditFeature && oldEditFeature !== editFeature) {
            GetSource(map, "assets").addFeature(oldEditFeature);
        }

        const assetProperties = editFeature.get("asset");
        setId(assetProperties.id);
        setUuid(assetProperties.uuid);
        setName(assetProperties.name);
        setOldEditFeature(editFeature);
        GetSource(map, "assets").removeFeature(editFeature);
    }, [editFeature]);

    const handleSubmitClick = () => {
        setUuidError("");
        setNameError("");

        if (!uuid || !validUuid.test(uuid)) {
            return setUuidError("Please enter a valid asset uuid.");
        }
        const foundAsset = assets.find((asset) => asset.uuid === uuid);
        if (foundAsset && (!editFeature || foundAsset !== editFeature.get("asset"))) {
            return setUuidError("This uuid is already taken.");
        }
        if (!name) {
            return setNameError("Please enter an asset name.");
        }
        if (assetCoords.length === 0) {
            return alert("Please create a valid asset volume to continue.");
        }

        setSubmitting(true);

        const asset = {
            id,
            uuid,
            name,
            geometry: {
                type: "Polygon",
                coordinates: assetCoords
            }
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(asset)
        };
        fetch("/api/assets/insertupdate", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setAssetCreatorOpen(false))
            .catch((err) => handleFailedFetch(err));
    };

    const handleDeleteClick = () => {
        if (!confirm("Are you sure you want to delete this asset?")) {
            return;
        }
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        };
        fetch(`/api/assets/delete/${editFeature.get("asset").uuid}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setAssetCreatorOpen(false))
            .catch((err) => handleFailedFetch(err));
    };

    const handleCancelClick = () => {
        setAssetCreatorOpen(false);
        if (editFeature) {
            GetSource(map, "assets").addFeature(editFeature);
        }
    };

    const handleDrawTypeChange = (e, value) => {
        if (value) {
            setDrawType(value);
        }
    };

    return (
        <Drawer
            open={assetCreatorOpen}
            anchor={isDesktop ? "left" : "bottom"}
            variant={isDesktop ? "persistent" : "temporary"}
            hideBackdrop={true}
            sx={{ inset: "unset" }}
            PaperProps={{
                sx: {
                    maxHeight: isDesktop ? "100%" : "400px",
                    position: isDesktop ? "relative" : "fixed",
                    width: isDesktop ? "400px" : "100%"
                }
            }}
        >
            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6">Create Asset</Typography>
                    <IconButton onClick={handleCancelClick}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider />

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant="body1">Draw Method</Typography>
                    <ToggleButtonGroup fullWidth size="small" color="primary" value={drawType} onChange={handleDrawTypeChange} exclusive>
                        <ToggleButton value="Polygon">POLYGON</ToggleButton>
                        <ToggleButton value="Circle">CIRCLE</ToggleButton>
                        <ToggleButton value="Clear">CLEAR</ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant="body1">Asset</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                        <BadgeIcon />
                        <TextField
                            fullWidth
                            variant="standard"
                            label="Asset UUID"
                            value={uuid}
                            onChange={(e) => setUuid(e.target.value)}
                            error={uuidError.length > 0}
                            helperText={uuidError}
                        />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                        <BadgeIcon />
                        <TextField
                            fullWidth
                            variant="standard"
                            label="Asset Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={nameError.length > 0}
                            helperText={nameError}
                        />
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <LoadingButton fullWidth variant="contained" loading={submitting} onClick={handleSubmitClick}>
                        Submit
                    </LoadingButton>
                    {editFeature && (
                        <Button fullWidth variant="outlined" color="error" onClick={handleDeleteClick}>
                            Delete
                        </Button>
                    )}
                    <Button fullWidth variant="text" onClick={handleCancelClick}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
}
