import React, { useState, useEffect } from "react";

import { Document, Page, Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { ConvertISOToDate, ConvertMetersToFeet } from "../../util";

const styles = StyleSheet.create({
    body: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: "40pt"
    },
    headerWrapper: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20pt"
    },
    header: {
        fontFamily: "Times-Roman",
        fontSize: "25pt",
        fontWeight: "bold"
    },
    headerLogo: {
        height: "35pt"
    },
    imageWrapper: {
        marginBottom: "20pt"
    },
    imageCaption: {
        marginTop: "10pt",
        fontFamily: "Times-Roman",
        fontSize: "8pt",
        fontWeight: "bold",
        marginHorizontal: "auto"
    },
    contentWrapper: {
        marginBottom: "20pt"
    },
    content: {
        fontFamily: "Times-Roman",
        fontSize: "12pt",
        lineHeight: "1.5",
        hyphens: "none"
    }
});

export const ApprovalDocument = ({ mapBase64, flightDetailsVolume }) => {
    const start = ConvertISOToDate(flightDetailsVolume.time_start);
    const end = ConvertISOToDate(flightDetailsVolume.time_end);
    const alt_ft = ConvertMetersToFeet(flightDetailsVolume.volumes[0].altitude_max_agl_m);

    const reference_code = flightDetailsVolume.laanc.volumes
        .filter((volume) => volume.id !== "0")
        .map(({ id }) => id)
        .join(", ");

    const facility_code = flightDetailsVolume.laanc.volumes
        .filter((volume) => volume.airport)
        .map(({ airport }) => airport)
        .join(", ");

    const [logoBase64, setLogoBase64] = useState(null);

    useEffect(() => {
        const imageUrl = "./images/CAL+Blue-214w.png";
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const objectURL = URL.createObjectURL(blob);
                setLogoBase64(objectURL);
            })
            .catch((error) => {
                console.error("Error converting image to URL:", error);
            });
    }, []);

    return (
        <Document>
            <Page style={styles.body}>
                <View size="A4" style={styles.headerWrapper}>
                    <Text style={styles.header}>LAANC Approval Notice</Text>
                    {logoBase64 ? <Image src={logoBase64} style={styles.headerLogo} /> : <></>}
                </View>

                <View style={styles.imageWrapper}>
                    {mapBase64 ? <Image src={mapBase64} /> : <></>}
                    <Text style={styles.imageCaption}>{`${reference_code} / ${facility_code}, ${start} - ${end}, Max Alt ${alt_ft}ft`}</Text>
                </View>

                <View size="A4" style={styles.contentWrapper}>
                    {flightDetailsVolume.part_107 ? (
                        <Text style={styles.content} data-testid="part_107">
                            In accordance with Title 14 CFR Part 107.41, your operation is authorized within the designated airspace and timeframe constraints.
                            Altitude limits are absolute values above ground level which shall not be added to the height of any structures. This Authorization
                            is subject to cancellation at any time upon notice by the FAA Administrator or his/her authorized representative. This Authorization
                            does not constitute a waiver of any State law or local ordinance. {flightDetailsVolume.pilot_name} is the person designated as
                            responsible for the overall safety of UAS operations under this Authorization. During UAS operations for on-site
                            communication/recall, {flightDetailsVolume.pilot_name} shall be continuously available for direct contact at{" "}
                            {flightDetailsVolume.pilot_phone_number} by Air Traffic. Remote pilots are responsible to check the airspace they are operating in
                            and comply with all restrictions that may be present in accordance with 14 CFR 107.45 and 107.49 (a)(2), such as restricted and
                            Prohibited Airspace, Temporary Flight Restrictions, etc. Remote pilots are also responsible for complying with the operating
                            requirements in 14 CFR 107.29(a) when operating at night. Operations are not authorized in Class E airspace when there is a weather
                            ceiling less than 1,000 feet AGL. If the UAS loses communications or loses its GPS signal, it must return to a predetermined
                            location within the operating area and land. The remote pilot in command must abort the flight in the event of unpredicted obstacles
                            or emergencies. This certificate shall be presented for inspection upon the request of any authorized representative of the Federal
                            Aviation Administration, or of any State or municipal official charged with the duty of enforcing local laws or regulations.
                        </Text>
                    ) : (
                        <Text style={styles.content} data-testid="usc">
                            In accordance with 49 U.S.C. § 44809(a)(5), your operation is authorized within the designated airspace and timeframe constraints.
                            Altitude limits are absolute values above ground level which shall not be added to the height of any structures. This Authorization
                            is subject to cancellation at any time upon notice by the FAA Administrator or his/her authorized representative. This Authorization
                            does not constitute a waiver of any State law or local ordinance. {flightDetailsVolume.pilot_name} is the person designated as
                            responsible for the overall safety of UAS operations under this Authorization. During UAS operations for on-site
                            communication/recall, {flightDetailsVolume.pilot_name} shall be continuously available for direct contact at{" "}
                            {flightDetailsVolume.pilot_phone_number} by Air Traffic. {flightDetailsVolume.pilot_name} is responsible to check the airspace in
                            which the UAS will be operated and comply with all restrictions that may be present in accordance with § 44809(a)(5), such as
                            restricted and prohibited airspace, night operations, temporary flight restrictions, etc. This authorization is subject to the
                            following conditions: (1) operations are not authorized in Class E surface area airspace when there is a weather ceiling less than
                            1,000 feet AGL; (2) if the UAS loses communications or loses its GPS signal, it must return to a predetermined location within the
                            operating area and land; (3) night operations are only permitted if the operator has completed FAA training/testing and lighted
                            their sUAS with anticollision lighting visible for at least 3 statute miles that has a flash rate sufficient to avoid a collision.
                            and (4) the person manipulating the controls of the UAS must abort the flight in the event of unpredicted obstacles or emergencies.
                            This certificate shall be presented for inspection upon the request of any authorized representative of the Federal Aviation
                            Administration, or of any State or municipal official charged with the duty of enforcing local laws or regulations.
                        </Text>
                    )}
                </View>
            </Page>
        </Document>
    );
};

export default ApprovalDocument;
