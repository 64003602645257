import React, { useEffect, useRef } from "react";

// import { FullScreen } from "ol/control";

export default function OpenLayersFullScreen({ map }) {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!map || !containerRef.current) {
            return;
        }
        // const full_screen_control = new FullScreen({
        //     target: containerRef.current
        // });
        // map.addControl(full_screen_ref);
        const fullScreen = containerRef.current.querySelector("button");
        if (fullScreen) {
            fullScreen.classList.add("control-button");
        }
    }, [map]);

    return <div ref={containerRef}></div>;
}
