import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const LAANCAdvisoryTable = (props) => {
    const [advisories, setAdvisories] = useState([]);

    const laancNotice =
        "CAL USS is a provider of UAS services within the FAA’s Low- Altitude Authorization and Notification Capability (LAANC). LAANC may be used to satisfy compliance with Air Traffic authorization. Information provided here is based on real-time and available projected information on airspace status and airport-specific maps, and that information is subject to change. Planning tools should be checked prior to flight for any changes that could impact the operation. https://www.faa.gov/uas/programs_partnerships/data_exchange/privacy_statement ";
    const furtherCoordNotice =
        "Further Coordination is a manual process with commensurate timelines.  Requests may take 30-90 days for an FAA response.  After submission your request will be Pending, do not contact Air Traffic by phone as this may result in your request being denied.";
    const tfrNotice =
        "CAL USS cannot ensure the completion or accuracy of all displayed TFR data. Remote pilots are responsible for checking the airspace they are operating in and complying with all restrictions that may be present such as restricted and prohibited airspace, temporary flight restrictions, etc. To obtain the most accurate and up to date information operators should check https://tfr.faa.gov/tfr2/list.html and ensure their operation(s) do not overlap with any active TFRs or other restricted airspaces.";

    function ConvertLinksToURLs(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = text.split(urlRegex);

        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a href={part} key={index} target="_blank" rel="noopener noreferrer" style={{ color: "#FFFACD", wordBreak: "break-all" }}>
                        {part}
                    </a>
                );
            }
            return part;
        });
    }

    useEffect(() => {
        if (props.advisories) {
            let sortedAdvisories = [...props.advisories];
            sortedAdvisories = sortedAdvisories.sort((a, b) => (b.type < a.type ? -1 : 1));
            sortedAdvisories.push({ type: "NOTE", msg: laancNotice, id: "laancNotice" });
            sortedAdvisories.push({ type: "NOTE", msg: tfrNotice, id: "tfrNotice" });

            if (props.isFurtherCoordination) {
                sortedAdvisories.push({ type: "NOTE", msg: furtherCoordNotice, id: "furtherCoordNotice" });
            }
            setAdvisories(sortedAdvisories);
        }
    }, [props.advisories]);
    return (
        <Box sx={{ overflowY: "auto", overflowX: "hidden" }}>
            <Table size="small">
                <TableBody>
                    {advisories.map((row, i) => (
                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell sx={{ color: row.type == "BLOCK" ? "#fa6668" : "White", fontSize: 12, paddingLeft: "0px !important" }} id={row.id}>
                                <>
                                    <Typography variant="caption" color={row.type == "BLOCK" ? "#fa6668" : "White"} fontSize={12}>
                                        {row.type}
                                        {" - "}
                                    </Typography>
                                    {ConvertLinksToURLs(row.msg)}
                                </>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default LAANCAdvisoryTable;
