import { v4 as uuid_v4 } from "uuid";

export function CreateConversations(user, messages, newConversation) {
    if (!user || !Object.hasOwn(user, "id") || !Object.hasOwn(user, "user_role") || user.user_role === "First Responder") {
        return null;
    } else if (!messages || !Array.isArray(messages)) {
        return null;
    } else if (newConversation && !Object.hasOwn(newConversation, "conversation_uuid")) {
        return null;
    }

    const conversationMap = new Map();
    let totalUnreadMessages = 0;
    let newConversationMessage = false; // Does the new conversation have a non-deleted message in the new message set?

    for (const message of messages) {
        // Conversation has not yet been added, initialize and add it to the conversation map.
        if (!conversationMap.has(message.conversation_uuid)) {
            conversationMap.set(message.conversation_uuid, {
                conversation_uuid: message.conversation_uuid,
                recipients: message.recipients,
                recipientEmails: message.recipient_emails,
                messages: [],
                unreadMessages: [],
                totalMessages: 0
            });
        }

        // Ignore deleted messages.
        if (message.deleted_message) {
            continue;
        }

        const conversation = conversationMap.get(message.conversation_uuid);
        conversation.messages.push(message);
        conversation.totalMessages++;

        if (!message.read_message && message.sent_user_id !== user.id) {
            conversation.unreadMessages.push(message);
            totalUnreadMessages++;
        }
        if (newConversation && newConversation.conversation_uuid === message.conversation_uuid) {
            newConversationMessage = true;
        }
    }

    const conversations = [...conversationMap.values()].filter((c) => newConversationMessage || c.conversation_uuid !== newConversation?.conversation_uuid);
    const visibleConversations = conversations.filter((convo) => convo.totalMessages > 0);
    visibleConversations.forEach((convo) => convo.messages.sort((a, b) => new Date(a.date_sent) - new Date(b.date_sent)));
    visibleConversations.sort((a, b) => new Date(b.messages[b.messages.length - 1].date_sent) - new Date(a.messages[a.messages.length - 1].date_sent));

    // If a new conversation exists, but no messages have been sent in it, it needs to be added.
    if (newConversation && !newConversationMessage) {
        conversations.push(newConversation);
        visibleConversations.unshift(newConversation);
    }

    return { conversations, visibleConversations, totalUnreadMessages };
}

export function CreateConversation(existingConversations, recipients, recipientEmails) {
    if (!existingConversations || !recipients || !recipientEmails) {
        return null;
    } else if (!Array.isArray(existingConversations) || !Array.isArray(recipients) || !Array.isArray(recipientEmails)) {
        return null;
    } else if (recipients.length <= 1 || recipientEmails.length <= 1 || recipients.length !== recipientEmails.length) {
        return null;
    }

    const sortedRecipients = recipients.sort();
    const conversation = existingConversations.find((c) => c.recipients.sort().join(",") === sortedRecipients.join(","));
    const conversationUUID = conversation ? conversation.conversation_uuid : uuid_v4();

    // If the conversation exists but has no messages, then it's a new conversation in the user's perspective.
    let newConversation;
    if (conversation && (!conversation.totalMessages || conversation.totalMessages <= 0)) {
        newConversation = conversation;
    } else if (!conversation) {
        newConversation = {
            conversation_uuid: conversationUUID,
            recipients: sortedRecipients,
            recipientEmails: recipientEmails.sort(),
            messages: [],
            unreadMessages: []
        };
    }

    return { conversationUUID, newConversation };
}

export function getConversationHeaderFromRecipientsAndUser(recipients, user) {
    const other_recipients = recipients.filter((email) => {
        return email !== user.email;
    });
    return other_recipients.join(", ");
}
