import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { useMap } from "../../contexts/mapContext";
import { ConvertISOToDate, ConvertMetersToFeet } from "../../util";
import { FlightHistoryDialog } from "./flightHistoryDialog";
import { Box, Typography } from "@mui/material";

export function FlightInfoDialog(props) {
    const [flightInfo, setFlightInfo] = useState({});
    const [flightHistoryOpen, setFlightHistoryOpen] = useState(false);
    const { drones } = useMap();

    useEffect(() => {
        const flight = props.flight;
        const drone = drones.find((drone) => drone.vehicle_uuid === flight.vehicle_uuid);

        const flightInfo = {
            name: flight.name,
            uuid: flight.flight_uuid,
            startTime: ConvertISOToDate(flight.time_start),
            endTime: ConvertISOToDate(flight.time_end),
            minAltAgl: ConvertMetersToFeet(flight.volumes[0].altitude_min_agl_m),
            maxAltAgl: ConvertMetersToFeet(flight.volumes[0].altitude_max_agl_m),
            minAltHae: ConvertMetersToFeet(flight.volumes[0].altitude_min_hae_m),
            maxAltHae: ConvertMetersToFeet(flight.volumes[0].altitude_max_hae_m),
            pilot: flight.pilot_name === "   " ? flight.pilot_email : flight.pilot_name,
            vehicle: drone ? drone.name : "Unknown",
            owner: flight.owner
        };
        setFlightInfo(flightInfo);
    }, [props.flight]);

    return (
        <>
            <Dialog open={props.flightInfoOpen} onClose={() => props.setFlightInfoOpen(false)}>
                <DialogContent sx={{ pb: 0 }}>
                    <Box sx={{ minWidth: 350, marginTop: "5px" }}>
                        {[
                            {
                                title: "Operation",
                                value: flightInfo.name,
                                required: true
                            },
                            {
                                title: "Op UUID",
                                value: flightInfo.uuid,
                                required: true
                            },
                            {
                                title: "Pilot",
                                value: flightInfo.pilot,
                                required: false
                            },
                            {
                                title: "Vehicle",
                                value: flightInfo.vehicle,
                                required: true
                            },
                            {
                                title: "Min Altitude (AGL)",
                                value: `${flightInfo.minAltAgl}ft`,
                                required: true
                            },
                            {
                                title: "Max Altitude (AGL)",
                                value: `${flightInfo.maxAltAgl}ft`,
                                required: true
                            },
                            {
                                title: "Min Altitude (HAE)",
                                value: `${flightInfo.minAltHae}ft`,
                                required: true
                            },
                            {
                                title: "Max Altitude (HAE)",
                                value: `${flightInfo.maxAltHae}ft`,
                                required: true
                            },
                            {
                                title: "Owner",
                                value: flightInfo.owner,
                                required: false
                            }
                        ]
                            .filter(({ value, required }) => required || (!!value && String(value).trim()))
                            .map(({ title, value }, i) => (
                                <Typography key={i} variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>{title}: </b>
                                    <span id={title}>{value}</span>
                                </Typography>
                            ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setFlightInfoOpen(false)} sx={{ m: "auto" }} id="infoClose">
                        Close
                    </Button>
                    <Button onClick={() => setFlightHistoryOpen(true)} sx={{ m: "auto" }} id="flightHistory">
                        Flight History
                    </Button>
                </DialogActions>
            </Dialog>
            {flightHistoryOpen ? (
                <FlightHistoryDialog flightUUID={flightInfo.uuid} flightHistoryOpen={flightHistoryOpen} setFlightHistoryOpen={setFlightHistoryOpen} />
            ) : (
                <></>
            )}
        </>
    );
}
