import DataObjectIcon from "@mui/icons-material/DataObject";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";
import { JsonDialog } from "./jsonDialog";

export function PlanningOpLog() {
    const { handleFailedFetch } = useUserAuth();
    const [planOpData, setPlanOpData] = useState([]);
    const [sortModel, setSortModel] = useState([
        {
            field: "time_start",
            sort: "desc"
        }
    ]);

    const [jsonOpen, setJsonOpen] = useState(false);
    const [selectedJson, setSelectedJson] = useState({});

    const columns = [
        {
            field: "json",
            headerName: "",
            flex: 1,
            midWidth: 50,
            maxWidth: 50,
            display: "flex",
            renderCell: (params) => {
                return (
                    <IconButton size="medium" onClick={() => handleJsonOpen(params.row)}>
                        <DataObjectIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "flight_uuid",
            headerName: "ID",
            flex: 1,
            minWidth: 275
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 150
        },
        {
            field: "state",
            headerName: "State",
            flex: 1,
            minWidth: 100
        },
        {
            field: "time_start",
            headerName: "Time Start",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "time_end",
            headerName: "Time End",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "owner",
            headerName: "Owner",
            minWidth: 150,
            flex: 1
        },
        {
            field: "organization",
            headerName: "Org",
            minWidth: 50,
            flex: 1
        },
        {
            field: "conflicts",
            headerName: "Conflicts",
            minWidth: 75,
            flex: 1,
            renderCell: ({ row }) => {
                if (row.conflicts != "") {
                    return <div>Yes</div>;
                }
            }
        },
        {
            field: "date_created",
            headerName: "Created",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "date_updated",
            headerName: "Updated",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        }
    ];

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        const fetchData = async () => {
            await fetch("api/op/planning/get", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setPlanOpData(data))
                .catch((err) => handleFailedFetch(err));
        };
        fetchData();

        return () => setPlanOpData([]);
    }, []);

    const handleJsonOpen = (row) => {
        setSelectedJson(row);
        setJsonOpen(true);
    };

    return (
        <Box sx={{ height: 474 }}>
            <DataGrid
                disableVirtualization
                rows={planOpData}
                columns={columns}
                disableRowSeletionOnClick
                getRowId={(row) => row.id}
                pagination
                slots={{ pagination: CustomPagination }}
                initialState={{ pagination: { paginationModel: { pageSize: 7 } } }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
            />
            {jsonOpen ? <JsonDialog jsonOpen={jsonOpen} setJsonOpen={setJsonOpen} selectedJson={selectedJson}></JsonDialog> : <></>}
        </Box>
    );
}
