import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { ConvertISOToDate } from "../util";
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import { CustomPagination } from "../customPagination";
import { useUserAuth } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

function ReadAllToolbar({ handleReadAllClick }) {
    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<MarkEmailReadIcon />} onClick={handleReadAllClick}>
                Read All
            </Button>
        </GridToolbarContainer>
    );
}

export function UserNotification() {
    const navigate = useNavigate();
    const { user, notifications, getNotifications, handleFailedFetch } = useUserAuth();
    const [sortModel, setSortModel] = useState([{ field: "time_sent", sort: "desc" }]);

    const columns = [
        {
            field: "read",
            headerName: "Read",
            flex: 1,
            maxWidth: 50,
            align: "center",
            renderCell: ({ row }) => {
                return (
                    <Checkbox
                        checked={row.read}
                        onClick={() => {
                            handleReadClick(row);
                        }}
                        inputProps={{ "data-testid": "ReadNotification" }}
                    />
                );
            }
        },
        {
            field: "notification_type",
            headerName: "Type",
            flex: 1,
            maxWidth: 200
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            minWidth: 400
        },
        {
            field: "time_sent",
            headerName: "Sent",
            maxWidth: 200,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            type: "actions",
            field: "actions",
            headerName: "Actions",
            cellClassName: "actions",
            getActions: ({ row }) => {
                const viewable = row.notification_type_id === 2;
                return [
                    viewable ? (
                        <GridActionsCellItem
                            key={row.id}
                            icon={<TravelExploreIcon />}
                            label="View"
                            onClick={() => {
                                navigate(`/map`, {
                                    state: { volumeUuidToZoomTo: row.associated_record_uuid }
                                });
                                if (!row.read) {
                                    handleReadClick(row);
                                }
                            }}
                        />
                    ) : (
                        <></>
                    ),
                    <GridActionsCellItem
                        key={row.id}
                        icon={<CancelIcon />}
                        label="Delete"
                        onClick={() => {
                            handleDeleteClick(row);
                        }}
                    />
                ];
            }
        }
    ];

    // API Request to Delete Notification
    const handleDeleteClick = async (row) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(row)
        };
        await fetch("api/user/notification/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));

        getNotifications(user);
    };

    // API Request to Mark Notification as Read
    const handleReadClick = async (row) => {
        row.read = !row.read;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(row)
        };
        await fetch("api/user/notification/read", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));

        getNotifications(user);
    };

    // API Request to Mark All Notifications as Read.
    const handleReadAllClick = async () => {
        for (const notification of notifications) {
            if (notification.read) {
                continue;
            } else {
                notification.read = true;
            }

            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(notification)
            };
            await fetch("api/user/notification/read", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .catch((err) => handleFailedFetch(err));
        }

        getNotifications(user);
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "1500px !important", pt: 3, px: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Notifications
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        height: 774.2,
                        "& .cmp_row_read-true": {
                            color: "#4b4c4c!important"
                        }
                    }}
                >
                    <DataGrid
                        disableVirtualization
                        rows={notifications}
                        columns={columns}
                        disableRowSeletionOnClick
                        getRowId={(row) => row.id}
                        slots={{ toolbar: ReadAllToolbar, pagination: CustomPagination }}
                        slotProps={{ toolbar: { handleReadAllClick } }}
                        initialState={{ pagination: { paginationModel: { pageSize: 12 } } }}
                        getRowClassName={(params) => `cmp_row_read-${params.row.read}`}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                    />
                </Box>
            </Paper>
        </Grid>
    );
}
