import React, { useEffect, useState } from "react";
import { ConvertFeetToMeters, ConvertMetersToFeet } from "../../util";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DownloadIcon from "@mui/icons-material/Download";
import InputAdornment from "@mui/material/InputAdornment";

// Component requires 4 props
// - downloadWaypointDialogOpen and setDownloadWaypointDialogOpen - controls visibility of the dialog
// - downloadWaypointOperation and setDownloadWaypointMenuOperation - controls operation being exported

const ExportFlightWaypointsDialog = (props) => {
    const [altitudeAglFt, setAltitudeAglFt] = useState("0");
    const [minAltitudeAglFt, setMinAltitudeAglFt] = useState(0);
    const [maxAltitudeAglFt, setMaxAltitudeAglFt] = useState(0);

    useEffect(() => {
        let min_agl_ft = 0;
        let max_agl_ft = 0;

        if (props.downloadWaypointOperation !== null) {
            min_agl_ft = ConvertMetersToFeet(props.downloadWaypointOperation.volumes[0].altitude_min_agl_m);
            max_agl_ft = ConvertMetersToFeet(props.downloadWaypointOperation.volumes[0].altitude_max_agl_m);
        }
        setMinAltitudeAglFt(min_agl_ft);
        setMaxAltitudeAglFt(max_agl_ft);
    }, [props.downloadWaypointOperation]);

    const getWaypointsForMissionPlanner = (alt_agl_m) => {
        let waypoints = "QGC WPL 110\n";
        props.downloadWaypointOperation.waypoints.forEach(({ lat, lng }, index) => {
            waypoints += `${index + 1} 0 3 16 0.000000 0.000000 0.000000 0.000000 ${lat} ${lng} ${alt_agl_m} 1\n`;
        });
        return waypoints;
    };
    const getWaypointsForUgCS = (alt_agl_m) => {
        let waypoints = "Latitude,Longitude,AltitudeAGL\n";
        props.downloadWaypointOperation.waypoints.forEach(({ lat, lng }) => {
            waypoints += `${lat},${lng},${alt_agl_m}\n`;
        });
        return waypoints;
    };
    const handleDownloadWaypoints = (type) => {
        try {
            if (isNotValidAltitude(altitudeAglFt) === true) {
                return alert(
                    `Please enter an altitude within the specified range. Your input should lie between ${minAltitudeAglFt}ft and ${maxAltitudeAglFt}ft.`
                );
            }
            // currently we only support two GCS's, if this changes we will need to update this
            const alt_agl_m = ConvertFeetToMeters(altitudeAglFt);
            let waypoints = "";
            if (type === "txt") {
                waypoints = getWaypointsForMissionPlanner(alt_agl_m);
            } else {
                waypoints = getWaypointsForUgCS(alt_agl_m);
            }
            const blob = new Blob([waypoints], { type: "text/plain" });
            const file = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = file;
            link.download = `${props.downloadWaypointOperation.name}.${type}`;

            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );
            document.body.removeChild(link);
        } catch (err) {
            alert("This operation could not be downloaded at this time. Please try again later.");
        }
        handleCloseDownloadWaypointDialog();
    };
    const isNotValidAltitude = (alt_agl_ft) => {
        if (alt_agl_ft.length > 0) {
            if (parseFloat(alt_agl_ft) <= minAltitudeAglFt || parseFloat(alt_agl_ft) >= maxAltitudeAglFt) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };
    const handleCloseDownloadWaypointDialog = () => {
        props.setDownloadWaypointDialogOpen(false);
        props.setDownloadWaypointOperation(null);
    };
    return props.downloadWaypointOperation !== null ? (
        <Dialog open={props.downloadWaypointDialogOpen} onClose={handleCloseDownloadWaypointDialog}>
            <DialogTitle>Export to GCS</DialogTitle>
            <DialogContent>
                <DialogContentText>{"To export this operation, please enter a flight altitude within the bounds of your operation."}</DialogContentText>
                <TextField
                    autoFocus
                    label="Altitude (AGL)"
                    fullWidth
                    variant="standard"
                    value={altitudeAglFt}
                    onChange={(e) => setAltitudeAglFt(e.target.value)}
                    type="number"
                    helperText={`Altitude should lie between ${minAltitudeAglFt + 1}ft and ${maxAltitudeAglFt - 1}ft`}
                    InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                    sx={{ mt: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDownloadWaypointDialog} sx={{ mr: "auto" }} id="cancel">
                    Cancel
                </Button>
                <Button onClick={() => handleDownloadWaypoints("txt")} startIcon={<DownloadIcon />}>
                    Mission Planner
                </Button>
                <Button onClick={() => handleDownloadWaypoints("csv")} startIcon={<DownloadIcon />}>
                    UgCS
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <></>
    );
};

export default ExportFlightWaypointsDialog;
