import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import { DataGrid } from "@mui/x-data-grid";
import { ConvertISOToDate } from "../../util";
import { CustomPagination } from "../../customPagination";
import { useMap } from "../../contexts/mapContext";
import { MapPreview } from "../../map/mapPreview";
import { useSocket } from "../../contexts/socketContext";

const columns = [
    {
        field: "name",
        headerName: "Name",
        editable: false,
        minWidth: 300,
        flex: 1
    },
    {
        field: "city",
        headerName: "City",
        editable: false,
        minWidth: 250,
        flex: 1
    },
    {
        field: "local_type",
        headerName: "Type",
        editable: false,
        minWidth: 100,
        maxWidth: 250,
        flex: 1
    },
    {
        field: "upper_val_ft_msl",
        headerName: "Alt ft (MSL)",
        editable: false,
        minWidth: 125,
        maxWidth: 125,
        flex: 1
    },
    {
        field: "date_created",
        headerName: "Date Created",
        editable: false,
        minWidth: 150,
        maxWidth: 200,
        type: "date",
        flex: 1,
        valueFormatter: (value) => `${ConvertISOToDate(value)}`
    }
];

export function Airspaces() {
    const [showMap, setShowMap] = useState(false);
    const [selectedAirspaces, setSelectedAirspaces] = useState([]);
    const [airspacesAreSelected, setAirspacesAreSelected] = useState(false);

    const { airspaces } = useMap();
    const { planningOperations, publishedOperations, constraints, alertVolumes } = useSocket();

    useEffect(() => {
        if (airspacesAreSelected === false) {
            setSelectedAirspaces(airspaces);
        }
    }, [airspaces, selectedAirspaces, airspacesAreSelected]);

    const handleCheckboxSelected = (ids) => {
        if (ids.length > 0 && showMap === false) {
            setShowMap(true);
        }
        const selected = airspaces.filter(({ id }) => {
            return ids.includes(id);
        });
        if (selected.length > 0) {
            setAirspacesAreSelected(true);
            setSelectedAirspaces(selected);
        } else {
            setAirspacesAreSelected(false);
            setSelectedAirspaces(airspaces);
        }
    };

    return (
        <>
            <Box sx={{ width: 1, height: 578 }}>
                <DataGrid
                    rows={airspaces}
                    columns={columns}
                    checkboxSelection
                    disableRowSeletionOnClick
                    disableVirtualization
                    pagination
                    slots={{ pagination: CustomPagination }}
                    initialState={{ pagination: { paginationModel: { pageSize: 9 } } }}
                    onRowSelectionModelChange={handleCheckboxSelected}
                />
            </Box>
            <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <Button variant="contained" color="primary" size="small" sx={{ mr: "auto" }} onClick={() => setShowMap((prev) => !prev)}>
                    {showMap ? "Hide Map" : "Preview on Map"}
                </Button>
            </Box>
            {showMap ? (
                <Paper sx={{ pb: 2, height: "600px" }}>
                    <MapPreview
                        id={2}
                        planningOperations={planningOperations}
                        publishedOperations={publishedOperations}
                        airspaces={selectedAirspaces}
                        constraints={constraints}
                        alertVolumes={alertVolumes}
                    />
                </Paper>
            ) : (
                <></>
            )}
        </>
    );
}
