import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { kml } from "@tmcw/togeojson";
import { buffer, circle, featureCollection, lineString, lineToPolygon } from "@turf/turf";
import { v4 as uuid_v4 } from "uuid";
import Button from "../../components/button";
import { useUserAuth } from "../../contexts/authContext";
import { useEnv } from "../../contexts/envContext";
import { useMap } from "../../contexts/mapContext";
import { MapDraw } from "../../map/mapDraw";
import {
    ConvertFeaturesToVolumes,
    ConvertFeetToMeters,
    ConvertMetersToFeet,
    FormatPhoneNumber,
    GetNewEndDate,
    GetTimezone,
    IsInvalidAltitude,
    IsInvalidPhoneNumber,
    IsValidDate
} from "../../util";
import { Altitudes } from "../forms/altitudes";
import * as CONSTANT from "./opConstants";
import { canEditFlight, canPublishByState, canSubmitToLAANC, isFlightBlocked, updateSafetyJustification, validateFlightTimes } from "./opUtil";
import { useSocket } from "../../contexts/socketContext";

const FlightWorkflowDetails = lazy(() => import("./flightWorkflowDetails"));
const BypassDetails = lazy(() => import("./bypassDetails"));

const allSteps = [
    { label: "Add Flight Info", completed: false, optional: false },
    { label: "Add Alert Volume", completed: false, optional: true },
    { label: "Review & Deconflict", completed: false, optional: false },
    { label: "Publish", completed: false, optional: false }
];

export const CreateFlightDialog = (props) => {
    const alertFileUploadRef = useRef(null);

    const [steps, setSteps] = useState(allSteps);
    const [activeStep, setActiveStep] = useState(0);
    const [activeDrawMethod, setActiveDrawMethod] = useState("Clear");
    const [activeAlertMethodTab, setActiveAlertMethodTab] = useState("None");
    const [canEdit, setCanEdit] = useState(true);

    const [validFlightStart, setValidFlightStart] = useState();
    const [validFlightEnd, setValidFlightEnd] = useState();

    const [flightStart, setFlightStart] = useState();
    const [flightEnd, setFlightEnd] = useState();
    const [flightFieldsDisabled, setFlightFieldsDisabled] = useState(false);
    const [waypointHierarchy, setWaypointHierarchy] = useState([]);
    const [flightVolumesFinal, setFlightVolumesFinal] = useState([]);
    const [flightBuffer, setFlightBuffer] = useState(100);
    const [flightRadius, setFlightRadius] = useState(0);

    const [flightName, setFlightName] = useState("");
    const [flightMinAltitude, setFlightMinAltitude] = useState(0);
    const [flightMaxAltitude, setFlightMaxAltitude] = useState(0);
    const [flightPriority, setFlightPriority] = useState(0);
    const [flightPilot, setFlightPilot] = useState("");
    const [flightPilotPhoneNumber, setFlightPilotPhoneNumber] = useState("");
    const [flightVehicleUUID, setFlightVehicleUUID] = useState("");
    const [flightPart107, setFlightPart107] = useState(true);
    const [flightBVLOS, setFlightBVLOS] = useState(false);

    const [alertVolumes, setAlertVolumes] = useState([]);
    const [alertName, setAlertName] = useState("");
    const [alertManned, setAlertManned] = useState("all");
    const [alertColorId, setAlertColorId] = useState(0);
    const [alertBuffer, setAlertBuffer] = useState("");
    const [alertMinAltitude, setAlertMinAltitude] = useState(0);
    const [alertMaxAltitude, setAlertMaxAltitude] = useState(0);

    const [operationAlertId, setOperationAlertId] = useState(0);
    const [operationAlertUuid, setOperationAlertUuid] = useState("");
    const [operationVolumeUuid, setOperationVolumeUuid] = useState("");
    const [operationVolume, setOperationVolume] = useState({});

    const [laancVolumes, setLAANCVolumes] = useState([]);
    const [selectedLaancVolumeNames, setSelectedLaancVolumeNames] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [page, setPage] = useState(0);

    const [bypassBlock, setBypassBlock] = useState(false);
    const [bypassReason, setBypassReason] = useState(null);

    const [safetyJustification, setSafetyJustification] = useState("");

    const { visibleDrones } = useMap();
    const { user, updateInfo, snackbar, setSnackbar } = useUserAuth();
    const { handleFailedFetch } = useUserAuth();
    const { laancOn } = useEnv();
    const { socket } = useSocket();

    // initialize variables
    useEffect(() => {
        const start = new Date().getTime() + 15 * 60 * 1000;
        const end = start + 8 * 60 * 60 * 1000;

        setFlightStart(start);
        setFlightEnd(end);
        setValidFlightStart(start);
        setValidFlightEnd(end);

        setFlightPilot(user);
        setFlightPilotPhoneNumber(user.phone_number || "");

        if (visibleDrones.length > 0) {
            setFlightVehicleUUID(visibleDrones[0].vehicle_uuid);
        }
    }, []);

    // update operation volume when updated through redis
    useEffect(() => {
        props.planningOperations.forEach((operation) => {
            if (operation.flight_uuid === operationVolumeUuid) {
                setOperationVolume(operation);

                if (operation.laanc != null) {
                    const newLaancVolumes = operation.laanc.volumes.map((volume, i) => {
                        return { ...volume, name: `Volume ${i + 1}`, rowIndex: i + 1 };
                    });
                    setLAANCVolumes(newLaancVolumes);
                }
            }
        });
    }, [props.planningOperations]);

    const handleAltitudeChange = (minAltitude, maxAltitude) => {
        setFlightMinAltitude(minAltitude);
        setFlightMaxAltitude(maxAltitude);
    };

    const handleChangeAlertUploadMethod = (e, value) => {
        if (value === "KML") {
            alertFileUploadRef.current.click();
        } else if (value === "Automatic") {
            const alert_color = props.colors.find(({ id }) => id === alertColorId).color_rgb;
            const alerts = [];
            flightVolumesFinal.forEach((volume) => {
                const alert = {
                    ...volume,
                    name: alertName,
                    altitude_lower_hae: flightMinAltitude || 0,
                    altitude_upper_hae: flightMaxAltitude || 0,
                    color: alert_color,
                    positions: []
                };
                volume.vertices.forEach(({ lng, lat }) => {
                    alert.positions.push(lng, lat);
                });
                alerts.push(alert);
            });
            setAlertVolumes(alerts);
            setAlertMinAltitude(flightMinAltitude || 0);
            setAlertMaxAltitude(flightMaxAltitude || 0);
        } else if (value === "None") {
            setAlertVolumes([]);
        }
        setActiveAlertMethodTab(value);
    };

    const handleAlertBufferChange = (e) => {
        if (e.target.value) {
            const newBuffer = parseFloat(e.target.value);
            const features = [];
            flightVolumesFinal.forEach(({ type, vertices }) => {
                if (type === "circle") {
                    const center = [vertices[0].lng, vertices[0].lat];
                    const feature = circle(center, flightRadius / 5280, { steps: 64, units: "miles" });
                    features.push(feature);
                } else if (type === "polygon") {
                    const coordinates = vertices.map(({ lng, lat }) => [lng, lat]);
                    const linestring = lineString(coordinates);
                    const feature = lineToPolygon(linestring);
                    features.push(feature);
                }
            });
            const collection = featureCollection(features);
            const geojson = buffer(collection, newBuffer / 5280.0, { units: "miles" });
            const volumes = [];
            geojson.features.forEach(({ geometry }) => {
                const type = "polygon";
                const color = props.colors.find(({ id }) => id === alertColorId).color_rgb;
                const vertices = geometry.coordinates[0].map((arr) => {
                    return { lng: arr[0], lat: arr[1] };
                });
                const positions = geometry.coordinates[0].reduce((acc, current) => {
                    return acc.concat(current);
                });
                volumes.push({
                    type: type,
                    name: alertName,
                    altitude_lower_hae: alertMinAltitude || 0,
                    altitude_upper_hae: alertMaxAltitude || 0,
                    color: color,
                    positions: positions,
                    vertices: vertices
                });
            });
            setAlertVolumes(volumes);
        }
        setAlertBuffer(e.target.value);
    };

    const handleAlertFileChange = (acceptedFiles) => {
        if (acceptedFiles[0].size > 500000) return setSnackbar({ children: "Please enter a file less than 500KB", severity: "error" });

        if (!/(\.kml|\.kmz)$/i.exec(acceptedFiles[0].name.toLowerCase())) return setSnackbar({ children: "Please enter a valid file type", severity: "error" });

        let fileData = new FileReader();
        fileData.onloadend = (e) => {
            const content = e.target.result;
            const kmlParsed = new DOMParser().parseFromString(content, "text/xml");

            const converted = kml(kmlParsed);
            const features = converted.features;
            const alert = ConvertFeaturesToVolumes(features, flightStart, flightEnd, user);

            if (!alert.volumes.length) {
                return setSnackbar({ children: "Please enter a file with one or more valid polygons", severity: "error" });
            }
            const minHeight = ConvertMetersToFeet(alert.minAlt);
            const maxHeight = ConvertMetersToFeet(alert.maxAlt);
            alert.volumes.forEach((volume) => {
                volume.altitude_lower_hae = alert.minAlt;
                volume.altitude_upper_hae = alert.maxAlt;
            });
            setAlertMinAltitude(minHeight);
            setAlertMaxAltitude(maxHeight);
            setAlertVolumes(alert.volumes);
        };
        fileData.readAsText(acceptedFiles[0]);
    };

    const handleAlertAltitudeChange = (minAltitude, maxAltitude) => {
        setAlertMinAltitude(minAltitude);
        setAlertMaxAltitude(maxAltitude);
    };

    const handleNextStep = () => {
        if (activeStep === 0) {
            if (!validateFlightTimes(flightStart, flightEnd, false, setSnackbar)) {
                return;
            } else if (!flightVolumesFinal.length) {
                return setSnackbar({ children: "Please create a volume first", severity: "error" });
            }

            const badName = !flightName || (flightName && !flightName.trim());
            const badAltitude = IsInvalidAltitude(flightMinAltitude, flightMaxAltitude);
            const badNumber = IsInvalidPhoneNumber(flightPilotPhoneNumber);

            if (badName || badAltitude || badNumber) {
                return setSnackbar({ children: "Please fill out the required fields", severity: "error" });
            }

            const finalFlightVolumes = [];
            const maxFlightAltitude = parseFloat(flightMaxAltitude);
            const minFlightAltitude = parseFloat(flightMinAltitude);
            const bufferValue = parseFloat(flightBuffer);
            const radiusValue = parseFloat(flightRadius);

            if (
                (activeDrawMethod === "Circle" && (isNaN(radiusValue) || radiusValue <= 0)) ||
                (activeDrawMethod === "Waypoints" && (isNaN(bufferValue) || bufferValue <= 0))
            ) {
                return setSnackbar({ children: "Please enter a valid value", severity: "error" });
            }
            if (!flightVehicleUUID) {
                return setSnackbar({ children: "Please select a flight vehicle", severity: "error" });
            }

            let pilot_uuid = "";
            if (!user.pilot_uuid) {
                const uuid = uuid_v4();
                const updatedUser = { ...user };
                updatedUser.pilot_uuid = uuid;

                pilot_uuid = uuid;
                updateInfo(updatedUser);
                socket.emit("storeUserChatSocket", updatedUser);
            } else pilot_uuid = user.pilot_uuid;

            if (!user.phone_number) {
                const updatedUser = { ...user };
                updatedUser.phone_number = flightPilotPhoneNumber;
                updateInfo(updatedUser);
            }

            flightVolumesFinal.forEach((volume) => {
                finalFlightVolumes.push({
                    flight_uuid: operationVolumeUuid,
                    name: flightName,
                    vertices: volume.vertices,
                    type: activeDrawMethod,
                    organization_id: user.organization_id,
                    altitude_min_agl_m: ConvertFeetToMeters(minFlightAltitude),
                    altitude_max_agl_m: ConvertFeetToMeters(maxFlightAltitude),
                    radius: ConvertFeetToMeters(flightRadius),
                    time_start: new Date(flightStart).toISOString(),
                    time_end: new Date(flightEnd).toISOString(),
                    created_user_id: user.id,
                    priority: flightPriority,
                    pilot_uuid: pilot_uuid,
                    pilot_email: flightPilot.email,
                    pilot_name: flightPilot.first_name + " " + flightPilot.last_name,
                    pilot_phone_number: flightPilotPhoneNumber,
                    vehicle_uuid: flightVehicleUUID,
                    waypoints: waypointHierarchy,
                    bvlos: flightBVLOS,
                    part_107: flightPart107
                });
            });

            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(finalFlightVolumes)
            };

            fetch("/api/op/planning", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((operation) => {
                    setOperationVolumeUuid(operation.flight_uuid);
                    setOperationVolume(operation);
                    handleSetStepComplete(0, true);
                    setActiveStep(1);
                    setCanEdit(false);
                })
                .catch((err) => handleFailedFetch(err));
        }

        if (activeStep === 1) {
            if (activeAlertMethodTab === "None") {
                if (!operationAlertUuid) {
                    handleSetStepComplete(1, true);
                    setActiveStep(2);
                    return;
                }
                const requestOptions = {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        created_user_id: operationVolume ? operationVolume.created_user_id : undefined,
                        organization_id: operationVolume ? operationVolume.organization_id : undefined
                    })
                };
                fetch(`api/alertVolume/delete/${operationVolumeUuid}`, requestOptions)
                    .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                    .then(() => {
                        handleSetStepComplete(1, true);
                        setActiveStep(2);
                    })
                    .catch((err) => handleFailedFetch(err));
                return;
            }

            if (!alertVolumes.length) {
                return setSnackbar({ children: "Please create a volume first", severity: "error" });
            } else if (IsInvalidAltitude(alertMinAltitude, alertMaxAltitude)) {
                return setSnackbar({ children: "Please fill out the required fields", severity: "error" });
            }

            const bufferValue = parseFloat(alertBuffer);
            const minAlertAltitude = parseFloat(alertMinAltitude);
            const maxAlertAltitude = parseFloat(alertMaxAltitude);
            const alertColor = props.colors.find(({ id }) => id === alertColorId).color;
            const alertRGB = props.colors.find(({ id }) => id === alertColorId).color_rgb;
            const operationVolumeState = operationVolume ? operationVolume.state : "PLANNING";

            const badAlertName = !alertName || (alertName && !alertName.trim());
            if (badAlertName || (activeAlertMethodTab === "Automatic" && (isNaN(bufferValue) || bufferValue < 0))) {
                return setSnackbar({ children: "Please fill out the required fields", severity: "error" });
            }

            const polygons = [];
            alertVolumes.forEach((vol) => {
                const polygon = {
                    type: "polygon",
                    vertices: vol.vertices
                };
                polygons.push(polygon);
            });

            const finalAlertVolume = {
                name: alertName,
                manned: alertManned,
                created_user_id: user.id,
                date_updated: new Date().toISOString(),
                date_created: new Date().toISOString(),
                updated_user_id: user.id,
                organization_id: user.organization_id,
                altitude_min_agl_m: ConvertFeetToMeters(minAlertAltitude),
                altitude_max_agl_m: ConvertFeetToMeters(maxAlertAltitude),
                time_start: new Date(flightStart).toISOString(),
                time_end: new Date(flightEnd).toISOString(),
                polygons: polygons,
                uuid: operationAlertUuid,
                flight_name: flightName,
                flight_uuid: operationVolumeUuid,
                flight_status: operationVolumeState,
                color_id: alertColorId,
                color_name: alertColor,
                color_rgb: alertRGB,
                deleted: false,
                id: operationAlertId
            };

            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(finalAlertVolume)
            };

            fetch("api/alertVolume/insertUpdate", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((alert) => {
                    if (alert.uuid != undefined) {
                        //These fields only need to be set the first time an Alert is inserted.
                        setOperationAlertUuid(alert.uuid);
                        setOperationAlertId(alert.id);
                    }

                    handleSetStepComplete(1, true);
                    setActiveStep(2);
                })
                .catch((err) => handleFailedFetch(err));
        }

        if (activeStep === 2) {
            if (!operationVolume || !operationVolume.name) {
                return setSnackbar({ children: "Something went wrong... please try again", severity: "error" });
            }
            const awaitOperation = { ...operationVolume };
            awaitOperation.state = CONSTANT.DECONFLICTION_CHECK_STATUS;
            awaitOperation.laanc = null;
            awaitOperation.faa_approval_required = null;

            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(awaitOperation)
            };

            fetch("/api/op/deconflict", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then(() => {
                    handleSetStepComplete(2, true);
                    setActiveStep(3);
                })
                .catch((err) => handleFailedFetch(err));
        }
        if (activeStep === 3) {
            let bypassJSON;
            if (bypassBlock) {
                if (!bypassReason) return setSnackbar({ children: "Please enter bypass reason", severity: "error" });

                bypassJSON = {
                    timestamp: new Date().toISOString(),
                    explanation: bypassReason
                };
            }
            const operation = { ...operationVolume };
            operation.state = CONSTANT.ACCEPTED_STATUS;
            operation.version = 1;
            operation.block_override = bypassJSON;

            //update any Further Coordination volumes with the optional Safety Justification
            updateSafetyJustification(operation, safetyJustification);

            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify([operation])
            };
            fetch("/api/op/publish", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then(() => handleClose())
                .catch((err) => handleFailedFetch(err));
        }
    };

    const handleSubmitToLAANC = () => {
        if (!validateFlightTimes(flightStart, flightEnd, false, setSnackbar)) {
            return;
        }

        const operation = { ...operationVolume };
        operation.state = CONSTANT.FAA_APPROVAL_CHECK_STATUS;

        //update any Further Coordination volumes with the optional Safety Justification
        updateSafetyJustification(operation, safetyJustification);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };
        fetch("/api/op/deconflict", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleUpdateVolumeState = (id, state) => {
        const updatedOperation = { ...operationVolume };

        updatedOperation.laanc.volumes.forEach((volume) => {
            if (volume.id === id) {
                volume.state = state;
            }
        });

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedOperation)
        };

        fetch("/api/op/deconflict", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleBackStep = (step) => {
        const previous = step - 1;
        if (previous === 0) {
            setActiveAlertMethodTab("None");
            setAlertVolumes([]);
            setCanEdit(true);
        }
        handleSetStepComplete(previous, false);
        setActiveStep(previous);
    };

    const handleSetStepComplete = (index, value) => {
        const updatedSteps = steps.map((step, i) => {
            if (index === i) {
                return { ...step, completed: value };
            }
            return step;
        });
        setSteps(updatedSteps);
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumber = FormatPhoneNumber(e.target.value);
        setFlightPilotPhoneNumber(phoneNumber);
    };

    const handleStartDateChange = (value) => {
        const prev_start = validFlightStart;
        const prev_end = validFlightEnd;

        if (IsValidDate(value)) {
            const new_end = GetNewEndDate(prev_start, prev_end, value);
            setFlightEnd(new_end);
            setValidFlightStart(value);
            setValidFlightEnd(new_end);
        }
        setFlightStart(value);
    };

    const handleEndDateChange = (value) => {
        if (IsValidDate(value)) {
            setValidFlightEnd(value);
        }
        setFlightEnd(value);
    };

    const handleClose = () => {
        props.setCreateFlightDialogOpen(false);
    };

    const handleLaancVolumeSelected = (names) => {
        if (names.length > 0) {
            const selectedName = names[names.length - 1];
            const index = laancVolumes.findIndex(({ name }) => name === selectedName);
            if (index !== -1) {
                const newPage = Math.floor(index / 3);
                setPage(newPage);
            }
            setSelectedLaancVolumeNames([selectedName]);
            setSelectionModel([selectedName]);
        } else if (names.length === 0) {
            setSelectedLaancVolumeNames([]);
            setSelectionModel([]);
        }
    };

    //button definitions
    const SaveButton = () => (
        <Button onClick={handleNextStep} endIcon={<NavigateNextIcon />} id="save">
            Save
        </Button>
    );

    const SubmitToLAANCButton = () => (
        <Button onClick={handleSubmitToLAANC} id="submitToLAANC" data-testid="submitToLaancButton">
            Submit to LAANC
        </Button>
    );

    const BackButton = () => (
        <Button
            onClick={() => {
                handleBackStep(activeStep);
                setBypassBlock(false);
            }}
            id="back"
        >
            Back
        </Button>
    );

    const PublishButton = () => (
        <Button onClick={handleNextStep} id="publish" data-testid="publishButton">
            Publish
        </Button>
    );

    const ProceedWithoutLAANCButton = () => (
        <Button disabled={flightFieldsDisabled} onClick={() => setBypassBlock(true)} id="proceedWithoutLaanc" data-testid="proceedWithoutLaancButton">
            Proceed without LAANC
        </Button>
    );

    return (
        <Dialog
            onClose={() => props.setCreateFlightDialogOpen(false)}
            open={props.createFlightDialogOpen}
            maxWidth="lg"
            fullWidth
            PaperProps={{ sx: { maxHeight: "98vh" } }}
        >
            <DialogTitle sx={{ pt: "8px", pb: "8px" }}>Create Flight</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <MapDraw
                    type={props.type}
                    canEdit={canEdit}
                    activeDrawMethod={activeDrawMethod}
                    setActiveDrawMethod={setActiveDrawMethod}
                    constraints={props.constraints}
                    operations={props.publishedOperations}
                    start={flightStart}
                    end={flightEnd}
                    minAltitude={flightMinAltitude}
                    setMinAltitude={setFlightMinAltitude}
                    maxAltitude={flightMaxAltitude}
                    setMaxAltitude={setFlightMaxAltitude}
                    radius={flightRadius}
                    waypointBuffer={flightBuffer}
                    setWaypointHierarchy={setWaypointHierarchy}
                    disabled={flightFieldsDisabled}
                    setDisabled={setFlightFieldsDisabled}
                    finalVolumes={flightVolumesFinal}
                    setFinalVolumes={setFlightVolumesFinal}
                    alertColorId={alertColorId}
                    alertVolumes={alertVolumes}
                    alertMinAltitude={alertMinAltitude}
                    alertMaxAltitude={alertMaxAltitude}
                    laancVolumes={laancVolumes}
                    selectedLaancVolumeNames={selectedLaancVolumeNames}
                    handleLaancVolumeSelected={handleLaancVolumeSelected}
                    setName={setFlightName}
                />

                <Divider sx={{ mt: 2, mb: 1 }} />

                <Stepper activeStep={activeStep}>
                    {steps.map(({ label, completed, optional }) => (
                        <Step key={label} completed={completed}>
                            <StepLabel optional={optional ? <Typography variant="caption">Optional</Typography> : <></>} data-testid={label}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Divider sx={{ mb: 2, mt: 1 }} />

                <Box>
                    {activeStep === 0 ? (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%", gap: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label={`Start Time (${GetTimezone()}) *`}
                                        value={dayjs(flightStart)}
                                        disabled={flightFieldsDisabled}
                                        onChange={handleStartDateChange}
                                        slotProps={{
                                            textField: {
                                                id: "startTime",
                                                fullWidth: true,
                                                error: snackbar && !validateFlightTimes(flightStart, flightEnd),
                                                inputProps: { "data-testid": "startTime" }
                                            }
                                        }}
                                    />
                                    <DateTimePicker
                                        label={`End Time (${GetTimezone()}) *`}
                                        value={dayjs(flightEnd)}
                                        disabled={flightFieldsDisabled}
                                        onChange={handleEndDateChange}
                                        slotProps={{
                                            textField: {
                                                id: "endTime",
                                                fullWidth: true,
                                                error: snackbar && !validateFlightTimes(flightStart, flightEnd),
                                                inputProps: { "data-testid": "endTime" }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns:
                                        activeDrawMethod === "Waypoints" || activeDrawMethod === "Circle" ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr",
                                    gap: 2
                                }}
                            >
                                <TextField
                                    onChange={(e) => setFlightName(e.target.value)}
                                    disabled={flightFieldsDisabled}
                                    error={snackbar && !flightName}
                                    sx={{ mt: 0, mb: 0 }}
                                    value={flightName}
                                    variant="outlined"
                                    margin="dense"
                                    label="Flight Name"
                                    fullWidth
                                    id="flightName"
                                    inputProps={{ "data-testid": "flightName" }}
                                />
                                <FormControl disabled={flightFieldsDisabled}>
                                    <InputLabel id="select-priority-air">Flight Priority</InputLabel>
                                    <Select
                                        value={flightPriority}
                                        id="selectType"
                                        labelId="select-label"
                                        label="Flight Priority"
                                        onChange={(e) => setFlightPriority(e.target.value)}
                                        inputProps={{ "data-testid": "selectType" }}
                                    >
                                        <MenuItem value={CONSTANT.PERSONAL_TYPE} id="optionNoPriority">
                                            0 - No Priority/Personal/Hobbyist
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.TRAINING_TYPE} id="optionTraining">
                                            10 - Training
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.COMMERCIAL_TYPE} id="optionCommercial">
                                            20 - Commercial
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.PUBLIC_SAFETY_TYPE} id="optionPublicSafety">
                                            30 - Public Safety
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.EMERGENCY_TYPE} id="optionEmergency">
                                            40 - Emergency
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    margin="dense"
                                    label="Flight Buffer"
                                    type="number"
                                    fullWidth
                                    value={flightBuffer}
                                    onWheel={(e) => e.target.blur()}
                                    disabled={flightFieldsDisabled}
                                    onChange={(e) => setFlightBuffer(e.target.value)}
                                    error={snackbar && (isNaN(parseFloat(flightBuffer)) || parseFloat(flightBuffer) <= 0)}
                                    sx={{ my: 0, display: activeDrawMethod === "Waypoints" ? "block" : "none" }}
                                    InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                    id="flightBuffer"
                                    inputProps={{ "data-testid": "flightBuffer" }}
                                />
                                <TextField
                                    margin="dense"
                                    label="Flight Radius"
                                    fullWidth
                                    type="number"
                                    value={flightRadius}
                                    onWheel={(e) => e.target.blur()}
                                    disabled={flightFieldsDisabled}
                                    onChange={(e) => setFlightRadius(e.target.value)}
                                    error={snackbar && (isNaN(parseFloat(flightRadius)) || parseFloat(flightRadius) <= 0)}
                                    sx={{ my: 0, display: activeDrawMethod === "Circle" ? "block" : "none" }}
                                    InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                    id="flightRadius"
                                    inputProps={{ "data-testid": "flightRadius" }}
                                />
                                <Altitudes
                                    minAltitude={flightMinAltitude}
                                    maxAltitude={flightMaxAltitude}
                                    handleAltitudeChange={handleAltitudeChange}
                                    disabled={flightFieldsDisabled}
                                    error={snackbar && IsInvalidAltitude(flightMinAltitude, flightMaxAltitude)}
                                />
                            </Box>
                            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 2 }}>
                                <TextField
                                    value={flightPilot.email}
                                    sx={{ mt: 0, mb: 0 }}
                                    label="Flight Pilot"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    disabled
                                />
                                <TextField
                                    value={flightPilotPhoneNumber}
                                    disabled={flightFieldsDisabled}
                                    onChange={handlePhoneNumberChange}
                                    error={snackbar && IsInvalidPhoneNumber(flightPilotPhoneNumber)}
                                    sx={{ mt: 0, mb: 0 }}
                                    label="Flight Pilot Phone Number"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    id="phoneNumber"
                                    inputProps={{ "data-testid": "phoneNumber" }}
                                />
                                <FormControl disabled={flightFieldsDisabled}>
                                    <InputLabel id="select-vehicle-air">Flight Vehicle</InputLabel>
                                    <Select
                                        id="selectVehicle"
                                        label="Flight Vehicle"
                                        value={flightVehicleUUID}
                                        onChange={(e) => setFlightVehicleUUID(e.target.value)}
                                        error={snackbar && !flightVehicleUUID}
                                        inputProps={{ "data-testid": "selectVehicle" }}
                                    >
                                        {visibleDrones.map((drone, i) => (
                                            <MenuItem key={i} value={drone.vehicle_uuid} id={drone.vehicle_uuid}>
                                                {drone.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                    <FormControlLabel
                                        label="BVLOS"
                                        labelPlacement="top"
                                        sx={{ mr: 0, ml: 0 }}
                                        control={<Switch checked={flightBVLOS} onChange={(event) => setFlightBVLOS(event.target.checked)} />}
                                    />
                                    <FormControlLabel
                                        label="Part 107"
                                        labelPlacement="top"
                                        sx={{ mr: 0, ml: 0 }}
                                        control={<Switch checked={flightPart107} onChange={(event) => setFlightPart107(event.target.checked)} id="part107" />}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {activeStep === 1 ? (
                        <Box sx={{ display: "flex" }}>
                            <TabContext value={activeAlertMethodTab}>
                                <Box sx={{ borderRight: 1, borderColor: "divider", width: "200px" }}>
                                    <TabList onChange={handleChangeAlertUploadMethod} orientation="vertical">
                                        <Tab label="None" value="None" data-testid="tabNone" />
                                        <Tab label="KML Upload" value="KML" data-testid="tabKmlUpload" />
                                        <Tab label="Automatic" value="Automatic" data-testid="tabAutomatic" />
                                    </TabList>
                                </Box>

                                <input
                                    type="file"
                                    ref={alertFileUploadRef}
                                    style={{ display: "none" }}
                                    onChange={(e) => handleAlertFileChange(e.target.files)}
                                    onClick={(e) => {
                                        e.target.value = "";
                                    }}
                                    data-testid="fileUpload"
                                />

                                <TabPanel value="None" sx={{ width: "100%", py: "4px" }}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "16px",
                                            gap: "16px"
                                        }}
                                    >
                                        <TaskAltOutlinedIcon fontSize="large" />
                                        <Typography variant="body1" id="alertMessage">
                                            {"Choose to add an alert, or click below to finish"}
                                        </Typography>
                                    </Box>
                                </TabPanel>
                                <TabPanel value="KML" sx={{ width: "100%", py: "4px" }}>
                                    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 2 }}>
                                        <TextField
                                            onChange={(e) => setAlertName(e.target.value)}
                                            error={snackbar && !alertName}
                                            sx={{ mt: 0, mb: 0 }}
                                            value={alertName}
                                            variant="outlined"
                                            margin="dense"
                                            label="Alert Name"
                                            fullWidth
                                            id="alertName"
                                            inputProps={{ "data-testid": "alertName" }}
                                        />
                                        <FormControl>
                                            <InputLabel id="select-vehicle-air">Alerts For</InputLabel>
                                            <Select
                                                value={alertManned}
                                                id="selectManned"
                                                inputProps={{ "data-testid": "selectMannedKML" }}
                                                label="Alerts For"
                                                onChange={(e) => setAlertManned(e.target.value)}
                                            >
                                                <MenuItem value={"all"} id="kmlOptionAll">
                                                    All Aircraft
                                                </MenuItem>
                                                <MenuItem value={"manned"} id="kmlOptionManned">
                                                    Manned
                                                </MenuItem>
                                                <MenuItem value={"unmanned"} id="kmlOptionUnmanned">
                                                    Unmanned
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel>Alert Color</InputLabel>
                                            <Select
                                                value={alertColorId}
                                                label="Alert Color"
                                                inputProps={{ "data-testid": "selectColorKML" }}
                                                onChange={(e) => setAlertColorId(e.target.value)}
                                                id="selectColor"
                                            >
                                                {props.colors.map(({ id, color }) => (
                                                    <MenuItem key={id} value={id} id={color}>
                                                        {color}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Altitudes
                                            minAltitude={alertMinAltitude}
                                            maxAltitude={alertMaxAltitude}
                                            handleAltitudeChange={handleAlertAltitudeChange}
                                            error={snackbar && IsInvalidAltitude(alertMinAltitude, alertMaxAltitude)}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="Automatic" sx={{ width: "100%", py: "4px" }}>
                                    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 2 }}>
                                        <TextField
                                            onChange={(e) => setAlertName(e.target.value)}
                                            error={snackbar && !alertName}
                                            sx={{ mt: 0, mb: 0 }}
                                            value={alertName}
                                            variant="outlined"
                                            margin="dense"
                                            label="Alert Name"
                                            fullWidth
                                            id="alertName"
                                            inputProps={{ "data-testid": "alertName" }}
                                        />
                                        <TextField
                                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                            error={snackbar && (isNaN(parseFloat(alertBuffer)) || parseFloat(alertBuffer) < 0)}
                                            onChange={handleAlertBufferChange}
                                            sx={{ mt: 0, mb: 0 }}
                                            type="number"
                                            value={alertBuffer}
                                            variant="outlined"
                                            margin="dense"
                                            label="Alert Buffer"
                                            fullWidth
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ "data-testid": "alertBuffer" }}
                                        />
                                        <FormControl>
                                            <InputLabel id="select-vehicle-air">Alerts For</InputLabel>
                                            <Select
                                                value={alertManned}
                                                id="selectManned"
                                                label="Alerts For"
                                                onChange={(e) => setAlertManned(e.target.value)}
                                                inputProps={{ "data-testid": "selectManned" }}
                                            >
                                                <MenuItem value={"all"} id="optionAll">
                                                    All Aircraft
                                                </MenuItem>
                                                <MenuItem value={"manned"} id="optionManned">
                                                    Manned
                                                </MenuItem>
                                                <MenuItem value={"unmanned"} id="optionUnmanned">
                                                    Unmanned
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel>Alert Color</InputLabel>
                                            <Select
                                                value={alertColorId}
                                                label="Alert Color"
                                                onChange={(e) => setAlertColorId(e.target.value)}
                                                id="selectColor"
                                                inputProps={{ "data-testid": "selectColor" }}
                                            >
                                                {props.colors.map(({ id, color }) => (
                                                    <MenuItem key={id} value={id} id={color}>
                                                        {color}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Altitudes
                                            minAltitude={alertMinAltitude}
                                            maxAltitude={alertMaxAltitude}
                                            handleAltitudeChange={handleAlertAltitudeChange}
                                            error={snackbar && IsInvalidAltitude(alertMinAltitude, alertMaxAltitude)}
                                        />
                                    </Box>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {activeStep === 2 ? (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 1, pt: 1 }}>
                            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label={`Start Time (${GetTimezone()}) *`}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                inputProps: { "data-testid": "startTimeReview" }
                                            }
                                        }}
                                        onChange={(value) => value}
                                        value={dayjs(flightStart)}
                                        disabled
                                    />
                                    <DateTimePicker
                                        label={`End Time (${GetTimezone()}) *`}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                inputProps: { "data-testid": "endTimeReview" }
                                            }
                                        }}
                                        onChange={(value) => value}
                                        value={dayjs(flightEnd)}
                                        disabled
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 2 }}>
                                <TextField
                                    sx={{ mt: 0, mb: 0 }}
                                    value={flightName}
                                    variant="outlined"
                                    margin="dense"
                                    label="Flight Name"
                                    disabled
                                    fullWidth
                                    id="flightNameReview"
                                    inputProps={{ "data-testid": "flightNameReview" }}
                                />
                                <FormControl disabled>
                                    <InputLabel id="select-priority-air">Flight Priority</InputLabel>
                                    <Select value={flightPriority} id="selectType" labelId="select-label" label="Flight Priority">
                                        <MenuItem value={CONSTANT.PERSONAL_TYPE}>0 - No Priority/Personal/Hobbyist</MenuItem>
                                        <MenuItem value={CONSTANT.TRAINING_TYPE}>10 - Training</MenuItem>
                                        <MenuItem value={CONSTANT.COMMERCIAL_TYPE}>20 - Commercial</MenuItem>
                                        <MenuItem value={CONSTANT.PUBLIC_SAFETY_TYPE}>30 - Public Safety</MenuItem>
                                        <MenuItem value={CONSTANT.EMERGENCY_TYPE}>40 - Emergency</MenuItem>
                                    </Select>
                                </FormControl>
                                <Altitudes minAltitude={flightMinAltitude} maxAltitude={flightMaxAltitude} disabled />
                            </Box>
                            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 2 }}>
                                <TextField
                                    value={flightPilot.email}
                                    sx={{ mt: 0, mb: 0 }}
                                    label="Flight Pilot"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    disabled
                                />
                                <TextField
                                    value={flightPilotPhoneNumber}
                                    sx={{ mt: 0, mb: 0 }}
                                    label="Flight Pilot Phone Number"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    disabled
                                    inputProps={{ "data-testid": "phoneNumberReview" }}
                                />
                                <FormControl disabled>
                                    <InputLabel id="select-vehicle-air">Flight Vehicle</InputLabel>
                                    <Select id="selectVehicleReview" label="Flight Vehicle" value={flightVehicleUUID}>
                                        {visibleDrones.map((drone, i) => (
                                            <MenuItem key={i} value={drone.vehicle_uuid}>
                                                {drone.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {activeAlertMethodTab !== "None" ? (
                                <>
                                    <Divider textAlign="left" flexItem>
                                        Alert Information
                                    </Divider>

                                    <Box
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: activeAlertMethodTab === "Automatic" ? "1fr 1fr 1fr" : "1fr 1fr",
                                            gap: 2
                                        }}
                                    >
                                        <TextField
                                            sx={{ mt: 0, mb: 0 }}
                                            value={alertName}
                                            variant="outlined"
                                            margin="dense"
                                            label="Alert Name"
                                            fullWidth
                                            disabled
                                        />
                                        {activeAlertMethodTab === "Automatic" ? (
                                            <TextField
                                                sx={{ mt: 0, mb: 0 }}
                                                value={alertBuffer}
                                                variant="outlined"
                                                margin="dense"
                                                label="Alert Buffer"
                                                fullWidth
                                                disabled
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        <FormControl disabled>
                                            <InputLabel id="select-vehicle-air">Alerts For</InputLabel>
                                            <Select value={alertManned} id="selectManned" label="Alerts For">
                                                <MenuItem value={"all"}>All Aircraft</MenuItem>
                                                <MenuItem value={"manned"}>Manned</MenuItem>
                                                <MenuItem value={"unmanned"}>Unmanned</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 2 }}>
                                        <Altitudes minAltitude={alertMinAltitude} maxAltitude={alertMaxAltitude} disabled />
                                        <FormControl disabled>
                                            <InputLabel>Alert Color</InputLabel>
                                            <Select value={alertColorId} label="Alert Color">
                                                {props.colors.map(({ id, color }) => (
                                                    <MenuItem key={id} value={id}>
                                                        {color}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </>
                            ) : (
                                <></>
                            )}
                        </Box>
                    ) : (
                        <></>
                    )}
                    {activeStep === 3 ? (
                        laancOn === "false" ? (
                            <Box
                                sx={{
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "16px",
                                    gap: "16px"
                                }}
                            >
                                {operationVolume.state === CONSTANT.DECONFLICTION_CLEAR_STATUS ? (
                                    <>
                                        <TaskAltOutlinedIcon fontSize="large" />
                                        <Typography variant="body1" id="publishMessage">
                                            {"You're all set, click below to publish"}
                                        </Typography>
                                    </>
                                ) : operationVolume.state === CONSTANT.DECONFLICTION_BLOCKED_STATUS ? (
                                    <>
                                        <ReportProblemOutlinedIcon fontSize="large" />
                                        <Typography variant="body1" id="conflictMessage">
                                            {"Your operation is conflicted, please update your flight"}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <TimerOutlinedIcon fontSize="large" />
                                        <Typography variant="body1">{"Your operation is still awaiting approval"}</Typography>
                                    </>
                                )}
                            </Box>
                        ) : bypassBlock ? (
                            <Suspense fallback={<></>}>
                                <BypassDetails setBypassReason={setBypassReason} />
                            </Suspense>
                        ) : (
                            <Suspense fallback={<></>}>
                                <FlightWorkflowDetails
                                    state={operationVolume.state}
                                    advisories={operationVolume.advisories}
                                    key={operationVolume.state}
                                    volumes={laancVolumes}
                                    setSelectedLaancVolumeNames={setSelectedLaancVolumeNames}
                                    handleUpdateVolume={handleUpdateVolumeState}
                                    handleLaancVolumeSelected={handleLaancVolumeSelected}
                                    selectionModel={selectionModel}
                                    page={page}
                                    setPage={setPage}
                                    setSafetyJustification={setSafetyJustification}
                                />
                            </Suspense>
                        )
                    ) : (
                        <></>
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button sx={{ mr: "auto" }} onClick={handleClose} id="close">
                    Close
                </Button>

                {activeStep !== 0 && canEditFlight(operationVolume.state) ? <BackButton /> : null}
                {activeStep < 3 ? <SaveButton /> : null}
                {activeStep == 3 && canPublishByState(operationVolume.state, laancOn) ? <PublishButton /> : null}
                {activeStep == 3 && isFlightBlocked(operationVolume.state) ? bypassBlock ? <PublishButton /> : <ProceedWithoutLAANCButton /> : null}
                {activeStep == 3 && canSubmitToLAANC(operationVolume.state, laancOn) && !bypassBlock ? <SubmitToLAANCButton /> : null}
            </DialogActions>
        </Dialog>
    );
};
