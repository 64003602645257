import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import Tooltip from "@mui/material/Tooltip";

import { useUserAuth } from "../contexts/authContext";

export const EntityEditDialog = (props) => {
    const [editCallsign, setEditCallsign] = useState("");
    const [editDpz, setEditDpz] = useState(false);
    const [editFlightUUID, setEditFlightUUID] = useState("N/A");

    const [selectedDaaConfigurationUuid, setSelectedDaaConfigurationUuid] = useState("");
    const [allDaaConfigurations, setAllDaaConfigurations] = useState([]);
    const [fetchingConfigurations, setFetchingConfigurations] = useState(true);

    const [callsignErrorText, setCallsignErrorText] = useState("");
    const [daaConfigurationErrorText, setDaaConfigurationErrorText] = useState("");

    const { user, handleFailedFetch } = useUserAuth();

    useEffect(() => {
        setEditCallsign(props.asdEditEntity.name);
        setEditDpz(props.asdEditEntity.show_dpz);

        const operation = props.operations.find(({ flight_uuid }) => {
            return flight_uuid === props.asdEditEntity.flight_uuid;
        });
        if (operation !== undefined) {
            setEditFlightUUID(props.asdEditEntity.flight_uuid);
        }
        fetchDaaConfigurations();
    }, []);

    const fetchDaaConfigurations = () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("/api/zca_configs/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const prevConfigUuid = props.asdEditEntity.daa_config_uuid;
                if (prevConfigUuid && prevConfigUuid !== "") {
                    const selected = data.find(({ config_uuid }) => {
                        return prevConfigUuid === config_uuid;
                    });
                    if (selected !== undefined) {
                        setSelectedDaaConfigurationUuid(prevConfigUuid);
                    }
                }
                setAllDaaConfigurations(data);
                setFetchingConfigurations(false);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const handleUpdateASDEntity = async () => {
        // ensure fields are valid
        let validationError = false;
        if (!editCallsign) {
            setCallsignErrorText("Please enter a valid callsign.");
            validationError = true;
        } else if (callsignErrorText !== "") {
            setCallsignErrorText("");
        }
        if (editDpz === true && selectedDaaConfigurationUuid === "") {
            setDaaConfigurationErrorText("Please select a valid DAA Configuration.");
            validationError = true;
        } else if (daaConfigurationErrorText !== "") {
            setDaaConfigurationErrorText("");
        }
        if (validationError === true) {
            return;
        }
        const asd = {
            id: props.asdEditEntity.id,
            callsign: editCallsign,
            show_dpz: editDpz,
            organization: user.organization_id,
            history_locations: props.asdEditEntity.history_locations,
            flight_uuid: editFlightUUID,
            user_id: user.id,
            alerting_params_uuid: selectedDaaConfigurationUuid
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(asd)
        };
        await fetch("api/asd/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                props.setASDEditDialogOpen(false);
                props.handleShowEntityDpz(asd);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const handleDaaConfigurationChange = ({ target }) => {
        if (editDpz === false) {
            setEditDpz(true);
        } else if (editDpz === true && target.value === "") {
            setEditDpz(false);
        }
        setSelectedDaaConfigurationUuid(target.value);
    };
    const handleDaaSettingChange = () => {
        setEditDpz((prev) => {
            const newValue = !prev;
            if (newValue === false) {
                setSelectedDaaConfigurationUuid("");
            } else {
                const defaultConfiguration = allDaaConfigurations
                    .filter((configuration) => configuration.organization_id === user.organization_id)
                    .find((configuration) => configuration.org_default === true);

                if (defaultConfiguration !== undefined) {
                    setSelectedDaaConfigurationUuid(defaultConfiguration.config_uuid);
                }
            }
            return newValue;
        });
    };
    return fetchingConfigurations === false ? (
        <Dialog
            hideBackdrop
            open={props.asdEditDialogOpen}
            onContextMenu={(e) => e.preventDefault()}
            PaperProps={{ style: { position: "fixed", top: "0px", width: { sm: "400px" } } }}
            container={props.mapContainer ? props.mapContainer : undefined}
        >
            <DialogTitle sx={{ padding: "10px 20px !important" }}>Tag Entity</DialogTitle>
            <DialogContent sx={{ padding: "5px 20px !important" }}>
                <Box display="flex">
                    <TextField
                        inputProps={{ "data-testid": "editCallsign" }}
                        onChange={(e) => setEditCallsign(e.target.value)}
                        sx={{ width: "100%" }}
                        size="small"
                        variant="outlined"
                        value={editCallsign || ""}
                        id="callsignInput"
                        label="Callsign"
                        error={callsignErrorText !== ""}
                        helperText={callsignErrorText}
                    />
                    <Tooltip title={editDpz ? "Disable DAA" : "Enable DAA"}>
                        <span>
                            <IconButton onClick={handleDaaSettingChange} onMouseDown={(e) => e.preventDefault()} edge="end">
                                {editDpz ? <NotificationsIcon /> : <NotificationsOffIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box sx={{ pt: "10px" }}>
                    <FormControl size="small" sx={{ width: "100%" }}>
                        <InputLabel id="operation-label">Operation</InputLabel>
                        <Select
                            labelId="operation-label"
                            inputProps={{ "data-testid": "operations" }}
                            value={editFlightUUID}
                            label="Operation"
                            onChange={(event) => setEditFlightUUID(event.target.value)}
                        >
                            <MenuItem key="" value="N/A">
                                <em>Not Associated</em>
                            </MenuItem>
                            {props.operations.map((op) => (
                                <MenuItem key={op.flight_uuid} value={op.flight_uuid}>
                                    {op.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ pt: "10px" }}>
                    <FormControl size="small" sx={{ width: "100%" }} error={daaConfigurationErrorText !== ""}>
                        <InputLabel shrink id="daa-config-label">
                            DAA Configuration
                        </InputLabel>
                        <Select
                            labelId="daa-config-label"
                            displayEmpty
                            value={selectedDaaConfigurationUuid}
                            input={<OutlinedInput notched label="DAA Configuration" />}
                            onChange={handleDaaConfigurationChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {allDaaConfigurations.map(({ config_uuid, name }) => (
                                <MenuItem key={config_uuid} value={config_uuid}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{daaConfigurationErrorText}</FormHelperText>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setASDEditDialogOpen(false)} data-testid="cancel" size="small">
                    Cancel
                </Button>
                <Button onClick={handleUpdateASDEntity} data-testid="accept" size="small">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <></>
    );
};
