import { useEffect } from "react";

import Geocoder from "ol-geocoder";

import "../../css/ol-geocoder.css";

const useOpenLayersGeocoder = (map) => {
    useEffect(() => {
        if (!map) {
            return;
        }
        const geocoder = new Geocoder("nominatim", {
            provider: "osm",
            lang: "en-US",
            placeholder: "Search by city",
            targetType: "glass-button",
            limit: 5,
            preventDefault: true,
            preventPanning: true,
            preventMarker: true,
            debug: false,
            autoComplete: true,
            keepOpen: false
        });
        map.addControl(geocoder);

        geocoder.on("addresschosen", ({ place }) => {
            map.getView().setCenter([place.lon, place.lat]);
            map.getView().setZoom(12);
        });

        return () => {
            map.removeControl(geocoder);
        };
    }, [map]);
};

export default useOpenLayersGeocoder;
