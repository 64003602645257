import React, { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import SellIcon from "@mui/icons-material/Sell";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VectorTileLayer from "ol/layer/VectorTile";

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Divider,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
    Typography
} from "@mui/material";
import { ConvertISOToDate, ConvertMetersToFeet } from "../util";
import { getOperationPriority } from "../manager/operations/opUtil";
import { useUserAuth } from "../contexts/authContext";
import {
    getDeleteConstraintButtonVisibility,
    getEditConstraintButtonVisibility,
    getGeometryStyleFromColor,
    getPublishConstraintButtonVisibility,
    getTagButtonVisibility,
    IsMapBeingDrawnOn
} from "./utils/olUtil";

const infoMapping = {
    asd: (asd) => {
        const latDeg = isNaN(asd.lat_deg) ? "--" : asd.lat_deg.toFixed(5);
        const lonDeg = isNaN(asd.lon_deg) ? "--" : asd.lon_deg.toFixed(5);
        const info = [
            { title: "Tail Number: ", value: asd.tail_number },
            { title: "Track Angle: ", value: `${asd.course_deg}°` },
            { title: "Position: ", value: `( ${lonDeg}, ${latDeg} )` },
            { title: "Altitude (HAE): ", value: `${asd.alt_hae_ft}ft` },
            { title: "Ground Speed: ", value: `${asd.ground_speed_kn}kn` },
            { title: "Source Type: ", value: asd.source_type },
            { title: "Classification: ", value: asd.classification },
            { title: "Source Name: ", value: asd.source_name || asd.source_id },
            { title: "Last Update: ", value: ConvertISOToDate(asd.timestamp) }
        ];
        if (asd.coasted) {
            info.push({ title: "COASTED", value: "", color: "rgba(200, 200, 0, 0.8)" });
        }
        if (asd.eot) {
            info.push({ title: "EOT", value: "", color: "rgba(200, 0, 0, 0.8)" });
        }
        return info;
    },
    flight: (flight) => [
        { title: "State: ", value: flight.state },
        { title: "Priority: ", value: `${getOperationPriority(flight.priority)}` },
        { title: "Altitude (AGL): ", value: `${ConvertMetersToFeet(flight.volumes[0].altitude_max_agl_m)}ft` },
        { title: "Time Start: ", value: `${ConvertISOToDate(flight.time_start)}` },
        { title: "Time End: ", value: `${ConvertISOToDate(flight.time_end)}` }
    ],
    constraint: (constraint) => [
        { title: "State: ", value: constraint.state },
        { title: "Type: ", value: `${constraint.type}` },
        { title: "Altitude (AGL): ", value: `${ConvertMetersToFeet(constraint.volumes[0].altitude_max_agl_m)}ft` },
        { title: "Time Start: ", value: `${ConvertISOToDate(constraint.time_start)}` },
        { title: "Time End: ", value: `${ConvertISOToDate(constraint.time_end)}` }
    ],
    alert_volume: (alert_volume) => [
        { title: "Altitude Min (HAE): ", value: `${ConvertMetersToFeet(alert_volume.altitude_min_hae_m)}ft` },
        { title: "Altitude Max (HAE): ", value: `${ConvertMetersToFeet(alert_volume.altitude_max_hae_m)}ft` },
        { title: "Altitude Min (AGL): ", value: `${ConvertMetersToFeet(alert_volume.altitude_min_agl_m)}ft` },
        { title: "Altitude Max (AGL): ", value: `${ConvertMetersToFeet(alert_volume.altitude_max_agl_m)}ft` },
        { title: "Time Start: ", value: `${ConvertISOToDate(alert_volume.time_start)}` },
        { title: "Time End: ", value: `${ConvertISOToDate(alert_volume.time_end)}` },
        { title: "Date Created: ", value: `${ConvertISOToDate(alert_volume.date_created)}` }
    ],
    sensor: (sensor) => [
        { title: "Sensor ID: ", value: `${sensor.truth_source}` },
        { title: "Sensor Type: ", value: `${sensor.sensor_type}` }
    ],
    LOCAL_TYPE: (localType, feature) => [
        { title: "Name: ", value: `${feature.getProperties().NAME}` },
        { title: "Local Type: ", value: `${localType}` }
    ],
    laanc_volume: (laancVolume) => {
        const info = [
            { title: "LAANC Ref Code: ", value: laancVolume.id },
            { title: "Submission State: ", value: `${laancVolume.faa_request_type.toUpperCase().replace(/_/g, " ")}` },
            { title: "LAANC Volume State: ", value: `${laancVolume.state.toUpperCase().replace(/_/g, " ")}` },
            { title: "Altitude (AGL): ", value: `${ConvertMetersToFeet(laancVolume.altitude_max_agl_m)}ft` },
            { title: "Facility Code: ", value: `${laancVolume.airport}` },
            { title: "Time Start: ", value: `${ConvertISOToDate(laancVolume.time_start)}` },
            { title: "Time End: ", value: `${ConvertISOToDate(laancVolume.time_end)}` }
        ];
        if (laancVolume.advisories) {
            laancVolume.advisories.forEach((advisory) => {
                info.push({ title: advisory.type + ": ", value: `${advisory.msg}` });
            });
        }
        return info;
    }
};

export default function OpenLayersInfoCard({
    map,
    showButtons,
    featureToShow,
    handleWatchEntityButtonClick,
    handleTagButtonClick,
    handleEditConstraintButtonClick,
    airspaceSubLayers,
    mapContainer
}) {
    const [popperPosition, setPopperPosition] = useState(null);
    const [featuresClicked, setFeaturesClicked] = useState([]);
    const [featureInfo, setFeatureInfo] = useState([]);
    const [featureDeleted, setFeatureDeleted] = useState(false);

    const featuresClickedRef = useRef([]);
    const selected_sensor_feature_ref = useRef(null);
    const { user, setSnackbar, handleFailedFetch } = useUserAuth();

    useEffect(() => {
        if (!map) {
            return;
        }

        map.on("click", handleMapSelection);
        setupDynamicCursor();
        handleFeatureChanges();

        return () => {
            if (map) {
                map.un("click", handleMapSelection);
            }
        };
    }, [map, airspaceSubLayers]);

    useEffect(() => {
        featuresClickedRef.current = featuresClicked;

        if (selected_sensor_feature_ref.current) {
            const style = getGeometryStyleFromColor("#000000");
            selected_sensor_feature_ref.current.setStyle(style);
            selected_sensor_feature_ref.current = null;
        }

        // We will only get the info of a feature if there is only 1 selected.
        if (featuresClicked.length !== 1) {
            setFeatureDeleted(false);
            return;
        }

        // Get the entity's info to display in the info card.
        const properties = featuresClicked[0].getProperties();
        for (const type in infoMapping) {
            if (type in properties) {
                const featureInfo = infoMapping[type](properties[type], featuresClicked[0]);
                setFeatureInfo(featureInfo);
                break;
            }
        }

        const is_sensor = featuresClicked[0].get("sensor");
        if (is_sensor) {
            const style = getGeometryStyleFromColor("#00FF00");
            featuresClicked[0].setStyle(style);
            selected_sensor_feature_ref.current = featuresClicked[0];
        }
    }, [featuresClicked]);

    useEffect(() => {
        if (featureToShow) {
            setFeaturesClicked([featureToShow]);
        }
    }, [featureToShow]);

    const handleMapSelection = ({ pixel, originalEvent }) => {
        if (IsMapBeingDrawnOn(map)) {
            return false;
        }

        const features = [];
        map.forEachFeatureAtPixel(pixel, (feature, layer) => {
            if (feature.get("name")) {
                features.push(feature);
            } else if (!(layer instanceof VectorTileLayer)) {
                return;
            }

            const properties = feature.getProperties();
            const setting = airspaceSubLayers ? airspaceSubLayers.find((layer) => layer.local_type === properties.LOCAL_TYPE) : null;
            if (setting && setting.visible) {
                features.push(feature);
            }
        });
        const filteredFeatures = features.filter((feature) => {
            if (!feature.getId()) {
                return true;
            }
            const id = feature.getId();
            return !id.includes("_pl") && !id.includes("_hl") && !id.includes("_dpz");
        });

        setFeaturesClicked(filteredFeatures);
        setFeatureDeleted(false);

        if (features.length > 0) {
            const virtualElement = {
                getBoundingClientRect: () => ({
                    width: 0,
                    height: 0,
                    top: originalEvent.y,
                    right: originalEvent.x,
                    bottom: originalEvent.y,
                    left: originalEvent.x
                }),
                nodeType: 1
            };
            setPopperPosition(virtualElement);
        } else if (popperPosition) {
            setPopperPosition(null);
        }
    };

    // Sets up the cursor changing to pointer when hovering over selectable layers.
    const setupDynamicCursor = () => {
        map.on("pointermove", (event) => {
            if (IsMapBeingDrawnOn(map)) {
                return;
            }

            let selectableLayer = false;
            map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                if (layer.get("selectable")) {
                    selectableLayer = true;
                    return true;
                } else if (!(layer instanceof VectorTileLayer)) {
                    return;
                }

                const properties = feature.getProperties();
                const setting = airspaceSubLayers ? airspaceSubLayers.find((layer) => layer.local_type === properties.LOCAL_TYPE) : null;
                if (setting && setting.visible) {
                    selectableLayer = true;
                    return true;
                }
            });
            map.getTargetElement().style.cursor = selectableLayer ? "pointer" : "";
        });
    };

    // Handles the changes and removals of features whose info is being displayed.
    const handleFeatureChanges = () => {
        map.getLayers().on("add", ({ element }) => {
            const layer = element;
            if (!layer.get("selectable")) {
                return;
            }
            const source = layer.getSource();
            source.on("changefeature", (event) => {
                const changedFeature = event.feature;
                if (featuresClickedRef.current.length === 1 && featuresClickedRef.current[0].getId() === changedFeature.getId()) {
                    setFeatureDeleted(false);
                    setFeaturesClicked([changedFeature]);
                }
            });
            source.on("removefeature", (event) => {
                const removedFeature = event.feature;
                if (featuresClickedRef.current.length === 1 && featuresClickedRef.current[0].getId() === removedFeature.getId()) {
                    if (removedFeature.get("asd")) {
                        setFeatureDeleted(true);
                    } else {
                        setFeaturesClicked([]);
                    }
                } else if (featuresClickedRef.current.length > 1) {
                    setFeaturesClicked((prev) => prev.filter((feature) => feature.getId() !== removedFeature.getId()));
                }
            });
        });
    };

    const handlePublishConstraintButtonClick = (feature) => {
        if (!confirm("Are you sure you want to publish this constraint?")) {
            return;
        }

        const constraint = feature.get("constraint");
        const current_time_ms = Date.now();
        const constraint_start_time_ms = new Date(constraint.time_start).getTime();

        if (current_time_ms > constraint_start_time_ms) {
            alert("The start time of this constraint has passed. Please update the start time before publishing.");
            return;
        }

        constraint.version = parseInt(constraint.version) + 1;
        constraint.state = "ACCEPTED";

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("api/constraint/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setSnackbar({ children: "Constraint successfully updated", severity: "success" }))
            .catch((err) => handleFailedFetch(err));
    };

    const handleDeleteConstraintButtonClick = (feature) => {
        if (!confirm("Are you sure you want to delete this constraint?")) {
            return;
        }

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(feature.get("constraint"))
        };
        fetch("/api/constraint/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setSnackbar({ children: "Constraint successfully deleted", severity: "success" }))
            .catch((err) => handleFailedFetch(err));
    };

    return featuresClicked.length > 1 ? (
        // Shows the feature menu list if multiple features are selected at once.
        <Popper open={!!popperPosition} anchorEl={popperPosition} placement={"bottom-end"} container={mapContainer ? mapContainer : undefined}>
            <Paper>
                <ClickAwayListener onClickAway={() => setFeaturesClicked([])}>
                    <MenuList dense sx={{ maxHeight: 200, overflow: "auto" }}>
                        {featuresClicked.map((feature, i) => (
                            <MenuItem key={i} onClick={() => setFeaturesClicked([feature])}>
                                {feature.get("name") || feature.get("NAME")}
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    ) : featuresClicked.length === 1 ? (
        // Shows the info card if only 1 feature is selected.
        <Card
            raised
            sx={{
                position: "absolute",
                zIndex: "1000",
                top: "45px",
                right: "10px",
                maxHeight: "600px",
                minWidth: "300px",
                maxWidth: "400px",
                boxShadow: featureDeleted ? "0 0 4px 2.5px rgba(255, 0, 0, 0.5)" : "0 4px 10px rgba(0, 0, 0, 0.2)"
            }}
        >
            <CardHeader
                disableTypography
                title={
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                            overflow: "hidden"
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                flexGrow: 1,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontStyle: featureDeleted ? "italic" : "normal",
                                mr: "auto"
                            }}
                        >
                            {featuresClicked[0].get("name") || featuresClicked[0].get("NAME")}
                        </Typography>
                        {[
                            {
                                title: "Open Watch Toolbar",
                                icon: <VisibilityIcon fontSize="small" />,
                                visible: showButtons && "asd" in featuresClicked[0].getProperties() && !featureDeleted,
                                onClick: () => handleWatchEntityButtonClick(featuresClicked[0].getId())
                            },
                            {
                                title: "Tag Vehicle",
                                icon: <SellIcon fontSize="small" />,
                                visible: showButtons && getTagButtonVisibility(user, featuresClicked[0]) && !featureDeleted,
                                onClick: () => handleTagButtonClick(featuresClicked[0])
                            },
                            {
                                title: "Publish Constraint",
                                icon: <PublishIcon fontSize="small" />,
                                visible: showButtons && getPublishConstraintButtonVisibility(user, featuresClicked[0]) && !featureDeleted,
                                onClick: () => handlePublishConstraintButtonClick(featuresClicked[0])
                            },
                            {
                                title: "Edit Constraint",
                                icon: <EditIcon fontSize="small" />,
                                visible: showButtons && getEditConstraintButtonVisibility(user, featuresClicked[0]) && !featureDeleted,
                                onClick: () => handleEditConstraintButtonClick(featuresClicked[0])
                            },
                            {
                                title: "Delete Constraint",
                                icon: <DeleteIcon fontSize="small" />,
                                visible: showButtons && getDeleteConstraintButtonVisibility(user, featuresClicked[0]) && !featureDeleted,
                                onClick: () => handleDeleteConstraintButtonClick(featuresClicked[0])
                            },
                            {
                                title: "Close",
                                icon: <CloseIcon fontSize="small" />,
                                visible: true,
                                onClick: () => setFeaturesClicked([])
                            }
                        ].map(({ title, visible, icon, onClick }, i) =>
                            visible === true ? (
                                <Tooltip key={i} title={title}>
                                    <IconButton size="small" aria-label="Monitor" onClick={onClick}>
                                        {icon}
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <React.Fragment key={i} />
                            )
                        )}
                    </Box>
                }
                sx={{
                    p: "4px 4px 4px 12px !important",
                    width: "100%",
                    "& .MuiCardHeader-content": {
                        width: "100%"
                    }
                }}
            />
            <Divider />
            <CardContent sx={{ display: "grid", gridTemplateColumns: "1fr", gap: "4px", pt: "12px !important", pb: "14px !important" }}>
                {featureInfo.map(({ title, value, color }, i) => (
                    <Typography
                        key={i}
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: "flex", alignItems: "start", justifyContent: "flex-start", width: "100%", overflow: "hidden" }}
                        component="div"
                    >
                        <b style={{ color: color ? color : "unset" }}>{title}</b>
                        <div
                            style={{
                                flexGrow: 1,
                                fontStyle: featureDeleted ? "italic" : "normal",
                                textAlign: "right"
                            }}
                        >
                            {value}
                        </div>
                    </Typography>
                ))}
            </CardContent>
        </Card>
    ) : (
        <></>
    );
}
