import React, { useState } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const MapLayersLayer = ({ layer }) => {
    const [expanded, setExpanded] = useState(false);

    const handleLayerClick = (e, handleClick) => {
        e.stopPropagation();
        handleClick();
    };
    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                <Checkbox checked={layer.checked} onClick={(e) => handleLayerClick(e, layer.handleLayerClick)} indeterminate={layer.indeterminate} />

                <Typography variant="body1" sx={{ mr: "auto" }}>
                    {layer.label}
                </Typography>

                {layer.sublayers.length > 0 ? (
                    <IconButton onClick={() => setExpanded((prev) => !prev)}>{expanded === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}</IconButton>
                ) : (
                    <></>
                )}
            </Box>
            {layer.sublayers.length > 0 ? (
                <Collapse in={expanded}>
                    <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                        {layer.sublayers.map((sublayer, i) => (
                            <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                                {sublayer.handleSublayerClick ? <Checkbox checked={sublayer.checked} onClick={sublayer.handleSublayerClick} /> : <></>}

                                <Typography variant="body1" sx={{ mr: "auto" }}>
                                    {sublayer.label}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Collapse>
            ) : (
                <></>
            )}
        </Box>
    );
};

export const MapLayersDropdown = ({
    mapLayersOpen,
    setMapLayersOpen,
    mapLayersAnchorEl,
    altitudeLayerRange,
    handleAltitudeLayerRangeChanged,
    sections,
    mapContainer,
    narChecked,
    handleNarTrackSettingChange
}) => {
    const marks = [
        { value: 0, label: "0ft" },
        { value: 4500, label: "4500ft" },
        { value: 9000, label: "9000ft" },
        { value: 13500, label: "13500ft" },
        { value: 18000, label: "18000ft" }
    ];
    return (
        <Popover
            open={mapLayersOpen}
            anchorEl={mapLayersAnchorEl}
            onClose={() => setMapLayersOpen(false)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            container={mapContainer ? mapContainer : undefined}
        >
            <Box sx={{ p: 2, width: "300px" }}>
                <Typography variant="h5" gutterBottom>
                    Layers
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {altitudeLayerRange && handleAltitudeLayerRangeChanged ? (
                        <Box>
                            <Typography variant="body1" gutterBottom>
                                Altitude
                            </Typography>

                            <Box sx={{ px: 2 }}>
                                <Slider
                                    value={altitudeLayerRange}
                                    onChange={handleAltitudeLayerRangeChanged}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                    max={18000}
                                    step={250}
                                    min={0}
                                />
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, mt: 1 }}>
                                <Checkbox checked={narChecked} onClick={handleNarTrackSettingChange} />

                                <Typography variant="body1" sx={{ mr: "auto" }}>
                                    Show NAR Tracks
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {sections.map((section, section_index) => (
                        <Box key={section_index}>
                            <Typography variant="body1">{section.title}</Typography>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                {section.layers.map((layer, layer_index) => (
                                    <MapLayersLayer key={layer_index} layer={layer} />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Popover>
    );
};
