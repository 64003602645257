import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import { useUserAuth } from "../contexts/authContext";
import { useMap } from "../contexts/mapContext";

export function DroneDialog(props) {
    const { snackbar, setSnackbar } = useUserAuth();
    const { handleFailedFetch } = useUserAuth();
    const { getDrones } = useMap();

    const row = props.row;
    const [name, setName] = useState(row.name);
    const [brand, setBrand] = useState(row.brand);
    const [model, setModel] = useState(row.model);
    const [droneType, setDroneType] = useState(row.drone_type);
    const [serialNumber, setSerialNumber] = useState(row.serial_number);
    const [serialNumberInternal, setSerialNumberInternal] = useState(row.serial_number_internal);
    const [techNumber, setTechNumber] = useState(row.tech_number);
    const [notes, setNotes] = useState(row.notes);
    const [status, setStatus] = useState(row.status);

    const handleSubmit = async () => {
        if (!name || !brand || !model) {
            return setSnackbar({ children: "Please fill in the below fields", severity: "error" });
        }

        const newRow = {
            id: row.id,
            vehicle_uuid: row.vehicle_uuid,
            name: name,
            brand: brand,
            model: model,
            drone_type: droneType,
            serial_number: serialNumber,
            serial_number_internal: serialNumberInternal,
            tech_number: techNumber,
            notes: notes,
            status: status,
            organization_id: row.organization_id
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newRow)
        };
        await fetch("/api/drone/insertUpdate", requestOptions).catch((err) => handleFailedFetch(err));

        getDrones();
        handleClose();
    };

    const handleClose = () => {
        props.setDroneDialogOpen(false);
    };

    return (
        <Dialog onClose={handleClose} open={props.droneDialogOpen} maxWidth="lg" PaperProps={{ sx: { width: { xs: "100%", sm: "auto" } } }}>
            <DialogTitle>Drone Manager</DialogTitle>
            <DialogContent sx={{ pb: 0, pt: 0 }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: { sm: "10px" } }}>
                    <TextField value={name} onChange={(e) => setName(e.target.value)} margin="normal" id="name" label="Name" error={snackbar && !name} />
                    <TextField value={brand} onChange={(e) => setBrand(e.target.value)} margin="normal" id="brand" label="Brand" error={snackbar && !brand} />
                    <TextField value={model} onChange={(e) => setModel(e.target.value)} margin="normal" id="model" label="Model" error={snackbar && !model} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: { sm: "10px" } }}>
                    <TextField value={droneType} onChange={(e) => setDroneType(e.target.value)} margin="normal" id="drone_type" label="Drone Type" />
                    <TextField
                        value={serialNumber}
                        onChange={(e) => setSerialNumber(e.target.value)}
                        margin="normal"
                        id="serial_number"
                        label="Serial Number"
                    />
                    <TextField
                        value={serialNumberInternal}
                        onChange={(e) => setSerialNumberInternal(e.target.value)}
                        margin="normal"
                        id="serial_number_internal"
                        label="Serial Number Internal"
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: { sm: "10px" } }}>
                    <TextField value={techNumber} onChange={(e) => setTechNumber(e.target.value)} margin="normal" id="tech_number" label="Tech Number" />
                    <TextField value={notes} onChange={(e) => setNotes(e.target.value)} margin="normal" id="notes" label="Notes" />
                    <TextField value={status} onChange={(e) => setStatus(e.target.value)} margin="normal" id="status" label="Status" />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ mr: "auto" }} onClick={handleClose} data-testid="close">
                    Close
                </Button>
                <Button onClick={handleSubmit} data-testid="save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
