import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useUserAuth } from "../contexts/authContext";
import { ErrorComponent } from "../errorComponent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IsInvalidEmail } from "./userUtil";

export function Forgot() {
    const { forgotPassword, statusMessage, setStatusMessage } = useUserAuth();
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #121212 inset" };
    const isMobile = useMediaQuery("(max-width:400px)");
    const navigateTo = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get("email") !== data.get("confirm-email")) {
            setError(true);
            setErrorMessage("The two emails must match");
            return false;
        }

        if (IsInvalidEmail(data.get("email"))) {
            setErrorMessage("The entered emails are invalid");
            setError(true);
            return false;
        }

        // create copy of user
        const updatedUser = {};
        (updatedUser.email = data.get("email")), (updatedUser.reset_password = true);

        setError(false);
        setErrorMessage("");
        forgotPassword(updatedUser);
    };

    const handleCancel = () => {
        navigateTo("/login");
    };

    useEffect(() => {
        setStatusMessage("");
    }, []);

    return (
        <Grid item xs={16}>
            <div style={{ justifyContent: "space-around", width: "100%", display: "inline-flex", height: "500px" }}>
                <Box sx={{ justifyContent: "space-around", alignItems: "center", display: "flex", maxWidth: "400px" }}>
                    <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot Password?
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ m: 4 }}>
                            <TextField
                                inputProps={{ style: inputStyle, "data-testid": "email" }}
                                error={error}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoFocus
                            />
                            <TextField
                                inputProps={{ style: inputStyle, "data-testid": "confirm-email" }}
                                error={error}
                                helperText={errorMessage}
                                margin="normal"
                                required
                                fullWidth
                                id="confirm-email"
                                label="Confirm Email"
                                name="confirm-email"
                            />
                            <ErrorComponent statusMessage={statusMessage} />
                            <Box sx={{ justifyContent: "space-between", alignItems: "center", display: "flex" }}>
                                <Button onClick={handleCancel} variant="contained" sx={{ mt: 3, mb: 2 }} size={isMobile ? "small" : "medium"}>
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={statusMessage && !statusMessage.includes("Error") ? true : false}
                                    size={isMobile ? "small" : "medium"}
                                >
                                    Send Email
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Grid>
    );
}
