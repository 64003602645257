import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Drawer } from "@mui/material";
import { useUserAuth } from "../contexts/authContext";
import { useMap } from "../contexts/mapContext";
import { useSocket } from "../contexts/socketContext";
import { GetFeature, GetSource, JumpToFeature } from "../openlayers/utils/olUtil";

export default function AssetCards({ map, editFeature, setEditFeature, assetCardsOpen }) {
    const { assets } = useSocket();
    const { handleFailedFetch } = useUserAuth();
    const { getAssetMonitor, isAssetMonitoring, startAssetMonitoring, stopAssetMonitoring } = useMap();
    const isDesktop = useMediaQuery("(min-width:600px)");

    const handleDeleteClick = (assetUUID) => {
        if (!confirm("Are you sure you want to delete this asset?")) {
            return;
        }
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        };
        fetch(`/api/assets/delete/${assetUUID}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                stopAssetMonitoring(map, assetUUID);
                const assetSource = GetSource(map, "assets");
                const asset = assetSource.getFeatureById(assetUUID);
                if (asset) {
                    assetSource.removeFeature(asset);
                }
            })
            .catch((err) => handleFailedFetch(err));
    };

    return (
        <Drawer
            open={assetCardsOpen}
            anchor={isDesktop ? "right" : "bottom"}
            variant={isDesktop ? "persistent" : "temporary"}
            hideBackdrop={true}
            sx={{ inset: "unset" }}
            PaperProps={{
                sx: {
                    maxHeight: isDesktop ? "100%" : "400px",
                    position: isDesktop ? "relative" : "fixed",
                    width: isDesktop ? "375px" : "100%"
                }
            }}
        >
            {assets.map((asset) => {
                return (
                    <Card key={asset.uuid} sx={{ m: "4px", overflow: "unset" }}>
                        <CardHeader
                            title={
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <Typography
                                        variant="h5"
                                        sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "180px", mr: "auto" }}
                                    >
                                        {asset.name}
                                    </Typography>
                                    <Tooltip title="Monitor">
                                        <IconButton
                                            aria-label="Monitor"
                                            onClick={() => {
                                                if (editFeature && editFeature.getId() === asset.uuid) {
                                                    return;
                                                } else if (isAssetMonitoring(asset.uuid)) {
                                                    stopAssetMonitoring(map, asset.uuid);
                                                } else {
                                                    startAssetMonitoring(map, asset.uuid);
                                                }
                                            }}
                                        >
                                            {isAssetMonitoring(asset.uuid) ? <VisibilityIcon color="success" /> : <VisibilityOffIcon color="error" />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View">
                                        <IconButton aria-label="View" onClick={() => JumpToFeature(map, GetFeature(map, "assets", asset.uuid))}>
                                            <TravelExploreIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <IconButton aria-label="Edit" onClick={() => setEditFeature(GetFeature(map, "assets", asset.uuid))}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="Delete" onClick={() => handleDeleteClick(asset.uuid)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                            sx={{ pb: isAssetMonitoring(asset.uuid) ? "4px !important" : "16px" }}
                        />
                        {isAssetMonitoring(asset.uuid) && (
                            <CardContent sx={{ display: "flex", flexDirection: "row", gap: 3, pt: "0px !important", pb: "16px !important" }}>
                                <Typography variant="body2" color="text.secondary">
                                    <b>Alerts:&nbsp;</b>
                                    <span style={{ color: "yellow", fontWeight: "lighter" }}>{getAssetMonitor(asset.uuid).get("alerts")}</span>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <b>Severe Alerts:&nbsp;</b>
                                    <span style={{ color: "red", fontWeight: "bolder" }}>{getAssetMonitor(asset.uuid).get("severeAlerts")}</span>
                                </Typography>
                            </CardContent>
                        )}
                    </Card>
                );
            })}
        </Drawer>
    );
}
