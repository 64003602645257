import { Box, InputAdornment, TextField } from "@mui/material";
import React from "react";

export const Altitudes = (props) => {
    return (
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2, gridColumn: "span 2" }}>
            <TextField
                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                onChange={(e) => props.handleAltitudeChange(e.target.value, props.maxAltitude)}
                disabled={props.disabled}
                error={props.error}
                sx={{ mt: 0, mb: 0 }}
                value={props.minAltitude}
                label="Minimum Altitude (AGL)"
                type="number"
                variant="outlined"
                margin="dense"
                fullWidth
                onWheel={(e) => e.target.blur()}
                id="minAltitude"
                inputProps={{ "data-testid": "minAltitude" }}
            />
            <TextField
                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                onChange={(e) => props.handleAltitudeChange(props.minAltitude, e.target.value)}
                disabled={props.disabled}
                error={props.error}
                type="number"
                label="Maximum Altitude (AGL)"
                value={props.maxAltitude}
                sx={{ mt: 0, mb: 0 }}
                variant="outlined"
                margin="dense"
                fullWidth
                onWheel={(e) => e.target.blur()}
                id="maxAltitude"
                inputProps={{ "data-testid": "maxAltitude" }}
            />
        </Box>
    );
};
