import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../contexts/authContext";
import { ErrorComponent } from "../errorComponent";
import { PASSWORD_ERROR, IsInvalidEmail, IsInvalidPassword } from "./userUtil";

export function CreateUser() {
    const [emailError, setEmailError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
    const [organization, setOrganization] = React.useState("");
    const [requestDisabled, setRequestDisabled] = React.useState(false);
    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #121212 inset" };
    const isMobile = useMediaQuery("(max-width:400px)");
    const { organizations, signup, statusMessage, setStatusMessage, handleFailedFetch } = useUserAuth();
    const navigateTo = useNavigate();

    React.useEffect(() => {
        setStatusMessage("");
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        //Reset the error messages
        setEmailErrorMessage("");
        setPasswordErrorMessage("");
        setEmailError(false);
        setPasswordError(false);

        const data = new FormData(e.currentTarget);
        const updatedUser = {
            email: data.get("email"),
            password: data.get("password"),
            organization_id: data.get("organization"),
            oidc: false
        };

        //Check if Email address already exists
        let isError = false;
        await fetch("api/user/check/" + updatedUser.email)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                if (data.found) {
                    setEmailErrorMessage("The entered email is already associated with another account");
                    setEmailError(true);
                    isError = true;
                }
            })
            .catch((err) => handleFailedFetch(err));

        //Check if email is valid
        if (IsInvalidEmail(updatedUser.email)) {
            setEmailErrorMessage("The entered email is invalid");
            setEmailError(true);
            isError = true;
        }

        //Check if password is valid
        if (IsInvalidPassword(updatedUser.password)) {
            setPasswordErrorMessage(PASSWORD_ERROR);
            setPasswordError(true);
            return false;
        }

        //Cancel form submission if there is an error
        if (isError) return false;

        setRequestDisabled(true);
        signup(updatedUser);
    };

    const handleCancel = () => {
        navigateTo("/login");
    };

    return (
        <Box>
            <Grid item xs={16}>
                <div style={{ justifyContent: "space-around", width: "100%", display: "inline-flex", height: "500px" }}>
                    <Box sx={{ justifyContent: "space-around", alignItems: "center", display: "flex", maxWidth: "400px" }}>
                        <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Request Account
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} sx={{ m: 4 }}>
                                <TextField
                                    error={emailError}
                                    helperText={emailError ? `${emailErrorMessage}` : ""}
                                    inputProps={{ style: inputStyle, "data-testid": "email" }}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoFocus
                                />
                                <TextField
                                    error={passwordError}
                                    helperText={passwordErrorMessage}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    inputProps={{ style: inputStyle, "data-testid": "password" }}
                                />
                                <FormControl required fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="organization-label">Organization</InputLabel>
                                    <Select
                                        inputProps={{ "data-testid": "organization" }}
                                        name="organization"
                                        labelId="organization-label"
                                        id="selectOrganization"
                                        data-testid="selectOrganization"
                                        value={organization}
                                        label="Organization"
                                        onChange={(e) => setOrganization(e.target.value)}
                                    >
                                        {organizations.map((organization) => (
                                            <MenuItem key={organization.id} value={organization.id} id={organization.id}>
                                                {organization.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <ErrorComponent statusMessage={statusMessage} />
                                <Box sx={{ justifyContent: "space-between", alignItems: "center", display: "flex" }}>
                                    <Button onClick={handleCancel} variant="contained" sx={{ mt: 3, mb: 2 }} size={isMobile ? "small" : "medium"}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={requestDisabled}
                                        size={isMobile ? "small" : "medium"}
                                        id="requestAccount"
                                    >
                                        Request New Account
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Grid>
        </Box>
    );
}
