import React, { useEffect } from "react";
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export function ErrorComponent(props) {
    const [statusMessage, setStatusMessage] = React.useState("");
    const [statusColor, setStatusColor] = React.useState("red");

    useEffect(() => {
        if (props.statusMessage != null) {
            if (props.statusMessage.includes("Error")) {
                setStatusColor("red");
            }
            else {
                setStatusColor("#90caf9");
            }

            setStatusMessage(props.statusMessage);
        }
    }, [props]);

    return (
        <>
            {statusMessage.length > 0 
                    ?   <Typography component="h6" color={statusColor} sx={{mt: 2}}>
                            <ErrorOutlineIcon /> {statusMessage}
                        </Typography>
                    : <></>
            }
        </>
    );
}