import React, { useEffect, useState } from "react";

import LayersIcon from "@mui/icons-material/Layers";

import Box from "@mui/material/Box";

import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

import { ConvertCmpVolumesToFeatures, GetLayer, JumpToFeatures } from "./utils/olUtil";

import { useSocket } from "../contexts/socketContext";
import { useUserAuth } from "../contexts/authContext";

import useMap from "./hooks/useMap";

import OpenLayersMap from "./olMap";
import OpenLayersInfoCard from "./olInfoCard";
import { MapLayersDropdown } from "../map/mapLayersDropdown";

const MapPreview = ({ selected_planned_flights, selected_published_flights, selected_constraints, selected_alert_volumes }) => {
    const [mapLayersOpen, setMapLayersOpen] = useState(false);
    const [mapLayersAnchorEl, setMapLayersAnchorEl] = useState(null);

    const [plannedOperationsVisible, setPlannedOperationsVisible] = useState(false);
    const [publishedOperationsVisible, setPublishedOperationsVisible] = useState(false);
    const [constraintsVisible, setConstraintsVisible] = useState(false);
    const [alertVolumesVisible, setAlertVolumesVisible] = useState(false);

    const { publishedOperations, planningOperations, constraints, alertVolumes } = useSocket();
    const { userMapSettings } = useUserAuth();

    const map = useMap(false);

    // fire all
    useEffect(() => {
        handleMapPreviewLoaded();
    }, [map]);

    // update and jump to selected geometries when they change
    useEffect(() => {
        handleUpdateSelectedGeometries();
    }, [map, selected_planned_flights, selected_published_flights, selected_constraints, selected_alert_volumes]);

    // update other geometries when they change
    useEffect(() => {
        handleUpdateGeometriesThatAreNotSelected();
    }, [map, planningOperations, publishedOperations, constraints, alertVolumes]);

    // function to create vector layers and initialize map layers
    const handleMapPreviewLoaded = () => {
        if (map) {
            const layer_names = [
                {
                    layer_name: "planned_flights",
                    visible: Array.isArray(selected_planned_flights) ? true : false,
                    setVisible: setPlannedOperationsVisible
                },
                {
                    layer_name: "published_flights",
                    visible: Array.isArray(selected_published_flights) ? true : false,
                    setVisible: setPublishedOperationsVisible
                },
                {
                    layer_name: "constraints",
                    visible: Array.isArray(selected_constraints) ? true : false,
                    setVisible: setConstraintsVisible
                },
                {
                    layer_name: "alert_volumes",
                    visible: Array.isArray(selected_alert_volumes) ? true : false,
                    setVisible: setAlertVolumesVisible
                }
            ];
            layer_names.forEach(({ layer_name, visible, setVisible }) => {
                const vector_source = new VectorSource({
                    features: []
                });
                const vector_layer = new VectorLayer({
                    source: vector_source,
                    properties: { name: layer_name },
                    visible: visible
                });
                map.addLayer(vector_layer);

                if (visible) {
                    setVisible(true);
                }
            });
        }
    };
    // function to update selected geometries and jump to them
    const handleUpdateSelectedGeometries = () => {
        if (map) {
            const layer_names = [
                {
                    layer_name: "planned_flights",
                    volumes: selected_planned_flights
                },
                {
                    layer_name: "published_flights",
                    volumes: selected_published_flights
                },
                {
                    layer_name: "constraints",
                    volumes: selected_constraints
                },
                {
                    layer_name: "alert_volumes",
                    volumes: selected_alert_volumes
                }
            ];
            layer_names.map(({ layer_name, volumes }) => {
                if (Array.isArray(volumes)) {
                    const layer = GetLayer(map, layer_name);
                    if (!layer) {
                        return;
                    }
                    const source = layer.getSource();
                    if (!source) {
                        return;
                    }
                    source.clear();

                    const features = ConvertCmpVolumesToFeatures(volumes, userMapSettings);
                    source.addFeatures(features);
                    JumpToFeatures(map, features);
                }
            });
        }
    };
    // function to update the geoemetries that are not initially visible
    const handleUpdateGeometriesThatAreNotSelected = () => {
        if (map) {
            const layer_names = [
                {
                    layer_name: "planned_flights",
                    volumes: Array.isArray(selected_planned_flights) ? undefined : planningOperations
                },
                {
                    layer_name: "published_flights",
                    volumes: Array.isArray(selected_published_flights) ? undefined : publishedOperations
                },
                {
                    layer_name: "constraints",
                    volumes: Array.isArray(selected_constraints) ? undefined : constraints
                },
                {
                    layer_name: "alert_volumes",
                    volumes: Array.isArray(selected_alert_volumes) ? undefined : alertVolumes
                }
            ];
            layer_names.map(({ layer_name, volumes }) => {
                if (Array.isArray(volumes) === false) {
                    return;
                }
                const layer = GetLayer(map, layer_name);
                if (!layer) {
                    return;
                }
                const source = layer.getSource();
                if (source) {
                    source.clear();

                    const features = ConvertCmpVolumesToFeatures(volumes, userMapSettings);
                    source.addFeatures(features);
                }
            });
        }
    };
    const handleToggleLayersButtonClick = (e) => {
        setMapLayersAnchorEl(e.currentTarget);
        setMapLayersOpen((prev) => !prev);
    };
    const handleLayerToggleButtonClick = (layer_name, setVisible) => {
        const layer = GetLayer(map, layer_name);
        if (layer) {
            const updatedVisibility = !layer.getVisible();
            layer.setVisible(updatedVisibility);
            setVisible(updatedVisibility);
        }
    };
    return (
        <Box>
            <OpenLayersMap
                map={map}
                buttons={[
                    {
                        name: "Toggle Layers",
                        visible: true,
                        icon: <LayersIcon />,
                        onClick: handleToggleLayersButtonClick
                    }
                ]}
            >
                <OpenLayersInfoCard map={map} showButtons={false} />
            </OpenLayersMap>
            {mapLayersOpen === true ? (
                <MapLayersDropdown
                    mapLayersOpen={mapLayersOpen}
                    setMapLayersOpen={setMapLayersOpen}
                    mapLayersAnchorEl={mapLayersAnchorEl}
                    sections={[
                        {
                            title: "Volumes",
                            layers: [
                                {
                                    label: "Planning Operations",
                                    checked: plannedOperationsVisible,
                                    handleLayerClick: () => handleLayerToggleButtonClick("planned_flights", setPlannedOperationsVisible),
                                    sublayers: []
                                },
                                {
                                    label: "Operations",
                                    checked: publishedOperationsVisible,
                                    handleLayerClick: () => handleLayerToggleButtonClick("published_flights", setPublishedOperationsVisible),
                                    sublayers: []
                                },
                                {
                                    label: "Constraints",
                                    checked: constraintsVisible,
                                    handleLayerClick: () => handleLayerToggleButtonClick("constraints", setConstraintsVisible),
                                    sublayers: []
                                },
                                {
                                    label: "Alert Volumes",
                                    checked: alertVolumesVisible,
                                    handleLayerClick: () => handleLayerToggleButtonClick("alert_volumes", setAlertVolumesVisible),
                                    sublayers: []
                                }
                            ]
                        }
                    ]}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};

export default MapPreview;
