// Flight State Constants
export const PLANNING_STATUS = "PLANNING"; 
export const DECONFLICTION_CHECK_STATUS = "DECONFLICTION_CHECK";
export const DECONFLICTION_CLEAR_STATUS = "DECONFLICTION_CLEAR";
export const DECONFLICTION_BLOCKED_STATUS = "DECONFLICTION_BLOCKED";
export const FAA_PRECHECK_STATUS = "FAA_PRECHECK"; 
export const PRECHECK_BLOCKED_STATUS = "PRECHECK_BLOCKED";
export const PRECHECK_CLEAR_STATUS = "PRECHECK_CLEAR";
export const LAANC_REQUIRED_STATUS = "LAANC_REQUIRED";
export const LAANC_NOT_REQUIRED_STATUS = "LAANC_NOT_REQUIRED";
export const LAANC_CHECK_STATUS = "LAANC_CHECK";
export const LAANC_BLOCKED_STATUS = "LAANC_BLOCKED";
export const LAANC_FURTHER_COORD_STATUS = "LAANC_FURTHER_COORDINATION";
export const LAANC_AUTO_APPROVED_STATUS = "LAANC_AUTO_APPROVED";
export const FAA_APPROVAL_CHECK_STATUS = "FAA_APPROVAL_CHECK";
export const FAA_APPROVAL_CLEAR_STATUS = "FAA_APPROVAL_CLEAR";
export const FAA_APPROVAL_PENDING_STATUS = "FAA_APPROVAL_PENDING"; 
export const FAA_APPROVAL_BLOCKED_STATUS = "FAA_APPROVAL_BLOCKED";
export const ACCEPTED_STATUS = "ACCEPTED";

// Flight Priority Constants
export const PERSONAL_TYPE = 0;
export const TRAINING_TYPE = 10;
export const COMMERCIAL_TYPE = 20;
export const PUBLIC_SAFETY_TYPE = 30;
export const EMERGENCY_TYPE = 40;

// Flight LAANC states
export const LAANC_NOT_SUBMITTED = "NOT_SUBMITTED";
export const LAANC_PENDING = "PENDING";
export const LAANC_AUTHORIZED = "AUTHORIZED";
export const LAANC_RESCINDED_AWAITING = "RESCINDED_AWAITING";
export const LAANC_RESCINDED_ACK = "RESCIND_ACK";
export const LAANC_INVALID_AWAITING = "INVALID_AWAITING";
export const LAANC_INVALID_CANCEL = "INVALID_CANCEL";
export const LAANC_DENIED = "DENIED";
export const LAANC_EXPIRED = "EXPIRED";
export const LAANC_OPERATOR_CANCEL = "OPERATOR_CANCEL";
export const LAANC_AUTO_CANCEL = "AUTO_CANCEL";
export const LAANC_CLOSED = "CLOSED";
export const LAANC_COMPLETE = "COMPLETE";
export const LAANC_DEAUTHORIZED = "DEAUTHORIZED";
export const LAANC_NEVER_AUTHORIZED = "NEVER_AUTHORIZED";
export const LAANC_DELETED = "DELETED";