import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import CircleStyle from "ol/style/Circle";

import { MultiPoint } from "ol/geom";
import { ConvertMetersToFeet, DateHasAlreadyPassed, GetVerticalSpeedIndicator } from "../../util";
import { allModels } from "../../map/mapModels";

const LAANC_STYLE_DATA = {
    LAANC_AIRSPACE: {
        CLASS_B: {
            fillColor: "rgba(0, 135, 191, 0.1)",
            strokeColor: "rgb(0, 135, 191)"
        },
        CLASS_C: {
            fillColor: "rgba(157, 107, 158, 0.1)",
            strokeColor: "rgb(157, 107, 158)"
        },
        CLASS_D: {
            fillColor: "rgba(5, 142, 184, 0.1)",
            strokeColor: "rgb(5, 142, 184)",
            strokeLineDash: [8, 4]
        },
        CLASS_E: {
            fillColor: "rgba(158, 110, 157, 0.1)",
            strokeColor: "rgb(158, 110, 157)",
            strokeLineDash: [8, 4]
        },
        MODE_C: {
            fillColor: "rgba(157, 107, 158, 0.1)",
            strokeColor: "rgb(157, 107, 158)"
        }
    },
    UASFM: (apt1_laanc, ceiling) => {
        const opacity = Math.max(0, 0.6 - 0.075 * Math.floor(ceiling / 50));
        return {
            fillColor: apt1_laanc === 1 ? `rgba(144, 202, 249, ${opacity})` : `rgba(255, 80, 80, ${opacity})`,
            strokeColor: apt1_laanc === 1 ? "rgb(144, 202, 249)" : "rgb(255, 80, 80)",
            textLabel: `${ceiling}ft`
        };
    },
    NSUFR: {
        fillColor: "rgba(216, 171, 198, 0.1)",
        strokeColor: "rgb(216, 171, 198)",
        strokeLineDash: [8, 4]
    },
    SU_AIRSPACE: {
        A: {
            fillColor: "rgba(3, 136, 191, 0.1)",
            strokeColor: "rgb(3, 136, 191)",
            strokeLineDash: [1, 2]
        },
        MOA: {
            fillColor: "rgba(157, 107, 158, 0.1)",
            strokeColor: "rgb(157, 107, 158)",
            strokeLineDash: [2, 4]
        },
        P: {
            fillColor: "rgba(3, 136, 191, 0.1)",
            strokeColor: "rgb(3, 136, 191)",
            strokeLineDash: [2, 4]
        },
        R: {
            fillColor: "rgba(3, 136, 191, 0.1)",
            strokeColor: "rgb(3, 136, 191)",
            strokeLineDash: [2, 4]
        },
        W: {
            fillColor: "rgba(3, 136, 191, 0.1)",
            strokeColor: "rgb(3, 136, 191)",
            strokeLineDash: [2, 4]
        }
    },
    LAANC_STADIUMS: (name) => ({
        fillColor: "rgba(0, 255, 0, 0.1)",
        strokeColor: "rgb(0, 255, 0)",
        textLabel: name
    }),
    FAA_TFR: (startTime) => {
        const isActive = DateHasAlreadyPassed(startTime);
        return {
            fillColor: isActive ? "rgba(255, 0, 0, 0.1)" : "rgba(255, 165, 0, 0.1)",
            strokeColor: isActive ? "rgb(255, 0, 0)" : "rgb(255, 165, 0)"
        };
    },
    AIRPORTS: (type_code) => {
        let image = "";
        if (type_code === "HP") {
            image = "../static/2d-models/airport/HelicopterIcon.svg";
        } else {
            image = "../static/2d-models/airport/AirplaneIcon.svg";
        }
        return {
            image: image
        };
    },
    DC_FRZ_SFRA: (sector) => {
        if (sector === "DC SFRA") {
            return {
                strokeColor: `rgba(255, 165, 0, 1)`,
                fillColor: `rgba(255, 165, 0, 0.1)`
            };
        } else {
            return {
                strokeColor: `rgba(255, 0, 0, 1)`,
                fillColor: `rgba(255, 0, 0, 0.1)`
            };
        }
    }
};

export const FILL_WHITE = new Fill({ color: "rgba(255, 255, 255, 0.4)" });
export const FILL_BLUE = new Fill({ color: "rgba(51, 153, 204, 0.4)" });
export const STROKE_WHITE = new Stroke({ color: "#FFFFFF", width: 1.25 });
export const STROKE_BLUE = new Stroke({ color: "#3399CC", width: 1.25 });

// Default style for volumes.
export const STYLE_VOLUME = new Style({
    fill: FILL_BLUE,
    stroke: STROKE_WHITE
});

// Default style for volumes currently being created/edited.
export const STYLE_VOLUME_EDIT = [
    new Style({
        fill: FILL_WHITE,
        stroke: STROKE_BLUE
    }),
    new Style({
        image: new Circle({
            fill: FILL_BLUE,
            stroke: STROKE_WHITE,
            radius: 5
        }),
        geometry: (feature) => {
            if (feature?.getGeometry()?.getCoordinates()?.[0]) {
                return new MultiPoint(feature.getGeometry().getCoordinates()[0]);
            }
        }
    })
];

export const GEOFENCE_STYLE = [
    new Style({
        fill: FILL_WHITE,
        stroke: STROKE_BLUE
    })
];

// Additional style for assets currently being monitored.
export const STYLE_VOLUME_MONITOR = new Style({
    stroke: new Stroke({
        color: "red",
        width: 2,
        lineDash: [10, 10]
    }),
    fill: new Fill({
        color: "rgba(255, 0, 0, 0.1)"
    })
});

// Default style for ASD entities.
export const STYLE_ASD = new Style({
    image: new Icon({
        src: "../static/2d-models/adsb/adsb-default.png"
    })
});

// Gets the feature's style or the 1st style if it has multiple.
export function GetFirstStyle(feature) {
    const style = feature.getStyle();
    return Array.isArray(style) ? style[0] : style;
}

// Gets the LAANC volume's style based on its properties.
export function GetLaancStyle(feature) {
    const properties = feature.getProperties();
    const layer = properties.layer;

    let styleData;
    if (layer === "LAANC_AIRSPACE") {
        styleData = LAANC_STYLE_DATA[layer][properties.LOCAL_TYPE];
    } else if (layer.includes("UASFM")) {
        styleData = LAANC_STYLE_DATA["UASFM"](properties.APT1_LAANC, properties.CEILING);
    } else if (layer.includes("NSUFR")) {
        styleData = LAANC_STYLE_DATA["NSUFR"];
    } else if (layer === "SU_AIRSPACE") {
        styleData = LAANC_STYLE_DATA[layer][properties.TYPE_CODE];
    } else if (layer === "LAANC_STADIUMS") {
        styleData = LAANC_STYLE_DATA[layer](properties.NAME);
    } else if (layer === "FAA_TFR" && !DateHasAlreadyPassed(properties.effectiveEnd)) {
        styleData = LAANC_STYLE_DATA[layer](properties.effectiveStart);
    } else if (layer === "AIRPORTS") {
        styleData = LAANC_STYLE_DATA[layer](properties.TYPE_CODE);
    } else if (layer === "DC_FRZ_SFRA") {
        styleData = LAANC_STYLE_DATA[layer](properties.SECTOR);
    }
    if (!styleData) {
        styleData = {
            fillColor: "rgba(0, 0, 0, 0)",
            strokeColor: "rgba(0, 0, 0, 0)",
            strokeLineDash: undefined,
            textLabel: ""
        };
    }
    if (!styleData.fillColor) styleData.fillColor = "rgba(0, 0, 0, 0)";
    if (!styleData.strokeColor) styleData.strokeColor = "rgba(0, 0, 0, 0)";
    if (!styleData.textLabel) styleData.textLabel = "";

    let text = undefined;
    if (styleData.textLabel) {
        text = new Text({
            text: styleData.textLabel,
            font: "12px Calibri,sans-serif",
            fill: new Fill({ color: "#fff" }),
            stroke: new Stroke({ color: "#000", width: 3 })
        });
    }
    let image = undefined;
    if (styleData.image) {
        image = new Icon({
            src: styleData.image
        });
    }
    return new Style({
        fill: new Fill({
            color: styleData.fillColor
        }),
        stroke: new Stroke({
            color: styleData.strokeColor,
            width: 2,
            lineDash: styleData.strokeLineDash
        }),
        text: text,
        image: image
    });
}

export function GetLaancAirspaceStyle(feature, settings) {
    let fill_color = "rgba(0, 0, 0, 0)";
    let line_dash = undefined;
    let stroke_color = "rgba(0, 0, 0, 0)";

    const properties = feature.getProperties();
    const setting = settings.find((layer) => {
        return layer.local_type === properties.LOCAL_TYPE;
    });
    if (setting !== undefined && setting.visible === true) {
        if (properties.LOCAL_TYPE === "CLASS_B") {
            stroke_color = `rgba(0, 135, 191, 1)`;
            fill_color = `rgba(0, 135, 191, 0.1)`;
            line_dash = undefined;
        } else if (properties.LOCAL_TYPE === "CLASS_C") {
            stroke_color = `rgba(157, 107, 158, 1)`;
            fill_color = `rgba(157, 107, 158, 0.1)`;
            line_dash = undefined;
        } else if (properties.LOCAL_TYPE === "CLASS_D") {
            stroke_color = `rgba(5, 142, 184, 1)`;
            fill_color = `rgba(5, 142, 184, 0.1)`;
            line_dash = [8, 4];
        } else if (properties.LOCAL_TYPE.includes("CLASS_E")) {
            stroke_color = `rgba(158, 110, 157, 1)`;
            fill_color = `rgba(158, 110, 157, 0.1)`;
            line_dash = [8, 4];
        } else if (properties.LOCAL_TYPE === "MODE C") {
            stroke_color = `rgba(157, 107, 158, 1)`;
            fill_color = `rgba(157, 107, 158, 0.1)`;
            line_dash = undefined;
        }
    }
    return new Style({
        fill: new Fill({
            color: fill_color
        }),
        stroke: new Stroke({
            color: stroke_color,
            width: 2,
            lineDash: line_dash
        })
    });
}

export function getSpecialUseAirspaceStyle(feature, layers) {
    let style = new Style({
        fill: undefined,
        stroke: undefined
    });
    const properties = feature.getProperties();
    if (properties && properties.TYPE_CODE) {
        const layer = layers.find((layer) => {
            return layer.type_code === properties.TYPE_CODE;
        });
        if (layer !== undefined && layer.visible === true) {
            const su_airspace_style = LAANC_STYLE_DATA["SU_AIRSPACE"][properties.TYPE_CODE];
            if (su_airspace_style) {
                style = new Style({
                    fill: new Fill({
                        color: su_airspace_style.fillColor
                    }),
                    stroke: new Stroke({
                        color: su_airspace_style.strokeColor,
                        width: 2,
                        lineDash: su_airspace_style.strokeLineDash
                    })
                });
            }
        }
    }
    return style;
}

export function getLabelTextFromFeatureAndUser(feature, user) {
    const properties = feature.getProperties();
    const callsign = properties.asd.callsign[user.organization_id];
    const vertical_speed_indicator = GetVerticalSpeedIndicator(properties.asd.climb_rate_fpm);

    const label = `${callsign === undefined ? "" : `${callsign}\n`}${properties.asd.alt_hae_ft}ft ${vertical_speed_indicator}\n${
        properties.asd.ground_speed_kn
    }kn`;

    return new Text({
        text: label,
        font: "12px Calibri,sans-serif",
        fill: new Fill({ color: "#fff" }),
        stroke: new Stroke({
            color: "#000",
            width: 3
        }),
        backgroundFill: new Fill({
            color: "rgba(0, 0, 0, 0.8)"
        }),
        padding: [5, 5, 5, 5],
        offsetX: 50
    });
}

export function getAsdPredLineStyle(properties, source_type_settings) {
    let vehicle_color = source_type_settings.color;
    if (properties.asd.simulated) {
        vehicle_color = "rgba(0, 225, 255, 1)";
    }
    return new Style({
        stroke: new Stroke({
            color: vehicle_color,
            width: 2
        })
    });
}

export function getAsdHistLineStyle(properties, source_type_settings) {
    let vehicle_color = source_type_settings.color;
    if (properties.asd.simulated) {
        vehicle_color = "rgba(0, 225, 255, 1)";
    }
    return new Style({
        stroke: new Stroke({
            color: vehicle_color,
            width: 2,
            lineDash: [5, 5]
        })
    });
}

export function getAsdDpzStyle(settings) {
    if (settings.well_clear_volume) {
        return new Style({
            stroke: new Stroke({
                color: "rgba(0, 200, 0, 1)",
                width: 2
            })
        });
    } else {
        return new Style({
            stroke: undefined
        });
    }
}

export function getAsdTrackIconStyle(properties, settings, source_type_settings) {
    const emitter_type = properties.asd && properties.asd.adsb ? properties.asd.adsb.emitter_cat : 0;
    let scale = settings.entity_size + 0.2;

    let src = `../static/2d-models/shapes/${source_type_settings.icon}.png`;
    if (source_type_settings.icon === "icon") {
        src = allModels.get(0);
        if (properties.asd.source_type === "TELEMETRY") {
            src = allModels.get(8);
        } else if (emitter_type && allModels.has(emitter_type)) {
            src = allModels.get(emitter_type);
        }
    } else {
        scale * 0.75;
    }
    let vehicle_color = source_type_settings.color;
    if (properties.asd.simulated) {
        vehicle_color = "rgba(0, 225, 255, 1)";
    }
    return new Style({
        image: new Icon({
            src: src,
            color: vehicle_color,
            scale: scale,
            rotation: -(properties.asd.rotation * (Math.PI / 180))
        })
    });
}

export function getAsdTrackLabelStyle(feature, settings, user) {
    const labels_are_enabled = settings.label_visible === "on";
    const label_is_already_visible = feature.get("show_label") === true;
    const alert_volume_labels_are_enabled = settings.label_visible === "alerts" && feature.get("alert_volume_confliction_alert");

    if (labels_are_enabled || label_is_already_visible || alert_volume_labels_are_enabled) {
        const text = getLabelTextFromFeatureAndUser(feature, user);
        return new Style({
            text: text
        });
    } else {
        return new Style({
            text: undefined
        });
    }
}

export function getAsdZeroConflictionStyle(feature, settings, user) {
    const properties = feature.getProperties();
    const vehicle_has_zca_enabled = properties.asd.show_dpz[user.organization_id];
    const user_enabled_well_clear_volumes = settings.well_clear_volume;
    const active_zero_conflict_alerts = feature.get("zero_confliction_alerts");

    if (Array.isArray(active_zero_conflict_alerts)) {
        const ownship_alerts = [];
        const intruder_alerts = [];

        active_zero_conflict_alerts.forEach((zero_conflict_alert) => {
            const ownship_id = zero_conflict_alert.ownship_track.id;
            if (ownship_id === properties.asd.id) {
                ownship_alerts.push(zero_conflict_alert);
            }
            const intruder_id = zero_conflict_alert.intruder_track.id;
            if (intruder_id === properties.asd.id) {
                intruder_alerts.push(zero_conflict_alert);
            }
        });

        let image = undefined;
        if (ownship_alerts.length) {
            let well_clear_volume_color_rgb = "255, 235, 0";
            const zc_alerts_contain_warning = ownship_alerts.find(({ severity }) => {
                return severity === "WARNING";
            });
            if (zc_alerts_contain_warning) {
                well_clear_volume_color_rgb = "200, 0, 0";
            }
            image = getCircleImageFromColor(well_clear_volume_color_rgb);
        }
        let text = undefined;
        if (intruder_alerts.length) {
            const zca_text = getZeroConflictAlertTextFromAlert(intruder_alerts[0]);
            text = zca_text;
        }
        return new Style({
            text: text,
            image: image
        });
    } else if (vehicle_has_zca_enabled && user_enabled_well_clear_volumes) {
        const green_well_clear_volume = getCircleImageFromColor("0, 200, 0");
        return new Style({
            text: undefined,
            image: green_well_clear_volume
        });
    } else {
        return new Style({
            text: undefined,
            image: undefined
        });
    }
}

export function getAsdCoastedStyle(properties) {
    if (properties.asd.coasted) {
        return new Style({
            image: new Icon({
                src:
                    "data:image/svg+xml;base64," +
                    btoa(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <line x1="4" y1="4" x2="20" y2="20" stroke="yellow" stroke-width="2"/>
              <line x1="20" y1="4" x2="4" y2="20" stroke="yellow" stroke-width="2"/>
              </svg>
              `)
            })
        });
    } else {
        return new Style({
            image: undefined
        });
    }
}

export function getAsdEndOfTrackStyle(properties) {
    if (properties.asd.eot) {
        return new Style({
            image: new Icon({
                src:
                    "data:image/svg+xml;base64," +
                    btoa(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <line x1="4" y1="4" x2="20" y2="20" stroke="red" stroke-width="2"/>
                    <line x1="20" y1="4" x2="4" y2="20" stroke="red" stroke-width="2"/>
                    </svg>
                    `)
            })
        });
    } else {
        return new Style({
            image: undefined
        });
    }
}

export function getAsdFeatureStyleFromLayersAndSettings(feature, altitude_range, asd_vector_layer, settings, user) {
    const style_arr = [];

    const properties = feature.getProperties();
    if ("asd" in properties) {
        // check if track is within altitude layer
        let vehicle_is_within_altitude_range = true;
        if (altitude_range !== undefined) {
            const alt_hae_ft = parseFloat(properties.asd.alt_hae_ft) < 0 ? 1 : properties.asd.alt_hae_ft;
            const alt_is_within_range = alt_hae_ft >= altitude_range[0] && alt_hae_ft <= altitude_range[1];
            const is_telemetry = properties.asd.source_type === "TELEMETRY" ? true : false;
            vehicle_is_within_altitude_range = alt_is_within_range || is_telemetry;
        }
        // check if survieillance sources have been toggled off
        let map_layer_is_enabled = true;
        if (asd_vector_layer !== undefined) {
            map_layer_is_enabled = asd_vector_layer;
        }
        // check source map settings
        let source_is_visible = true;
        if (settings.source_mapping[properties.asd.source_id]) {
            source_is_visible = settings.source_mapping[properties.asd.source_id].visibility;
        }
        // if all are true get the additional styles for each trac
        if (map_layer_is_enabled && vehicle_is_within_altitude_range && source_is_visible) {
            const source_type_settings = settings.source_type_settings.find(({ source_type }) => {
                return source_type === properties.asd.source_type;
            });
            if (source_type_settings) {
                const feature_id = feature.getId();
                const feature_is_pred_line = feature_id.includes("_pl");
                const feature_is_hist_line = feature_id.includes("_hl");
                const feature_is_dpz = feature_id.includes("_dpz");

                if (feature_is_pred_line && settings.pred_line) {
                    const pred_line_style = getAsdPredLineStyle(properties, source_type_settings);
                    style_arr.push(pred_line_style);
                } else if (feature_is_hist_line && settings.hist_line) {
                    const hist_line_style = getAsdHistLineStyle(properties, source_type_settings);
                    style_arr.push(hist_line_style);
                } else if (feature_is_dpz && properties.asd.show_dpz[user.organization_id]) {
                    const dpz_style = getAsdDpzStyle(settings);
                    style_arr.push(dpz_style);
                } else {
                    const asd_icon_style = getAsdTrackIconStyle(properties, settings, source_type_settings);
                    style_arr.push(asd_icon_style);

                    const asd_label_style = getAsdTrackLabelStyle(feature, settings, user);
                    style_arr.push(asd_label_style);

                    const alert_confliction_style = getAlertVolumeConflictionAlertStyle(feature);
                    style_arr.push(alert_confliction_style);

                    const zero_confliction_style = getAsdZeroConflictionStyle(feature, settings, user);
                    style_arr.push(zero_confliction_style);

                    const eot_style = getAsdEndOfTrackStyle(properties);
                    style_arr.push(eot_style);

                    const coasted_style = getAsdCoastedStyle(properties);
                    style_arr.push(coasted_style);
                }
            }
        }
    }
    return style_arr;
}

export function getAlertVolumeConflictionAlertStyle(feature) {
    if (feature.get("alert_volume_confliction_alert")) {
        return new Style({
            image: new CircleStyle({
                radius: 20,
                fill: undefined,
                stroke: new Stroke({
                    color: "rgba(255, 0, 0, 0.8)",
                    width: 2
                })
            })
        });
    } else {
        return new Style({
            image: undefined
        });
    }
}

export function getCircleImageFromColor(color_rgb) {
    return new CircleStyle({
        radius: 20,
        fill:
            color_rgb === "0, 200, 0"
                ? new Fill({
                      color: `rgba(${color_rgb}, .1)`
                  })
                : undefined,
        stroke: new Stroke({
            color: `rgba(${color_rgb}, .8)`,
            width: 2
        })
    });
}

export function getZeroConflictAlertTextFromAlert(zero_conflict_alert) {
    const horiz_sep_ft = ConvertMetersToFeet(zero_conflict_alert.horiz_sep_m);
    const vert_sep_ft = ConvertMetersToFeet(zero_conflict_alert.vert_sep_m);
    const severity = zero_conflict_alert.severity;
    const time_los_sec = zero_conflict_alert.time_los_sec;

    const label = `Horz: ${horiz_sep_ft}ft\nVert: ${vert_sep_ft}ft\nAlert: ${severity}\nTime: ${time_los_sec}s`;

    let background_color_rgb = "255, 235, 0";
    if (severity === "WARNING") {
        background_color_rgb = "200, 0, 0";
    }
    return new Text({
        text: label,
        font: "16px Calibri, sans-serif",
        fill: new Fill({
            color: "#fff"
        }),
        stroke: new Stroke({
            color: "#000",
            width: 2
        }),
        backgroundFill: new Fill({
            color: `rgba(${background_color_rgb}, 1)`
        }),
        padding: [5, 5, 5, 5],
        offsetY: -75
    });
}

export function getColorFromLaancType(state, opacity) {
    if (state === "AUTO_APPROVED") {
        return `rgba(224, 210, 104, ${opacity})`;
    } else if (state === "FURTHER_COORDINATION" || state === "BLOCKED") {
        return `rgba(203, 76, 78, ${opacity})`;
    } else {
        return `rgba(255, 255, 255, ${opacity})`;
    }
}
