import React from "react";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import CloseIcon from "@mui/icons-material/Close";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

export const EntityWatchToolbar = ({ asdWatchToolbarOpen, asdWatchToolbarData, handleWatchEntityButtonClick, audioAlertsEnabled, setAudioAlertsEnabled }) => {
    const num_of_zc_alerts = asdWatchToolbarData.zero_conflict_alerts.length;
    const zc_alerts_contain_warning = asdWatchToolbarData.zero_conflict_alerts.find(({ severity }) => {
        return severity === "WARNING";
    });
    const fields = [
        {
            title: "Callsign",
            value: `${asdWatchToolbarData.callsign}`
        },
        {
            title: "Track Angle",
            value: `${asdWatchToolbarData.track_angle || "-- "}°`
        },
        {
            title: "Speed",
            value: `${asdWatchToolbarData.ground_speed_kn || "-- "}kn`
        },
        {
            title: "Altitude (AGL)",
            value: `${asdWatchToolbarData.alt_agl_ft || "-- "}ft`
        },
        {
            title: "Altitude (MSL)",
            value: `${asdWatchToolbarData.alt_msl_ft || "-- "}ft`
        },
        {
            title: "Alerts",
            value: [
                asdWatchToolbarData.zero_conflict_alerts.length > 0
                    ? {
                          color: zc_alerts_contain_warning ? "red" : "yellow",
                          value: asdWatchToolbarData.alert_conflict_alert
                              ? `${num_of_zc_alerts} DAA Alert${num_of_zc_alerts !== 1 ? "s, " : ", "}`
                              : `${num_of_zc_alerts} DAA Alert${num_of_zc_alerts !== 1 ? "s" : ""}`
                      }
                    : {
                          color: "#6b778c",
                          value: ""
                      },
                asdWatchToolbarData.alert_conflict_alert
                    ? {
                          color: "red",
                          value: "In Alert Volume"
                      }
                    : {
                          color: "#6b778c",
                          value: ""
                      }
            ]
        }
    ];
    return (
        <Collapse in={asdWatchToolbarOpen}>
            <Box sx={{ display: "flex", flexDirection: "row", px: 1 }}>
                <List dense={true} sx={{ flex: 1, display: "grid", gridTemplateColumns: { xs: "repeat(3, 1fr)", md: "repeat(6, 1fr)" } }}>
                    {fields.map(({ title, value }, fields_index) => (
                        <ListItem key={fields_index} sx={{ flex: 1, alignItems: "flex-start", py: 0, pl: 0, minWidth: 0 }}>
                            <ListItemText
                                disableTypography
                                sx={{ my: 0 }}
                                primary={
                                    <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                                        {title}
                                    </Typography>
                                }
                                secondary={
                                    Array.isArray(value) === false ? (
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#6b778c", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                        >
                                            {value}
                                        </Typography>
                                    ) : (
                                        value.map(({ value, color }, i) => (
                                            <Typography
                                                key={i}
                                                variant="body2"
                                                component="span"
                                                sx={{ color: color, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                            >
                                                {value}
                                            </Typography>
                                        ))
                                    )
                                }
                            />
                            {fields_index !== fields.length - 1 ? (
                                <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "unset" } }} />
                            ) : (
                                <></>
                            )}
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "row" }, justifyContent: "center", alignItems: "center" }}>
                    {[
                        {
                            title: `Audio Alerts ${audioAlertsEnabled ? "Enabled" : "Disabled"}`,
                            icon: audioAlertsEnabled ? <VolumeMuteIcon /> : <VolumeOffIcon />,
                            onClick: () => setAudioAlertsEnabled((prev) => !prev)
                        },
                        {
                            title: "Close",
                            icon: <CloseIcon />,
                            onClick: () => handleWatchEntityButtonClick()
                        }
                    ].map(({ title, icon, onClick }, i) => (
                        <Tooltip key={i} title={title}>
                            <IconButton onClick={onClick}>{icon}</IconButton>
                        </Tooltip>
                    ))}
                </Box>
            </Box>
        </Collapse>
    );
};
