import React from "react";
import Box from "@mui/material/Box";
import { useUserAuth } from "../contexts/authContext";
import { ConvertISOToDate } from "../util";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../customPagination";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CustomGridToolbar } from "../customGridToolbar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export function DataLoggingOTDQuery() {
    const [data, setData] = React.useState([]);
    const [inputEnd, setInputEnd] = React.useState("");
    const [inputStart, setInputStart] = React.useState("");
    const [altitudeMin, setAltitudeMin] = React.useState(0);
    const [altitudeMax, setAltitudeMax] = React.useState(0);
    const [labelValue, setLabelValue] = React.useState("");
    const [tailNumber, setTailNumber] = React.useState("");
    const [sourceID, setSourceID] = React.useState("");
    const [awaitingResults, setAwaitingResults] = React.useState(false);
    const { handleFailedFetch, setSnackbar } = useUserAuth();

    const handleSubmit = () => {
        if (inputStart == "" || inputEnd == "") {
            setSnackbar({ children: "Please fill out the required fields", severity: "error" });
            return false;
        }

        const query = {
            start_param: new Date(inputStart).toLocaleDateString(),
            end_pararm: new Date(inputEnd).toLocaleDateString(),
            label_param: labelValue,
            tail_number_param: tailNumber,
            source_id_param: sourceID,
            alt_param_min: altitudeMin,
            alt_param_max: altitudeMax,
            polygon_param: null
        };
        setAwaitingResults(true);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(query)
        };
        fetch("api/dl/otdquery", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                setData(data);
                setAwaitingResults(false);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const columns = [
        {
            field: "message_time",
            headerName: "Message Time",
            minWidth: 200,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "track_uuid",
            headerName: "Track UUID",
            flex: 1,
            minWidth: 300
        },
        {
            field: "label",
            headerName: "Label",
            flex: 1,
            minWidth: 100
        },
        {
            field: "tail_number",
            headerName: "Tail Number",
            flex: 1,
            minWidth: 100
        },
        {
            field: "source_id",
            headerName: "Source ID",
            minWidth: 100,
            flex: 1
        },
        {
            field: "source_type",
            headerName: "Source Type",
            minWidth: 100,
            flex: 1
        },
        {
            field: "location",
            headerName: "Location",
            minWidth: 125,
            flex: 1,
            valueFormatter: (value) => `${value.x.toFixed(2)}, ${value.y.toFixed(2)}`
        },
        {
            field: "altitude_hae_m",
            headerName: "Alt HAE M",
            minWidth: 50,
            flex: 1
        },
        {
            field: "date_recorded",
            headerName: "Date Recorded",
            minWidth: 200,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        }
    ];

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", mb: "16px" }}>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr 1fr" },
                        gap: "10px"
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Time *"
                            value={dayjs(inputStart)}
                            onChange={(value) => setInputStart(value)}
                            slotProps={{ textField: { inputProps: { "data-testid": "start-date-picker" } } }}
                        />
                        <DatePicker
                            label="Stop Time *"
                            value={dayjs(inputEnd)}
                            onChange={(value) => setInputEnd(value)}
                            slotProps={{ textField: { inputProps: { "data-testid": "stop-date-picker" } } }}
                        />
                    </LocalizationProvider>
                    <TextField
                        onChange={(e) => setLabelValue(e.target.value)}
                        inputProps={{ "data-testid": "label" }}
                        label="Label "
                        value={labelValue}
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        onChange={(e) => setTailNumber(e.target.value)}
                        inputProps={{ "data-testid": "tailNumber" }}
                        label="Tail Number "
                        value={tailNumber}
                        variant="outlined"
                        fullWidth
                    />
                </Box>
                <Box
                    sx={{
                        gridColumn: { md: "1/3" },
                        display: "grid",
                        gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr 1fr" },
                        gap: "10px"
                    }}
                >
                    <TextField
                        onChange={(e) => setSourceID(e.target.value)}
                        inputProps={{ "data-testid": "source_id" }}
                        label="Source ID "
                        value={sourceID}
                        variant="outlined"
                        fullWidth
                    />

                    <TextField
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        onChange={(e) => setAltitudeMin(e.target.value)}
                        inputProps={{ "data-testid": "altitudeMin" }}
                        label="Minimum Altitude "
                        value={altitudeMin}
                        variant="outlined"
                        type="number"
                        fullWidth
                    />
                    <TextField
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        onChange={(e) => setAltitudeMax(e.target.value)}
                        inputProps={{ "data-testid": "altitudeMax" }}
                        label="Maximum Altitude "
                        value={altitudeMax}
                        variant="outlined"
                        type="number"
                        fullWidth
                    />
                    <Button
                        sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
                        data-testid="RunQuery"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={awaitingResults}
                        color="primary"
                    >
                        {awaitingResults ? <>Awaiting Results</> : <>Run Query</>}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ height: 600 }}>
                <DataGrid
                    disableVirtualization
                    rows={data}
                    columns={columns}
                    disableRowSeletionOnClick
                    getRowId={(row) => row.id}
                    pagination
                    slots={{ pagination: CustomPagination, Toolbar: CustomGridToolbar }}
                    initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                />
            </Box>
        </>
    );
}
