import React, { useState } from "react";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import InputAdornment from "@mui/material/InputAdornment";

import { useUserAuth } from "../contexts/authContext";

export const MapSettingsDropdown = ({ mapSettingsOpen, setMapSettingsOpen, mapSettingsAnchorEl, setSnackbar, mapContainer }) => {
    // auth state variables
    const { userMapSettings, updateMapSettings } = useUserAuth();

    // toggle state variables
    const [predLineSetting, setPredLineSetting] = useState(userMapSettings.pred_line);
    const [histLineSetting, setHistLineSetting] = useState(userMapSettings.hist_line);
    const [wellClearVolumeSetting, setWellClearVolumeSetting] = useState(userMapSettings.well_clear_volume);
    const [volumeLabelsSetting, setVolumeLabelsSetting] = useState(userMapSettings.volume_labels);

    // dropdown state variables
    const [labelVisible, setLabelVisible] = useState(userMapSettings.label_visible);

    // text state variables
    const [latitude, setLatitude] = useState(userMapSettings.latitude);
    const [longitude, setLongitude] = useState(userMapSettings.longitude);

    // slider state variables
    const [predLineLead, setPredLineLead] = useState(userMapSettings.pred_line_lead_time);
    const [operationOpacity, setOperationOpacity] = useState(userMapSettings.op_opacity);
    const [weatherOpacity, setWeatherOpacity] = useState(userMapSettings.weather_opacity);
    const [brightness, setBrightness] = useState(userMapSettings.brightness);
    const [entitySize, setEntitySize] = useState(userMapSettings.entity_size);

    const handleLatChange = (event) => {
        const lat = event.target.value;
        if (parseFloat(lat) > 90 || parseFloat(lat) < -90) {
            return;
        }
        setLatitude(lat);
    };

    const handleLongChange = (event) => {
        const long = event.target.value;
        if (parseFloat(long) > 180 || parseFloat(long) < -180) {
            return;
        }
        setLongitude(long);
    };

    const handleSaveSettings = () => {
        const mapSettings = { ...userMapSettings };
        mapSettings.label_visible = labelVisible;
        mapSettings.pred_line_lead_time = predLineLead;
        mapSettings.pred_line = predLineSetting;
        mapSettings.hist_line = histLineSetting;
        mapSettings.well_clear_volume = wellClearVolumeSetting;
        mapSettings.volume_labels = volumeLabelsSetting;
        mapSettings.op_opacity = operationOpacity;
        mapSettings.latitude = parseFloat(latitude);
        mapSettings.longitude = parseFloat(longitude);
        mapSettings.entity_size = parseFloat(entitySize);
        mapSettings.brightness = brightness;
        mapSettings.weather_opacity = weatherOpacity;

        //Push updates to database
        setSnackbar({ children: "Map successfully updated", severity: "success" });
        updateMapSettings(mapSettings);
    };

    const handleResetSettings = () => {
        setLabelVisible("off");
        setPredLineSetting(false);
        setHistLineSetting(false);
        setWellClearVolumeSetting(false);
        setVolumeLabelsSetting(false);
        setLongitude(-82.9988);
        setLatitude(39.9612);
        setPredLineLead(20);
        setEntitySize(0.3);
        setOperationOpacity(0.5);
        setBrightness(1);
        setWeatherOpacity(1);
    };
    return (
        <Popover
            open={mapSettingsOpen}
            anchorEl={mapSettingsAnchorEl}
            onClose={() => setMapSettingsOpen(false)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            container={mapContainer ? mapContainer : undefined}
        >
            <Box sx={{ p: 2, maxWidth: { xs: "100%", sm: "375px" } }}>
                <Typography variant="h5">Settings</Typography>

                <Box sx={{ my: 1, mx: 0 }}>
                    <InputLabel id="entity-label" style={{ color: "#FFF", mt: 2 }}>
                        Home Location:
                    </InputLabel>
                    <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: "10px" }}>
                        <TextField
                            onChange={handleLatChange}
                            id="latitudeInput"
                            variant="standard"
                            label="Latitude"
                            value={latitude}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                        />
                        <TextField
                            onChange={handleLongChange}
                            id="longitudeInput"
                            variant="standard"
                            label="Longitude"
                            value={longitude}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                        />
                    </Box>
                </Box>

                <Box sx={{ mt: 2, mx: 0 }}>
                    <InputLabel id="entity-label" style={{ color: "#FFF", mt: 2 }}>
                        Entity Settings:
                    </InputLabel>
                    <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: "10px" }}>
                        <FormControl variant="standard">
                            <InputLabel id="select-label-air">Entity Labels</InputLabel>
                            <Select
                                value={labelVisible}
                                id="selectLabel"
                                labelId="select-label"
                                label="Entity Labels"
                                onChange={(e) => setLabelVisible(e.target.value)}
                            >
                                <MenuItem value={"off"}>On Hover</MenuItem>
                                <MenuItem value={"on"}>Always On</MenuItem>
                                <MenuItem value={"alerts"}>In Alert Volumes</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="standard">
                            <Box sx={{ mb: 0 }}>
                                <Typography variant="body2" color="textSecondary">
                                    Entity Size
                                </Typography>
                            </Box>
                            <Slider
                                onChange={(e) => setEntitySize(e.target.value)}
                                valueLabelDisplay="auto"
                                aria-label="Size"
                                data-testid="slider"
                                value={entitySize}
                                step={0.05}
                                min={0.25}
                                max={1}
                                marks
                            />
                        </FormControl>
                        <FormControl variant="standard">
                            <Box sx={{ mb: 0 }}>
                                <Typography variant="body2" color="textSecondary">
                                    Prediction Lines (by seconds):
                                </Typography>
                            </Box>
                            <Slider
                                onChange={(e) => setPredLineLead(e.target.value)}
                                valueLabelDisplay="auto"
                                data-testid="slider"
                                value={predLineLead}
                                step={5}
                                min={10}
                                max={120}
                                marks
                            />
                        </FormControl>
                    </Box>
                </Box>

                <FormControl component="fieldset" variant="standard" sx={{ mt: 1, width: "100%" }}>
                    <FormGroup sx={{ gap: "2px", mx: 0 }}>
                        <FormControlLabel
                            label="Prediction Lines:"
                            labelPlacement="start"
                            sx={{ justifyContent: "space-between", ml: 0 }}
                            control={<Switch checked={predLineSetting} id="selectPred" onChange={(e) => setPredLineSetting(e.target.checked)} />}
                        />
                        <FormControlLabel
                            label="History Lines:"
                            labelPlacement="start"
                            sx={{ justifyContent: "space-between", ml: 0 }}
                            control={<Switch checked={histLineSetting} id="selectHist" onChange={(e) => setHistLineSetting(e.target.checked)} />}
                        />
                        <FormControlLabel
                            label="Well Clear Volumes:"
                            labelPlacement="start"
                            sx={{ justifyContent: "space-between", ml: 0 }}
                            control={
                                <Switch checked={wellClearVolumeSetting} id="selectClearVols" onChange={(e) => setWellClearVolumeSetting(e.target.checked)} />
                            }
                        />
                        <FormControlLabel
                            label="Volume Labels:"
                            labelPlacement="start"
                            sx={{ justifyContent: "space-between", ml: 0 }}
                            control={
                                <Switch checked={volumeLabelsSetting} id="selectVolumeLabels" onChange={(e) => setVolumeLabelsSetting(e.target.checked)} />
                            }
                        />
                    </FormGroup>
                </FormControl>

                <Box sx={{ my: 1, mx: 0 }}>
                    <InputLabel id="entity-label" style={{ color: "#FFF" }}>
                        Volume Opacity:
                    </InputLabel>
                    <Slider
                        onChange={(e) => setOperationOpacity(e.target.value)}
                        valueLabelDisplay="auto"
                        aria-label="Opacity"
                        data-testid="slider"
                        value={operationOpacity}
                        step={0.1}
                        min={0}
                        max={1}
                        marks
                    />
                </Box>

                <Box sx={{ my: 2, mx: 0 }}>
                    <InputLabel id="entity-label" style={{ color: "#FFF" }}>
                        Weather Opacity:
                    </InputLabel>
                    <Slider
                        onChange={(event) => setWeatherOpacity(event.target.value)}
                        valueLabelDisplay="auto"
                        aria-label="WeatherOpacity"
                        data-testid="slider"
                        value={weatherOpacity}
                        step={0.1}
                        min={0.1}
                        max={1}
                        marks
                        id="weatherOpacity"
                    />
                </Box>

                <Box sx={{ my: 2, mx: 0 }}>
                    <InputLabel id="entity-label" style={{ color: "#FFF" }}>
                        Map Brightness:
                    </InputLabel>
                    <Slider
                        onChange={(event) => setBrightness(event.target.value)}
                        valueLabelDisplay="auto"
                        aria-label="Brightness"
                        data-testid="slider"
                        value={brightness}
                        step={0.1}
                        min={0}
                        max={1}
                        marks
                        id="layerBrightness"
                    />
                </Box>

                <Button onClick={handleResetSettings} variant="contained" color="primary" size="small" sx={{ mr: 1 }} id="reset">
                    Reset
                </Button>
                <Button onClick={handleSaveSettings} variant="contained" color="primary" size="small" id="save">
                    Save
                </Button>
            </Box>
        </Popover>
    );
};
