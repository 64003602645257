import { useEffect, useRef, useState } from "react";
import * as turf from "@turf/turf";

import Collection from "ol/Collection";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import Translate from "ol/interaction/Translate";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import LineString from "ol/geom/LineString";
import Circle from "ol/geom/Circle";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";

import * as TEST_UTIL from "../../testUtil";

import { GetCoordinatesForSubmissions, JumpToFeature } from "../utils/olUtil";
import { GEOFENCE_STYLE, STYLE_VOLUME_EDIT } from "../utils/olStyleUtil";

export default function useDrawEdit(map, drawType = "Polygon", existingFeature = null, buffer = null, radius = null, setRadius = null) {
    const [featureCoords, setFeatureCoords] = useState([]);
    const [oldDrawType, setOldDrawType] = useState(drawType);

    const layerRef = useRef(null);
    const featureRef = useRef(null);
    const geofenceRef = useRef(null);

    const waypointsRef = useRef([]);
    const bufferRef = useRef(100);

    // Map interactions used:
    const drawRef = useRef(null);
    const editRef = useRef(null);
    const moveRef = useRef(null);
    const snapRef = useRef(null);

    useEffect(() => {
        bufferRef.current = buffer || 1;

        if (drawType === "Waypoints" && buffer && buffer > 0 && waypointsRef.current.length > 0 && geofenceRef.current) {
            const linestring = turf.lineString(waypointsRef.current);
            const buffered = turf.buffer(linestring, buffer, { units: "meters" });

            const geometry = geofenceRef.current;
            geometry.setCoordinates(buffered.geometry.coordinates);

            const geojson = GetCoordinatesForSubmissions(geometry);
            setFeatureCoords(geojson.coordinates);
        }
    }, [drawType, buffer]);

    useEffect(() => {
        if (drawType === "Circle" && radius && radius > 0 && featureRef.current) {
            const geometry = featureRef.current.getGeometry();
            if (geometry instanceof Circle) {
                const radius_deg = radius / 364000;
                geometry.setRadius(radius_deg);

                const geojson = GetCoordinatesForSubmissions(geometry);
                setFeatureCoords(geojson.coordinates);
            }
        }
    }, [drawType, radius]);

    useEffect(() => {
        // Should only be called in a testing environment -- set test coordinates and return.
        if (map && map.get("name") === "test") {
            setFeatureCoords(TEST_UTIL.testingCoordinates);
            return;
        }

        // Ensure old map data is removed.
        handleRemoval();

        // Check if the draw type is different.
        // This indicates you want to draw a new feature while currently editing an existing feature.
        const isNewDrawType = oldDrawType !== drawType;
        setOldDrawType(drawType);

        if (!map || drawType === "Clear") {
            return;
        } else if (drawType === "Upload") {
            if (existingFeature) {
                handleEdit(existingFeature.clone());
            }
        } else if (!existingFeature || isNewDrawType) {
            handleDraw();
        } else {
            handleEdit(existingFeature.clone());
        }

        return () => {
            handleRemoval();
        };
    }, [map, drawType, existingFeature]);

    const handleDraw = () => {
        drawRef.current = new Draw({ source: new VectorSource({ wrapX: false }), type: drawType === "Waypoints" ? "LineString" : drawType });
        drawRef.current.on("drawend", (event) => handleEdit(event.feature));
        map.addInteraction(drawRef.current);
    };

    const handleEdit = (feature) => {
        if (drawRef.current) {
            map.removeInteraction(drawRef.current);
        }
        feature.setStyle(STYLE_VOLUME_EDIT);

        const editable_features = new Collection([feature]);
        const vector_layer_features = new Collection([feature]);

        let geometry = feature.getGeometry();
        if (geometry instanceof LineString) {
            const coords = geometry.getCoordinates();
            waypointsRef.current = coords;

            const linestring = turf.lineString(coords);
            const buffered = turf.buffer(linestring, bufferRef.current, { units: "meters" });

            const geofence_polygon = new Polygon(buffered.geometry.coordinates);
            geofenceRef.current = geofence_polygon;
            geometry = geofence_polygon;

            const geofence_feature = new Feature(geofence_polygon);
            geofence_feature.setStyle(GEOFENCE_STYLE);
            vector_layer_features.push(geofence_feature);
        }
        if (geometry instanceof Circle && setRadius) {
            const radius_deg = geometry.getRadius();
            const radius_ft = (radius_deg * 364000).toFixed(0);
            setRadius(radius_ft);
        }
        JumpToFeature(map, feature);

        const geojson = GetCoordinatesForSubmissions(geometry);
        setFeatureCoords(geojson.coordinates);

        layerRef.current = new VectorLayer({
            source: new VectorSource({
                features: vector_layer_features
            }),
            zIndex: 1
        });
        editRef.current = new Modify({
            features: editable_features,
            deleteCondition: (event) => event.originalEvent.button === 2
        });
        moveRef.current = new Translate({ filter: (f) => f === feature });
        snapRef.current = new Snap({ features: editable_features });

        featureRef.current = feature;

        const updateCoordinates = (event) => {
            if (event.features.getLength() > 0) {
                const geometry = feature.getGeometry();
                if (geometry instanceof Circle && setRadius) {
                    const radius_deg = geometry.getRadius();
                    const radius_ft = (radius_deg * 364000).toFixed(0);
                    setRadius(radius_ft);

                    const geojson = GetCoordinatesForSubmissions(geometry);
                    setFeatureCoords(geojson.coordinates);
                } else if (geometry instanceof LineString) {
                    const waypoint_coordinates = geometry.getCoordinates();
                    waypointsRef.current = waypoint_coordinates;

                    if (geofenceRef.current) {
                        const linestring = turf.lineString(waypoint_coordinates);
                        const buffered = turf.buffer(linestring, bufferRef.current, { units: "meters" });

                        const geofence_coordinates = buffered.geometry.coordinates;
                        geofenceRef.current.setCoordinates(geofence_coordinates);

                        const geofence_geometry = geofenceRef.current;
                        const geojson = GetCoordinatesForSubmissions(geofence_geometry);
                        setFeatureCoords(geojson.coordinates);
                    }
                } else {
                    const geojson = GetCoordinatesForSubmissions(geometry);
                    setFeatureCoords(geojson.coordinates);
                }
            }
        };

        editRef.current.on("modifyend", (event) => updateCoordinates(event));
        moveRef.current.on("translateend", (event) => updateCoordinates(event));

        map.addLayer(layerRef.current);
        map.addInteraction(moveRef.current);
        map.addInteraction(editRef.current);
        map.addInteraction(snapRef.current);
    };

    const handleRemoval = () => {
        setFeatureCoords([]);

        if (map && layerRef.current) map.removeLayer(layerRef.current);
        if (map && drawRef.current) map.removeInteraction(drawRef.current);
        if (map && editRef.current) map.removeInteraction(editRef.current);
        if (map && moveRef.current) map.removeInteraction(moveRef.current);
        if (map && snapRef.current) map.removeInteraction(snapRef.current);

        layerRef.current = null;
        drawRef.current = null;
        editRef.current = null;
        moveRef.current = null;
        snapRef.current = null;
        geofenceRef.current = null;
        waypointsRef.current = [];
        bufferRef.current = 100;
    };

    return featureCoords;
}
