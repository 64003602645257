import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import { Viewer } from "resium";
import { LoadingButton } from "@mui/lab";
import { GetColorFromState } from "../../util";
import { zoomToEntities, createLAANCVolumeEntity, getCheckedFromSubLayers, getIndeterminateFromSubLayers } from "../../map/mapUtil";
import { Cartesian3, Matrix4, SceneMode, createWorldTerrainAsync, subscribeAndEvaluate } from "cesium";
import { ScreenSpaceEventType, defined, ScreenSpaceEventHandler } from "cesium";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../customPagination";
import { BlobProvider } from "@react-pdf/renderer";
import { ApprovalDocument } from "./approvalDocument";
import { isValidBase64, updateLaancVolumesAfterAcknowledge, retrieveSafetyJustification } from "./opUtil";
import { MapContextMenu } from "../../map/mapContextMenu";
import { LAANCAdvisoryTable } from "./laancAdvisoryTable";
import { isFurtherCoordination } from "./opUtil";
import { usePrimitive } from "../../hooks/usePrimitive";
import { MapLayers } from "../../map/mapLayers";

import { useEnv } from "../../contexts/envContext";
import { useUserAuth } from "../../contexts/authContext";
import { useMap } from "../../contexts/mapContext";

import * as CONSTANT from "./opConstants";

const component = {
    type: 4,
    name: "Flight Details",
    description: "Map used to display flight details",
    volumes: {
        operations: false,
        constraints: false,
        alerts: false,
        airspaces: true,
        facilityMap: true,
        stadiums: true,
        national: true,
        prohibited: true,
        restricted: true,
        military: true,
        warning: true,
        alert: true,
        tfr: true
    }
};

const laancStates = [
    { state: CONSTANT.LAANC_COMPLETE, color: "#5fa052" },
    { state: CONSTANT.LAANC_AUTHORIZED, color: "#5fa052" },
    { state: CONSTANT.LAANC_RESCINDED_ACK, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_INVALID_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DENIED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_EXPIRED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_OPERATOR_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_AUTO_CANCEL, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_CLOSED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DEAUTHORIZED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_NEVER_AUTHORIZED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_DELETED, color: "#CB4C4E" },
    { state: CONSTANT.LAANC_RESCINDED_AWAITING, color: "#E0D268" },
    { state: CONSTANT.LAANC_INVALID_AWAITING, color: "#E0D268" },
    { state: CONSTANT.LAANC_PENDING, color: "#E0D268" },
    { state: CONSTANT.LAANC_NOT_SUBMITTED, color: "#E0D268" }
];

const columns = [
    {
        field: "name",
        headerName: "Name",
        headerHeight: 30,
        flex: 1
    },
    {
        field: "id",
        headerName: "Reference Code",
        flex: 1,
        renderCell: (params) => {
            return <div id={`reference-code-${params.row.rowIndex}`}>{params.value}</div>;
        }
    },
    {
        field: "faa_request_type",
        headerName: "Request Type",
        flex: 1,
        renderCell: ({ formattedValue }) => {
            return <div>{formattedValue.toUpperCase().replace(/_/g, " ")}</div>;
        }
    },
    {
        field: "airport",
        headerName: "Airport",
        flex: 1
    },
    {
        field: "state",
        headerName: "State",
        flex: 1,
        renderCell: ({ formattedValue }) => {
            let fontColor = GetColorFromState(formattedValue, laancStates);
            return <div style={{ fontWeight: "bold", color: fontColor }}>{formattedValue.toUpperCase().replace(/_/g, " ")}</div>;
        }
    }
];

export const FlightDetailsDialog = ({
    publishedOperations,
    flightDetailsVolume,
    flightDetailsDialogOpen,
    setFlightDetailsVolume,
    setFlightDetailsDialogOpen,
    mapContainer
}) => {
    const viewerRef = useRef(null);
    const wrapperRef = useRef(null);

    const [viewerState, setViewerState] = useState(null);
    const [open, setOpen] = useState(false);
    const [terrainProvider, setTerrainProvider] = useState(undefined);
    const [selectionModel, setSelectionModel] = useState([]);
    const [mapBase64, setMapBase64] = useState(null);
    const [printButtonDisabled, setPrintButtonDisabled] = useState(false);
    const [tabValue, setTabValue] = useState("1");
    const [isFC, setIsFC] = useState(false);
    const [safetyJustification, setSafetyJustification] = useState("");
    const [layers, setLayers] = useState([
        {
            id: 4,
            name: "Airspaces",
            checked: true,
            indeterminate: false,
            subLayers: [
                { name: "CLASS_A", checked: true, elements: airspacesClassA },
                { name: "CLASS_B", checked: true, elements: airspacesClassB },
                { name: "CLASS_C", checked: true, elements: airspacesClassC },
                { name: "CLASS_D", checked: true, elements: airspacesClassD },
                { name: "CLASS_E2", checked: true, elements: airspacesClassE2 },
                { name: "CLASS_E3", checked: true, elements: airspacesClassE3 },
                { name: "CLASS_E4", checked: true, elements: airspacesClassE4 },
                { name: "CLASS_E5", checked: true, elements: airspacesClassE5 },
                { name: "MODE C", checked: true, elements: airspacesModeC }
            ],
            elements: [
                airspacesClassA,
                airspacesClassB,
                airspacesClassC,
                airspacesClassD,
                airspacesClassE2,
                airspacesClassE3,
                airspacesClassE4,
                airspacesClassE5,
                airspacesModeC
            ],
            color: "default"
        },
        {
            id: 5,
            name: "Facility Maps",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: [facilityMaps, facilityMapLabels],
            color: "default"
        },
        {
            id: 6,
            name: "Stadiums",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: [stadiums, stadiumLabels],
            color: "default"
        },
        {
            id: 7,
            name: "National Security No Fly",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: noFlyZones,
            color: "default"
        },
        {
            id: 8,
            name: "Prohibited Airspaces",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: prohibitedAirspaces,
            color: "default"
        },
        {
            id: 9,
            name: "Restricted Airspaces",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: restrictedAirspaces,
            color: "default"
        },
        {
            id: 10,
            name: "Military Operation Areas",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: militaryOperationAreas,
            color: "default"
        },
        {
            id: 11,
            name: "Warning Areas",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: warningAreas,
            color: "default"
        },
        {
            id: 12,
            name: "Alert Areas",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: alertAreas,
            color: "default"
        },
        {
            id: 13,
            name: "TFR Areas",
            checked: true,
            indeterminate: false,
            subLayers: [],
            elements: tfrAreas,
            color: "default"
        }
    ]);

    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [contextMenuEntities, setContextMenuEntities] = useState([]);
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState(null);

    const { airspacePrimitiveClassA, airspacePrimitiveClassB, airspacePrimitiveClassC, airspacePrimitiveClassD, airspacePrimitiveModeC } = useMap();
    const { airspacePrimitiveClassE2, airspacePrimitiveClassE3, airspacePrimitiveClassE4, airspacePrimitiveClassE5, tfrAreasPrimitive } = useMap();
    const { stadiumsPrimitive, stadiumLabelCollection, nationalSecurityNoFlyPrimitive, prohibitedAirspacePrimitive, restrictedAirspacePrimitive } = useMap();
    const { facilityMapPrimitive, facilityMapLabelCollection, militaryOperationAreasPrimitive, warningAreasPrimitive, alertAreasPrimitive } = useMap();
    const { userMapSettings, handleFailedFetch } = useUserAuth();
    const { providerViewModels } = useEnv();

    const [airspacesClassA] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassA);
    const [airspacesClassB] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassB);
    const [airspacesClassC] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassC);
    const [airspacesClassD] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassD);
    const [airspacesClassE2] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE2);
    const [airspacesClassE3] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE3);
    const [airspacesClassE4] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE4);
    const [airspacesClassE5] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE5);
    const [airspacesModeC] = usePrimitive(viewerState, component, 0, airspacePrimitiveModeC);

    const [facilityMaps, facilityMapLabels] = usePrimitive(viewerState, component, 1, facilityMapPrimitive, facilityMapLabelCollection);
    const [stadiums, stadiumLabels] = usePrimitive(viewerState, component, 2, stadiumsPrimitive, stadiumLabelCollection);
    const [noFlyZones] = usePrimitive(viewerState, component, 3, nationalSecurityNoFlyPrimitive);
    const [prohibitedAirspaces] = usePrimitive(viewerState, component, 4, prohibitedAirspacePrimitive);
    const [restrictedAirspaces] = usePrimitive(viewerState, component, 5, restrictedAirspacePrimitive);
    const [militaryOperationAreas] = usePrimitive(viewerState, component, 6, militaryOperationAreasPrimitive);
    const [warningAreas] = usePrimitive(viewerState, component, 7, warningAreasPrimitive);
    const [alertAreas] = usePrimitive(viewerState, component, 8, alertAreasPrimitive);
    const [tfrAreas] = usePrimitive(viewerState, component, 9, tfrAreasPrimitive);

    const flightEntities = useRef([]);
    const laancEntities = useRef([]);

    // initialize variables
    useEffect(() => {
        setOpen(true);
    }, []);

    // initalize map settings
    useEffect(() => {
        if (!open || !viewerRef.current || !viewerRef.current.cesiumElement) {
            return;
        }
        const viewer = viewerRef.current.cesiumElement;
        const sceneMode = userMapSettings.map_scene_mode;
        const tilePreference = userMapSettings.map_preference;

        if (sceneMode === "SCENE3D") viewer.scene.mode = SceneMode.SCENE3D;
        if (sceneMode === "COLUMBUS_VIEW") viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
        if (sceneMode === "SCENE2D") viewer.scene.mode = SceneMode.SCENE2D;

        viewer.navigationHelpButton.viewModel.showInstructions = false;
        viewer.selectedEntityChanged.addEventListener(handleSelectedEntityChanged);
        viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        viewer.scene.screenSpaceCameraController.enableRotate = false;
        viewer.scene.screenSpaceCameraController.enableTranslate = false;
        viewer.scene.screenSpaceCameraController.enableZoom = false;
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        viewer.scene.screenSpaceCameraController.enableLook = false;
        viewer.scene.globe.depthTestAgainstTerrain = false;
        viewer.scene.requestRenderMode = true;

        if (tilePreference >= 0 && tilePreference < providerViewModels.length) {
            viewer.baseLayerPicker.viewModel.selectedImagery = providerViewModels[tilePreference];
        }
        viewer.camera.lookAt(Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude), new Cartesian3(0.0, 0.0, 200000.0));
        viewer.camera.lookAtTransform(Matrix4.IDENTITY);

        viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
            e.cancel = true;
            viewer.camera.flyTo({
                destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
            });
        });
        viewer.scene.morphComplete.addEventListener(() => {
            setTimeout(() => {
                viewer.camera.flyTo({
                    destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
                });
            }, [500]);
        });
        subscribeAndEvaluate(viewer.baseLayerPicker.viewModel, "selectedImagery", () => {
            for (let i = 0; i < viewer.imageryLayers.length; i++) {
                const layer = viewer.imageryLayers.get(i);
                if (layer !== undefined && layer.isBaseLayer()) {
                    layer.brightness = userMapSettings.brightness;
                }
            }
        });
        setViewerState(viewer);
    }, [open]);

    // update airspaces for map layers
    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const { volumes } = component;
            if (volumes.airspaces === true) {
                const allAirspaces = [
                    airspacesClassA,
                    airspacesClassB,
                    airspacesClassC,
                    airspacesClassD,
                    airspacesClassE2,
                    airspacesClassE3,
                    airspacesClassE4,
                    airspacesClassE5,
                    airspacesModeC
                ];
                const populated = allAirspaces.every((airspace) => {
                    return airspace !== null;
                });
                if (populated === true) {
                    const subLayers = [
                        { name: "CLASS_A", checked: airspacesClassA.show, elements: airspacesClassA },
                        { name: "CLASS_B", checked: airspacesClassB.show, elements: airspacesClassB },
                        { name: "CLASS_C", checked: airspacesClassC.show, elements: airspacesClassC },
                        { name: "CLASS_D", checked: airspacesClassD.show, elements: airspacesClassD },
                        { name: "CLASS_E2", checked: airspacesClassE2.show, elements: airspacesClassE2 },
                        { name: "CLASS_E3", checked: airspacesClassE3.show, elements: airspacesClassE3 },
                        { name: "CLASS_E4", checked: airspacesClassE4.show, elements: airspacesClassE4 },
                        { name: "CLASS_E5", checked: airspacesClassE5.show, elements: airspacesClassE5 },
                        { name: "MODE C", checked: airspacesModeC.show, elements: airspacesModeC }
                    ];
                    handleUpdateElementsInLayer(4, allAirspaces, subLayers);
                }
            }
        }
    }, [
        airspacesClassA,
        airspacesClassB,
        airspacesClassC,
        airspacesClassD,
        airspacesClassE2,
        airspacesClassE3,
        airspacesClassE4,
        airspacesClassE5,
        airspacesModeC
    ]);

    // update facility maps/stadiums/prohibited/restricted airspaces for map layers
    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const { volumes } = component;
            if (facilityMaps && volumes.facilityMap === true) {
                handleUpdateElementsInLayer(5, [facilityMaps, facilityMapLabels]);
            }
            if (stadiums && volumes.stadiums === true) {
                handleUpdateElementsInLayer(6, [stadiums, stadiumLabels]);
            }
            if (noFlyZones && volumes.national === true) {
                handleUpdateElementsInLayer(7, noFlyZones);
            }
            if (prohibitedAirspaces && volumes.prohibited === true) {
                handleUpdateElementsInLayer(8, prohibitedAirspaces);
            }
            if (restrictedAirspaces && volumes.restricted === true) {
                handleUpdateElementsInLayer(9, restrictedAirspaces);
            }
            if (militaryOperationAreas && volumes.military === true) {
                handleUpdateElementsInLayer(10, militaryOperationAreas);
            }
            if (warningAreas && volumes.warning === true) {
                handleUpdateElementsInLayer(11, warningAreas);
            }
            if (alertAreas && volumes.alert === true) {
                handleUpdateElementsInLayer(12, alertAreas);
            }
            if (tfrAreas && volumes.tfr === true) {
                handleUpdateElementsInLayer(13, tfrAreas);
            }
        }
    }, [facilityMaps, stadiums, noFlyZones, prohibitedAirspaces, restrictedAirspaces, militaryOperationAreas, warningAreas, alertAreas, tfrAreas]);

    // useEffect to select laanc entities
    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

            handler.setInputAction((click) => {
                viewer.selectedEntity = undefined;
                const pickedEntities = viewer.scene.drillPick(click.position);
                if (!defined(pickedEntities)) {
                    return;
                }
                const filteredEntities = pickedEntities.map((entity) => entity.id);
                const finalEntities = filteredEntities.filter((entity, index) => {
                    return laancEntities.current.includes(entity) && filteredEntities.indexOf(entity) === index;
                });
                if (finalEntities.length > 1) {
                    const xOffset = wrapperRef.current.getBoundingClientRect().left;
                    const yOffset = wrapperRef.current.getBoundingClientRect().top;
                    const virtualElement = {
                        getBoundingClientRect: () => ({
                            width: 0,
                            height: 0,
                            top: yOffset + click.position.y,
                            right: xOffset + click.position.x,
                            bottom: yOffset + click.position.y,
                            left: xOffset + click.position.x
                        })
                    };
                    setContextMenuOpen(true);
                    setContextMenuEntities(finalEntities);
                    setContextMenuAnchorEl(virtualElement);
                } else if (finalEntities.length === 1) {
                    viewer.selectedEntity = finalEntities[0];
                    const selectionModel = [finalEntities[0].name];
                    setSelectionModel(selectionModel);
                }
            }, ScreenSpaceEventType.LEFT_CLICK);
        }
    }, [open]);

    // useEffect to fetch and set terrain provider
    useEffect(() => {
        async function fetchTerrainProvider() {
            const terrain = await createWorldTerrainAsync();
            setTerrainProvider(terrain);
        }
        fetchTerrainProvider();
    }, []);

    // listen for changes to volume and update accordingly
    useEffect(() => {
        publishedOperations.forEach((operation) => {
            if (operation.flight_uuid === flightDetailsVolume.flight_uuid) {
                setFlightDetailsVolume(operation);
            }
        });
    }, [publishedOperations]);

    // draw operation
    useEffect(() => {
        if (open && viewerRef.current && viewerRef.current.cesiumElement) {
            if (flightDetailsVolume.laanc != null) {
                drawLAANCVolumes(flightDetailsVolume.laanc.volumes);
                setIsFC(isFurtherCoordination(flightDetailsVolume.laanc.volumes));
                let safety_justification = retrieveSafetyJustification(flightDetailsVolume);
                //safety_justification = safety_justification === "" ? "N/A" : safety_justification;
                setSafetyJustification(safety_justification);
            }
        }
    }, [open]);

    const drawLAANCVolumes = (volumes) => {
        const viewer = viewerRef.current.cesiumElement;
        volumes.forEach((volume, index) => {
            const laancVolume = createLAANCVolumeEntity(volume, index);
            const laancEntity = viewer.entities.add(laancVolume);
            flightEntities.current.push(laancEntity);
            laancEntities.current.push(laancEntity);
        });
        zoomToEntities(viewer, laancEntities.current);
        handleScreenshot();
    };
    const handleSelectedEntityChanged = () => {
        const viewer = viewerRef.current.cesiumElement;
        const entity = viewer.selectedEntity;

        if (!laancEntities.current.includes(entity)) {
            viewer.selectedEntity = undefined;
            setSelectionModel([]);
        } else {
            const selectionModel = [entity.name];
            setSelectionModel(selectionModel);
        }
    };
    const handleRowSelected = (selection) => {
        if (selection.length > 1) {
            const selectionSet = new Set(selectionModel);
            const result = selection.filter((s) => !selectionSet.has(s));

            handleSelectEntity(result);
            setSelectionModel(result);
        } else {
            handleSelectEntity(selection);
            setSelectionModel(selection);
        }
    };
    const handleSelectEntity = (names) => {
        const selected = laancEntities.current.find((e) => e.name === names[0]);
        const viewer = viewerRef.current?.cesiumElement;

        if (selected !== undefined) {
            viewer.selectedEntity = selected;
        } else {
            viewer.selectedEntity = undefined;
        }
    };
    const handleUpdateLaancState = (state) => {
        const laanc = {
            ...flightDetailsVolume.laanc,
            state: state
        };
        let operation = {
            ...flightDetailsVolume,
            laanc: laanc
        };
        operation = updateLaancVolumesAfterAcknowledge(operation);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify([operation])
        };
        fetch("/api/op/publish", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setFlightDetailsDialogOpen(false))
            .catch((err) => handleFailedFetch(err));
    };
    const handlePrintClick = (blob) => {
        const file = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = file;
        link.download = "Approval.pdf";
        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );
        document.body.removeChild(link);
    };
    const handleSetMapBase64 = () => {
        const viewer = viewerRef.current?.cesiumElement;
        const canvas = document.createElement("canvas");

        const { clientWidth, clientHeight } = wrapperRef.current;
        canvas.width = clientWidth;
        canvas.height = clientHeight;

        const context = canvas.getContext("2d");
        viewer.scene.initializeFrame();
        viewer.scene.render();
        context.drawImage(viewer.scene.canvas, 0, 0);

        const imageData = canvas.toDataURL("image/png");
        if (isValidBase64(imageData)) {
            setMapBase64(imageData);
        } else {
            alert("There was an error generating your Approval PDF. If you wish to print it, please close the dialog and try again.");
            setPrintButtonDisabled(true);
        }
        viewer.scene.screenSpaceCameraController.enableRotate = true;
        viewer.scene.screenSpaceCameraController.enableTranslate = true;
        viewer.scene.screenSpaceCameraController.enableZoom = true;
        viewer.scene.screenSpaceCameraController.enableTilt = true;
        viewer.scene.screenSpaceCameraController.enableLook = true;
    };
    const handleScreenshot = () => {
        const viewer = viewerRef.current?.cesiumElement;
        viewer.render();

        const handleTileLoaded = () => {
            if (viewer.scene.globe.tilesLoaded) {
                viewer.scene.globe.tileLoadProgressEvent.removeEventListener(handleTileLoaded);
                requestAnimationFrame(handleSetMapBase64);
            }
        };
        if (viewer.scene.globe.tilesLoaded) {
            handleSetMapBase64();
        } else {
            viewer.scene.globe.tileLoadProgressEvent.addEventListener(handleTileLoaded);
        }
    };

    const handleSetSelectedEntity = (entity) => {
        const viewer = viewerRef.current.cesiumElement;
        if (viewer.entities.contains(entity)) {
            viewer.selectedEntity = entity;
            const selectionModel = [entity.name];
            setSelectionModel(selectionModel);
        }
        handleCloseContextMenu();
    };

    const handleCloseContextMenu = () => {
        setContextMenuOpen(false);
        setContextMenuAnchorEl(null);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const toggleElementsVisibility = (elements, value) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            if (Array.isArray(elements)) {
                elements.forEach((element) => {
                    element.show = value;
                });
            } else {
                elements.show = value;
            }
            const viewer = viewerRef.current.cesiumElement;
            viewer.scene.requestRender();
        }
    };
    const handleUpdateElementsInLayer = (id, newElements, subLayers = null) => {
        setLayers((previous) => {
            return previous.map((layer) => {
                if (layer.id !== id) {
                    return layer;
                }

                let color = "default";
                if (Array.isArray(newElements) && newElements.length > 0 && newElements[0] && newElements[0].color) {
                    color = newElements[0].color;
                } else if (newElements && newElements.color) {
                    color = newElements.color;
                }

                // Reset Airspaces to default color.
                if (layer.id === 4) {
                    color = "default";
                }

                if (subLayers === null) {
                    return { ...layer, elements: newElements, color: color };
                } else {
                    return {
                        ...layer,
                        elements: newElements,
                        subLayers: subLayers,
                        checked: getCheckedFromSubLayers(subLayers),
                        indeterminate: getIndeterminateFromSubLayers(subLayers),
                        color: color
                    };
                }
            });
        });
    };
    return flightDetailsVolume.laanc ? (
        <Dialog
            open={flightDetailsDialogOpen}
            onClose={() => setFlightDetailsDialogOpen(false)}
            maxWidth="lg"
            fullWidth
            container={mapContainer ? mapContainer : undefined}
        >
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, pl: 3 }}>
                <Typography variant="h5">{flightDetailsVolume.name}:</Typography>
                &nbsp;
                <Typography
                    variant="h5"
                    sx={{
                        color: laancStates.find(({ state }) => state === flightDetailsVolume.laanc.state)
                            ? laancStates.find(({ state }) => state === flightDetailsVolume.laanc.state).color
                            : "#fff"
                    }}
                >
                    {flightDetailsVolume.laanc.state.replace(/_/g, " ")}
                </Typography>
                &nbsp;
                <Typography variant="h5" sx={{ fontStyle: "italic" }}>
                    {flightDetailsVolume.block_override === undefined ? "" : "• Bypassed"}
                </Typography>
            </Box>
            <DialogContent sx={{ pb: 0 }}>
                <Box ref={wrapperRef} sx={{ position: "relative", height: "500px", mb: 1 }}>
                    <Viewer terrainProvider={terrainProvider} animation={false} timeline={false} ref={viewerRef} full />

                    <MapLayers
                        viewerRef={viewerRef}
                        viewerContainerRef={wrapperRef}
                        layers={layers}
                        setLayers={setLayers}
                        toggleElementsVisibility={toggleElementsVisibility}
                    />
                    <MapContextMenu
                        contextMenuOpen={contextMenuOpen}
                        contextMenuAnchorEl={contextMenuAnchorEl}
                        contextMenuEntities={contextMenuEntities}
                        handleCloseContextMenu={handleCloseContextMenu}
                        handleSetSelectedEntity={handleSetSelectedEntity}
                    />
                </Box>
                <Box>
                    {flightDetailsVolume.laanc.state === CONSTANT.LAANC_INVALID_AWAITING ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "3px solid grey",
                                backgroundColor: "#ffffff29",
                                gap: "50px",
                                px: "200px",
                                mt: "16px"
                            }}
                        >
                            <ReportProblemOutlinedIcon sx={{ fontSize: "32px" }} />
                            <Typography variant="body1">
                                Flight LAANC Approval is now invalid. Please acknowledge the invalid status indicating that the pilot/operator is aware that
                                their flight is no longer approved for LAANC.
                            </Typography>
                        </Box>
                    ) : flightDetailsVolume.laanc.state === CONSTANT.LAANC_RESCINDED_AWAITING ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "3px solid grey",
                                backgroundColor: "#ffffff29",
                                gap: "50px",
                                px: "200px",
                                mt: "16px"
                            }}
                        >
                            <ReportProblemOutlinedIcon sx={{ fontSize: "32px" }} />
                            <Typography variant="body1">
                                Flight LAANC Approval has been rescinded by the FAA. Please immediately acknowledge the rescind indicating that the
                                pilot/operator is aware that their FAA approval has been nullified and the flight is no longer valid.
                            </Typography>
                        </Box>
                    ) : (
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs onChange={handleTabChange} variant="scrollable" value={tabValue} scrollButtons allowScrollButtonsMobile>
                                    <Tab label="LAANC Volumes" value="1" />
                                    <Tab label="FAA Advisories" value="2" />
                                    {isFC && <Tab label="Safety Justification" value="3" />}
                                </Tabs>
                            </Box>
                            <TabPanel value="1" sx={{ padding: 1 }}>
                                <DataGrid
                                    rows={flightDetailsVolume.laanc.volumes.map((row, i) => ({ ...row, name: `Volume ${i + 1}`, rowIndex: i + 1 }))}
                                    columns={columns}
                                    getRowId={(row) => row.name}
                                    rowHeight={30}
                                    headerHeight={40}
                                    pagination
                                    slots={{ pagination: CustomPagination }}
                                    initialState={{ pagination: { paginationModel: { pageSize: 3 } } }}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    onRowSelectionModelChange={handleRowSelected}
                                    disableVirtualization
                                    autoHeight
                                    rowSelectionModel={selectionModel}
                                    width={600}
                                    sx={{
                                        bgcolor: "#121212",
                                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                            display: "none"
                                        }
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value="2" sx={{ padding: 1 }}>
                                <LAANCAdvisoryTable advisories={flightDetailsVolume.advisories} isFurtherCoordination={isFC} />
                            </TabPanel>
                            <TabPanel value="3" sx={{ padding: 1 }}>
                                <Typography variant="body1">{safetyJustification}</Typography>
                            </TabPanel>
                        </TabContext>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button size="small" onClick={() => setFlightDetailsDialogOpen(false)} id="close">
                    Close
                </Button>
                {flightDetailsVolume.laanc.state === CONSTANT.LAANC_INVALID_AWAITING ? (
                    <Button size="small" onClick={() => handleUpdateLaancState(CONSTANT.LAANC_INVALID_CANCEL)} id="acknowledgeInvalid">
                        Acknowledge Invalid
                    </Button>
                ) : (
                    <></>
                )}
                {flightDetailsVolume.laanc.state === CONSTANT.LAANC_RESCINDED_AWAITING ? (
                    <Button size="small" onClick={() => handleUpdateLaancState(CONSTANT.LAANC_RESCINDED_ACK)} id="acknowledgeRescind">
                        Acknowledge Rescind
                    </Button>
                ) : (
                    <></>
                )}
                {flightDetailsVolume.laanc.state === CONSTANT.LAANC_AUTHORIZED ? (
                    <BlobProvider document={<ApprovalDocument mapBase64={mapBase64} flightDetailsVolume={flightDetailsVolume} />}>
                        {({ blob }) => (
                            <LoadingButton
                                size="small"
                                loading={mapBase64 === null && printButtonDisabled === false ? true : false}
                                onClick={() => handlePrintClick(blob)}
                                disabled={printButtonDisabled}
                                id="printApproval"
                            >
                                <span>Print Approval</span>
                            </LoadingButton>
                        )}
                    </BlobProvider>
                ) : (
                    <></>
                )}
            </DialogActions>
        </Dialog>
    ) : (
        <Backdrop open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default FlightDetailsDialog;
