//Unit testing setup for MapDraw
export const testingVolumes = [
    {
        id: 0,
        type: "polygon",
        vertices: [
            { lat: 0, lng: 0 },
            { lat: 1, lng: 1 },
            { lat: 2, lng: 2 },
            { lat: 3, lng: 3 }
        ]
    },
    {
        id: 1,
        type: "circle",
        vertices: [{ lat: 0, lng: 0 }]
    }
];

// Unit testing setup for OL coordinates.
export const testingCoordinates = [
    [
        [-84.01598963776132, 39.21041560156954],
        [-82.12568464495808, 39.98489812782287],
        [-82.94012383983329, 38.882554958323624],
        [-84.01598963776132, 40.21041560156954]
    ]
];
