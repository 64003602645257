import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import CircleStyle from "ol/style/Circle";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import KML from "ol/format/KML.js";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import Translate from "ol/interaction/Translate";
import GeometryCollection from "ol/geom/GeometryCollection";
import LineString from "ol/geom/LineString";
import Polygon, { circular } from "ol/geom/Polygon";
import MultiPoint from "ol/geom/MultiPoint";
import Point from "ol/geom/Point";

import { center, circle, convex, distance, lineString, point, points, buffer as turf_buffer } from "@turf/turf";
import { createEmpty, extend } from "ol/extent";

import { ConvertMetersToFeet, DateHasAlreadyPassed, GetVerticalSpeedIndicator } from "../util";
import { GetColorFromState } from "../util";

export const Colors = {
    white: "rgb(255, 255, 255)",
    black: "rgb(0, 0, 0)",
    yellow: "rgb(255, 255, 0)",
    orange: "rgb(255, 165, 0)",
    lime: "rgb(0, 255, 0)",
    green: "rgb(0, 200, 0)",
    red: "rgb(255, 80, 80)",
    red2: "rgb(255, 0, 0)",
    blue1: "rgb(0, 135, 191)",
    blue2: "rgb(5, 142, 184)",
    blue3: "rgb(3, 136, 191)",
    blue4: "rgb(51, 153, 204)",
    lightBlue1: "rgb(144, 202, 249)",
    lightBlue2: "rgb(0, 225, 255)",
    pink1: "rgb(157, 107, 158)",
    pink2: "rgb(158, 110, 157)",
    lightPink: "rgb(216, 171, 198)"
};

export const LowOpacityColors = {
    yellow: "rgba(255, 255, 0, 0.1)",
    orange: "rgba(255, 165, 0, 0.1)",
    lime: "rgba(0, 255, 0, 0.1)",
    red2: "rgba(255, 0, 0, 0.1)",
    blue1: "rgba(0, 135, 191, 0.1)",
    blue2: "rgba(5, 142, 184, 0.1)",
    blue3: "rgba(3, 136, 191, 0.1)",
    pink1: "rgba(157, 107, 158, 0.1)",
    pink2: "rgba(158, 110, 157, 0.1)",
    lightPink: "rgba(216, 171, 198, 0.1)"
};

const LAANC_STYLE_DATA = {
    LAANC_AIRSPACE: {
        CLASS_B: {
            fillColor: LowOpacityColors.blue1,
            strokeColor: Colors.blue1
        },
        CLASS_C: {
            fillColor: LowOpacityColors.pink1,
            strokeColor: Colors.pink1
        },
        CLASS_D: {
            fillColor: LowOpacityColors.blue2,
            strokeColor: Colors.blue2,
            strokeLineDash: [8, 4]
        },
        CLASS_E: {
            fillColor: LowOpacityColors.pink2,
            strokeColor: Colors.pink2,
            strokeLineDash: [8, 4]
        },
        MODE_C: {
            fillColor: LowOpacityColors.pink2,
            strokeColor: Colors.pink2
        }
    },
    UASFM: (apt1_laanc, ceiling) => {
        const opacity = Math.max(0, 0.6 - 0.075 * Math.floor(ceiling / 50));
        return {
            fillColor: apt1_laanc === 1 ? `rgba(144, 202, 249, ${opacity})` : `rgba(255, 80, 80, ${opacity})`,
            strokeColor: apt1_laanc === 1 ? Colors.lightBlue1 : Colors.red,
            textLabel: `${ceiling}ft`
        };
    },
    NSUFR: {
        fillColor: LowOpacityColors.lightPink,
        strokeColor: Colors.lightPink,
        strokeLineDash: [8, 4]
    },
    SU_AIRSPACE: {
        A: {
            fillColor: LowOpacityColors.blue3,
            strokeColor: Colors.blue3,
            strokeLineDash: [1, 2]
        },
        MOA: {
            fillColor: LowOpacityColors.pink1,
            strokeColor: Colors.pink1,
            strokeLineDash: [2, 4]
        },
        P: {
            fillColor: LowOpacityColors.blue3,
            strokeColor: Colors.blue3,
            strokeLineDash: [2, 4]
        },
        R: {
            fillColor: LowOpacityColors.blue3,
            strokeColor: Colors.blue3,
            strokeLineDash: [2, 4]
        },
        W: {
            fillColor: LowOpacityColors.blue3,
            strokeColor: Colors.blue3,
            strokeLineDash: [2, 4]
        }
    },
    LAANC_STADIUMS: (name) => ({
        fillColor: LowOpacityColors.lime,
        strokeColor: Colors.lime,
        textLabel: name
    }),
    FAA_TFR: (properties) => {
        let stroke_color = Colors.red2;
        let fill_color = LowOpacityColors.red2;

        try {
            const tfr_is_active = DateHasAlreadyPassed(properties.effectiveStart);
            const height_information = JSON.parse(properties.heightInformation);

            if (parseFloat(height_information.lowerLevel) > 400 && height_information.uomLowerLevel === "FT") {
                stroke_color = Colors.yellow;
                fill_color = LowOpacityColors.yellow;
            } else if (tfr_is_active === false) {
                stroke_color = Colors.orange;
                fill_color = LowOpacityColors.orange;
            }
        } catch (err) {
            console.error(err);
        }
        return {
            fillColor: fill_color,
            strokeColor: stroke_color
        };
    },
    AIRPORTS: (type_code) => {
        let image = "";
        if (type_code === "HP") {
            image = "../static/2d-models/airport/HelicopterIcon.svg";
        } else {
            image = "../static/2d-models/airport/AirplaneIcon.svg";
        }
        return {
            image: image
        };
    },
    DC_FRZ_SFRA: (sector) => {
        if (sector === "DC SFRA") {
            return {
                strokeColor: Colors.orange,
                fillColor: LowOpacityColors.orange
            };
        } else {
            return {
                strokeColor: Colors.red2,
                fillColor: LowOpacityColors.red2
            };
        }
    }
};

export const FILL_WHITE = new Fill({ color: "rgba(255, 255, 255, 0.4)" });
export const FILL_BLUE = new Fill({ color: "rgba(51, 153, 204, 0.4)" });
export const STROKE_WHITE = new Stroke({ color: "#FFFFFF", width: 1.25 });
export const STROKE_BLUE = new Stroke({ color: "#3399CC", width: 1.25 });

// Default style for volumes.
export const STYLE_VOLUME = new Style({
    fill: FILL_BLUE,
    stroke: STROKE_WHITE
});

// Default style for volumes currently being created/edited.
export function GetEditVolumeStyle(settings, colorHex) {
    if (!colorHex) {
        colorHex = settings.op_state_settings.find((op) => op.state === "PLANNING").color;
    }
    const colorRgba = convertHexColorToRgbaColor(colorHex, 0.4);
    return [
        new Style({
            fill: new Fill({ color: colorRgba }),
            stroke: new Stroke({ color: colorHex, width: 3.5 })
        }),
        new Style({
            image: new CircleStyle({
                fill: new Fill({ color: colorHex }),
                stroke: new Stroke({ color: colorHex, width: 1.25 }),
                radius: 5
            }),
            geometry: (feature) => {
                if (!feature || feature.get("edit") || feature.get("type") === "Circle" || !feature.getGeometry()) {
                    return;
                }

                const coords = feature.getGeometry().getCoordinates();
                if (!coords || coords.length === 0 || !Array.isArray(coords)) {
                    return;
                } else if (Array.isArray(coords[0])) {
                    return new MultiPoint(Array.isArray(coords[0][0]) ? coords[0] : coords);
                } else {
                    return new Point(coords);
                }
            }
        })
    ];
}

export function GetGeofenceStyle(settings) {
    const colorHex = settings.op_state_settings.find((op) => op.state === "PLANNING").color;
    const colorRgba = convertHexColorToRgbaColor(colorHex, 0.4);
    return new Style({
        fill: new Fill({ color: colorRgba }),
        stroke: new Stroke({ color: colorHex, width: 1 })
    });
}

// Additional style for assets currently being monitored.
export const STYLE_VOLUME_MONITOR = new Style({
    stroke: new Stroke({
        color: "red",
        width: 2,
        lineDash: [10, 10]
    }),
    fill: new Fill({
        color: LowOpacityColors.red2
    })
});

// Default style for ASD entities.
export const STYLE_ASD = new Style({
    image: new Icon({
        src: "../static/2d-models/adsb/adsb-default.png"
    })
});

// Gets the feature's style or the 1st style if it has multiple.
export function GetFirstStyle(feature) {
    const style = feature.getStyle();
    return Array.isArray(style) ? style[0] : style;
}

export const allModels = new Map();
allModels.set(0, "../static/2d-models/adsb/adsb-default.png");
allModels.set(1, "../static/2d-models/adsb/adsb-light.png");
allModels.set(2, "../static/2d-models/adsb/adsb-light.png");
allModels.set(3, "../static/2d-models/adsb/adsb-large.png");
allModels.set(4, "../static/2d-models/adsb/adsb-heavy.png");
allModels.set(5, "../static/2d-models/adsb/adsb-heavy.png");
allModels.set(7, "../static/2d-models/adsb/adsb-rotocraft.png");
allModels.set(8, "../static/2d-models/telemetry/telemetry-quad.png");

// Gets the LAANC volume's style based on its properties.
export function GetLaancStyle(feature) {
    const properties = feature.getProperties();
    const layer = properties.layer;

    let styleData;
    if (layer === "LAANC_AIRSPACE") {
        styleData = LAANC_STYLE_DATA[layer][properties.LOCAL_TYPE];
    } else if (layer.includes("UASFM")) {
        styleData = LAANC_STYLE_DATA["UASFM"](properties.APT1_LAANC, properties.CEILING);
    } else if (layer.includes("NSUFR")) {
        styleData = LAANC_STYLE_DATA["NSUFR"];
    } else if (layer === "SU_AIRSPACE") {
        styleData = LAANC_STYLE_DATA[layer][properties.TYPE_CODE];
    } else if (layer === "LAANC_STADIUMS") {
        styleData = LAANC_STYLE_DATA[layer](properties.NAME);
    } else if (layer === "FAA_TFR" && !DateHasAlreadyPassed(properties.effectiveEnd)) {
        styleData = LAANC_STYLE_DATA[layer](properties);
    } else if (layer === "AIRPORTS") {
        styleData = LAANC_STYLE_DATA[layer](properties.TYPE_CODE);
    } else if (layer === "DC_FRZ_SFRA") {
        styleData = LAANC_STYLE_DATA[layer](properties.SECTOR);
    }
    if (!styleData) {
        styleData = {
            fillColor: "rgba(0, 0, 0, 0)",
            strokeColor: "rgba(0, 0, 0, 0)",
            strokeLineDash: undefined,
            textLabel: ""
        };
    }
    if (!styleData.fillColor) styleData.fillColor = "rgba(0, 0, 0, 0)";
    if (!styleData.strokeColor) styleData.strokeColor = "rgba(0, 0, 0, 0)";
    if (!styleData.textLabel) styleData.textLabel = "";

    let text = undefined;
    if (styleData.textLabel) {
        text = new Text({
            text: styleData.textLabel,
            font: "12px Calibri,sans-serif",
            fill: new Fill({ color: Colors.white }),
            stroke: new Stroke({ color: Colors.black, width: 3 })
        });
    }
    let image = undefined;
    if (styleData.image) {
        image = new Icon({
            src: styleData.image
        });
    }
    return new Style({
        fill: new Fill({
            color: styleData.fillColor
        }),
        stroke: new Stroke({
            color: styleData.strokeColor,
            width: 2,
            lineDash: styleData.strokeLineDash
        }),
        text: text,
        image: image
    });
}

export function GetLaancAirspaceStyle(feature, settings) {
    let fill_color = "rgba(0, 0, 0, 0)";
    let line_dash = undefined;
    let stroke_color = "rgba(0, 0, 0, 0)";

    const properties = feature.getProperties();
    const setting = settings.find((layer) => {
        return layer.local_type === properties.LOCAL_TYPE;
    });
    if (setting !== undefined && setting.visible === true) {
        switch (properties.LOCAL_TYPE) {
            case "CLASS_B":
                stroke_color = Colors.blue1;
                fill_color = LowOpacityColors.blue1;
                line_dash = undefined;
                break;
            case "CLASS_C":
                stroke_color = Colors.pink1;
                fill_color = LowOpacityColors.pink1;
                line_dash = undefined;
                break;
            case "CLASS_D":
                stroke_color = Colors.blue2;
                fill_color = LowOpacityColors.blue2;
                line_dash = [8, 4];
                break;
            case "MODE C":
                stroke_color = Colors.pink1;
                fill_color = LowOpacityColors.pink1;
                line_dash = undefined;
                break;
            default:
                if (properties.LOCAL_TYPE.includes("CLASS_E")) {
                    stroke_color = Colors.pink2;
                    fill_color = LowOpacityColors.pink2;
                    line_dash = [8, 4];
                }
        }
    }
    return new Style({
        fill: new Fill({
            color: fill_color
        }),
        stroke: new Stroke({
            color: stroke_color,
            width: 2,
            lineDash: line_dash
        })
    });
}

export function getSpecialUseAirspaceStyle(feature, layers) {
    let style = new Style({
        fill: undefined,
        stroke: undefined
    });
    const properties = feature.getProperties();
    if (properties && properties.TYPE_CODE) {
        const layer = layers.find((layer) => {
            return layer.type_code === properties.TYPE_CODE;
        });
        if (layer !== undefined && layer.visible === true) {
            const su_airspace_style = LAANC_STYLE_DATA["SU_AIRSPACE"][properties.TYPE_CODE];
            if (su_airspace_style) {
                style = new Style({
                    fill: new Fill({
                        color: su_airspace_style.fillColor
                    }),
                    stroke: new Stroke({
                        color: su_airspace_style.strokeColor,
                        width: 2,
                        lineDash: su_airspace_style.strokeLineDash
                    })
                });
            }
        }
    }
    return style;
}

export function getLabelTextFromFeatureAndUser(feature, user) {
    const properties = feature.getProperties();
    const callsign = properties.asd.callsign[user.organization_id];
    const callsign_text = `${callsign === undefined ? "" : `${callsign}\n`}`;

    let altitude_text = "";
    if (isNaN(properties.asd.alt_hae_ft) === false) {
        const vertical_speed_indicator = GetVerticalSpeedIndicator(properties.asd.climb_rate_fpm);
        altitude_text = `${properties.asd.alt_hae_ft}ft ${vertical_speed_indicator}\n`;
    } else {
        altitude_text = `NAR\n`;
    }
    const label = `${callsign_text}${altitude_text}${properties.asd.ground_speed_kn}kn`;

    return new Text({
        text: label,
        font: "12px Calibri,sans-serif",
        fill: new Fill({ color: Colors.white }),
        stroke: new Stroke({
            color: Colors.black,
            width: 3
        }),
        backgroundFill: new Fill({
            color: "rgba(0, 0, 0, 0.8)"
        }),
        padding: [5, 5, 5, 5],
        offsetX: -50
    });
}

export function getAsdPredLineStyle(properties, source_type_settings) {
    let vehicle_color = source_type_settings.color;
    if (properties.asd.simulated) {
        vehicle_color = Colors.lightBlue2;
    }
    return new Style({
        stroke: new Stroke({
            color: vehicle_color,
            width: 2
        })
    });
}

export function getAsdHistLineStyle(properties, source_type_settings) {
    let vehicle_color = source_type_settings.color;
    if (properties.asd.simulated) {
        vehicle_color = Colors.lightBlue2;
    }
    return new Style({
        stroke: new Stroke({
            color: vehicle_color,
            width: 2,
            lineDash: [5, 5]
        })
    });
}

export function getAsdDpzStyle(settings) {
    if (settings.well_clear_volume) {
        return new Style({
            stroke: new Stroke({
                color: Colors.green,
                width: 2
            })
        });
    } else {
        return new Style({
            stroke: undefined
        });
    }
}

export function getAsdTrackIconStyle(properties, settings, source_type_settings) {
    const emitter_type = properties.asd && properties.asd.adsb ? properties.asd.adsb.emitter_cat : 0;
    let scale = settings.entity_size + 0.2;

    let src = `../static/2d-models/shapes/${source_type_settings.icon}.png`;
    if (source_type_settings.icon === "icon") {
        src = allModels.get(0);
        if (properties.asd.source_type === "TELEMETRY") {
            src = allModels.get(8);
        } else if (emitter_type && allModels.has(emitter_type)) {
            src = allModels.get(emitter_type);
        }
    } else {
        scale * 0.75;
    }
    let vehicle_color = source_type_settings.color;
    if (properties.asd.simulated) {
        vehicle_color = Colors.lightBlue2;
    }
    return new Style({
        image: new Icon({
            src: src,
            color: vehicle_color,
            scale: scale,
            rotation: -(properties.asd.rotation * (Math.PI / 180))
        })
    });
}

export function getAsdTrackLabelStyle(feature, layers, settings, user) {
    const labels_are_enabled = settings.label_visible === "on";
    const label_is_already_visible = feature.get("show_label") === true;
    const alert_volume_labels_are_enabled = settings.label_visible === "alerts" && feature.get("alert_volume_confliction_alert") && layers.alert_volumes;

    if (labels_are_enabled || label_is_already_visible || alert_volume_labels_are_enabled) {
        const text = getLabelTextFromFeatureAndUser(feature, user);
        return new Style({
            text: text
        });
    } else {
        return new Style({
            text: undefined
        });
    }
}

export function getAsdZeroConflictionStyle(feature, settings, user) {
    const properties = feature.getProperties();
    const vehicle_has_zca_enabled = properties.asd.show_dpz[user.organization_id];
    const user_enabled_well_clear_volumes = settings.well_clear_volume;
    const active_zero_conflict_alerts = feature.get("zero_confliction_alerts");

    if (Array.isArray(active_zero_conflict_alerts)) {
        const ownship_alerts = [];
        const intruder_alerts = [];

        active_zero_conflict_alerts.forEach((zero_conflict_alert) => {
            const ownship_id = zero_conflict_alert.ownship_track.id;
            if (ownship_id === properties.asd.id) {
                ownship_alerts.push(zero_conflict_alert);
            }
            const intruder_id = zero_conflict_alert.intruder_track.id;
            if (intruder_id === properties.asd.id) {
                intruder_alerts.push(zero_conflict_alert);
            }
        });
        let image = undefined;
        if (ownship_alerts.length) {
            let well_clear_volume_color_rgb = "255, 235, 0";
            const zc_alerts_contain_warning = ownship_alerts.find(({ severity }) => {
                return severity === "WARNING";
            });
            if (zc_alerts_contain_warning) {
                well_clear_volume_color_rgb = "200, 0, 0";
            }
            image = getCircleImageFromColor(well_clear_volume_color_rgb);
        }
        let text = undefined;
        if (intruder_alerts.length) {
            const zca_text = getZeroConflictAlertTextFromAlert(intruder_alerts[0]);
            text = zca_text;
        }
        return new Style({
            text: text,
            image: image
        });
    } else if (vehicle_has_zca_enabled && user_enabled_well_clear_volumes) {
        const green_well_clear_volume = getCircleImageFromColor("0, 200, 0");
        return new Style({
            text: undefined,
            image: green_well_clear_volume
        });
    } else {
        return new Style({
            text: undefined,
            image: undefined
        });
    }
}

export function getAsdCoastedStyle(properties) {
    if (properties.asd.coasted) {
        return new Style({
            image: new Icon({
                src:
                    "data:image/svg+xml;base64," +
                    btoa(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <line x1="4" y1="4" x2="20" y2="20" stroke="yellow" stroke-width="2"/>
              <line x1="20" y1="4" x2="4" y2="20" stroke="yellow" stroke-width="2"/>
              </svg>
              `)
            })
        });
    } else {
        return new Style({
            image: undefined
        });
    }
}

export function getAsdEndOfTrackStyle(properties) {
    if (properties.asd.eot) {
        return new Style({
            image: new Icon({
                src:
                    "data:image/svg+xml;base64," +
                    btoa(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <line x1="4" y1="4" x2="20" y2="20" stroke="red" stroke-width="2"/>
                    <line x1="20" y1="4" x2="4" y2="20" stroke="red" stroke-width="2"/>
                    </svg>
                    `)
            })
        });
    } else {
        return new Style({
            image: undefined
        });
    }
}

export function getAsdFeatureStyleFromLayersAndSettings(feature, layers, settings, user) {
    if (!feature.get("asd")) {
        return [];
    }

    const properties = feature.getProperties();
    const asd = properties.asd;

    // Check: Altitude Range
    const alt_hae_ft = asd.alt_hae_ft;
    if (isNaN(alt_hae_ft) === true) {
        if (layers && layers.nar_tracks === false && asd.source_type !== "TELEMETRY") {
            return [];
        }
    } else if (isNaN(alt_hae_ft) === false && layers && layers.altitude_range) {
        const altitude = parseFloat(asd.alt_hae_ft) < 0 ? 1 : asd.alt_hae_ft;
        const altitudeWithinRange = altitude >= layers.altitude_range[0] && altitude <= layers.altitude_range[1];
        if (!altitudeWithinRange && asd.source_type !== "TELEMETRY") {
            return [];
        }
    }

    // Check: Surveillance Sources Map Layer
    if (layers && !layers.surveillance_sources) {
        return [];
    }

    // Check: Source Setting Visibility
    if (settings.source_mapping[asd.source_id] && !settings.source_mapping[asd.source_id].visibility) {
        return [];
    }

    // Check: Source Type (icon/color) Presence
    const sourceSettings = settings.source_type_settings.find(({ source_type }) => source_type === asd.source_type);
    if (!sourceSettings) {
        return [];
    }

    const feature_id = feature.getId();
    const feature_is_icon = !feature_id.includes("_");
    const feature_is_pred_line = feature_id.includes("_pl");
    const feature_is_hist_line = feature_id.includes("_hl");
    const feature_is_dpz = feature_id.includes("_dpz");
    const feature_is_zc_line = feature_id.includes("_") && !feature_id.includes("_pl") && !feature_id.includes("_hl") && !feature_id.includes("_dpz");

    if (feature_is_pred_line && settings.pred_line) {
        return [getAsdPredLineStyle(properties, sourceSettings)];
    } else if (feature_is_hist_line && settings.hist_line) {
        return [getAsdHistLineStyle(properties, sourceSettings)];
    } else if (feature_is_dpz && asd.show_dpz[user.organization_id]) {
        return [getAsdDpzStyle(settings)];
    } else if (feature_is_zc_line) {
        const zc_line_style = feature.getStyle();
        if (zc_line_style) {
            return zc_line_style;
        } else {
            return [];
        }
    } else if (!feature_is_icon) {
        return [];
    }

    // All the different styles for regular ASD entities.
    const asd_icon_style = getAsdTrackIconStyle(properties, settings, sourceSettings);
    const asd_label_style = getAsdTrackLabelStyle(feature, layers, settings, user);
    const alert_confliction_style = getAlertVolumeConflictionAlertStyle(feature, layers);
    const zero_confliction_style = getAsdZeroConflictionStyle(feature, settings, user);
    const eot_style = getAsdEndOfTrackStyle(properties);
    const coasted_style = getAsdCoastedStyle(properties);
    return [asd_icon_style, asd_label_style, alert_confliction_style, zero_confliction_style, eot_style, coasted_style];
}

export function getAlertVolumeConflictionAlertStyle(feature, layers) {
    if (feature.get("alert_volume_confliction_alert") && layers.alert_volumes) {
        return new Style({
            image: new CircleStyle({
                radius: 20,
                fill: undefined,
                stroke: new Stroke({
                    color: "rgba(255, 0, 0, 0.8)",
                    width: 2
                })
            })
        });
    } else {
        return new Style({
            image: undefined
        });
    }
}

export function getCircleImageFromColor(color_rgb) {
    return new CircleStyle({
        radius: 20,
        fill:
            color_rgb === "0, 200, 0"
                ? new Fill({
                      color: `rgba(${color_rgb}, .1)`
                  })
                : undefined,
        stroke: new Stroke({
            color: `rgba(${color_rgb}, .8)`,
            width: 2
        })
    });
}

export function getZeroConflictAlertTextFromAlert(zero_conflict_alert) {
    const horiz_sep_ft = ConvertMetersToFeet(zero_conflict_alert.horiz_sep_m);
    const vert_sep_ft = ConvertMetersToFeet(zero_conflict_alert.vert_sep_m);
    const severity = zero_conflict_alert.severity;
    const time_los_sec = zero_conflict_alert.time_los_sec;

    const label = `Horz: ${horiz_sep_ft}ft\nVert: ${vert_sep_ft}ft\nAlert: ${severity}\nTime: ${time_los_sec}s`;

    let background_color_rgb = "255, 235, 0";
    if (severity === "WARNING") {
        background_color_rgb = "200, 0, 0";
    }
    return new Text({
        text: label,
        font: "16px Calibri, sans-serif",
        fill: new Fill({
            color: "#fff"
        }),
        stroke: new Stroke({
            color: "#000",
            width: 2
        }),
        backgroundFill: new Fill({
            color: `rgba(${background_color_rgb}, 1)`
        }),
        padding: [5, 5, 5, 5],
        offsetY: -75
    });
}

export function getColorFromLaancType(state, opacity) {
    if (state === "AUTO_APPROVED") {
        return `rgba(224, 210, 104, ${opacity})`;
    } else if (state === "FURTHER_COORDINATION" || state === "BLOCKED") {
        return `rgba(203, 76, 78, ${opacity})`;
    } else {
        return `rgba(255, 255, 255, ${opacity})`;
    }
}

const GeoJson = new GeoJSON();
const Kml = new KML();

// Creates a new asset feature using the asset structure.
export function CreateAsset(asset) {
    const assetGeometry = GeoJson.readGeometry(asset.geometry);
    const assetFeature = new Feature(assetGeometry);
    assetFeature.setId(asset.uuid);
    assetFeature.setProperties({ name: asset.name, asset: asset });
    assetFeature.setStyle(
        new Style({
            fill: FILL_BLUE,
            stroke: STROKE_WHITE,
            text: new Text({
                text: asset.name,
                font: "bold 16px sans-serif",
                fill: new Fill({ color: "#FFFFFF" }),
                stroke: new Stroke({ color: "#000000", width: 3 }),
                textAlign: "center",
                textBaseline: "middle",
                offsetX: 0,
                offsetY: 0
            })
        })
    );
    return assetFeature;
}

// Searches for a layer given its name.
export function GetLayer(map, layerName) {
    if (!map) {
        return null;
    }
    for (const layer of map.getLayers().getArray()) {
        if (layer.get("name") === layerName) {
            return layer;
        }
    }
    return null;
}

// Searches for a source given a layer's name.
export function GetSource(map, layerName) {
    const layer = GetLayer(map, layerName);
    return layer ? layer.getSource() : null;
}

// Searches for a list of features given a layer's name.
export function GetFeatures(map, layerName) {
    const source = GetSource(map, layerName);
    return source ? source.getFeatures() : null;
}

// Searches for a feature given a layer's name and feature's UUID.
export function GetFeature(map, layerName, featureUUID) {
    const source = GetSource(map, layerName);
    return source && featureUUID ? source.getFeatureById(featureUUID) : null;
}

export function GetAllFeaturesWithId(map, layer_name, feature_uuid) {
    const relevant_features = [];

    const source = GetSource(map, layer_name);
    if (source) {
        const all_features = source.getFeatures();

        if (Array.isArray(all_features)) {
            all_features.forEach((feature) => {
                const feature_id = feature.getId();
                if (feature_id.includes(feature_uuid)) {
                    relevant_features.push(feature);
                }
            });
        }
    }
    return relevant_features;
}

// Jumps to the specified feature on the map.
export function JumpToFeature(map, feature) {
    if (!map || !feature) {
        return;
    } else if (feature.get("asd")) {
        map.getView().fit(feature.getGeometry(), { maxZoom: 12 });
    } else if (feature.getGeometry() instanceof GeometryCollection) {
        for (const geo of feature.getGeometry().getGeometries()) {
            if (geo instanceof Polygon || geo instanceof LineString) {
                map.getView().fit(geo, { padding: [200, 200, 200, 200] });
                break;
            }
        }
    } else {
        map.getView().fit(feature.getGeometry(), { padding: [200, 200, 200, 200] });
    }
}

export function JumpToFeatures(map, features) {
    if (features.length === 0) {
        return;
    }
    const extent = createEmpty();
    features.forEach((feature) => {
        extend(extent, feature.getGeometry().getExtent());
    });
    map.getView().fit(extent, {
        maxZoom: 16,
        padding: [200, 200, 200, 200]
    });
}

// Converts a feature into the GeoJSON format.
export function ConvertGeometryToGeoJson(geometry) {
    if (typeof geometry.getRadius === "function") {
        const polygon = circle(geometry.getCenter(), geometry.getRadius(), { units: "degrees", steps: 128 });
        return polygon.geometry;
    } else {
        return GeoJson.writeGeometryObject(geometry);
    }
}

export function GetCoordinatesForSubmissions(geometry) {
    if (typeof geometry.getRadius === "function") {
        return { coordinates: [[geometry.getCenter()]] };
    } else if (geometry.get("type") === "Circle") {
        return { coordinates: [[geometry.getInteriorPoint().getCoordinates()]] };
    } else if (geometry instanceof Polygon) {
        return GeoJson.writeGeometryObject(geometry);
    } else if (geometry instanceof LineString) {
        return { type: "LineString", coordinates: [GeoJson.writeGeometryObject(geometry).coordinates] };
    } else if (!(geometry instanceof GeometryCollection)) {
        return null;
    }

    const geometries = geometry
        .getGeometries()
        .map((geo) => {
            if (geo instanceof Polygon) {
                return GeoJson.writeGeometryObject(geo);
            } else if (geo instanceof LineString) {
                return { type: "LineString", coordinates: [GeoJson.writeGeometryObject(geometry).coordinates] };
            }
            return null;
        })
        .filter(Boolean); // Remove nulls or undefined from the array

    return geometries.length === 1 ? geometries[0] : geometries;
}

// Converts our CMP volumes structure to an array of OL features.
export function ConvertCmpVolumesToFeatures(cmpVolumes, userMapSettings) {
    const features = [];
    for (const cmpVolume of cmpVolumes) {
        const volumes = "volumes" in cmpVolume ? cmpVolume.volumes : cmpVolume.polygons;
        for (let i = 0; i < volumes.length; ++i) {
            const volume = volumes[i];

            let geometry;
            if (volume.polygon) {
                const coordinates = [volume.polygon.map(({ lng, lat }) => [lng, lat])];
                geometry = new Polygon(coordinates);
            } else if (volume.type === "polygon") {
                const coordinates = [volume.vertices.map(({ lng, lat }) => [lng, lat])];
                geometry = new Polygon(coordinates);
            } else if (volume.circle) {
                const center = [volume.circle.center.lng, volume.circle.center.lat];
                const radius = volume.circle.radius_m;
                geometry = circular(center, radius, 128);
                geometry.set("type", "Circle");
            } else if (volume.type === "circle") {
                const center = [volume.lng, volume.lat];
                const radius = volume.radius;
                geometry = circular(center, radius, 128);
                geometry.set("type", "Circle");
            }
            if (!geometry) {
                continue;
            }
            const feature = new Feature({
                geometry: geometry
            });
            feature.set("name", cmpVolume.name);

            if ("flight_uuid" in cmpVolume && "uuid" in cmpVolume === false) {
                feature.setId(`${cmpVolume.flight_uuid}_${i}`);
                feature.set("flight", cmpVolume);
            } else if ("constraint_uuid" in cmpVolume) {
                feature.setId(`${cmpVolume.constraint_uuid}_${i}`);
                feature.set("constraint", cmpVolume);
            } else if ("uuid" in cmpVolume) {
                feature.setId(`${cmpVolume.uuid}_${i}`);
                feature.set("alert_volume", cmpVolume);
            }
            const style = getGeometryStyleFromFeatureAndSettings(feature, userMapSettings);
            feature.setStyle(style);

            features.push(feature);
        }
    }
    return features;
}

export function ConvertLAANCVolumesToFeatures(laancVolumes) {
    const features = [];

    laancVolumes.forEach((volume, index) => {
        let geometry;
        if (volume.polygon) {
            const coordinates = [volume.polygon.map(({ lng, lat }) => [lng, lat])];
            geometry = new Polygon(coordinates);
        } else if (volume.circle) {
            const center = [volume.circle.center.lng, volume.circle.center.lat];
            const radius = volume.circle.radius_m;
            geometry = circular(center, radius, 128);
            geometry.set("type", "Circle");
        }
        if (!geometry) {
            return;
        }
        const feature = new Feature({
            geometry: geometry
        });
        feature.set("name", "Volume " + (index + 1));
        feature.setId("Volume " + (index + 1));
        feature.set("laanc_volume", volume);

        const style = new Style({
            fill: new Fill({
                color: getColorFromLaancType(volume.faa_request_type, 0.5)
            }),
            stroke: new Stroke({
                color: getColorFromLaancType(volume.faa_request_type, 1),
                width: 2
            })
        });
        feature.setStyle(style);
        features.push(feature);

        index += 1;
    });

    return features;
}

// Converts a KML file into an OL feature.
// It will include "name" and "altitudes" properties.
/* istanbul ignore next */
export async function ConvertKmlFileToFeatures(file) {
    return new Promise((resolve, reject) => {
        if (!/(\.kml|\.kmz)$/i.exec(file.name.toLowerCase())) {
            return reject("Please choose a valid KML/KMZ file type.");
        } else if (file.size > 500000) {
            return reject("Please choose a KML file that is less than 500KB.");
        }

        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            const kmlDoc = new DOMParser().parseFromString(e.target.result, "text/xml");
            const kmlFeatures = Kml.readFeatures(kmlDoc);
            const validFeatures = [];
            for (const kmlFeature of kmlFeatures) {
                const geometry = kmlFeature.getGeometry();
                if (geometry && (geometry instanceof Polygon || geometry instanceof LineString || geometry instanceof GeometryCollection)) {
                    validFeatures.push(kmlFeature);
                }
            }

            if (!("name" in validFeatures[0].getProperties)) {
                validFeatures[0].set("name", "KML File");
            }
            validFeatures[0].set("altitudes", ExtractAltitudesFromFeatures(validFeatures));
            resolve(validFeatures);
        };
        fileReader.onerror = () => reject("KML file could not be parsed correctly.");
        fileReader.readAsText(file);
    });
}

// Extracts the minimum and maximum altitudes out of an OL geometry.
/* istanbul ignore next */
export function ExtractAltitudesFromFeatures(features) {
    const altitudes = {
        minAltitude: 0,
        maxAltitude: 0
    };

    const extractAltitudes = (geometry) => {
        if (!geometry || (!(geometry instanceof Polygon) && !(geometry instanceof LineString))) {
            return;
        }
        const coordinates = geometry instanceof Polygon ? geometry.getCoordinates()[0] : geometry.getCoordinates();
        for (const coordinate of coordinates) {
            altitudes.minAltitude = Math.min(altitudes.minAltitude, coordinate[2]);
            altitudes.maxAltitude = Math.max(altitudes.maxAltitude, coordinate[2]);
        }
    };
    features.forEach((feature) => {
        const geometry = feature.getGeometry();
        if (geometry instanceof GeometryCollection) {
            geometry.getGeometries().forEach((geo) => extractAltitudes(geo));
        } else {
            extractAltitudes(geometry);
        }
    });
    return altitudes;
}

// Checks if the map is currently being drawn/modified/snapped on.
export function IsMapBeingDrawnOn(map) {
    for (const interaction of map.getInteractions().getArray()) {
        if (interaction instanceof Draw || interaction instanceof Modify || interaction instanceof Translate || interaction instanceof Snap) {
            return true;
        }
    }
    return false;
}

export function getGeofenceFeatureFromGeometryAndBuffer(geometry, buffer, settings) {
    const coords = geometry.getCoordinates();
    const linestring = lineString(coords);
    const buffered = turf_buffer(linestring, buffer, { units: "feet" });

    const geofence_polygon = new Polygon(buffered.geometry.coordinates);
    const geofence_feature = new Feature({ geometry: geofence_polygon });
    geofence_feature.setStyle(GetGeofenceStyle(settings));

    return geofence_feature;
}

export function convertHexColorToRgbaColor(hex, opacity) {
    hex = hex.replace("#", "");

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getGeometryStyleFromColor(color_hex) {
    return new Style({
        stroke: new Stroke({
            color: convertHexColorToRgbaColor(color_hex, 1),
            width: 2
        }),
        fill: new Fill({
            color: convertHexColorToRgbaColor(color_hex, 0.2)
        })
    });
}

export function getGeometryStyleFromFeatureAndSettings(feature, userMapSettings) {
    const properties = feature.getProperties();

    let color_hex = "#000";
    if ("flight" in properties) {
        color_hex = GetColorFromState(properties.flight.state, userMapSettings.op_state_settings);
    } else if ("constraint" in properties) {
        color_hex = GetColorFromState(properties.constraint.state, userMapSettings.op_state_settings);
    } else if ("alert_volume" in properties) {
        color_hex = properties.alert_volume.color_rgb;
    }
    let text = undefined;
    if (userMapSettings.volume_labels) {
        text = new Text({
            text: feature.get("name"),
            font: "12px Calibri,sans-serif",
            fill: new Fill({ color: "#fff" }),
            stroke: new Stroke({ color: "#000", width: 2 }),
            overflow: true
        });
    }
    return new Style({
        stroke: new Stroke({
            color: convertHexColorToRgbaColor(color_hex, 1),
            width: 2
        }),
        fill: new Fill({
            color: convertHexColorToRgbaColor(color_hex, userMapSettings.op_opacity)
        }),
        text: text
    });
}

export function wrap_neg180_to_180(num) {
    return ((((num + 180) % 360) + 360) % 360) - 180;
}

export function extrapolatePosition(lat, lon, hdg_deg, distance_m) {
    const M_PER_NM = 1852.0;
    const RAD_PER_DEG = Math.PI / 180.0;
    const DEG_PER_RAD = 180.0 / Math.PI;

    hdg_deg = hdg_deg % 360;
    let distance_deg = distance_m / M_PER_NM / 60.0;

    let b = distance_deg * RAD_PER_DEG;
    let cos_b = Math.cos(b);
    let sin_b = Math.sin(b);
    let a = (90.0 - lat) * RAD_PER_DEG;
    let cos_a = Math.cos(a);
    let sin_a = Math.sin(a);
    let C = hdg_deg * RAD_PER_DEG;
    let cos_C = Math.cos(C);

    let cos_c = cos_a * cos_b + sin_a * sin_b * cos_C;
    let c = Math.acos(Math.min(Math.max(cos_c, -1.0), 1.0));
    let sin_c = Math.sin(c);
    const new_lat = 90.0 - c * DEG_PER_RAD;

    let cos_B = (cos_b - cos_c * cos_a) / (sin_c * sin_a);
    let B = Math.acos(Math.min(Math.max(cos_B, -1.0), 1.0));
    if (C > Math.PI) {
        B = -B;
    }
    const new_lon = wrap_neg180_to_180(lon + B * DEG_PER_RAD);

    return [new_lat, new_lon];
}

export function getPredLineCoordinatesFromTrackAndSettings(track, settings) {
    const bearing = track.course_deg;
    const distance = track.ground_speed_mps * settings.pred_line_lead_time;

    const start_lon = track.lon_deg;
    const start_lat = track.lat_deg;

    const [final_lat, final_lon] = extrapolatePosition(start_lat, start_lon, bearing, distance);

    return [
        [track.lon_deg, track.lat_deg],
        [final_lon, final_lat]
    ];
}

export function getPredLineStyleFromTrackAndSettings(track, settings) {
    const source_type_settings = settings.source_type_settings.find(({ source_type }) => {
        return source_type === track.source_type;
    });
    if (source_type_settings && settings.pred_line) {
        return new Style({
            stroke: new Stroke({
                color: source_type_settings.color,
                width: 2
            })
        });
    } else {
        return new Style({
            stroke: null
        });
    }
}

export function getHistLineCoordinatesFromTrack(track) {
    const history_line_coordinates = [];

    for (let i = 0; i < track.history_locations.length; i += 3) {
        const lon = track.history_locations[i];
        const lat = track.history_locations[i + 1];
        history_line_coordinates.push([lon, lat]);
    }
    return history_line_coordinates;
}

export function getHistLineStyleFromTrackAndSettings(track, settings) {
    const source_type_settings = settings.source_type_settings.find(({ source_type }) => {
        return source_type === track.source_type;
    });
    if (source_type_settings && settings.hist_line) {
        return new Style({
            stroke: new Stroke({
                color: source_type_settings.color,
                width: 2,
                lineDash: [5, 5]
            })
        });
    } else {
        return new Style({
            stroke: null
        });
    }
}

export function getDpzCoordinatesFromTrack(track) {
    const radius_m = 609.6;
    const center = [track.lon_deg, track.lat_deg];
    const geometry = circular(center, radius_m, 128);
    return geometry.getCoordinates();
}

export function getTagButtonVisibility(user, feature) {
    if ("asd" in feature.getProperties()) {
        return user.user_role === "Admin" || user.user_role === "Airspace Manager" || user.user_role === "Operator";
    } else {
        return false;
    }
}
export function getPublishConstraintButtonVisibility(user, feature) {
    const properties = feature.getProperties();
    if ("constraint" in properties && properties.constraint.owner === "cmp-ui-online") {
        return properties.constraint.state !== "ACCEPTED" && (user.user_role === "Admin" || user.user_role === "Airspace Manager");
    } else {
        return false;
    }
}
export function getEditConstraintButtonVisibility(user, feature) {
    const properties = feature.getProperties();
    if ("constraint" in properties && properties.constraint.owner === "cmp-ui-online") {
        return user.user_role === "Admin" || user.user_role === "Airspace Manager";
    } else {
        return false;
    }
}
export function getDeleteConstraintButtonVisibility(user, feature) {
    const properties = feature.getProperties();
    if ("constraint" in properties && properties.constraint.owner === "cmp-ui-online") {
        if (user.user_role === "First Responder") {
            return properties.constraint.created_user_id == user.id;
        } else {
            return user.user_role === "Admin" || user.user_role === "Airspace Manager";
        }
    } else {
        return false;
    }
}

export function updateFeaturesZeroConflictionAlerts(feature, new_zero_conflict_alert) {
    if (new_zero_conflict_alert.state === "ACTIVE") {
        const current_zero_conflictions = feature.get("zero_confliction_alerts");
        if (Array.isArray(current_zero_conflictions)) {
            const zero_conflict_already_exists = current_zero_conflictions.find(({ alert_uuid }) => {
                return alert_uuid === new_zero_conflict_alert.alert_uuid;
            });
            if (zero_conflict_already_exists) {
                const updated_zero_conflictions = current_zero_conflictions.map((zero_conflict_alert) => {
                    if (zero_conflict_alert.alert_uuid === new_zero_conflict_alert.alert_uuid) {
                        return new_zero_conflict_alert;
                    } else {
                        return zero_conflict_alert;
                    }
                });
                feature.set("zero_confliction_alerts", updated_zero_conflictions);
            } else {
                feature.set("zero_confliction_alerts", [...current_zero_conflictions, new_zero_conflict_alert]);
            }
        } else {
            feature.set("zero_confliction_alerts", [new_zero_conflict_alert]);
        }
    } else if (new_zero_conflict_alert.state === "EXPIRED") {
        const current_zero_conflictions = feature.get("zero_confliction_alerts");
        if (Array.isArray(current_zero_conflictions)) {
            const updated_zero_conflict_alerts = current_zero_conflictions.filter(({ alert_uuid }) => {
                return alert_uuid !== new_zero_conflict_alert.alert_uuid;
            });
            if (updated_zero_conflict_alerts.length === 0) {
                feature.unset("zero_confliction_alerts");
            } else {
                feature.set("zero_confliction_alerts", updated_zero_conflict_alerts);
            }
        }
    }
}

// create a feature for zca line
export function createZeroConflictionLineFeature(ownship_feature, intruder_feature, zero_conflict_message) {
    const coordinates = getZeroConflictionLineCoordinates(ownship_feature, intruder_feature);
    const lineString = new LineString(coordinates);

    const feature = new Feature({
        geometry: lineString
    });
    const style = getZeroConflictionLineStyleFromZeroConflictMessage(zero_conflict_message, ownship_feature);
    feature.setStyle(style);

    const id = `${ownship_feature.getId()}_${intruder_feature.getId()}`;
    feature.setId(id);

    const ownship_asd_message = ownship_feature.get("asd");
    if (ownship_asd_message) {
        feature.set("asd", ownship_asd_message);
    }
    return feature;
}

// get coordinates to update the location of the zca line
export function getZeroConflictionLineCoordinates(ownship_feature, intruder_feature) {
    const ownship_coordinates = ownship_feature.getGeometry().getCoordinates();
    const intruder_coordinates = intruder_feature.getGeometry().getCoordinates();
    return [ownship_coordinates, intruder_coordinates];
}

// gets style for the line
export function getZeroConflictionLineStyleFromZeroConflictMessage(zero_conflict_message, ownship_feature) {
    let color_rgb = "255, 235, 0";
    if (zero_conflict_message.severity === "WARNING") {
        color_rgb = "200, 0, 0";
    }
    const ownship_style = ownship_feature.getStyle();
    if (Array.isArray(ownship_style)) {
        const first_style = ownship_style[0];
        if (first_style && first_style.getImage()) {
            return new Style({
                stroke: new Stroke({
                    color: `rgba(${color_rgb}, .8)`,
                    width: 2
                })
            });
        }
    }
    return new Style({
        stroke: undefined
    });
}

export function updateZeroConflictLineFeature(map, feature) {
    const zero_conflict_alerts = feature.get("zero_confliction_alerts");
    if (!Array.isArray(zero_conflict_alerts)) {
        return;
    }

    zero_conflict_alerts.forEach((zero_conflict_alert) => {
        const ownship_id = zero_conflict_alert.ownship_track.id;
        const ownship_feature = GetFeature(map, "asd", ownship_id);

        const intruder_id = zero_conflict_alert.intruder_track.id;
        const intruder_feature = GetFeature(map, "asd", intruder_id);

        const feature_id = `${ownship_id}_${intruder_id}`;
        const zero_conflict_line_feature = GetFeature(map, "asd", feature_id);

        if (zero_conflict_line_feature && intruder_feature && ownship_feature) {
            const updated_coordinates = getZeroConflictionLineCoordinates(ownship_feature, intruder_feature);
            zero_conflict_line_feature.getGeometry().setCoordinates(updated_coordinates);

            const ownship_asd_message = ownship_feature.get("asd");
            if (ownship_asd_message) {
                zero_conflict_line_feature.set("asd", ownship_asd_message);
            }
        }
    });
}

export function getBorderFromMapLayerOption(preference) {
    switch (preference.title) {
        case "Airspaces":
            return "2px dotted #9e6e9d";
        case "Facility Maps":
            return "2px solid #90caf9";
        case "Stadiums":
            return "2px solid #00ff00";
        case "National Security UAS Flight Restrictions":
            return "2px dotted #d8abc6";
        case "Alert Areas":
            return "2px solid #0388bf";
        case "Military Operation Areas":
            return "2px dotted #9d6b9e";
        case "Prohibited Airspaces":
            return "2px dotted #0388bf";
        case "Restricted Airspaces":
            return "2px dotted #0388bf";
        case "Warning Areas":
            return "2px dotted #0388bf";
        case "Temporary Flight Restrictions":
            return "2px solid #ff0000";
        case "Airports":
            return "";
        case "DC FRZ SFRA":
            return "2px solid #ffa500";
        default:
            return "3px solid white";
    }
}

export function getSourceName(sensor_id, sourceData) {
    let sourceName = "Unknown";

    if (sourceData) {
        if (sourceData.get(sensor_id)) sourceName = sourceData.get(sensor_id);
        else if (sensor_id) sourceName = sensor_id;
    } else {
        sourceName = sensor_id;
    }

    return sourceName;
}

export function getWaypointFeatureStyleFromFeatureAndSettings(feature, settings) {
    const planning_setting = settings.op_state_settings.find((op) => {
        return op.state === "PLANNING";
    });
    let color = Colors.blue4;
    if (planning_setting) {
        color = planning_setting.color;
    }
    const stroke_black = new Stroke({ color: Colors.black });
    const fill_black = new Fill({ color: Colors.black });

    const stroke = new Stroke({ color: color });
    const fill = new Fill({ color: color });

    const styles = [];
    const feature_style = new Style({
        fill: fill_black,
        stroke: stroke
    });
    styles.push(feature_style);

    const coordinates = feature.getGeometry().getCoordinates();
    coordinates.forEach((coordinate, index) => {
        const point_style = new Style({
            image: new CircleStyle({
                fill: fill,
                stroke: stroke_black,
                radius: 12
            }),
            text: new Text({
                text: `${index + 1}`,
                font: "12px Arial",
                fill: fill_black,
                stroke: stroke_black
            }),
            geometry: new Point(coordinate)
        });
        styles.push(point_style);
    });
    return styles;
}

export function getUpdatedWaypointsFromCoordinatesAndDefaultAltitude(updated_coordinates, previous_coordinates, default_altitude_agl_ft) {
    const updated = updated_coordinates.map(([lng, lat], i) => {
        let previous = undefined;
        if (updated_coordinates.length > previous_coordinates.length) {
            previous = previous_coordinates.find((coordinate) => {
                return coordinate.lat === lat && coordinate.lng === lng;
            });
        } else if (updated_coordinates.length === previous_coordinates.length) {
            previous = previous_coordinates[i];
        }
        if (previous) {
            return { lng: lng, lat: lat, alt_agl_ft: previous.alt_agl_ft };
        } else {
            return { lng: lng, lat: lat, alt_agl_ft: default_altitude_agl_ft };
        }
    });
    return updated;
}

export function getGeofenceCoordinatesFromCoordinatesTypeAndBuffer(coordinates, type, horizontal_buffer_ft) {
    if (!Array.isArray(coordinates) || coordinates.length < 2) {
        console.error("Invalid waypoints: must be an array of at least two [lon, lat] pairs.");
        return null;
    }

    let geojson = null;
    switch (type) {
        case "CIRCLE": {
            const circle_center = center(points(coordinates));
            const radius_ft = coordinates.reduce((farthest, coord) => Math.max(farthest, distance(circle_center, point(coord), { units: "feet" })), 0);
            geojson = circle(circle_center, radius_ft, { units: "feet" });
            break;
        }
        case "POLYGON":
            geojson = coordinates.length > 2 ? convex(points(coordinates)) : lineString(coordinates);
            break;
        case "CORRIDOR":
            geojson = lineString(coordinates);
            break;
    }

    if (geojson) {
        const buffered = turf_buffer(geojson, Math.max(horizontal_buffer_ft, 1), { units: "feet" });
        return buffered.geometry.coordinates;
    } else {
        return null;
    }
}

export function getMapTileOptionIconFromOptionType(type) {
    switch (type) {
        case "LIGHT":
            return "url(/images/LightStreetViewIcon.png)";
        case "DARK":
            return "url(/images/DarkStreetViewIcon.png)";
        case "SATELLITE":
            return "url(/images/TerrainIcon.png)";
        case "SATELLITE_WITH_LABELS":
            return "url(/images/TerrainLabelsIcon.png)";
        case "VFR_SECTIONAL":
            return "url(/images/VfrMapTileIcon.png)";
        default:
            return "url(/images/LightStreetViewIcon.png)";
    }
}

export function getUpdatedZeroConflictionAlertsFromZeroConflictMessage(previous_messages, new_message) {
    const updated_zero_conflict_alerts = new Map(previous_messages);
    const ownship_id = new_message.ownship_track.id;

    if (new_message.state === "ACTIVE") {
        const previous_zero_confliction_alerts = updated_zero_conflict_alerts.get(ownship_id);
        if (Array.isArray(previous_zero_confliction_alerts) && previous_zero_confliction_alerts.length > 0) {
            const previous_zero_confliction_alert_index = previous_zero_confliction_alerts.findIndex((zc_alert) => {
                return zc_alert.alert_uuid === new_message.alert_uuid;
            });
            if (previous_zero_confliction_alert_index !== -1) {
                previous_zero_confliction_alerts[previous_zero_confliction_alert_index] = new_message;
                updated_zero_conflict_alerts.set(ownship_id, previous_zero_confliction_alerts);
            } else {
                updated_zero_conflict_alerts.set(ownship_id, [...previous_zero_confliction_alerts, new_message]);
            }
        } else {
            updated_zero_conflict_alerts.set(ownship_id, [new_message]);
        }
    } else if (new_message.state === "EXPIRED") {
        const previous_zero_confliction_alerts = updated_zero_conflict_alerts.get(ownship_id);
        if (Array.isArray(previous_zero_confliction_alerts) && previous_zero_confliction_alerts.length > 0) {
            const filtered_zero_conflict_alerts = previous_zero_confliction_alerts.filter((zc_alert) => {
                return zc_alert.alert_uuid !== new_message.alert_uuid;
            });
            if (filtered_zero_conflict_alerts.length > 0) {
                updated_zero_conflict_alerts.set(ownship_id, filtered_zero_conflict_alerts);
            } else {
                updated_zero_conflict_alerts.delete(ownship_id);
            }
        }
    }
    return updated_zero_conflict_alerts;
}

export function getIsNewZeroConflictionAlert(previous_messages, new_message) {
    const previous_zero_conflict_alerts = new Map(previous_messages);
    const ownship_id = new_message.ownship_track.id;

    if (new_message.state === "ACTIVE") {
        const previous_zero_confliction_alerts = previous_zero_conflict_alerts.get(ownship_id);
        if (Array.isArray(previous_zero_confliction_alerts) && previous_zero_confliction_alerts.length > 0) {
            const previous_zero_confliction_alert_index = previous_zero_confliction_alerts.findIndex((zc_alert) => {
                return zc_alert.alert_uuid === new_message.alert_uuid;
            });
            if (previous_zero_confliction_alert_index === -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export function getIsHigherSeverityZeroConflictionAlert(previous_messages, new_message) {
    const previous_zero_conflict_alerts = new Map(previous_messages);
    const ownship_id = new_message.ownship_track.id;

    if (new_message.state === "ACTIVE") {
        const zero_conflict_alerts = previous_zero_conflict_alerts.get(ownship_id);
        if (Array.isArray(zero_conflict_alerts) && zero_conflict_alerts.length > 0) {
            const existing_zero_conflict_alert = zero_conflict_alerts.find((zc_alert) => {
                return zc_alert.alert_uuid === new_message.alert_uuid;
            });
            if (existing_zero_conflict_alert && existing_zero_conflict_alert.severity === "CAUTION" && new_message.severity === "WARNING") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}
