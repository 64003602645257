import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const BannerAlertList = ({ bannerAlerts, bannerOpen, setBannerOpen, navigationOpen, setAlertDialogOpen }) => {
    const [visibleAlert, setVisibleAlert] = useState(null);
    const [visibleAlertIndex, setVisibleAlertIndex] = useState(null);

    useEffect(() => {
        if (bannerAlerts.length > 0) {
            const mostRecent = bannerAlerts[0];
            setVisibleAlert(mostRecent);
            setVisibleAlertIndex(0);
        } else {
            setVisibleAlert(null);
            setVisibleAlertIndex(null);
        }
    }, [bannerAlerts]);

    // maps severity to material ui theme value
    const getSeverityFromAlert = (alert) => {
        if (alert.severity === "ADVISORY") {
            return "info";
        } else if (alert.severity === "CAUTION") {
            return "warning";
        } else {
            return "error";
        }
    };
    // handle left chevron click
    const handleLeftClick = () => {
        setVisibleAlertIndex((prev) => {
            const newIndex = prev - 1;
            setVisibleAlert(bannerAlerts[newIndex]);
            return newIndex;
        });
    };
    // handle right chevron click
    const handleRightClick = () => {
        setVisibleAlertIndex((prev) => {
            const newIndex = prev + 1;
            setVisibleAlert(bannerAlerts[newIndex]);
            return newIndex;
        });
    };
    return (
        <Collapse in={bannerOpen}>
            <Box
                sx={{
                    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    transition: (theme) =>
                        theme.transitions.create(["width", "margin"], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen
                        }),
                    ...(navigationOpen && {
                        ml: { md: `0px` },
                        transition: (theme) =>
                            theme.transitions.create(["width", "margin"], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen
                            })
                    })
                }}
            >
                {visibleAlert !== null ? (
                    <Alert
                        sx={{
                            "& > *": { display: "flex", alignItems: "center", p: 0, mr: "4px" },
                            "& .MuiAlert-action": { mr: 0 },
                            p: "8px 12px"
                        }}
                        variant="filled"
                        severity={getSeverityFromAlert(visibleAlert)}
                        action={
                            <IconButton color="inherit" size="small" onClick={() => setBannerOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        }
                    >
                        {bannerAlerts && bannerAlerts.length > 1 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "4px",
                                    whiteSpace: "nowrap",
                                    mr: "4px"
                                }}
                            >
                                <IconButton color="inherit" size="small" onClick={handleLeftClick} disabled={visibleAlertIndex === 0} sx={{ p: 0 }}>
                                    <ChevronLeftIcon />
                                </IconButton>

                                <Typography component="span" variant="caption">
                                    {`${
                                        bannerAlerts.findIndex(({ alert_id }) => {
                                            return alert_id === visibleAlert.alert_id;
                                        }) + 1
                                    } of ${bannerAlerts.length}`}
                                </Typography>

                                <IconButton
                                    size="small"
                                    onClick={handleRightClick}
                                    disabled={visibleAlertIndex === bannerAlerts.length - 1}
                                    sx={{ p: 0 }}
                                    color="inherit"
                                >
                                    <ChevronRightIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <></>
                        )}
                        <Typography variant="caption">
                            {`${visibleAlert.message} `}
                            <Link component="button" variant="caption" color="inherit" underline="hover" onClick={() => setAlertDialogOpen(true)}>
                                {bannerAlerts.length > 1 ? `- ${bannerAlerts.length - 1} others ` : ""}
                            </Link>
                        </Typography>
                    </Alert>
                ) : (
                    <Alert
                        variant="filled"
                        severity={"success"}
                        action={
                            <IconButton color="inherit" size="small" onClick={() => setBannerOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        }
                    >
                        <Typography variant="body1" color="inherit">
                            {"No issues to report - all systems up and running."}
                        </Typography>
                    </Alert>
                )}
            </Box>
        </Collapse>
    );
};

export default BannerAlertList;
