import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Button from "../../components/button";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { buffer, circle, featureCollection } from "@turf/turf";
import {
    ConvertFeaturesToAlertVolumes,
    ConvertFeetToMeters,
    ConvertMetersToFeet,
    ConvertVolumesToFeatures,
    GetTimezone,
    IsInvalidAltitude,
    IsInvalidDate
} from "../../util";

import { useUserAuth } from "../../contexts/authContext";
import { MapDraw } from "../../map/mapDraw";
import { Altitudes } from "../forms/altitudes";
import { useMediaQuery } from "@mui/material";

export const CreateAlertDialog = (props) => {
    const [activeMethodTab, setActiveMethodTab] = useState("");
    const [dateError, setDateError] = useState(false);
    const [altitudeError, setAltitudeError] = useState(false);

    const [alertFieldsDisabled, setAlertFieldsDisabled] = useState(false);
    const [alertVolumesFinal, setAlertVolumesFinal] = useState([]);
    const [alertGeoJson, setAlertGeoJson] = useState(null);

    const [alertStart, setAlertStart] = useState();
    const [alertEnd, setAlertEnd] = useState();
    const [alertName, setAlertName] = useState("");
    const [alertOperation, setAlertOperation] = useState("");
    const [alertManned, setAlertManned] = useState("all");
    const [alertBuffer, setAlertBuffer] = useState("");
    const [alertRadius, setAlertRadius] = useState("");
    const [alertColorId, setAlertColorId] = useState(0);
    const [alertMinAltitude, setAlertMinAltitude] = useState(0);
    const [alertMaxAltitude, setAlertMaxAltitude] = useState(0);

    const { user, snackbar, setSnackbar } = useUserAuth();
    const { handleFailedFetch } = useUserAuth();
    const isDesktop = useMediaQuery("(min-width:900px)");

    useEffect(() => {
        setAlertStart(new Date().getTime());
        setAlertEnd(new Date().getTime() + 8 * 60 * 60 * 1000);

        // For unit test purposes.
        if (props.type === -1 && props.publishedOperations) {
            setActiveMethodTab("Automatic");
            handleOperationChange({ target: { value: props.publishedOperations[0] } });
        }
    }, []);

    const handleOperationChange = (e) => {
        const op = e.target.value;
        setAlertOperation(op);

        // Populate min/max altitudes.
        if (activeMethodTab === "Automatic") {
            setAlertMinAltitude(ConvertMetersToFeet(op.volumes[0].altitude_min_agl_m));
            setAlertMaxAltitude(ConvertMetersToFeet(op.volumes[0].altitude_max_agl_m));
        }

        // Set GeoJson below for polygons.
        if ("polygon" in op.volumes[0]) {
            return setAlertGeoJson({
                type: "FeatureCollection",
                features: ConvertVolumesToFeatures(op.volumes).filter((f) => f.geometry)
            });
        }

        // Set GeoJson below for circles.
        const circleVol = op.volumes[0].circle;
        const radius = ConvertMetersToFeet(circleVol.radius_m);
        const center = [circleVol.center.lng, circleVol.center.lat];
        const turfCircle = circle(center, (radius || 0) / 5280, { steps: 64, units: "miles" });
        const geoJson = featureCollection([turfCircle]);
        setAlertGeoJson(geoJson);
    };

    const handleBufferChange = (e) => {
        const newBuffer = parseFloat(e.target.value);
        const newGeoJson = buffer(alertGeoJson, (newBuffer || 0) / 5280.0, { units: "miles" });
        const newConvertedVolumes = ConvertFeaturesToAlertVolumes(newGeoJson.features);
        const newVolumes = newConvertedVolumes.volumes;
        const newColorRgb = props.colors.find(({ id }) => id === alertColorId).color_rgb;

        newVolumes.forEach((volume) => {
            volume.altitude_lower_hae = ConvertFeetToMeters(alertMinAltitude || 0);
            volume.altitude_upper_hae = ConvertFeetToMeters(alertMaxAltitude || 0);
            volume.color = newColorRgb;
            volume.name = alertName;
            volume.positions = [];
            volume.vertices.forEach(({ lng, lat }) => volume.positions.push(lng, lat));
        });

        setAlertVolumesFinal(newVolumes);
        setAlertBuffer(e.target.value);
    };

    const handleAltitudeChange = (minAltitude, maxAltitude) => {
        setAlertMinAltitude(minAltitude);
        setAlertMaxAltitude(maxAltitude);
    };

    const handlePublish = () => {
        setDateError(false);
        setAltitudeError(false);

        if (alertVolumesFinal.length === 0) {
            return setSnackbar({ children: "Please create a volume first.", severity: "error" });
        }
        if (IsInvalidDate(alertStart, alertEnd)) {
            setDateError(true);
            return setSnackbar({ children: "Please enter valid start and end times.", severity: "error" });
        }
        if (!alertName || !alertName.trim()) {
            return setSnackbar({ children: "Please enter a valid name.", severity: "error" });
        }
        if (IsInvalidAltitude(alertMinAltitude, alertMaxAltitude)) {
            setAltitudeError(true);
            return setSnackbar({ children: "Please fill out the altitude fields.", severity: "error" });
        }
        if (activeMethodTab === "Automatic" && !alertOperation) {
            return setSnackbar({ children: "Please select an operation.", severity: "error" });
        }

        const bufferValue = parseFloat(alertBuffer);
        const radiusValue = parseFloat(alertRadius);
        if (
            (activeMethodTab === "Automatic" && (isNaN(bufferValue) || bufferValue < 0)) ||
            (activeMethodTab === "Circle" && (isNaN(radiusValue) || radiusValue <= 0))
        ) {
            return setSnackbar({ children: "Please enter a valid value.", severity: "error" });
        }

        const minAlertAltitude = parseFloat(alertMinAltitude);
        const maxAlertAltitude = parseFloat(alertMaxAltitude);
        const alertColor = props.colors.find(({ id }) => id === alertColorId).color;
        const alertRGB = props.colors.find(({ id }) => id === alertColorId).color_rgb;
        const polygons = [];

        alertVolumesFinal.forEach((vol) => {
            const polygon = {
                type: "polygon",
                vertices: vol.vertices
            };
            if (activeMethodTab === "Circle") {
                const radius_ft = parseFloat(alertRadius);
                const radius_m = ConvertFeetToMeters(radius_ft);

                polygon.lat = vol.vertices[0].lat;
                polygon.lng = vol.vertices[0].lng;
                polygon.radius = radius_m;
                polygon.type = "circle";
            }
            polygons.push(polygon);
        });

        const finalAlertVolume = {
            name: alertName,
            manned: alertManned,
            created_user_id: user.id,
            date_updated: new Date().toISOString(),
            date_created: new Date().toISOString(),
            updated_user_id: user.id,
            organization_id: user.organization_id,
            altitude_min_agl_m: ConvertFeetToMeters(minAlertAltitude),
            altitude_max_agl_m: ConvertFeetToMeters(maxAlertAltitude),
            time_start: new Date(alertStart).toISOString(),
            time_end: new Date(alertEnd).toISOString(),
            polygons: polygons,
            flight_name: alertOperation.name,
            flight_uuid: alertOperation.flight_uuid,
            flight_status: alertOperation.state,
            color_id: alertColorId,
            color_name: alertColor,
            color_rgb: alertRGB,
            deleted: false,
            id: 0
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(finalAlertVolume)
        };
        fetch("api/alertVolume/insertUpdate", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => props.setCreateAlertDialogOpen(false))
            .catch((err) => handleFailedFetch(err));
    };

    return (
        <Dialog onClose={() => props.setCreateAlertDialogOpen(false)} open={props.createAlertDialogOpen} maxWidth={isDesktop ? "lg" : "xs"} fullWidth>
            <DialogTitle>Create Alert Volume</DialogTitle>

            <DialogContent sx={{ pb: 0 }}>
                <MapDraw
                    type={props.type}
                    canEdit={true}
                    activeDrawMethod={activeMethodTab}
                    setActiveDrawMethod={setActiveMethodTab}
                    operations={props.publishedOperations}
                    start={alertStart}
                    end={alertEnd}
                    minAltitude={alertMinAltitude}
                    setMinAltitude={setAlertMinAltitude}
                    maxAltitude={alertMaxAltitude}
                    setMaxAltitude={setAlertMaxAltitude}
                    radius={alertRadius}
                    disabled={alertFieldsDisabled}
                    setDisabled={setAlertFieldsDisabled}
                    finalVolumes={alertVolumesFinal}
                    setFinalVolumes={setAlertVolumesFinal}
                    automaticBufferId={alertOperation.flight_uuid}
                    automaticBuffer={alertBuffer}
                    alertColorId={alertColorId}
                    alerts={props.publishedAlertVolumes}
                    setName={setAlertName}
                />

                <Divider sx={{ mt: 2, mb: 1 }} />

                <Box sx={{ height: "250px", pt: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr", gap: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label={`Start Time (${GetTimezone()}) *`}
                                    value={dayjs(alertStart)}
                                    disabled={alertFieldsDisabled}
                                    onChange={(value) => setAlertStart(value)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: dateError,
                                            inputProps: { "data-testid": "startTime" }
                                        }
                                    }}
                                />
                                <DateTimePicker
                                    label={`End Time (${GetTimezone()}) *`}
                                    value={dayjs(alertEnd)}
                                    disabled={alertFieldsDisabled}
                                    onChange={(value) => setAlertEnd(value)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: dateError,
                                            inputProps: { "data-testid": "endTime" }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: isDesktop
                                    ? activeMethodTab === "Circle" || activeMethodTab === "Automatic"
                                        ? "1fr 1fr 1fr 1fr"
                                        : "1fr 1fr 1fr"
                                    : "1fr",
                                gap: 2
                            }}
                        >
                            <TextField
                                onChange={(e) => setAlertName(e.target.value)}
                                disabled={alertFieldsDisabled}
                                error={snackbar && (!alertName || !alertName.trim())}
                                sx={{ mt: 0, mb: 0 }}
                                value={alertName}
                                variant="outlined"
                                margin="dense"
                                label="Name"
                                fullWidth
                                id="alertName"
                                inputProps={{ "data-testid": "alertName" }}
                            />
                            <FormControl>
                                <InputLabel>Operation</InputLabel>
                                <Select
                                    error={snackbar && activeMethodTab === "Automatic" && !alertOperation}
                                    value={alertOperation}
                                    label="Operation"
                                    id="selectOperation"
                                    onChange={handleOperationChange}
                                >
                                    {props.publishedOperations
                                        .filter((op) => op.state !== "PLANNING")
                                        .map((op) => (
                                            <MenuItem key={op.flight_uuid} value={op} id={op.volumeId}>
                                                {op.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="select-vehicle-air">Alerts For</InputLabel>
                                <Select
                                    value={alertManned}
                                    id="selectManned"
                                    label="Alerts For"
                                    onChange={(e) => setAlertManned(e.target.value)}
                                    inputProps={{ "data-testid": "selectManned" }}
                                >
                                    <MenuItem value={"all"} id="all">
                                        All Aircraft
                                    </MenuItem>
                                    <MenuItem value={"manned"} id="manned">
                                        Manned
                                    </MenuItem>
                                    <MenuItem value={"unmanned"} id="unmanned">
                                        Unmanned
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                margin="dense"
                                label="Buffer"
                                id="alertBuffer"
                                type="number"
                                fullWidth
                                value={alertBuffer}
                                onWheel={(e) => e.target.blur()}
                                disabled={alertFieldsDisabled || !alertOperation}
                                onChange={handleBufferChange}
                                error={snackbar && (isNaN(parseFloat(alertBuffer)) || parseFloat(alertBuffer) < 0)}
                                sx={{ my: 0, display: activeMethodTab === "Automatic" ? "block" : "none" }}
                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                inputProps={{ "data-testid": "alertBuffer" }}
                            />
                            <TextField
                                margin="dense"
                                label="Radius"
                                fullWidth
                                type="number"
                                value={alertRadius}
                                onWheel={(e) => e.target.blur()}
                                disabled={alertFieldsDisabled}
                                onChange={(e) => setAlertRadius(e.target.value)}
                                error={snackbar && (isNaN(parseFloat(alertRadius)) || parseFloat(alertRadius) <= 0)}
                                sx={{ my: 0, display: activeMethodTab === "Circle" ? "block" : "none" }}
                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                inputProps={{ "data-testid": "alertRadius" }}
                            />
                        </Box>
                        <Box sx={{ display: "grid", gridTemplateColumns: isDesktop ? "1fr 1fr 1fr" : "1fr", gap: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel>Color</InputLabel>
                                <Select
                                    value={alertColorId}
                                    label="Color"
                                    onChange={(e) => setAlertColorId(e.target.value)}
                                    id="selectColor"
                                    inputProps={{ "data-testid": "selectColor" }}
                                >
                                    {props.colors ? (
                                        props.colors.map(({ id, color }) => (
                                            <MenuItem key={id} value={id} id={color.toLowerCase()}>
                                                {color}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem></MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <Altitudes
                                minAltitude={alertMinAltitude}
                                maxAltitude={alertMaxAltitude}
                                handleAltitudeChange={handleAltitudeChange}
                                disabled={alertFieldsDisabled}
                                error={altitudeError}
                            />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button sx={{ mr: "auto" }} onClick={() => props.setCreateAlertDialogOpen(false)}>
                    Close
                </Button>
                <Button onClick={handlePublish} id="publish">
                    Publish
                </Button>
            </DialogActions>
        </Dialog>
    );
};
