import React from "react";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/base/ClickAwayListener";

export const MapContextMenu = (props) => {
  return (
    <Popper
      open={props.contextMenuOpen === true && props.contextMenuAnchorEl !== null ? true : false}
      anchorEl={props.contextMenuAnchorEl}
      placement={"bottom-end"}
      sx={{ zIndex: 1300 }}
    >
      {() => (
        <Paper onContextMenu={(e) => e.preventDefault()} data-testid="container">
          <ClickAwayListener onClickAway={props.handleCloseContextMenu}>
            <MenuList dense sx={{ maxHeight: 200, overflow: "auto" }}>
              {props.contextMenuEntities.map((entity, i) => (
                <MenuItem key={i} onClick={() => props.handleSetSelectedEntity(entity)}>
                  {entity.name}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  );
};
