import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../contexts/authContext";
import { useMap } from "../contexts/mapContext";
import { CustomPagination } from "../customPagination";
import { DroneDialog } from "./droneDialog";

function EditToolbar(props) {
    const { setEditRow, setDroneDialogOpen } = props;
    const { user } = useUserAuth();

    const handleClick = () => {
        setEditRow({
            id: 0,
            name: "",
            brand: "",
            model: "",
            drone_type: "",
            serial_number: "",
            serial_number_internal: "",
            tech_number: "",
            notes: "",
            status: "",
            organization_id: user.organization_id
        });
        setDroneDialogOpen(true);
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Record
            </Button>
        </GridToolbarContainer>
    );
}

export function DroneSettings() {
    const { visibleDrones, getDrones } = useMap();
    const { getOrganizationByID } = useUserAuth();
    const [rows, setRows] = useState([]);
    const [editRow, setEditRow] = useState({});
    const [droneDialogOpen, setDroneDialogOpen] = useState(false);

    useEffect(() => {
        getDrones();
    }, []);

    useEffect(() => {
        setRows(visibleDrones);
    }, [visibleDrones]);

    const handleEditClick = (id) => {
        setEditRow(rows.find((row) => row.id === id));
        setDroneDialogOpen(true);
    };

    const droneColumns = [
        {
            field: "name",
            headerName: "Name",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "brand",
            headerName: "Brand",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "model",
            headerName: "Model",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "drone_type",
            headerName: "Drone Type",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "serial_number",
            headerName: "Serial Number",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "serial_number_internal",
            headerName: "Serial Number Internal",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "tech_number",
            headerName: "Tech Number",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "notes",
            headerName: "Notes",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "status",
            headerName: "Status",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "organization_id",
            headerName: "Org",
            editable: false,
            width: 100,
            flex: 1,
            renderCell: (cellValues) => {
                const org = getOrganizationByID(cellValues.formattedValue);
                if (org != undefined) {
                    return <div>{org.name}</div>;
                }
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        key={id}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(id)}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    return (
        <Grid
            item
            sx={{ maxWidth: "100% !important", direction: "column", justifyContent: "flex-start", alignItems: "flex-start", pt: 3, px: 3, width: "100%" }}
        >
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Manage Drones
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={droneColumns}
                    disableRowSeletionOnClick
                    disableVirtualization
                    autoHeight
                    slots={{ toolbar: EditToolbar, pagination: CustomPagination }}
                    slotProps={{ toolbar: { setEditRow, setDroneDialogOpen } }}
                    initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                />
                {droneDialogOpen ? (
                    <DroneDialog row={editRow} setRows={setRows} droneDialogOpen={droneDialogOpen} setDroneDialogOpen={setDroneDialogOpen} />
                ) : (
                    <></>
                )}
            </Paper>
        </Grid>
    );
}
