import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import { DataGrid } from "@mui/x-data-grid";
import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";
import { CreateOrg } from "./createOrg";
import { LoginHistory } from "./loginHistory";

export function UserList() {
    const { user, socket, handleFailedFetch, setSnackbar } = useUserAuth();

    const [activeIDs, setActiveIDs] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [openOrgDialog, setOpenOrgDialog] = useState(false);
    const [currentUser, setCurrentUser] = useState([]);
    const [openLoginHistory, setOpenLoginHistory] = useState(false);
    const [sortModel, setSortModel] = useState([{ field: "last_login", sort: "desc" }]);

    const isMobile = useMediaQuery("(max-width:400px)");

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("api/user/get_all/" + user.user_role + "/" + user.organization_id, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setAllUsers(data))
            .catch((err) => handleFailedFetch(err));
    }, []);

    useEffect(() => {
        if (socket !== null) {
            socket.on("updateActiveUserIDs", (ids) => {
                setActiveIDs(ids);
            });
            socket.emit("getActiveUserIDs");
        }
        return () => {
            if (socket !== null) {
                socket.off("updateActiveUserIDs");
            }
        };
    }, [socket]);

    const userColumns = [
        {
            field: "online",
            headerName: "Online",
            width: 70,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            display: "flex",
            renderCell: ({ row }) => {
                return activeIDs.includes(row.id) ? (
                    <Tooltip title="Online">
                        <CheckCircleIcon color="success" fontSize="medium" />
                    </Tooltip>
                ) : (
                    <Tooltip title="Offline">
                        <RadioButtonUncheckedIcon color="disabled" fontSize="medium" />
                    </Tooltip>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 150,
            valueGetter: (value, row) => {
                return `${row.first_name || ""} ${row.last_name || ""}`;
            }
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 250
        },
        {
            field: "phone_number",
            headerName: "Phone Number",
            flex: 1,
            minWidth: 150
        },
        {
            field: "organization",
            headerName: "Org",
            flex: 1,
            minWidth: 100
        },
        {
            field: "user_role_id",
            headerName: "Role",
            flex: 1,
            minWidth: 150,
            type: "singleSelect",
            editable: true,
            valueOptions:
                user.user_role === "Admin"
                    ? [
                          { label: "Admin", value: 0 },
                          { label: "Admin - Org", value: 3 },
                          { label: "Operations Manager", value: 1 },
                          { label: "Airspace Manager", value: 4 },
                          { label: "First Responder", value: 5 },
                          { label: "Operator", value: 2 }
                      ]
                    : [
                          { label: "Admin - Org", value: 3 },
                          { label: "Operations Manager", value: 1 },
                          { label: "Airspace Manager", value: 4 },
                          { label: "First Responder", value: 5 },
                          { label: "Operator", value: 2 }
                      ],
            /* eslint-disable no-unused-vars */
            valueFormatter: (value, row, column) => {
                const colDef = column;
                const option = colDef.valueOptions.find(({ value: optionValue }) => value === optionValue);
                return option.label;
            }
            /* eslint-disable no-unused-vars */
        },
        {
            field: "last_login",
            headerName: "Last Login",
            minWidth: 250,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "id",
            headerName: "Login History",
            align: "center",
            minWidth: 100,
            sortable: false,
            flex: 1,
            display: "flex",
            renderCell: ({ row }) => {
                return (
                    <ListAltIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            handleLoginHistory(row);
                        }}
                    />
                );
            }
        },
        {
            field: "pilot",
            headerName: "Pilot",
            align: "center",
            headerAlign: "center",
            sortable: false,
            minWidth: 50,
            flex: 1,
            renderCell: ({ row }) => {
                return <Checkbox checked={row.pilot_uuid !== null} />;
            }
        },
        {
            field: "type",
            headerName: "Active",
            align: "center",
            headerAlign: "center",
            sortable: false,
            minWidth: 50,
            flex: 1,
            renderCell: ({ row }) => {
                return <Checkbox checked={row.active} onClick={() => handleActivateClick(row)} id={`active-${row.email}`} />;
            }
        },
        {
            field: "reset_password",
            headerName: "Reset Password",
            align: "center",
            sortable: false,
            minWidth: 50,
            flex: 1,
            display: "flex",
            renderCell: ({ row }) => {
                const reset_password = row.reset_password;
                return reset_password ? (
                    <LockResetIcon sx={{ color: "#CB4C4E", cursor: "pointer" }} />
                ) : (
                    <LockResetIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            handleResetPassword(row);
                        }}
                    />
                );
            }
        },
        {
            field: "deleted",
            headerName: "Delete",
            align: "center",
            headerAlign: "center",
            minWidth: 75,
            flex: 1,
            sortable: false,
            display: "flex",
            renderCell: ({ row }) => {
                if (user.user_role === "Admin" || user.user_role === "Admin - Org") {
                    return (
                        <IconButton id={`delete-${row.email}`} onClick={(e) => handleDeleteClick(row)}>
                            <CancelIcon />
                        </IconButton>
                    );
                }
            }
        }
    ];

    const handleActivateClick = async (row) => {
        const setting = row.active ? "deactivate" : "activate";
        const returnValue = row.active
            ? confirm("This will de-activate the user in the system and user will no longer be able to login. Do you wish to continue?")
            : confirm("This will activate the user in the system. Do you wish to continue?");

        if (!returnValue) return;

        row.active = !row.active;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(row)
        };
        await fetch(`api/user/${setting}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setSnackbar({ children: `User successfully ${setting}d`, severity: "success" }))
            .catch((err) => handleFailedFetch(err));
    };

    const handleResetPassword = async (row) => {
        if (!confirm("This will force the user to reset their password. Do you wish to continue?")) return;
        if (row.oidc) return setSnackbar({ children: `Unable to update OHID account`, severity: "error" });

        row.reset_password = true;
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(row)
        };
        await fetch("api/user/forgot", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setSnackbar({ children: `User successfully updated`, severity: "success" }))
            .catch((err) => handleFailedFetch(err));
    };

    const handleDeleteClick = async (row) => {
        if (!confirm("This delete the user from the system. Do you wish to continue?")) return;

        row.deleted = true;
        setAllUsers(allUsers.filter((r) => r.id !== row.id));

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(row)
        };
        await fetch("api/user/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setSnackbar({ children: `User successfully removed`, severity: "success" }))
            .catch((err) => handleFailedFetch(err));
    };

    // api call to update a certain user
    const updateUser = async (updatedUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };

        await fetch("api/user/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setSnackbar({ children: `User successfully updated`, severity: "success" }))
            .catch((err) => handleFailedFetch(err));
    };

    const updateDatagridRow = (updatedRow, originalRow) => {
        if (originalRow.user_role_id != updatedRow.user_role_id) {
            allUsers.forEach(async (user) => {
                if (user.id === updatedRow.id) {
                    user.user_role_id = updatedRow.user_role_id;
                    await updateUser(user);
                }
            });
        }
        return updatedRow;
    };

    /* istanbul ignore next */
    const handleProcessRowUpdateError = React.useCallback((error) => {
        setSnackbar({ children: error.message, severity: "error" });
    }, []);

    const handleAddOrg = () => {
        setOpenOrgDialog(true);
    };

    const closeOrgDialog = () => {
        setOpenOrgDialog(false);
    };

    const handleLoginHistory = (row) => {
        setCurrentUser(row);
        setOpenLoginHistory(true);
    };

    const closeLoginHistory = () => {
        setCurrentUser(null);
        setOpenLoginHistory(false);
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", p: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Manage Users
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column" }}>
                <Box sx={{ height: 578 }}>
                    <DataGrid
                        disableVirtualization
                        rows={allUsers}
                        columns={userColumns}
                        disableRowSeletionOnClick
                        getRowId={(row) => row.id}
                        processRowUpdate={(updatedRow, originalRow) => updateDatagridRow(updatedRow, originalRow)}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        pagination
                        slots={{ pagination: CustomPagination }}
                        initialState={{ pagination: { paginationModel: { pageSize: 9 } } }}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    />
                </Box>
                {user.user_role === "Admin" ? (
                    <Box sx={{ mt: 2, gap: "5px" }}>
                        <Button
                            sx={{ marginRight: "auto" }}
                            variant="contained"
                            color="primary"
                            onClick={handleAddOrg}
                            data-testid="add"
                            size={isMobile ? "small" : "medium"}
                        >
                            View/Add Organization
                        </Button>
                    </Box>
                ) : null}
            </Paper>
            <CreateOrg open={openOrgDialog} key={openOrgDialog} close={closeOrgDialog} />
            <LoginHistory open={openLoginHistory} key={currentUser} user={currentUser} close={closeLoginHistory} />
        </Grid>
    );
}
