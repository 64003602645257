import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";

import { Viewer } from "resium";
import { Cartesian3, ScreenSpaceEventType, SceneMode, defined, Cartographic, Math, subscribeAndEvaluate } from "cesium";
import { getEntitiesFromOperationMessage, getEntitiesFromAlertVolumeMessage, getEntitiesFromConstraintMessage, zoomToEntities } from "../map/mapUtil";
import { getEntitiesFromAirspaces, getIndeterminateFromSubLayers, getCheckedFromSubLayers } from "../map/mapUtil";
import { pickPosition } from "../map/mapUtil";
import { MapContextMenu } from "./mapContextMenu";
import { MapLayers } from "./mapLayers";

import { useUserAuth } from "../contexts/authContext";
import { useEnv } from "../contexts/envContext";

export function MapPreview(props) {
    const viewerRef = useRef(null);
    const viewerContainerRef = useRef(null);
    const mousePositionRef = useRef(null);

    const [planningOperationEntities, setPlanningOperationEntities] = useState([]);
    const [publishedOperationEntities, setPublishedOperationEntities] = useState([]);
    const [airspaceEntities, setAirspaceEntities] = useState([]);
    const [constraintEntities, setConstraintEntities] = useState([]);
    const [alertVolumeEntities, setAlertVolumeEntities] = useState([]);

    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [contextMenuEntities, setContextMenuEntities] = useState([]);
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState({
        getBoundingClientRect: () => ({ width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0 })
    });

    const { userMapSettings, updateMapSettings } = useUserAuth();
    const { providerViewModels } = useEnv();

    const [layers, setLayers] = useState([
        {
            id: 0,
            name: "Planning Operations",
            checked: props.id === 0,
            indeterminate: false,
            subLayers: [],
            elements: planningOperationEntities
        },
        {
            id: 1,
            name: "Operations",
            checked: props.id === 1,
            indeterminate: false,
            subLayers: [],
            elements: publishedOperationEntities
        },
        {
            id: 2,
            name: "Airspaces",
            checked: props.id === 2,
            indeterminate: false,
            subLayers: [
                { name: "CLASS_A", checked: props.id === 2, elements: airspaceEntities },
                { name: "CLASS_B", checked: props.id === 2, elements: airspaceEntities },
                { name: "CLASS_C", checked: props.id === 2, elements: airspaceEntities },
                { name: "CLASS_D", checked: props.id === 2, elements: airspaceEntities },
                { name: "CLASS_E2", checked: props.id === 2, elements: airspaceEntities },
                { name: "CLASS_E3", checked: props.id === 2, elements: airspaceEntities },
                { name: "CLASS_E4", checked: props.id === 2, elements: airspaceEntities },
                { name: "CLASS_E5", checked: props.id === 2, elements: airspaceEntities },
                { name: "MODE C", checked: props.id === 2, elements: airspaceEntities }
            ],
            elements: airspaceEntities
        },
        {
            id: 3,
            name: "Constraints",
            checked: props.id === 3,
            indeterminate: false,
            subLayers: [],
            elements: constraintEntities
        },
        {
            id: 4,
            name: "Alert Volumes",
            checked: props.id === 4,
            indeterminate: false,
            subLayers: [],
            elements: alertVolumeEntities
        }
    ]);

    // initialize map with user settings
    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            viewer.scene.requestRenderMode = true;
            viewer.scene.screenSpaceCameraController.enableTilt = false;
            viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

            if (userMapSettings.map_preference >= 0 && userMapSettings.map_preference < providerViewModels.length) {
                viewer.baseLayerPicker.viewModel.selectedImagery = providerViewModels[userMapSettings.map_preference];
            }
            const sceneMode = userMapSettings.map_scene_mode;
            if (sceneMode === "SCENE3D") {
                viewer.scene.mode = SceneMode.SCENE3D;
            }
            if (sceneMode === "COLUMBUS_VIEW") {
                viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
            }
            if (sceneMode === "SCENE2D") {
                viewer.scene.mode = SceneMode.SCENE2D;
            }
            viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                viewer.camera.setView({
                    destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
                });
            });
            viewer.scene.morphComplete.addEventListener(() => {
                viewer.camera.setView({
                    destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
                });
            });
            subscribeAndEvaluate(viewer.baseLayerPicker.viewModel, "selectedImagery", (selectedImageryProviderViewModel) => {
                for (let i = 0; i < viewer.imageryLayers.length; i++) {
                    const layer = viewer.imageryLayers.get(i);
                    if (layer !== undefined && layer.isBaseLayer()) {
                        layer.brightness = userMapSettings.brightness;
                    }
                }
                const index = providerViewModels.findIndex((imageryLayer) => {
                    return imageryLayer.name === selectedImageryProviderViewModel.name;
                });
                if (index !== userMapSettings.map_preference) {
                    const imagerySettings = {
                        ...userMapSettings,
                        map_preference: index
                    };
                    updateMapSettings(imagerySettings);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;

            // updates mouse position display
            viewer.screenSpaceEventHandler.setInputAction(({ endPosition }) => {
                const cartesian = pickPosition(viewer, endPosition);
                if (cartesian) {
                    const cartographic = Cartographic.fromCartesian(cartesian);
                    const longitudeString = Math.toDegrees(cartographic.longitude);
                    const latitudeString = Math.toDegrees(cartographic.latitude);
                    mousePositionRef.current.innerHTML = `( ${latitudeString}, ${longitudeString} )`;
                }
            }, ScreenSpaceEventType.MOUSE_MOVE);

            // adds drill pick capabilities
            viewer.screenSpaceEventHandler.setInputAction((click) => {
                viewer.selectedEntity = undefined;
                const pickedEntities = viewer.scene.drillPick(click.position);
                if (defined(pickedEntities)) {
                    const filteredEntities = pickedEntities.map((entity) => {
                        return entity.id;
                    });
                    const contextEntities = filteredEntities.filter((entity) => {
                        return entity.id.includes("_") === false;
                    });
                    const finalEntities = contextEntities.filter((e, i) => {
                        return contextEntities.findIndex((a) => a.id === e.id) === i;
                    });
                    if (finalEntities.length > 1) {
                        setContextMenuOpen(true);
                        setContextMenuEntities(finalEntities);
                    } else {
                        viewer.selectedEntity = finalEntities[0];
                    }
                }
            }, ScreenSpaceEventType.LEFT_CLICK);
        }
    }, []);

    useEffect(() => {
        if (props.planningOperations !== undefined) {
            removeEntitiesFromPreview(planningOperationEntities);
            const entityCollection = getEntitiesFromOperationMessage(props.planningOperations, userMapSettings);

            const layer = layers.find(({ id }) => {
                return id === 0;
            });
            if (props.id === 0 && layer.checked === true) {
                const entities = addEntitiesToPreview(entityCollection);
                handleUpdateElementsInLayer(0, entities);
                setPlanningOperationEntities(entities);
                handleZoomToEntities(entities);
            } else {
                const entities = addEntitiesToPreview(entityCollection, false);
                handleUpdateElementsInLayer(0, entities);
                setPlanningOperationEntities(entities);
            }
        }
    }, [props.planningOperations]);

    useEffect(() => {
        if (props.publishedOperations !== undefined) {
            removeEntitiesFromPreview(publishedOperationEntities);
            const entityCollection = getEntitiesFromOperationMessage(props.publishedOperations, userMapSettings);

            const layer = layers.find(({ id }) => {
                return id === 1;
            });
            if (props.id === 1 && layer.checked === true) {
                const entities = addEntitiesToPreview(entityCollection);
                handleUpdateElementsInLayer(1, entities);
                setPublishedOperationEntities(entities);
                handleZoomToEntities(entities);
            } else {
                const entities = addEntitiesToPreview(entityCollection, false);
                handleUpdateElementsInLayer(1, entities);
                setPublishedOperationEntities(entities);
            }
        }
    }, [props.publishedOperations]);

    useEffect(() => {
        if (props.airspaces !== undefined) {
            removeEntitiesFromPreview(airspaceEntities);
            const types = ["CLASS_A", "CLASS_B", "CLASS_C", "CLASS_D", "CLASS_E2", "CLASS_E3", "CLASS_E4", "CLASS_E5", "MODE C"];
            const entityCollection = getEntitiesFromAirspaces(props.airspaces, userMapSettings);

            const layer = layers.find(({ id }) => {
                return id === 2;
            });
            if (props.id === 2 && layer.checked === true) {
                const entities = addEntitiesToPreview(entityCollection);
                const subLayers = [];
                types.forEach((type) => {
                    const filtered = entities.filter(({ airspace_type }) => {
                        return airspace_type === type;
                    });
                    const subLayer = { name: type, checked: true, elements: filtered };
                    subLayers.push(subLayer);
                });
                handleUpdateElementsInLayer(2, entities, subLayers);
                setAirspaceEntities(entities);
                handleZoomToEntities(entities);
            } else {
                const entities = addEntitiesToPreview(entityCollection, false);
                const subLayers = [];
                types.forEach((type) => {
                    const filtered = entities.filter(({ airspace_type }) => {
                        return airspace_type === type;
                    });
                    const subLayer = { name: type, checked: false, elements: filtered };
                    subLayers.push(subLayer);
                });
                handleUpdateElementsInLayer(2, entities, subLayers);
                setAirspaceEntities(entities);
            }
        }
    }, [props.airspaces]);

    useEffect(() => {
        if (props.constraints !== undefined) {
            removeEntitiesFromPreview(constraintEntities);
            const entityCollection = getEntitiesFromConstraintMessage(props.constraints, userMapSettings);

            const layer = layers.find(({ id }) => {
                return id === 3;
            });
            if (props.id === 3 && layer.checked === true) {
                const entities = addEntitiesToPreview(entityCollection);
                handleUpdateElementsInLayer(3, entities);
                setConstraintEntities(entities);
                handleZoomToEntities(entities);
            } else {
                const entities = addEntitiesToPreview(entityCollection, false);
                handleUpdateElementsInLayer(3, entities);
                setConstraintEntities(entities);
            }
        }
    }, [props.constraints]);

    useEffect(() => {
        if (props.alertVolumes !== undefined) {
            removeEntitiesFromPreview(alertVolumeEntities);
            const entityCollection = getEntitiesFromAlertVolumeMessage(props.alertVolumes, userMapSettings);

            const layer = layers.find(({ id }) => {
                return id === 4;
            });
            if (props.id === 4 && layer.checked === true) {
                const entities = addEntitiesToPreview(entityCollection);
                handleUpdateElementsInLayer(4, entities);
                setAlertVolumeEntities(entities);
                handleZoomToEntities(entities);
            } else {
                const entities = addEntitiesToPreview(entityCollection, false);
                handleUpdateElementsInLayer(4, entities);
                setAlertVolumeEntities(entities);
            }
        }
    }, [props.alertVolumes]);

    const addEntitiesToPreview = (entityCollection, show = true) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const newEntities = [];
            entityCollection.values.forEach((entity) => {
                const newEntity = viewer.entities.add(entity);
                newEntity.show = show;
                newEntities.push(newEntity);
            });
            viewer.scene.requestRender();
            return newEntities;
        }
    };
    const removeEntitiesFromPreview = (entities) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            entities.forEach((entity) => {
                viewer.entities.remove(entity);
            });
            viewer.scene.requestRender();
        }
    };
    const handleSetSelectedEntity = (entity) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            if (viewer.entities.contains(entity)) {
                viewer.selectedEntity = entity;
            } else {
                viewer.selectedEntity = undefined;
            }
            if (contextMenuOpen === true) {
                setContextMenuOpen(false);
            }
        }
    };
    const handleSetContextMenuAnchorEl = (e) => {
        const virtualElement = {
            getBoundingClientRect: () => ({
                width: 0,
                height: 0,
                top: e.clientY,
                right: e.clientX,
                bottom: e.clientY,
                left: e.clientX
            })
        };
        setContextMenuAnchorEl(virtualElement);
    };
    const handleZoomToEntities = (entities) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            zoomToEntities(viewer, entities);
        }
    };
    const toggleElementsVisibility = (elements, value) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            if (Array.isArray(elements)) {
                elements.forEach((element) => {
                    element.show = value;
                });
            } else {
                elements.show = value;
            }
            const viewer = viewerRef.current.cesiumElement;
            viewer.scene.requestRender();
        }
    };
    const handleUpdateElementsInLayer = (id, newElements, subLayers = null) => {
        setLayers((previous) => {
            return previous.map((layer) => {
                if (layer.id === id) {
                    if (subLayers === null) {
                        return { ...layer, elements: newElements };
                    } else {
                        return {
                            ...layer,
                            elements: newElements,
                            subLayers: subLayers,
                            checked: getCheckedFromSubLayers(subLayers),
                            indeterminate: getIndeterminateFromSubLayers(subLayers)
                        };
                    }
                } else {
                    return layer;
                }
            });
        });
    };
    return (
        <Box ref={viewerContainerRef} style={{ position: "relative" }} onClick={handleSetContextMenuAnchorEl}>
            <Viewer ref={viewerRef} animation={false} timeline={false} navigationHelpButton={false} imageryProviderViewModels={providerViewModels} />

            <Box ref={mousePositionRef} className="cesium-dialog" />

            <MapLayers
                viewerRef={viewerRef}
                viewerContainerRef={viewerContainerRef}
                layers={layers}
                setLayers={setLayers}
                toggleElementsVisibility={toggleElementsVisibility}
            />

            <MapContextMenu
                contextMenuOpen={contextMenuOpen}
                contextMenuAnchorEl={contextMenuAnchorEl}
                contextMenuEntities={contextMenuEntities}
                setContextMenuOpen={setContextMenuOpen}
                handleSetSelectedEntity={handleSetSelectedEntity}
            />
        </Box>
    );
}
