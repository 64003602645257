import React, { useState, createContext, useContext } from "react";
import { handleCreateProviderViewModels } from "../map/mapUtil";
import { Ion } from "cesium";

export const EnvContext = createContext();

export function useEnv() {
    return useContext(EnvContext);
}

export function EnvProvider({ children }) {
    const [dataLoggingOn, setDataLoggingOn] = useState("false");
    const [odotDeployment, setOdotDeployment] = useState("");
    const [correlatorId, setCorrelatorId] = useState("");
    const [laancOn, setLaancOn] = useState("false");
    const [providerViewModels, setProviderViewModels] = useState([]);
    const [laancTileServerUrl, setLaancTileServerUrl] = useState("");
    const [bingMapsKey, setBingMapsKey] = useState("");
    const [additionalTileserverUrls, setAdditionalTileserverUrls] = useState([]);

    const fetchEnvVariables = () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("api/env/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => updateEnvVariables(data))
            .catch((err) => console.error(err));
    };
    const updateEnvVariables = (data) => {
        const react_ion_token = data.REACT_ION_ACCESS_TOKEN;
        Ion.defaultAccessToken = react_ion_token;

        const data_logging_on = data.DATA_LOGGING_ON;
        setDataLoggingOn(data_logging_on);

        const odot_deployment = data.ODOT_DEPLOYMENT;
        setOdotDeployment(odot_deployment);

        const correlator_id = data.CORRELATOR_ID;
        setCorrelatorId(correlator_id);

        const laancOn = data.LAANC_ON;
        setLaancOn(laancOn);

        const provider_view_models = handleCreateProviderViewModels(data.IMAGERY_PROVIDER_URLS);
        setProviderViewModels(provider_view_models);

        const laancTileServerUrl = data.LAANC_TILESERVER_URL;
        setLaancTileServerUrl(laancTileServerUrl);

        const bing_maps_api_key = data.BING_MAPS_API_KEY;
        setBingMapsKey(bing_maps_api_key);

        try {
            const additional_tileserver_urls = JSON.parse(data.ADDITIONAL_TILESERVER_URLS);
            setAdditionalTileserverUrls(additional_tileserver_urls);
        } catch (err) {
            console.error(err);
        }

        // add more here...
    };

    const value = {
        dataLoggingOn,
        odotDeployment,
        correlatorId,
        laancOn,
        fetchEnvVariables,
        providerViewModels,
        setProviderViewModels,
        laancTileServerUrl,
        bingMapsKey,
        additionalTileserverUrls,
        setAdditionalTileserverUrls
    };

    return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
}
