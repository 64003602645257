import React, { useEffect, useRef } from "react";

import Box from "@mui/material/Box";

export default function MapFullScreen({ map_id, map, buttons, children }) {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!map || !containerRef.current) {
            return;
        }
        const fullScreen = containerRef.current.querySelector("button");
        if (fullScreen) {
            fullScreen.classList.add("control-button");
        }
    }, [map]);

    return (
        <Box id={map_id} sx={{ width: "100%", height: "100%", position: "relative" }}>
            {children}
            <Box sx={{ position: "absolute", zIndex: 1, right: "8px", top: "8px", display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "8px" }}>
                {buttons?.map(({ id, name, icon, onClick, visible }, i) =>
                    visible === true ? (
                        <button key={i} id={id} title={name} type="button" onClick={onClick} className="control-button">
                            {icon || name}
                        </button>
                    ) : (
                        <React.Fragment key={i} />
                    )
                )}
                <Box ref={containerRef} />
            </Box>
        </Box>
    );
}
