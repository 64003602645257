import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";

import AddIcon from "@mui/icons-material/Add";
import { useUserAuth } from "../contexts/authContext";

export function MiscSettings() {
    const [operationalStateDialogOpen, setOperationalStateDialogOpen] = useState(false);
    const [operationColorMenusOpen, setOperationColorMenusOpen] = useState(new Map());
    const [operationColorMenusAnchorEls, setOperationColorMenusAnchorEls] = useState(new Map());

    const { userMapSettings, updateMapSettings } = useUserAuth();

    const colors = [
        { value: "#e3acbb", label: "Pink" },
        { value: "#CB4C4E", label: "Red" },
        { value: "#CC5500", label: "Orange" },
        { value: "#E0D268", label: "Yellow" },
        { value: "#5fa052", label: "Green" },
        { value: "#6B9797", label: "Teal" },
        { value: "#90caf9", label: "Blue" },
        { value: "#118aee", label: "Blue - Dark" },
        { value: "#a66cb2", label: "Purple" },
        { value: "#997864", label: "Brown" },
        { value: "#A9A9A9", label: "Gray" },
        { value: "#333333", label: "Gray - Dark" },
        { value: "#000000", label: "Black" }
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "800px" }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "400px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h6" noWrap>
                        Operational States
                    </Typography>

                    <Tooltip title="Add">
                        <IconButton onClick={() => setOperationalStateDialogOpen(true)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <List dense>
                    {userMapSettings.op_state_settings.map(({ id, state, color }) => {
                        const colorListOpen = operationColorMenusOpen.get(id);
                        const colorListAnchorEl = operationColorMenusAnchorEls.get(id);

                        const handleOpenColorMenu = (e) => {
                            const updateColorMenus = new Map(operationColorMenusOpen);
                            updateColorMenus.set(id, true);
                            setOperationColorMenusOpen(updateColorMenus);

                            const updateAnchorEls = new Map(operationColorMenusAnchorEls);
                            updateAnchorEls.set(id, e.currentTarget);
                            setOperationColorMenusAnchorEls(updateAnchorEls);
                        };
                        const handleCloseColorMenu = () => {
                            const updateColorMenus = new Map(operationColorMenusOpen);
                            updateColorMenus.set(id, false);
                            setOperationColorMenusOpen(updateColorMenus);

                            const updateAnchorEls = new Map(operationColorMenusAnchorEls);
                            updateAnchorEls.set(id, null);
                            setOperationColorMenusAnchorEls(updateAnchorEls);
                        };
                        const handleSelectColor = (color_hex) => {
                            handleCloseColorMenu();
                            const updatedOperationSettings = userMapSettings.op_state_settings.map((setting) => {
                                if (setting.id === id) {
                                    return {
                                        ...setting,
                                        color: color_hex
                                    };
                                } else {
                                    return setting;
                                }
                            });
                            const updatedMapSettings = {
                                ...userMapSettings,
                                op_state_settings: updatedOperationSettings
                            };
                            updateMapSettings(updatedMapSettings);
                        };
                        return (
                            <React.Fragment key={id}>
                                <ListItem>
                                    <ListItemAvatar sx={{ cursor: "pointer" }} onClick={handleOpenColorMenu} data-testid={`op-color-list-item-${id}`}>
                                        <Avatar sx={{ bgcolor: color }}>{` `}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={state} />
                                </ListItem>
                                <Popover
                                    onClose={handleCloseColorMenu}
                                    open={colorListOpen || false}
                                    anchorEl={colorListAnchorEl}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "center",
                                        horizontal: "right"
                                    }}
                                >
                                    <List dense>
                                        {colors.map(({ value, label }, i) => (
                                            <ListItemButton key={i} onClick={() => handleSelectColor(value)}>
                                                <ListItemAvatar>
                                                    <Avatar sx={{ bgcolor: value, width: 24, height: 24 }}>{` `}</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={label} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Popover>
                            </React.Fragment>
                        );
                    })}
                </List>
            </Paper>

            {operationalStateDialogOpen === true ? (
                <AddOperationalStateDialog
                    operationalStateDialogOpen={operationalStateDialogOpen}
                    setOperationalStateDialogOpen={setOperationalStateDialogOpen}
                />
            ) : (
                <></>
            )}
        </Box>
    );
}

const AddOperationalStateDialog = (props) => {
    const [newOperationalStateName, setNewOperationalStateName] = useState("");
    const [newOperationalStateErrorText, setNewOperationalStateErrorText] = useState("");

    const { userMapSettings, updateMapSettings } = useUserAuth();

    const handleSubmit = () => {
        if (!newOperationalStateName) {
            setNewOperationalStateErrorText("Please enter a valid state name.");
            return;
        }
        const updatedOperationSettings = [
            ...userMapSettings.op_state_settings,
            {
                id: userMapSettings.op_state_settings.length,
                state: newOperationalStateName,
                color: "#000000"
            }
        ];
        const updatedMapSettings = {
            ...userMapSettings,
            op_state_settings: updatedOperationSettings
        };
        updateMapSettings(updatedMapSettings);
        props.setOperationalStateDialogOpen(false);
    };
    return (
        <Dialog open={props.operationalStateDialogOpen} onClose={() => props.setOperationalStateDialogOpen(false)}>
            <DialogTitle>Add Operational State</DialogTitle>
            <DialogContent>
                <DialogContentText>Please enter the name of your new operational state here. You can modify the color later.</DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="Name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    value={newOperationalStateName}
                    onChange={(e) => setNewOperationalStateName(e.target.value)}
                    error={newOperationalStateErrorText !== ""}
                    helperText={newOperationalStateErrorText}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOperationalStateDialogOpen(false)}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MiscSettings;
