import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserAuth } from "./authContext";
import { v4 as uuid_v4 } from "uuid";
import alertSound from "../../public/static/files/notify.wav";

export const SocketContext = createContext();

export function useSocket() {
    return useContext(SocketContext);
}

export function SocketProvider({ children }) {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [conversations, setConversations] = useState([]);
    const [visibleConversations, setVisibleConversations] = useState([]);
    const [newConversation, setNewConversation] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [selectedConversationUUID, setSelectedConversationUUID] = useState(null);
    const [userIdsAndEmails, setUserIdsAndEmails] = useState([]);
    const [chatSoundOn, setChatSoundOn] = useState(true);

    const [planningOperations, setPlanningOperations] = useState([]);
    const [publishedOperations, setPublishedOperations] = useState([]);
    const [constraints, setConstraints] = useState([]);
    const [alertVolumes, setAlertVolumes] = useState([]);
    const [assets, setAssets] = useState([]);

    const { user, getOrganizationByID, handleFailedFetch } = useUserAuth();

    useEffect(() => {
        if (socket !== null) {
            socket.emit("getOperations");
            socket.emit("getOperationsPlanning");
            socket.emit("getConstraints");
            socket.emit("getAlertVolumes");
            socket.emit("getAssets");
        }
    }, [socket]);

    useEffect(() => {
        if (!socket || !user) {
            return;
        }

        socket.on("receiveMessageFromUser", (message) => {
            setMessages((prev) => [...prev, message]);
            if (chatSoundOn && message.sent_user_id !== user.id) {
                new Audio(alertSound).play();
            }
        });

        socket.on("conversationRead", (conversationUUID) => {
            setMessages((prev) => {
                const messages = [...prev];
                messages.filter((m) => m.conversation_uuid === conversationUUID).forEach((m) => (m.read_message = true));
                return messages;
            });
        });

        return () => {
            if (socket !== null) {
                socket.off("receiveMessageFromUser");
                socket.off("conversationRead");
            }
        };
    }, [socket, user, chatSoundOn]);

    useEffect(() => {
        if (socket !== null && user !== null) {
            const setupVolumeIds = (volumes) => {
                const volumeCount = {};
                return volumes.map((volume) => {
                    const name = volume.name;
                    const count = volumeCount[name] ? volumeCount[name] + 1 : 1;
                    volumeCount[name] = count;
                    return {
                        ...volume,
                        volumeId: `${name}-${count}`
                    };
                });
            };
            socket.on("op_plan", (message) => {
                const visiblePlanningOperations = message.filter((op) => {
                    if (user.user_role_id !== 0) {
                        return op.organization_id === user.organization_id;
                    } else {
                        return true;
                    }
                });
                setPlanningOperations(setupVolumeIds(visiblePlanningOperations));
            });
            socket.on("operation", (message) => {
                setPublishedOperations(setupVolumeIds(message));
            });
            socket.on("constraint", (message) => {
                const visibleConstraints = message.filter((constraint) => {
                    if (user.user_role === "Operations Manager" || user.user_role === "Operator" || user.user_role === "Admin - Org") {
                        return constraint.state === "ACCEPTED";
                    } else if (user.user_role === "First Responder") {
                        return constraint.created_user_id === user.id;
                    } else {
                        return true;
                    }
                });
                setConstraints(setupVolumeIds(visibleConstraints));
            });
            socket.on("alert_volume", (message) => {
                const visibleAlerts = message.filter((alert) => {
                    if (user.user_role === "Admin") {
                        return true;
                    } else if (user.user_role === "Operator") {
                        return alert.created_user_id === user.id;
                    } else if (user.user_role !== "First Responder") {
                        return alert.organization_id === user.organization_id;
                    } else {
                        return false;
                    }
                });
                setAlertVolumes(setupVolumeIds(visibleAlerts));
            });
            socket.on("assets", (message) => {
                setAssets(setupVolumeIds(message));
            });
        }
        return () => {
            if (socket !== null) {
                socket.off("alert_volume");
                socket.off("constraint");
                socket.off("operation");
                socket.off("op_plan");
            }
        };
    }, [socket, user]);

    useEffect(() => {
        if (!user || user.user_role === "First Responder") {
            return setMessages([]);
        }

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        const fetchMessages = async () => {
            await fetch("api/messages/get", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setMessages(data))
                .catch((err) => handleFailedFetch(err));
        };

        fetchMessages();
        getUserIdsAndEmails();

        return () => {
            setMessages([]);
        };
    }, [user]);

    useEffect(() => {
        if (!user || user.user_role === "First Responder") {
            return;
        }

        const conversations = [];
        let newConversationMessage = false;
        let totalUnreadMessages = 0;

        for (const message of messages) {
            let conversation = conversations.find((convo) => convo.conversation_uuid === message.conversation_uuid);

            // Conversation has not yet been added, initialize and add it to the array.
            if (!conversation) {
                conversation = {
                    conversation_uuid: message.conversation_uuid,
                    recipients: message.recipients,
                    recipientEmails: message.recipient_emails,
                    messages: [],
                    unreadMessages: [],
                    totalMessages: 0
                };
                conversations.push(conversation);
            }

            // Ignore deleted messages.
            if (message.deleted_message) {
                continue;
            }

            conversation.messages.push(message);
            conversation.totalMessages++;
            if (!message.read_message && message.sent_user_id !== user.id) {
                conversation.unreadMessages.push(message);
                totalUnreadMessages++;
            }
            if (newConversation && newConversation.conversation_uuid === message.conversation_uuid) {
                newConversationMessage = true;
            }
        }

        // If a new conversation UUID is selected, but no messages have been sent in it, it should be added.
        if (newConversation && newConversation.conversation_uuid === selectedConversationUUID && !newConversationMessage) {
            conversations.push(newConversation);
        }

        // Filter for visible (displayed) conversations and sort all messages by date sent.
        let visibleConversations = conversations.filter(
            (convo) => convo.totalMessages > 0 && (convo.conversation_uuid !== newConversation?.conversation_uuid || newConversationMessage)
        );
        visibleConversations.forEach((convo) => convo.messages.sort((a, b) => new Date(a.date_sent) - new Date(b.date_sent)));
        visibleConversations.sort((a, b) => new Date(b.messages[b.messages.length - 1].date_sent) - new Date(a.messages[a.messages.length - 1].date_sent));

        // Add the new conversation to the top of the visible conversations.
        if (newConversation && !newConversationMessage) {
            visibleConversations = [newConversation, ...visibleConversations];
        }

        setTotalUnreadMessages(totalUnreadMessages);
        setConversations(conversations);
        setVisibleConversations(visibleConversations);
    }, [user, messages, newConversation]);

    useEffect(() => {
        const convo = conversations.find((convo) => convo.conversation_uuid === selectedConversationUUID);
        if (convo) {
            setSelectedConversation(convo);
        } else {
            setSelectedConversation(null);
        }

        // Indicates you clicked on an existing conversation that's not the current new one.
        // This will remove the previously created conversation if no messages have been sent in it.
        if (newConversation && newConversation.conversation_uuid !== selectedConversationUUID) {
            setNewConversation(null);
        }
    }, [conversations, selectedConversationUUID]);

    function sendMessage(content, setMessageInput = null) {
        if (!selectedConversation || !content) {
            return;
        }

        const message = {
            message_uuid: uuid_v4(),
            conversation_uuid: selectedConversation.conversation_uuid,
            sent_user_id: user.id,
            sent_user_email: user.email,
            content: content,
            date_sent: new Date().toISOString(),
            recipients: selectedConversation.recipients,
            recipient_emails: selectedConversation.recipientEmails
        };
        socket.emit("sendMessageToUser", message);

        // Reset chat input if applicable.
        if (setMessageInput) {
            setMessageInput("");
        }
    }

    function createConversation(recipients, recipientEmails) {
        const sortedRecipients = recipients.sort();
        const sortedRecipientEmails = recipientEmails.sort();
        const conversation = conversations.find((conversation) => {
            return conversation.recipients.sort().join(",") === sortedRecipients.join(",");
        });

        if (!conversation) {
            const conversationUUID = uuid_v4();
            setSelectedConversationUUID(conversationUUID);
            return setNewConversation({
                conversation_uuid: conversationUUID,
                recipients: sortedRecipients,
                recipientEmails: sortedRecipientEmails,
                messages: [],
                unreadMessages: []
            });
        }

        setSelectedConversationUUID(conversation.conversation_uuid);

        // If the conversation exists but has no messages, then it's a new conversation in the user's perspective.
        if (conversation.totalMessages === 0) {
            setNewConversation(conversation);
        }
    }

    async function getUserIdsAndEmails() {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        const fetchUserIdsAndEmails = async () => {
            await fetch("api/user/get_all_ids_and_emails", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => fetchAirspaceManagerEmails(data))
                .catch((err) => handleFailedFetch(err));
        };
        const fetchAirspaceManagerEmails = async (userIdsAndEmails) => {
            await fetch("api/user/get_airspace_managers", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => {
                    for (const airspaceManager of data) {
                        const newEmail = `${airspaceManager.email} (${getOrganizationByID(airspaceManager.organization_id).name} - Airspace Manager)`;
                        let found = false;

                        for (const userIdAndEmail of userIdsAndEmails) {
                            if (airspaceManager.id === userIdAndEmail.id) {
                                userIdAndEmail.email = newEmail;
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            userIdsAndEmails.push({ id: airspaceManager.id, email: newEmail });
                        }
                    }
                    setUserIdsAndEmails(userIdsAndEmails);
                })
                .catch((err) => handleFailedFetch(err));
        };

        await fetchUserIdsAndEmails();
        return userIdsAndEmails;
    }

    const value = {
        socket,
        setSocket,
        setMessages,
        sendMessage,
        totalUnreadMessages,
        conversations,
        visibleConversations,
        newConversation,
        createConversation,
        selectedConversation,
        selectedConversationUUID,
        setSelectedConversationUUID,
        userIdsAndEmails,
        getUserIdsAndEmails,
        chatSoundOn,
        setChatSoundOn,
        planningOperations,
        publishedOperations,
        constraints,
        alertVolumes,
        assets
    };

    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}
