import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import io from "socket.io-client";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import Collapse from "@mui/material/Collapse";

import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import BannerAlertList from "./bannerAlerts";
import InfoDialog from "./infoDialog";
import AlertDialog from "./alertDialog";
import { LeftNavigation } from "./leftNavigation";

import { useUserAuth } from "./contexts/authContext";
import { useMap } from "./contexts/mapContext";
import { useEnv } from "./contexts/envContext";

const drawerWidth = 240;

export function App() {
    const { fetchEnvVariables } = useEnv();
    const { fetchMapData } = useMap();
    const {
        user,
        socket,
        setSocket,
        logout,
        setUser,
        snackbar,
        setSnackbar,
        userMapSettings,
        userAirspaceSettings,
        userOperationalStates,
        unreadNotifications,
        handleFailedFetch,
        setStatusMessage,
        getNotifications,
        getMapSettings
    } = useUserAuth();

    const [navigationOpen, setNavigationOpen] = useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    const [bannerOpen, setBannerOpen] = useState(false);
    const [bannerAlerts, setBannerAlerts] = useState([]);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    const socketRef = useRef(null);
    const notificationTimerRef = useRef(null);

    const navigateTo = useNavigate();
    const handleCloseSnackbar = () => setSnackbar(null);
    const isDesktop = useMediaQuery("(min-width:900px)");

    useEffect(() => {
        fetchEnvVariables();
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("api/user/get/session", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => handleFetchedData(data))
            .catch((err) => handleFailedFetch(err));

        // disconnect the socket when the user closes the tab or window
        document.addEventListener("beforeunload", handleDisconnect);

        return () => {
            clearInterval(notificationTimerRef.current);
            handleDisconnect();
            document.removeEventListener("beforeunload", handleDisconnect);
        };
    }, []);

    const handleDisconnect = () => {
        if (socketRef.current !== null) {
            socketRef.current.disconnect();
            socketRef.current = null;
            setSocket(null);
        }
    };
    const handleFetchedData = (data) => {
        const auth = data.auth;
        const returnedUser = data.user;
        const message = data.message;

        if (!auth) {
            setStatusMessage(message);
            return navigateTo("/login");
        }

        const newSocket = io.connect();
        newSocket.on("connect", () => {
            newSocket.emit("storeUserChatSocket", returnedUser);
            newSocket.emit("getUiAlerts", returnedUser);

            newSocket.on("ui_alerts", (message) => {
                const sorted_alerts = message.sort((a, b) => {
                    return new Date(b.alert_time).getTime() - new Date(a.alert_time).getTime();
                });
                setBannerAlerts((prev) => {
                    if (prev && prev.length > 0 && sorted_alerts && sorted_alerts.length > 0) {
                        const prev_most_recent_time = new Date(prev[0].alert_time).getTime();
                        const new_most_recent_time = new Date(sorted_alerts[0].alert_time).getTime();

                        // only open the banner in the event that an alert is added or updated (most recent alert has a different timestamp and there are the same or more amount of alerts)
                        if (prev_most_recent_time !== new_most_recent_time && sorted_alerts.length >= prev.length) {
                            if (sorted_alerts.length > 0 && sorted_alerts[0].display_type === "BANNER") {
                                setBannerOpen(true);
                            }
                        }
                    } else if (sorted_alerts.length > 0 && sorted_alerts[0].display_type === "BANNER") {
                        setBannerOpen(true);
                    }
                    return sorted_alerts;
                });
            });
            socketRef.current = newSocket;
            setSocket(newSocket);
        });

        setUser(returnedUser);
        getMapSettings(returnedUser, fetchMapData);
        getNotifications(returnedUser);

        notificationTimerRef.current = setInterval(() => {
            getNotifications(returnedUser);
        }, 30000);
    };
    const handleLogoutClick = () => {
        logout();
    };
    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            {!user || !userMapSettings || !userOperationalStates || !userAirspaceSettings || !socket ? (
                <Backdrop open>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                    <AppBar
                        position="relative"
                        sx={{
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            transition: (theme) =>
                                theme.transitions.create(["width", "margin"], {
                                    easing: theme.transitions.easing.sharp,
                                    duration: theme.transitions.duration.leavingScreen
                                }),
                            ...(navigationOpen && {
                                ml: { md: `${drawerWidth}px` },
                                width: { md: `calc(100% - ${drawerWidth}px)` },
                                transition: (theme) =>
                                    theme.transitions.create(["width", "margin"], {
                                        easing: theme.transitions.easing.sharp,
                                        duration: theme.transitions.duration.enteringScreen
                                    })
                            })
                        }}
                    >
                        <Toolbar sx={{ px: 2, position: "relative" }}>
                            <IconButton color="inherit" onClick={() => setNavigationOpen((prev) => !prev)} sx={{ mr: 2 }}>
                                <MenuIcon />
                            </IconButton>

                            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                CMP UI Dashboard
                            </Typography>

                            {[
                                {
                                    tooltip: "System Information",
                                    icon: <InfoIcon />,
                                    badgeColor: "secondary",
                                    badgeContent: undefined,
                                    onClick: () => setInfoDialogOpen(true)
                                },
                                {
                                    tooltip: "System Alerts",
                                    icon: <WarningAmberIcon />,
                                    badgeColor: "error",
                                    badgeContent: bannerAlerts.length,
                                    onClick: () => setBannerOpen((prev) => !prev)
                                },
                                {
                                    tooltip: "View Notifications",
                                    icon: <NotificationsIcon />,
                                    badgeColor: "error",
                                    badgeContent: unreadNotifications.length,
                                    onClick: () => navigateTo("/notifications")
                                },
                                {
                                    tooltip: "View Profile",
                                    icon: <AccountCircleIcon />,
                                    badgeColor: "secondary",
                                    badgeContent: undefined,
                                    onClick: () => navigateTo("/account")
                                },
                                {
                                    tooltip: "Logout",
                                    icon: <LogoutIcon />,
                                    badgeColor: "secondary",
                                    badgeContent: undefined,
                                    onClick: () => handleLogoutClick()
                                }
                            ].map(({ tooltip, icon, badgeColor, badgeContent, onClick }, i) => (
                                <Tooltip key={i} title={tooltip}>
                                    <IconButton key={i} color="inherit" onClick={onClick}>
                                        <Badge color={badgeColor} badgeContent={badgeContent}>
                                            {icon}
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            ))}
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                        {isDesktop ? (
                            <Collapse in={navigationOpen} collapsedSize={72} orientation="horizontal">
                                <LeftNavigation open={navigationOpen} setOpen={setNavigationOpen} />
                            </Collapse>
                        ) : (
                            <Drawer open={navigationOpen} variant="temporary" onClose={() => setNavigationOpen((prev) => !prev)}>
                                <LeftNavigation open={navigationOpen} setOpen={setNavigationOpen} />
                            </Drawer>
                        )}
                        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, minWidth: 0, borderLeft: "1px solid rgba(255, 255, 255, 0.12)" }}>
                            <BannerAlertList
                                bannerAlerts={bannerAlerts}
                                bannerOpen={bannerOpen}
                                setBannerOpen={setBannerOpen}
                                navigationOpen={navigationOpen}
                                setAlertDialogOpen={setAlertDialogOpen}
                            />
                            <Box sx={{ position: "relative", flex: 1, backgroundColor: (theme) => theme.palette.grey[900] }}>
                                <Box sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, overflow: "auto" }}>
                                    <Outlet />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {alertDialogOpen === true && bannerAlerts.length > 0 ? (
                        <AlertDialog bannerAlerts={bannerAlerts} alertDialogOpen={alertDialogOpen} setAlertDialogOpen={setAlertDialogOpen} />
                    ) : (
                        <></>
                    )}

                    {infoDialogOpen === true ? <InfoDialog infoDialogOpen={infoDialogOpen} setInfoDialogOpen={setInfoDialogOpen} /> : <></>}

                    {!!snackbar && (
                        <Snackbar
                            open
                            onClose={handleCloseSnackbar}
                            autoHideDuration={6000}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            id="snackbar"
                        >
                            <Alert {...snackbar} onClose={handleCloseSnackbar} />
                        </Snackbar>
                    )}
                </Box>
            )}
        </Box>
    );
}
