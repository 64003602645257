import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { DataLoggingStatus } from "./dataLoggingStatus";
import { DataLoggingOTDQuery } from "./dataLoggingOTDQuery";
import { DataLoggingDAAQuery } from "./dataLoggingDAAQuery";
import { DataLoggingVolumeQuery } from "./dataLoggingVolumeQuery";

export function DataLoggingDashboard() {
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", p: 3, width: "100%" }}>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column" }}>
                <Box sx={{ width: "100%" }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs onChange={handleChange} variant="scrollable" value={value} scrollButtons allowScrollButtonsMobile>
                                <Tab label="Database Status" value="1" />
                                <Tab label="OTD Query" value="2" />
                                <Tab label="DAA Query" value="3" />
                                <Tab label="Alert Volume Query" value="4" />
                            </Tabs>
                        </Box>
                        <TabPanel value="1">
                            <DataLoggingStatus />
                        </TabPanel>
                        <TabPanel value="2">
                            <DataLoggingOTDQuery />
                        </TabPanel>
                        <TabPanel value="3">
                            <DataLoggingDAAQuery />
                        </TabPanel>
                        <TabPanel value="4">
                            <DataLoggingVolumeQuery />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
        </Grid>
    );
}
