import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import HeightIcon from "@mui/icons-material/Height";
import BadgeIcon from "@mui/icons-material/Badge";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SecurityIcon from "@mui/icons-material/Security";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ConvertCoordinatesToVertices, ConvertFeetToMeters, GetTimezone, IsInvalidAltitude } from "../../util";
import { DateHasAlreadyPassed, ConvertMetersToFeet, getFilteredVolumesFromUserInputs } from "../../util";
import { canEditFlight, canPublishByState, canSubmitToLAANC, getMaxAltitudeFromWaypointsAndVerticalBuffer, isFlightBlocked } from "./opUtil";
import { updateSafetyJustification, validateFlightTimes } from "./opUtil";
import { IsInvalidPhoneNumber, FormatPhoneNumber } from "../../users/userUtil";

import { v4 as uuid_v4 } from "uuid";

import { LAANCWorkflowDetails } from "./laancWorkflowDetails";
import { BypassDetails } from "./bypassDetails";
import { useMap } from "../../contexts/mapContext";
import { useUserAuth } from "../../contexts/authContext";
import { useEnv } from "../../contexts/envContext";

import * as CONSTANT from "./opConstants";
import { MapDrawComponent } from "../../map/mapDrawComponent";

const SubmissionMapping = {
    PLANNING: "Submitting...",
    LAANC: "LAANC..."
};

const FlightCreator = (props) => {
    const [drawType, setDrawType] = useState("Polygon");
    const [canEdit, setCanEdit] = useState(true);
    const [currentEditVolumes, setCurrentEditVolumes] = useState(null);

    const [flightGeojson, setFlightGeojson] = useState(null);
    const [flightUUID, setFlightUUID] = useState(props.flightUUID);

    const [flightWaypoints, setFlightWaypoints] = useState([]);
    const [flightWaypointsCollapsed, setFlightWaypointsCollapsed] = useState(true);
    const [defaultWaypointAltitudeAglFt, setDefaultWaypointAltitudeAglFt] = useState(200);

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [priority, setPriority] = useState(0);

    const [vehicleUUID, setVehicleUUID] = useState("");
    const [vehicleError, setVehicleError] = useState("");

    const [legalRuleset, setLegalRuleset] = useState("PART_107");
    const [los, setLos] = useState("LOS");

    const [horizontalBuffer, setHorizontalBuffer] = useState(100);
    const [horizontalBufferError, setHorizontalBufferError] = useState("");

    const [verticalBuffer, setVerticalBuffer] = useState(50);
    const [verticalBufferError, setVerticalBufferError] = useState("");
    const [geofenceType, setGeofenceType] = useState("POLYGON");

    const [radius, setRadius] = useState(0);
    const [radiusError, setRadiusError] = useState("");

    const [minAltitudeFt, setMinAltitudeFt] = useState(0);
    const [maxAltitudeFt, setMaxAltitudeFt] = useState(400);
    const [altitudeError, setAltitudeError] = useState("");

    const [startDate, setStartDate] = useState(dayjs().add(15, "minutes").set("seconds", 0).set("milliseconds", 0));
    const [endDate, setEndDate] = useState(dayjs().add(75, "minutes").set("seconds", 0).set("milliseconds", 0));

    const [dateError, setDateError] = useState("");

    const [durationMin, setDurationMin] = useState("60");
    const [durationError, setDurationError] = useState("");

    const [pilotPhoneNumber, setPilotPhoneNumber] = useState("");
    const [pilotPhoneNumberError, setPilotPhoneNumberError] = useState("");

    const name_input_ref = useRef(null);
    const vehicle_input_ref = useRef(null);
    const altitude_input_ref = useRef(null);
    const horz_buffer_input_ref = useRef(null);
    const vert_buffer_input_ref = useRef(null);
    const radius_input_ref = useRef(null);
    const date_input_ref = useRef(null);
    const duration_input_ref = useRef(null);
    const phone_number_input_ref = useRef(null);

    const [hasPopulatedFields, setHasPopulatedFields] = useState(false);
    const [cmpFlight, setCmpFlight] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(SubmissionMapping.PLANNING);

    const [laancVolumes, setLAANCVolumes] = useState([]);
    const [selectedLaancVolume, setSelectedLaancVolume] = useState(null);
    const [clearFeature, setClearFeature] = useState(null);
    const [bypassBlock, setBypassBlock] = useState(false);
    const [bypassReason, setBypassReason] = useState(null);

    const [safetyJustification, setSafetyJustification] = useState("");
    const [mobileFlightDrawerOpen, setMobileFlightDrawerOpen] = useState(false);

    const { planningOperations, publishedOperations, constraints, visibleDrones } = useMap();
    const { user, socket, setSnackbar, handleFailedFetch, updateInfo } = useUserAuth();
    const { laancOn } = useEnv();

    const [filteredConstraints, setFilteredConstraints] = useState([]);
    const [filteredPublishedFlights, setFilteredPublishedFlights] = useState([]);

    const isDesktop = useMediaQuery("(min-width:900px)");

    useEffect(() => {
        if (user) {
            setPilotPhoneNumber(user.phone_number || "");
        }
        if (visibleDrones.length > 0) {
            const first_vehicle_uuid = visibleDrones[0].vehicle_uuid;
            setVehicleUUID(first_vehicle_uuid);
        }
    }, []);

    useEffect(() => {
        const other_flights = publishedOperations.filter((flight) => {
            return flight.flight_uuid !== flightUUID;
        });
        const filtered_flights = getFilteredVolumesFromUserInputs(other_flights, startDate, durationMin);
        setFilteredPublishedFlights(filtered_flights);

        const filtered_constraints = getFilteredVolumesFromUserInputs(constraints, startDate, durationMin);
        setFilteredConstraints(filtered_constraints);
    }, [startDate, durationMin, publishedOperations, constraints]);

    useEffect(() => {
        const flight = publishedOperations.find((flight) => flight.flight_uuid === flightUUID);
        if (!flight || JSON.stringify(flight) === JSON.stringify(cmpFlight)) {
            return;
        }

        setCmpFlight(flight);

        if (hasPopulatedFields === false) {
            populateExistingFields(flight);
        } else {
            setHasPopulatedFields(true);
        }
    }, [publishedOperations]);

    useEffect(() => {
        const flight = planningOperations.find((flight) => flight.flight_uuid === flightUUID);
        if (!flight || JSON.stringify(flight) === JSON.stringify(cmpFlight)) {
            return;
        }

        setCmpFlight(flight);

        if (hasPopulatedFields === false) {
            populateExistingFields(flight);
        } else {
            setHasPopulatedFields(true);
        }

        // We only want to run events if the relevant data has changed.
        const hasStateChanged = flight.state !== cmpFlight.state;
        const hasLaancChanged = flight.laanc && JSON.stringify(flight.laanc) !== JSON.stringify(cmpFlight.laanc);

        if (laancOn === "false") {
            if (!hasStateChanged) {
                return;
            }
            switch (flight.state) {
                case CONSTANT.DECONFLICTION_CLEAR_STATUS:
                    setSubmitting(true);
                    publishFlight({ ...flight, state: CONSTANT.ACCEPTED_STATUS, version: 1 });
                    break;
                case CONSTANT.DECONFLICTION_BLOCKED_STATUS:
                    setSnackbar({
                        children: "Your Flight currently conflicts with another published Flight or Constraint, please update your flight to continue.",
                        severity: "error"
                    });
                    setSubmissionStatus(SubmissionMapping.PLANNING);
            }
        } else if (hasLaancChanged) {
            const newLaancVolumes = flight.laanc.volumes.map((volume, i) => {
                return { ...volume, name: `Volume ${i + 1}`, rowIndex: i + 1 };
            });
            setLAANCVolumes(newLaancVolumes);
            setSubmissionStatus(SubmissionMapping.LAANC);
            setCanEdit(false);
            setCurrentEditVolumes(null);
        } else if (flight.state == CONSTANT.LAANC_BLOCKED_STATUS && hasStateChanged) {
            setSubmissionStatus(SubmissionMapping.LAANC);
            setCanEdit(false);
        }
    }, [planningOperations]);

    // listen for edits to the operation
    useEffect(() => {
        socket.on("op_edit", (message) => {
            const flight = message;
            if (flight.flight_uuid !== flightUUID) return;

            if (flight.state === CONSTANT.DECONFLICTION_BLOCKED_STATUS) {
                if (laancOn === "true") {
                    flight.state = props.flightState;
                    publishFlight(flight);
                    return;
                } else {
                    alert("Your updated flight is conflicted, please update before proceeding.");
                    return;
                }
            } else if (flight.state === CONSTANT.LAANC_REQUIRED_STATUS) {
                return alert("This operation requires FAA Approval. If you wish to proceed, delete it and create another or modify your operational volume.");
            } else if (flight.state === CONSTANT.PRECHECK_BLOCKED_STATUS) {
                return alert("Your updated flight is blocked, please update before proceeding.");
            } else if (flight.state === CONSTANT.LAANC_NOT_REQUIRED_STATUS) {
                flight.state = props.flightState;
                publishFlight(flight);
            } else if (laancOn === "false" && flight.state === CONSTANT.DECONFLICTION_CLEAR_STATUS) {
                flight.state = props.flightState;
                publishFlight(flight);
            } else if (flight.state !== CONSTANT.DECONFLICTION_CHECK_STATUS && flight.state !== CONSTANT.DECONFLICTION_CLEAR_STATUS) {
                return alert("Unable to update this operation.");
            }
        });
        return () => {
            socket.off("op_edit");
        };
    }, []);

    const populateExistingFields = (flight) => {
        const startDate = dayjs(flight.time_start);
        const endDate = dayjs(flight.time_end);
        const flightDurationMin = endDate.diff(startDate, "minute");

        setName(flight.name);
        setStartDate(dayjs(flight.time_start));
        setDurationMin(flightDurationMin);
        setEndDate(dayjs(flight.time_start) + flightDurationMin * 60 * 1000);
        setPriority(flight.priority);
        setPilotPhoneNumber(flight.pilot_phone_number);
        setVehicleUUID(flight.vehicle_uuid);
        setLos(flight.bvlos ? "BVLOS" : "LOS");
        setLegalRuleset(flight.part_107 ? "PART_107" : "SECTION_44809");
        setFlightWaypoints(flight.waypoints);

        flight.volumes = flight.volumes.filter((v) => !v.off_nominal);

        flight.volumes.forEach((volume) => {
            if (volume.circle) {
                const radius = ConvertMetersToFeet(volume.circle.radius_m);
                setRadius(radius);
                setDrawType("Circle");
            } else if (volume.polygon) {
                setDrawType("Polygon");
            }
            setMinAltitudeFt(ConvertMetersToFeet(volume.altitude_min_agl_m));
            setMaxAltitudeFt(ConvertMetersToFeet(volume.altitude_max_agl_m));
        });

        setCurrentEditVolumes(flight);
    };

    const handlePilotPhoneNumberChange = (e) => {
        let input = FormatPhoneNumber(e.target.value);
        setPilotPhoneNumber(input);
    };

    const handleDurationFieldChange = (e) => {
        if (e.target.value === "" || /^[0-9]+$/.test(e.target.value)) {
            const newEndDate = startDate + e.target.value * 60 * 1000;
            setDurationMin(e.target.value);
            setEndDate(newEndDate);
        }
    };

    const handleRadiusChange = (e) => {
        const new_radius_value = e.target.value;
        setRadius(new_radius_value);
    };

    const handleHorizontalBufferChange = (e) => {
        const new_buffer_value = e.target.value;
        setHorizontalBuffer(new_buffer_value);
    };

    const handleVerticalBufferChange = (e) => {
        const new_buffer_value = e.target.value;
        setVerticalBuffer(new_buffer_value);
    };

    const checkInputFieldsAreValid = () => {
        let waypoints_includes_alt_below_zero = false;
        if (Array.isArray(flightWaypoints) && flightWaypoints.length > 0) {
            const alt_below_zero_found = flightWaypoints.find(({ alt_agl_ft }) => {
                return alt_agl_ft < 1;
            });
            if (alt_below_zero_found) {
                waypoints_includes_alt_below_zero = true;
            }
        }
        const prechecks = [
            {
                passed: flightGeojson && flightGeojson.length,
                prevErrorMessage: "",
                newErrorMessage: "",
                setErrorMessage: () => setSnackbar({ children: "Please create a valid flight volume to continue.", severity: "error" })
            },
            {
                passed: name && name.trim(),
                prevErrorMessage: nameError,
                newErrorMessage: "Please enter a flight name.",
                setErrorMessage: (value) => setNameError(value),
                ref: name_input_ref.current
            },
            {
                passed: vehicleUUID,
                prevErrorMessage: vehicleError,
                newErrorMessage: "Please select a vehicle for your flight.",
                setErrorMessage: (value) => setVehicleError(value),
                ref: vehicle_input_ref.current
            },
            {
                passed: drawType === "Waypoints" ? horizontalBuffer && horizontalBuffer > 0 : true,
                prevErrorMessage: horizontalBufferError,
                newErrorMessage: "Please enter a buffer value greater than 0.",
                setErrorMessage: (value) => setHorizontalBufferError(value),
                ref: horz_buffer_input_ref.current
            },
            {
                passed: drawType === "Waypoints" ? verticalBuffer && verticalBuffer > 0 : true,
                prevErrorMessage: verticalBufferError,
                newErrorMessage: "Please enter a buffer value greater than 0.",
                setErrorMessage: (value) => setVerticalBufferError(value),
                ref: vert_buffer_input_ref.current
            },
            {
                passed: waypoints_includes_alt_below_zero ? false : true,
                prevErrorMessage: "",
                newErrorMessage: "Please ensure all waypoint altitudes are greater than 0ft.",
                setErrorMessage: (value) => setSnackbar({ children: value, severity: "error" })
            },
            {
                passed: drawType === "Circle" ? radius && radius > 0 : true,
                prevErrorMessage: radiusError,
                newErrorMessage: "Please enter a radius value greater than 0.",
                setErrorMessage: (value) => setRadiusError(value),
                ref: radius_input_ref.current
            },
            {
                passed: drawType !== "Waypoints" ? IsInvalidAltitude(minAltitudeFt, maxAltitudeFt) === false : true,
                prevErrorMessage: altitudeError,
                newErrorMessage: "Invalid altitude.",
                setErrorMessage: (value) => setAltitudeError(value),
                ref: altitude_input_ref.current
            },
            {
                passed: startDate && startDate.isValid(),
                prevErrorMessage: dateError,
                newErrorMessage: "Please enter a valid start date and duration.",
                setErrorMessage: (value) => setDateError(value),
                ref: date_input_ref.current
            },
            {
                passed: props.publishedFlight == false ? !DateHasAlreadyPassed(startDate) : true,
                prevErrorMessage: dateError,
                newErrorMessage: "Start Date cannot be in the past.",
                setErrorMessage: (value) => setDateError(value),
                ref: date_input_ref.current
            },
            {
                passed: parseFloat(durationMin) > 1,
                prevErrorMessage: durationError,
                newErrorMessage: "Flight duration must be greater than 1 minute.",
                setErrorMessage: (value) => setDurationError(value),
                ref: duration_input_ref.current
            },
            {
                passed: parseFloat(durationMin) <= 720,
                prevErrorMessage: durationError,
                newErrorMessage: "Flight duration must be shorter than 12 hours.",
                setErrorMessage: (value) => setDurationError(value),
                ref: duration_input_ref.current
            },
            {
                passed: IsInvalidPhoneNumber(pilotPhoneNumber) === false,
                prevErrorMessage: pilotPhoneNumberError,
                newErrorMessage: "Please enter a valid phone number.",
                setErrorMessage: (value) => setPilotPhoneNumberError(value),
                ref: phone_number_input_ref.current
            }
        ];
        for (let i = 0; i < prechecks.length; i++) {
            const precheck = prechecks[i];
            if (precheck.passed && precheck.prevErrorMessage) {
                precheck.setErrorMessage("");
            } else if (!precheck.passed) {
                precheck.setErrorMessage(precheck.newErrorMessage);
                setSubmitting(false);
                setSubmissionStatus(SubmissionMapping.PLANNING);

                if (precheck.ref) {
                    precheck.ref.scrollIntoView({ behavior: "smooth", block: "center" });
                }
                return false;
            }
        }
        return true;
    };

    const handleSubmitFlightForPlanning = () => {
        const fields_are_valid = checkInputFieldsAreValid();
        if (!fields_are_valid) return;

        if (props.publishedFlight) {
            handleSubmitEditPublished();
        } else {
            let pilotUUID = user.pilot_uuid;
            if (!pilotUUID) {
                pilotUUID = uuid_v4();
                const updatedUser = { ...user, pilot_uuid: pilotUUID };
                updateInfo(updatedUser);
                socket.emit("storeUserChatSocket", updatedUser);
            }
            if (!user.phone_number) {
                updateInfo({ ...user, phone_number: pilotPhoneNumber });
            }
            let altitude_min_agl_m = 0;
            let altitude_max_agl_m = 0;

            if (flightWaypoints.length > 0) {
                const altitude_max_agl_ft = getMaxAltitudeFromWaypointsAndVerticalBuffer(flightWaypoints, verticalBuffer);
                altitude_max_agl_m = ConvertFeetToMeters(altitude_max_agl_ft);
            } else {
                const altitude_min_agl_ft = parseFloat(minAltitudeFt);
                altitude_min_agl_m = ConvertFeetToMeters(altitude_min_agl_ft);

                const altitude_max_agl_ft = parseFloat(maxAltitudeFt);
                altitude_max_agl_m = ConvertFeetToMeters(altitude_max_agl_ft);
            }

            const endDate = startDate.add(durationMin, "minutes");
            const radius_m = ConvertFeetToMeters(radius);

            const flight_volumes = flightGeojson.map((coords) => ({
                flight_uuid: flightUUID,
                name: name,
                vertices: ConvertCoordinatesToVertices(coords),
                type: drawType,
                organization_id: user.organization_id,
                altitude_min_agl_m: altitude_min_agl_m,
                altitude_max_agl_m: altitude_max_agl_m,
                radius: radius_m,
                time_start: new Date(startDate).toISOString(),
                time_end: new Date(endDate).toISOString(),
                created_user_id: user.id,
                priority: priority,
                pilot_uuid: pilotUUID,
                pilot_email: user.email,
                pilot_name: user.first_name + " " + user.last_name,
                pilot_phone_number: pilotPhoneNumber,
                vehicle_uuid: vehicleUUID,
                bvlos: los === "BVLOS",
                part_107: legalRuleset === "PART_107",
                state: CONSTANT.DECONFLICTION_CHECK_STATUS,
                waypoints: flightWaypoints
            }));
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(flight_volumes)
            };

            fetch("/api/op/planning", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((flight) => {
                    setCmpFlight(flight);
                    setSubmitting(false);
                    setFlightUUID(flight.flight_uuid);
                })
                .catch((err) => handleFailedFetch(err));
        }
    };

    const handleSubmitEditPublished = () => {
        const altitude_min_agl_m = ConvertFeetToMeters(parseFloat(minAltitudeFt));
        const altitude_max_agl_m = ConvertFeetToMeters(parseFloat(maxAltitudeFt));
        const radius_m = ConvertFeetToMeters(radius);

        const flight = { ...cmpFlight };
        flight.time_start = new Date(startDate).toISOString();
        flight.time_end = new Date(endDate).toISOString();
        flight.name = name;
        flight.priority = priority;
        flight.vehicle_uuid = vehicleUUID;
        flight.state = CONSTANT.DECONFLICTION_CHECK_STATUS;
        flight.waypoints = flightWaypoints;
        flight.part_107 = legalRuleset === "PART_107";
        flight.bvlos = los === "BVLOS";
        flight.version = parseInt(flight.version) + 1;
        flight.updated_user_id = user.id;
        flight.pilot_phone_number = pilotPhoneNumber;

        const newFlightVolumes = [];
        flightGeojson.forEach((coords) => {
            const vertices = ConvertCoordinatesToVertices(coords);
            const newFlightVolume = {
                time_start: new Date(startDate).toISOString(),
                time_end: new Date(endDate).toISOString(),
                altitude_min_agl_m: altitude_min_agl_m,
                altitude_max_agl_m: altitude_max_agl_m
            };
            if (drawType === "Circle") {
                newFlightVolume.circle = {
                    center: {
                        lng: vertices[0].lng,
                        lat: vertices[0].lat
                    },
                    radius_m: radius_m
                };
            } else {
                newFlightVolume.polygon = vertices;
            }
            newFlightVolumes.push(newFlightVolume);
        });
        flight.volumes = newFlightVolumes;
        setCmpFlight(flight);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(flight)
        };
        fetch("/api/op/edit", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((flight) => {
                setSubmitting(false);
                setCmpFlight(flight);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handlePublishFlight = () => {
        let bypassJSON;
        if (bypassBlock) {
            if (!bypassReason) return setSnackbar({ children: "Please enter bypass reason", severity: "error" });

            bypassJSON = {
                timestamp: new Date().toISOString(),
                explanation: bypassReason
            };
        }

        const flight = { ...cmpFlight };
        flight.state = CONSTANT.ACCEPTED_STATUS;
        flight.version = 1;
        flight.block_override = bypassJSON;

        //update any Further Coordination volumes with the optional Safety Justification
        updateSafetyJustification(flight, safetyJustification);
        publishFlight(flight);
    };

    const publishFlight = (flight) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify([flight])
        };
        fetch("/api/op/publish", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                setSubmitting(false);
                setSubmissionStatus(SubmissionMapping.COMPLETE);
                props.setFlightCreatorOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handleSubmitToLAANC = () => {
        if (!validateFlightTimes(startDate, endDate, false, setSnackbar)) {
            return;
        }

        const operation = { ...cmpFlight };
        operation.state = CONSTANT.FAA_APPROVAL_CHECK_STATUS;

        //update any Further Coordination volumes with the optional Safety Justification
        updateSafetyJustification(operation, safetyJustification);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };
        fetch("/api/op/deconflict", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handlePriorityChange = (e) => {
        const new_priority = e.target.value;
        setPriority(new_priority);
    };

    const handleMinAltitudeChange = (e) => {
        const new_min_alt_ft = e.target.value;
        setMinAltitudeFt(new_min_alt_ft);
    };

    const handleMaxAltitudeChange = (e) => {
        const new_max_alt_ft = e.target.value;
        setMaxAltitudeFt(new_max_alt_ft);
    };

    const handleStartDateChange = (value) => {
        setStartDate(value);
        setEndDate(value + durationMin * 60 * 1000);
    };

    const handleUpdateVolumeState = (id, state) => {
        const updatedOperation = { ...cmpFlight };

        updatedOperation.laanc.volumes.forEach((volume) => {
            if (volume.id === id) {
                volume.state = state;
            }
        });

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedOperation)
        };

        fetch("/api/op/deconflict", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleLaancVolumeSelected = (volume_id) => {
        setSelectedLaancVolume(volume_id);
    };

    const handleClose = () => {
        props.setFlightCreatorOpen(false);
    };

    const handleReturnToEdit = () => {
        setCanEdit(true);
        setSubmissionStatus(SubmissionMapping.PLANNING);
        setBypassBlock(false);
        setCurrentEditVolumes(cmpFlight);
        setClearFeature(true);

        // to repopulate fields and process as waypoints
        if (drawType === "Waypoints") {
            populateExistingFields(cmpFlight);
        }
    };

    const SubmitToLAANCButton = () => (
        <Button fullWidth onClick={handleSubmitToLAANC} id="submitToLAANC" data-testid="submitToLaancButton" variant="contained">
            Submit to LAANC
        </Button>
    );

    const EditButton = () => (
        <Button fullWidth onClick={handleReturnToEdit} variant="contained" id="edit">
            Edit Flight
        </Button>
    );

    const PublishButton = () => (
        <Button fullWidth onClick={handlePublishFlight} id="publish" data-testid="publishButton" variant="contained">
            Publish
        </Button>
    );

    const ProceedWithoutLAANCButton = () => (
        <Button fullWidth onClick={() => setBypassBlock(true)} id="proceedWithoutLaanc" data-testid="proceedWithoutLaancButton" variant="contained">
            Proceed without LAANC
        </Button>
    );

    const CloseButton = () => (
        <Button fullWidth variant="text" id="close" onClick={handleClose}>
            Close
        </Button>
    );

    const handleRemoveFlightWaypoint = (index) => {
        const updated_waypoints = flightWaypoints.filter((_, i) => {
            return i !== index;
        });
        if (updated_waypoints.length > 1) {
            setFlightWaypoints(updated_waypoints);
        } else {
            setFlightWaypoints([]);
            setDrawType("Clear");
        }
    };
    const handleChangeWaypointAltitude = (e, index) => {
        const updated_waypoints = flightWaypoints.map((waypoint, i) => {
            if (i === index) {
                return { ...waypoint, alt_agl_ft: e.target.value };
            } else {
                return waypoint;
            }
        });
        setFlightWaypoints(updated_waypoints);
    };
    const handleDefaultWaypointAltitudeChange = (e) => {
        const default_altitude_agl_ft = e.target.value;
        setDefaultWaypointAltitudeAglFt(default_altitude_agl_ft);

        const updated_flight_waypoints = flightWaypoints.map((waypoint) => {
            return { ...waypoint, alt_agl_ft: default_altitude_agl_ft };
        });
        setFlightWaypoints(updated_flight_waypoints);
    };
    const handleGeofenceChange = (e) => {
        const geofence_type = e.target.value;
        setGeofenceType(geofence_type);
    };
    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            <Collapse in={isDesktop || mobileFlightDrawerOpen} orientation="horizontal">
                <Box sx={{ p: 2, width: { xs: "100%", md: "375px" }, height: "100%", overflowY: "auto" }}>
                    <Button
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={() => setMobileFlightDrawerOpen(false)}
                        sx={{ display: isDesktop ? "none" : "flex", flexDirection: "row", marginLeft: "auto" }}
                    >
                        Back to map
                    </Button>

                    {submissionStatus === SubmissionMapping.PLANNING ? (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, overflowY: "auto", flexShrink: 0 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <BadgeIcon fontSize="small" />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Flight Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        error={nameError.length > 0}
                                        helperText={nameError}
                                        id="flightName"
                                        ref={name_input_ref}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <PriorityHighIcon fontSize="small" />
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Flight Priority"
                                        value={priority}
                                        onChange={handlePriorityChange}
                                    >
                                        {[
                                            { id: "optionNoPriority", title: "0 - No Priority/Personal/Hobbyist", value: CONSTANT.PERSONAL_TYPE },
                                            { id: "optionTraining", title: "10 - Training", value: CONSTANT.TRAINING_TYPE },
                                            { id: "optionCommercial", title: "20 - Commercial", value: CONSTANT.COMMERCIAL_TYPE },
                                            { id: "optionPublicSafety", title: "30 - Public Safety", value: CONSTANT.PUBLIC_SAFETY_TYPE },
                                            { id: "optionEmergency", title: "40 - Emergency", value: CONSTANT.EMERGENCY_TYPE }
                                        ].map(({ id, title, value }, i) => (
                                            <MenuItem key={i} value={value} id={id}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <AirplanemodeActiveIcon fontSize="small" />
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Flight Vehicle"
                                        value={vehicleUUID}
                                        onChange={(e) => setVehicleUUID(e.target.value)}
                                        error={vehicleError.length > 0}
                                        helperText={vehicleError}
                                        id="selectVehicle"
                                        ref={vehicle_input_ref}
                                    >
                                        {visibleDrones.map((drone, i) => (
                                            <MenuItem key={i} value={drone.vehicle_uuid} id={drone.vehicle_uuid}>
                                                {drone.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <SecurityIcon fontSize="small" sx={{ mt: "18px" }} />
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Ruleset
                                        </Typography>
                                        <ToggleButtonGroup
                                            exclusive
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            value={legalRuleset}
                                            onChange={(e) => setLegalRuleset(e.target.value)}
                                            color="primary"
                                        >
                                            <ToggleButton value="PART_107" id="Part 107" size="small">
                                                PART 107
                                            </ToggleButton>
                                            <ToggleButton value="SECTION_44809" id="Section 44809" size="small">
                                                SECTION 44809
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, mt: "5px" }}>
                                    <RemoveRedEyeIcon fontSize="small" sx={{ mt: "18px" }} />
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Line of Sight
                                        </Typography>
                                        <ToggleButtonGroup
                                            exclusive
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            value={los}
                                            onChange={(e) => setLos(e.target.value)}
                                            color="primary"
                                        >
                                            <ToggleButton value="LOS" size="small">
                                                LOS
                                            </ToggleButton>
                                            <ToggleButton value="BVLOS" size="small">
                                                BVLOS
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="body1">Flight Area</Typography>
                                {drawType === "Waypoints" && (
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, mt: "5px" }}>
                                            <ViewInArIcon fontSize="small" />
                                            <ToggleButtonGroup
                                                exclusive
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                value={geofenceType}
                                                onChange={handleGeofenceChange}
                                                color="primary"
                                            >
                                                <ToggleButton value="POLYGON" size="small">
                                                    POLYGON
                                                </ToggleButton>
                                                <ToggleButton value="CIRCLE" size="small">
                                                    CIRCLE
                                                </ToggleButton>
                                                <ToggleButton value="CORRIDOR" size="small">
                                                    CORRIDOR
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                            <PolylineOutlinedIcon fontSize="small" />
                                            <TextField
                                                type="number"
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                label="Horizontal Buffer"
                                                value={horizontalBuffer}
                                                onChange={handleHorizontalBufferChange}
                                                error={horizontalBufferError.length > 0}
                                                helperText={horizontalBufferError}
                                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                                onWheel={(e) => e.target.blur()}
                                                id="flightBuffer"
                                                ref={horz_buffer_input_ref}
                                            />
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                            <HeightIcon fontSize="small" />
                                            <TextField
                                                type="number"
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                label="Vertical Buffer"
                                                value={verticalBuffer}
                                                onChange={handleVerticalBufferChange}
                                                error={verticalBufferError.length > 0}
                                                helperText={verticalBufferError}
                                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                                onWheel={(e) => e.target.blur()}
                                                ref={vert_buffer_input_ref}
                                            />
                                        </Box>
                                    </Box>
                                )}
                                {drawType === "Circle" && (
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                        <PlaceOutlinedIcon fontSize="small" />
                                        <TextField
                                            type="number"
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            label="Radius"
                                            value={radius}
                                            onChange={handleRadiusChange}
                                            error={radiusError.length > 0}
                                            helperText={radiusError}
                                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                            onWheel={(e) => e.target.blur()}
                                            id="flightRadius"
                                            ref={radius_input_ref}
                                        />
                                    </Box>
                                )}
                                {drawType !== "Waypoints" && (
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }} ref={radius_input_ref}>
                                        <HeightIcon fontSize="small" />
                                        <TextField
                                            type="number"
                                            size="small"
                                            margin="dense"
                                            label="Min Alt (AGL)"
                                            value={minAltitudeFt}
                                            onChange={handleMinAltitudeChange}
                                            error={altitudeError.length > 0}
                                            helperText={altitudeError}
                                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                            onWheel={(e) => e.target.blur()}
                                            id="minAltitude"
                                        />
                                        <TextField
                                            type="number"
                                            size="small"
                                            margin="dense"
                                            label="Max Alt (AGL)"
                                            value={maxAltitudeFt}
                                            onChange={handleMaxAltitudeChange}
                                            error={altitudeError.length > 0}
                                            helperText={altitudeError}
                                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                            onWheel={(e) => e.target.blur()}
                                            id="maxAltitude"
                                        />
                                    </Box>
                                )}
                            </Box>

                            {Array.isArray(flightWaypoints) && flightWaypoints.length > 0 ? (
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                    <Typography variant="body1">Waypoints</Typography>

                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <FlightTakeoffIcon fontSize="small" />

                                        <TextField
                                            size="small"
                                            value={defaultWaypointAltitudeAglFt}
                                            onChange={handleDefaultWaypointAltitudeChange}
                                            label="Default Altitude (AGL)"
                                            margin="dense"
                                            fullWidth
                                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                            id={"defaultWaypointAltitude"}
                                        />
                                    </Box>

                                    <Collapse in={!flightWaypointsCollapsed} collapsedSize={60}>
                                        <List dense sx={{ p: 0 }}>
                                            {flightWaypoints.map(({ lat, lng, alt_agl_ft }, i) => (
                                                <ListItem key={i} dense sx={{ px: 0 }}>
                                                    <ListItemIcon sx={{ minWidth: "44px" }}>
                                                        <Tooltip title="Remove Waypoint" placement="right">
                                                            <IconButton onClick={() => handleRemoveFlightWaypoint(i)} size="small">
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={`Waypoint ${i + 1}`} secondary={`${lat.toFixed(4)}, ${lng.toFixed(4)}`} />

                                                    <TextField
                                                        value={alt_agl_ft}
                                                        onChange={(e) => handleChangeWaypointAltitude(e, i)}
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth={true}
                                                        sx={{ ml: "auto", maxWidth: "120px" }}
                                                        label="Altitude (AGL)"
                                                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                                        id={`waypointAltitude-${i}`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                    {flightWaypointsCollapsed === true ? (
                                        <Button
                                            onClick={() => setFlightWaypointsCollapsed(false)}
                                            size="small"
                                            variant="text"
                                            endIcon={<ExpandMoreIcon fontSize="small" />}
                                            id="showMore"
                                        >
                                            Show more
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => setFlightWaypointsCollapsed(true)}
                                            size="small"
                                            variant="text"
                                            endIcon={<ExpandLessIcon fontSize="small" />}
                                        >
                                            Show less
                                        </Button>
                                    )}
                                </Box>
                            ) : (
                                <></>
                            )}

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="body1">Date & Time</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                        <AccessTimeIcon fontSize="small" />
                                        <DateTimePicker
                                            label={`Start Time (${GetTimezone()}) *`}
                                            value={dayjs(startDate)}
                                            onChange={handleStartDateChange}
                                            slotProps={{
                                                textField: {
                                                    id: "startTime",
                                                    fullWidth: true,
                                                    size: "small",
                                                    margin: "dense",
                                                    error: dateError.length > 0,
                                                    helperText: dateError,
                                                    ref: date_input_ref,
                                                    inputProps: { "data-testid": "startTime" }
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                        <TimelapseIcon fontSize="small" />
                                        <TextField
                                            id="Duration"
                                            label="Duration"
                                            size="small"
                                            margin="dense"
                                            value={durationMin}
                                            onChange={handleDurationFieldChange}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mins</InputAdornment>
                                            }}
                                            error={durationError.length > 0}
                                            helperText={durationError}
                                            ref={duration_input_ref}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="body1">Pilot Details</Typography>

                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <PhoneIcon fontSize="small" />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Pilot Phone Number"
                                        value={pilotPhoneNumber}
                                        onChange={handlePilotPhoneNumberChange}
                                        error={pilotPhoneNumberError.length > 0}
                                        helperText={pilotPhoneNumberError}
                                        ref={phone_number_input_ref}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                {submissionStatus === SubmissionMapping.PLANNING ? (
                                    <LoadingButton
                                        fullWidth
                                        variant="contained"
                                        loading={submitting}
                                        loadingIndicator={submissionStatus}
                                        onClick={handleSubmitFlightForPlanning}
                                        id="Submit"
                                    >
                                        {"Submit Flight"}
                                    </LoadingButton>
                                ) : (
                                    <></>
                                )}
                                <CloseButton />
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }} id="flightDetails">
                            {bypassBlock ? (
                                <BypassDetails setBypassReason={setBypassReason} />
                            ) : (
                                <LAANCWorkflowDetails
                                    state={cmpFlight.state}
                                    advisories={cmpFlight.advisories}
                                    key={cmpFlight.state}
                                    volumes={laancVolumes}
                                    handleUpdateVolume={handleUpdateVolumeState}
                                    handleLaancVolumeSelected={handleLaancVolumeSelected}
                                    setSafetyJustification={setSafetyJustification}
                                />
                            )}

                            {canPublishByState(cmpFlight.state, laancOn) ? <PublishButton /> : null}
                            {canSubmitToLAANC(cmpFlight.state, laancOn) && !bypassBlock ? <SubmitToLAANCButton /> : null}
                            {isFlightBlocked(cmpFlight.state) ? bypassBlock ? <PublishButton /> : <ProceedWithoutLAANCButton /> : null}
                            {canEditFlight(cmpFlight.state) ? <EditButton /> : null}
                            <CloseButton />
                        </Box>
                    )}
                </Box>
            </Collapse>

            <Box sx={{ flex: 1, position: "relative", overflow: "hidden" }}>
                <MapDrawComponent
                    type="Flight"
                    laancVolumes={laancVolumes}
                    publishedFlights={filteredPublishedFlights}
                    constraints={filteredConstraints}
                    currentEditVolumes={currentEditVolumes}
                    drawType={drawType}
                    changeDrawType={setDrawType}
                    canEdit={canEdit}
                    radius={radius}
                    setRadius={setRadius}
                    buffer={horizontalBuffer}
                    setVolumeGeojson={setFlightGeojson}
                    volumeWaypoints={flightWaypoints}
                    setVolumeWaypoints={setFlightWaypoints}
                    selectedLAANCVolume={selectedLaancVolume}
                    setMinAltitude={setMinAltitudeFt}
                    setMaxAltitude={setMaxAltitudeFt}
                    setName={setName}
                    setMobileDrawerOpen={setMobileFlightDrawerOpen}
                    defaultWaypointAltitudeAglFt={defaultWaypointAltitudeAglFt}
                    geofenceType={geofenceType}
                    clearFeature={clearFeature}
                />
                <Fab
                    size="medium"
                    color="primary"
                    onClick={() => setMobileFlightDrawerOpen(true)}
                    sx={{
                        display: isDesktop || mobileFlightDrawerOpen ? "none" : "block",
                        position: "absolute",
                        bottom: 8,
                        left: 8
                    }}
                >
                    <KeyboardArrowRightIcon />
                </Fab>
            </Box>
        </Box>
    );
};

export default FlightCreator;
