import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";

import MapIcon from "@mui/icons-material/Map";
import ClearIcon from "@mui/icons-material/Clear";
import LayersIcon from "@mui/icons-material/Layers";
import ModeOutlined from "@mui/icons-material/ModeOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import Collection from "ol/Collection";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import Translate from "ol/interaction/Translate";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import LineString from "ol/geom/LineString";
import Circle from "ol/geom/Circle";

import OpenLayersMap from "../openlayers/olMap";
import OpenLayersInfoCard from "../openlayers/olInfoCard";
import useOpenLayersMap from "../openlayers/hooks/useMap";
import MapLaancLayersDropdown from "./mapLaancLayersDropdown";
import MapTileDropdown from "./mapTileDropdown";

import { getGeofenceFeatureFromGeometryAndBuffer, GetCoordinatesForSubmissions, ConvertKmlFileToFeatures } from "../openlayers/utils/olUtil";
import { GetLayer, ConvertCmpVolumesToFeatures, ConvertLAANCVolumesToFeatures, GetFeature } from "../openlayers/utils/olUtil";
import { JumpToFeature, JumpToFeatures } from "../openlayers/utils/olUtil";
import { STYLE_VOLUME_EDIT } from "../openlayers/utils/olStyleUtil";

import { useUserAuth } from "../contexts/authContext";

import * as turf from "@turf/turf";

export function MapDrawComponent(props) {
    const [drawType, setDrawType] = useState("Polygon");

    const { userMapSettings } = useUserAuth();

    const [mapLayersOpen, setMapLayersOpen] = useState(false);
    const [mapLayersAnchorEl, setMapLayersAnchorEl] = useState(null);

    const [mapTileDropdownOpen, setMapTileDropdownOpen] = useState(false);
    const [mapTileDropdownAnchorEl, setMapTileDropdownAnchorEl] = useState(null);

    const [selectedLaancFeature, setSelectedLaancFeature] = useState(null);

    const volume_upload_ref = useRef(null);
    const volume_vector_layer_ref = useRef(null);

    const volume_feature_ref = useRef(null); // for all operations
    const volume_geofence_feature_ref = useRef(null); // the feature representing the buffer around waypoint ops
    const volume_geofence_buffer_ref = useRef(100);
    const volume_waypoints_ref = useRef([]); // for the waypoints field in the volume submission

    const draw_interaction_ref = useRef(null);
    const translate_interaction_ref = useRef(null);
    const modify_interaction_ref = useRef(null);
    const snap_interaction_ref = useRef(null);

    const map = useOpenLayersMap(false);

    useEffect(() => {
        if (!map || !props.laancVolumes) return;

        handleResetDrawingData();

        const features = ConvertLAANCVolumesToFeatures(props.laancVolumes);
        const layer = GetLayer(map, "laanc_volumes");
        if (layer) {
            const source = layer.getSource();
            if (source) {
                source.clear();
                source.addFeatures(features);
            }
        } else {
            const vectorLayer = new VectorLayer({
                source: new VectorSource({ features: features }),
                name: "laanc_volumes",
                zIndex: 5,
                properties: { selectable: true }
            });
            map.addLayer(vectorLayer);
        }

        JumpToFeatures(map, features);
    }, [map, props.laancVolumes]);

    useEffect(() => {
        if (!map) return;

        const feature = GetFeature(map, "laanc_volumes", props.selectedLAANCVolume);
        setSelectedLaancFeature(feature);
        JumpToFeature(map, feature);
    }, [map, props.selectedLAANCVolume]);

    useEffect(() => {
        if (!map) return;

        const layer_names = [
            { layer_name: "flights", volumes: props.publishedFlights },
            { layer_name: "constraints", volumes: props.constraints }
        ];
        layer_names.map(({ layer_name, volumes }) => {
            const layer = GetLayer(map, layer_name);
            const features = ConvertCmpVolumesToFeatures(volumes, userMapSettings);
            if (layer) {
                const source = layer.getSource();
                if (source) {
                    source.clear();
                    source.addFeatures(features);
                }
            } else {
                const volumesLayer = new VectorLayer({
                    source: new VectorSource({ features: features }),
                    properties: { name: layer_name }
                });
                map.addLayer(volumesLayer);
            }
        });
    }, [map, props.publishedFlights, props.constraints]);

    useEffect(() => {
        if (!map || !props.currentEditVolumes) return;

        handleResetDrawingData();
        const features = ConvertCmpVolumesToFeatures([props.currentEditVolumes], userMapSettings);
        handleDrawFeatures(features);
    }, [map, props.currentEditVolumes]);

    useEffect(() => {
        if (!map) return;

        if (volume_feature_ref.current && props.radius) {
            const radius_deg = props.radius / 364000;

            const volume_geometry = volume_feature_ref.current.getGeometry();
            if (volume_geometry) {
                volume_geometry.setRadius(radius_deg);

                const geojson = GetCoordinatesForSubmissions(volume_geometry);
                props.setVolumeGeojson(geojson.coordinates);
            }
        }
    }, [map, props.radius]);

    useEffect(() => {
        if (!map) return;

        if (props.canEdit) {
            const layer = GetLayer(map, "laanc_volumes");
            if (layer) map.removeLayer(layer);

            if (!volume_vector_layer_ref.current) handleDrawTypeChange(undefined, "Polygon");
        } else {
            handleDrawOff();
        }
    }, [map, props.canEdit]);

    useEffect(() => {
        if (!map) return;
        volume_geofence_buffer_ref.current = props.buffer;

        if (volume_feature_ref.current && volume_geofence_feature_ref.current && props.buffer) {
            const waypoint_geometry = volume_feature_ref.current.getGeometry();
            const waypoint_coordinates = waypoint_geometry.getCoordinates();

            const linestring = turf.lineString(waypoint_coordinates);
            const buffered = turf.buffer(linestring, props.buffer, { units: "meters" });
            const geofence_coordinates = buffered.geometry.coordinates;

            const geofence_geometry = volume_geofence_feature_ref.current.getGeometry();
            geofence_geometry.setCoordinates(geofence_coordinates);

            const geojson = GetCoordinatesForSubmissions(geofence_geometry);
            props.setVolumeGeojson(geojson.coordinates);
        }
    }, [map, props.buffer]);

    const handleToggleLayersButtonClick = (e) => {
        setMapLayersAnchorEl(e.currentTarget);
        setMapLayersOpen((prev) => !prev);
    };
    const handleDrawTypeChange = (e, value) => {
        handleResetDrawingData();
        if (!value) {
            if (drawType === "Upload") {
                volume_upload_ref.current.click();
            }
            return;
        }
        setDrawType(value);

        if (value === "Upload") {
            volume_upload_ref.current.click();
            return;
        } else if (value === "Clear") {
            return;
        }
        draw_interaction_ref.current = new Draw({
            source: new VectorSource({ wrapX: false }),
            type: value === "Waypoints" ? "LineString" : value
        });
        draw_interaction_ref.current.on("drawend", handleDrawEnd);
        map.addInteraction(draw_interaction_ref.current);
        props.changeDrawType(value);
    };

    const handleDrawEnd = ({ feature }) => {
        if (draw_interaction_ref.current) {
            map.removeInteraction(draw_interaction_ref.current);
            draw_interaction_ref.current = null;
        }
        feature.setStyle(STYLE_VOLUME_EDIT);
        volume_feature_ref.current = feature;

        const editable_features = new Collection([feature]);
        const vector_layer_features = new Collection([feature]);

        // extract data from the drawn feature
        let geometry = feature.getGeometry();
        if (geometry instanceof LineString) {
            const waypoint_coordinates = geometry.getCoordinates();
            volume_waypoints_ref.current = waypoint_coordinates.map(([lng, lat]) => {
                return { lng: lng, lat: lat };
            });
            const geofence_feature = getGeofenceFeatureFromGeometryAndBuffer(geometry, props.buffer);
            vector_layer_features.push(geofence_feature);

            volume_geofence_feature_ref.current = geofence_feature;
            geometry = geofence_feature.getGeometry();
        }
        if (geometry instanceof Circle) {
            const radius_deg = geometry.getRadius();
            const radius_ft = (radius_deg * 364000).toFixed(0);
            props.setRadius(radius_ft);
        }
        JumpToFeature(map, feature);
        props.setMobileFlightDrawerOpen(true);

        const geojson = GetCoordinatesForSubmissions(geometry);
        props.setVolumeGeojson(geojson.coordinates);
        props.setVolumeWaypoints(volume_waypoints_ref.current);

        // add the feature to the map
        volume_vector_layer_ref.current = new VectorLayer({
            source: new VectorSource({ features: vector_layer_features }),
            zIndex: 1
        });
        map.addLayer(volume_vector_layer_ref.current);

        // add the ability to move the feature
        translate_interaction_ref.current = new Translate({
            filter: (f) => f === feature
        });
        map.addInteraction(translate_interaction_ref.current);
        translate_interaction_ref.current.on("translateend", handleGeometryUpdate);

        // add the ability to edit or add vertices
        modify_interaction_ref.current = new Modify({
            features: editable_features,
            deleteCondition: (event) => event.originalEvent.button === 2
        });
        map.addInteraction(modify_interaction_ref.current);
        modify_interaction_ref.current.on("modifyend", handleGeometryUpdate);

        // add vertex snapping
        snap_interaction_ref.current = new Snap({
            features: editable_features
        });
        map.addInteraction(snap_interaction_ref.current);
    };

    const handleGeometryUpdate = ({ features }) => {
        if (features.getLength() > 0) {
            const feature = features.item(0); // this may need to be updated??
            volume_feature_ref.current = feature;

            // if a circle was updated and the radius changed update it
            let geometry = feature.getGeometry();
            if (geometry instanceof Circle) {
                const radius_deg = geometry.getRadius();
                const radius_ft = (radius_deg * 364000).toFixed(0);
                props.setRadius(radius_ft);
            }
            // if a waypoint got updated update the buffer
            if (geometry instanceof LineString && volume_geofence_feature_ref.current && volume_feature_ref.current) {
                const waypoint_geometry = volume_feature_ref.current.getGeometry();
                const waypoint_coordinates = waypoint_geometry.getCoordinates();

                volume_waypoints_ref.current = waypoint_coordinates.map(([lng, lat]) => {
                    return { lng: lng, lat: lat };
                });
                const linestring = turf.lineString(waypoint_coordinates);
                const buffered = turf.buffer(linestring, volume_geofence_buffer_ref.current, { units: "meters" });
                const geofence_coordinates = buffered.geometry.coordinates;

                const geofence_geometry = volume_geofence_feature_ref.current.getGeometry();
                geofence_geometry.setCoordinates(geofence_coordinates);
                geometry = geofence_geometry;
            }
            const geojson = GetCoordinatesForSubmissions(geometry);
            props.setVolumeGeojson(geojson.coordinates);
            props.setVolumeWaypoints(volume_waypoints_ref.current);
        }
    };

    const handleGeometriesUpdate = () => {
        const layer = volume_vector_layer_ref.current;
        const source = layer.getSource();
        const features = source.getFeatures();

        const coords = [];
        features.forEach((feature) => {
            coords.push(GetCoordinatesForSubmissions(feature.getGeometry()).coordinates[0]);
        });

        props.setVolumeGeojson(coords);
        props.setVolumeWaypoints([]);
    };

    const handleResetDrawingData = () => {
        if (volume_vector_layer_ref.current) {
            map.removeLayer(volume_vector_layer_ref.current);
            volume_vector_layer_ref.current = null;
        }
        handleDrawOff();
        volume_feature_ref.current = null;
        volume_geofence_feature_ref.current = null;
        volume_waypoints_ref.current = [];

        props.setVolumeGeojson([]);
        props.setVolumeWaypoints([]);
    };

    const handleDrawOff = () => {
        if (draw_interaction_ref.current) {
            map.removeInteraction(draw_interaction_ref.current);
            draw_interaction_ref.current = null;
        }
        if (modify_interaction_ref.current) {
            map.removeInteraction(modify_interaction_ref.current);
            modify_interaction_ref.current = null;
        }
        if (snap_interaction_ref.current) {
            map.removeInteraction(snap_interaction_ref.current);
            snap_interaction_ref.current = null;
        }
        if (translate_interaction_ref.current) {
            map.removeInteraction(translate_interaction_ref.current);
            translate_interaction_ref.current = null;
        }
    };

    const handleDrawFeatures = (features) => {
        const coords = [];
        features.forEach((feature) => {
            const feature_coord = GetCoordinatesForSubmissions(feature.getGeometry());
            coords.push(feature_coord.coordinates[0]);
        });

        props.setVolumeGeojson(coords);

        // add the feature to the map
        const source = new VectorSource({ features: features });
        volume_vector_layer_ref.current = new VectorLayer({
            source: source,
            zIndex: 1
        });
        map.addLayer(volume_vector_layer_ref.current);
        JumpToFeatures(map, features);

        // add the ability to edit or add vertices
        modify_interaction_ref.current = new Modify({ source: source });
        map.addInteraction(modify_interaction_ref.current);
        modify_interaction_ref.current.on("modifyend", handleGeometriesUpdate);
    };

    const handleUpload = (files) => {
        ConvertKmlFileToFeatures(files[0])
            .then((features) => {
                props.setName(features[0].get("name"));
                props.setMinAltitude(features[0].get("altitudes").minAltitude);
                props.setMaxAltitude(features[0].get("altitudes").maxAltitude);
                handleDrawFeatures(features);
            })
            .catch((error) => {
                props.setSnackbar({ children: error.message, severity: "error" });
            });
    };

    const handleMapTileDropdownButtonClick = (e) => {
        setMapTileDropdownOpen((prev) => !prev);
        setMapTileDropdownAnchorEl(e.currentTarget);
    };

    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            <OpenLayersMap
                map={map}
                buttons={[
                    {
                        name: "Toggle Layers",
                        visible: true,
                        icon: <LayersIcon />,
                        onClick: handleToggleLayersButtonClick
                    },
                    {
                        id: "toggleMapTiles",
                        name: "Map Tiles",
                        visible: true,
                        icon: <MapIcon />,
                        onClick: handleMapTileDropdownButtonClick
                    }
                ]}
            >
                <OpenLayersInfoCard map={map} showButtons={false} featureToShow={selectedLaancFeature} />
            </OpenLayersMap>
            <input
                type="file"
                ref={volume_upload_ref}
                style={{ display: "none" }}
                onChange={(e) => handleUpload(e.target.files)}
                onClick={(e) => (e.target.value = "")}
                id="fileUpload"
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    position: "absolute",
                    left: "15px",
                    top: "15px",
                    zIndex: 100,
                    background: "#121212"
                }}
            >
                {props.canEdit ? (
                    <ToggleButtonGroup fullWidth orientation="vertical" color="primary" value={drawType} onChange={handleDrawTypeChange} exclusive>
                        {[
                            { title: "Upload", icon: <FileUploadOutlinedIcon fontSize="small" /> },
                            { title: "Polygon", icon: <ModeOutlined fontSize="small" /> },
                            { title: "Waypoints", icon: <PolylineOutlinedIcon fontSize="small" /> },
                            { title: "Circle", icon: <PlaceOutlinedIcon fontSize="small" /> },
                            { title: "Clear", icon: <ClearIcon fontSize="small" /> }
                        ].map(({ title, icon }, i) => (
                            <Tooltip key={i} title={title}>
                                <ToggleButton value={title}>{icon}</ToggleButton>
                            </Tooltip>
                        ))}
                    </ToggleButtonGroup>
                ) : (
                    <></>
                )}
            </Box>

            <MapLaancLayersDropdown
                map={map}
                mapLayersOpen={mapLayersOpen}
                setMapLayersOpen={setMapLayersOpen}
                mapLayersAnchorEl={mapLayersAnchorEl}
                setMapLayersAnchorEl={setMapLayersAnchorEl}
            />
            <MapTileDropdown
                mapTileDropdownOpen={mapTileDropdownOpen}
                setMapTileDropdownOpen={setMapTileDropdownOpen}
                mapTileDropdownAnchorEl={mapTileDropdownAnchorEl}
            />
        </Box>
    );
}
