import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const InfoDialog = ({ infoDialogOpen, setInfoDialogOpen }) => {
    const handleDownloadClick = () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/pdf" }
        };
        fetch("./static/files/CMP_UI_User_Manual_v1.1.0.pdf", requestOptions)
            .then((response) => response.blob())
            .then((blob) => downloadUserManual(blob))
            .catch((err) => console.error(err));
    };
    const downloadUserManual = (blob) => {
        const file = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = file;
        link.download = "CMP User Manual.pdf";
        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );
        document.body.removeChild(link);
    };
    const handleOpenFeedbackForm = () => {
        const feedbackUrl = "https://forms.office.com/r/YBkaSna3H6";
        window.open(feedbackUrl, "_blank");
    };
    const handleCloseDialog = () => {
        setInfoDialogOpen(false);
    };
    return (
        <Dialog open={infoDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>{"CMP UI - v1.1.1"}</DialogTitle>
            <DialogContent>
                <DialogContentText>{"Please download our current user manual or submit feedback to help improve to our system."}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} sx={{ mr: "auto" }}>
                    {"Close"}
                </Button>
                <Button onClick={handleDownloadClick} startIcon={<DownloadIcon />}>
                    {"User Manual"}
                </Button>
                <Button onClick={handleOpenFeedbackForm} startIcon={<OpenInNewIcon />}>
                    {"Feedback Form"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;
