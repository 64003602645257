import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuid_v4 } from "uuid";
import alertSound from "../../public/static/files/notify.wav";
import { useUserAuth } from "./authContext";
import { CreateConversation, CreateConversations } from "../chat/chatUtil";

export const ChatContext = createContext();

export function useChat() {
    return useContext(ChatContext);
}

export function ChatProvider({ children }) {
    const [messages, setMessages] = useState([]);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [conversations, setConversations] = useState([]);
    const [visibleConversations, setVisibleConversations] = useState([]);
    const [newConversation, setNewConversation] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [selectedConversationUUID, setSelectedConversationUUID] = useState(null);
    const [userIdsAndEmails, setUserIdsAndEmails] = useState([]);
    const [chatSoundOn, setChatSoundOn] = useState(true);

    const { user, socket, getOrganizationByID, handleFailedFetch } = useUserAuth();

    useEffect(() => {
        if (!socket || !user) {
            return;
        }

        socket.on("receiveMessageFromUser", (message) => {
            setMessages((prev) => [...prev, message]);
            if (chatSoundOn && message.sent_user_id !== user.id) {
                new Audio(alertSound).play();
            }
        });

        socket.on("conversationRead", (conversationUUID) => {
            setMessages((prev) => {
                const messages = [...prev];
                messages.filter((m) => m.conversation_uuid === conversationUUID).forEach((m) => (m.read_message = true));
                return messages;
            });
        });

        return () => {
            if (socket) {
                socket.off("receiveMessageFromUser");
                socket.off("conversationRead");
            }
        };
    }, [socket, user, chatSoundOn]);

    useEffect(() => {
        if (!user || user.user_role === "First Responder") {
            return setMessages([]);
        }

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        const fetchMessages = async () => {
            await fetch("api/messages/get", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setMessages(data))
                .catch((err) => handleFailedFetch(err));
        };

        fetchMessages();
        getUserIdsAndEmails();

        return () => {
            setMessages([]);
        };
    }, [user]);

    useEffect(() => {
        const conversationsResult = CreateConversations(user, messages, newConversation);
        if (conversationsResult) {
            setConversations(conversationsResult.conversations);
            setVisibleConversations(conversationsResult.visibleConversations);
            setTotalUnreadMessages(conversationsResult.totalUnreadMessages);
        }
    }, [user, messages, newConversation]);

    useEffect(() => {
        const convo = conversations.find((convo) => convo.conversation_uuid === selectedConversationUUID);
        if (convo) {
            setSelectedConversation(convo);
        } else {
            setSelectedConversation(null);
        }

        // Indicates you clicked on an existing conversation that's not the current new one.
        // This will remove the previously created conversation if no messages have been sent in it.
        if (newConversation && newConversation.conversation_uuid !== selectedConversationUUID) {
            setNewConversation(null);
        }
    }, [conversations, selectedConversationUUID]);

    function sendMessage(content, setMessageInput = null) {
        if (!selectedConversation || !content) {
            return;
        }

        const message = {
            message_uuid: uuid_v4(),
            conversation_uuid: selectedConversation.conversation_uuid,
            sent_user_id: user.id,
            sent_user_email: user.email,
            content: content,
            date_sent: new Date().toISOString(),
            recipients: selectedConversation.recipients,
            recipient_emails: selectedConversation.recipientEmails
        };
        socket.emit("sendMessageToUser", message);

        // Reset chat input if applicable.
        if (setMessageInput) {
            setMessageInput("");
        }
    }

    function createConversation(recipients, recipientEmails) {
        const conversationResult = CreateConversation(conversations, recipients, recipientEmails);
        if (conversationResult) {
            setSelectedConversationUUID(conversationResult.conversationUUID);
            setNewConversation(conversationResult.newConversation);
        }
    }

    async function getUserIdsAndEmails() {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        const fetchUserIdsAndEmails = async () => {
            await fetch("api/user/get_all_ids_and_emails", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => fetchAirspaceManagerEmails(data))
                .catch((err) => handleFailedFetch(err));
        };
        const fetchAirspaceManagerEmails = async (userIdsAndEmails) => {
            await fetch("api/user/get_airspace_managers", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => {
                    for (const airspaceManager of data) {
                        const newEmail = `${airspaceManager.email} (${getOrganizationByID(airspaceManager.organization_id).name} - Airspace Manager)`;
                        let found = false;

                        for (const userIdAndEmail of userIdsAndEmails) {
                            if (airspaceManager.id === userIdAndEmail.id) {
                                userIdAndEmail.email = newEmail;
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            userIdsAndEmails.push({ id: airspaceManager.id, email: newEmail });
                        }
                    }
                    setUserIdsAndEmails(userIdsAndEmails);
                })
                .catch((err) => handleFailedFetch(err));
        };

        await fetchUserIdsAndEmails();
        return userIdsAndEmails;
    }

    const value = {
        totalUnreadMessages,
        conversations,
        visibleConversations,
        newConversation,
        selectedConversation,
        selectedConversationUUID,
        userIdsAndEmails,
        chatSoundOn,
        setMessages,
        sendMessage,
        createConversation,
        setSelectedConversationUUID,
        getUserIdsAndEmails,
        setChatSoundOn
    };

    return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
