import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ForumIcon from "@mui/icons-material/Forum";
import GroupIcon from "@mui/icons-material/Group";
import LayersIcon from "@mui/icons-material/Layers";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MoreIcon from "@mui/icons-material/More";
import SettingsIcon from "@mui/icons-material/Settings";
import StorageIcon from "@mui/icons-material/Storage";
import PieChartIcon from "@mui/icons-material/PieChart";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import { useUserAuth } from "./contexts/authContext";
import { useEnv } from "./contexts/envContext";
import { useChat } from "./contexts/chatContext";

import "./css/cmp.css";

export function LeftNavigation({ open, setOpen }) {
    const { user } = useUserAuth();
    const { totalUnreadMessages } = useChat();
    const { dataLoggingOn } = useEnv();

    const [expanded, setExpanded] = useState(false);

    const isDesktop = useMediaQuery("(min-width:900px)");

    const handleChange = (panel) => (event, isExpanded) => {
        if (!open) setOpen(true);
        setExpanded(isExpanded ? panel : false);
    };

    const handleClick = () => {
        if (isDesktop) return false;
        else setOpen(false);
    };

    useEffect(() => {
        if (!open) setExpanded(false);
    }, [open]);

    return (
        <Box sx={{ width: "240px" }}>
            {isDesktop === false ? <Toolbar /> : <></>}

            <Divider />

            <List component="nav" className="nav">
                {user.user_role !== "First Responder" ? (
                    <Accordion sx={{ width: "100%", display: "grid" }} onChange={handleChange("panel1")} expanded={expanded === "panel1"} disableGutters>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <ListItemIcon sx={{ pl: "4px" }}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Airspaces" />
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                            <Link to="/" onClick={handleClick}>
                                <ListItem button sx={{ pl: "72px" }}>
                                    <ListItemText primary={"Airspace Manager"} />
                                </ListItem>
                            </Link>
                            <Link to="/operations" onClick={handleClick}>
                                <ListItem button data-testid="PushOperations" sx={{ pl: "72px" }}>
                                    <ListItemText primary="Operation Planning" />
                                </ListItem>
                            </Link>
                            <Link to="/dashboard" onClick={handleClick}>
                                <ListItem button sx={{ pl: "72px" }}>
                                    <ListItemText primary="Pilot Dashboard" />
                                </ListItem>
                            </Link>
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <></>
                )}

                <Link to="/map" onClick={handleClick}>
                    <ListItem button>
                        <ListItemIcon sx={{ pl: "4px" }}>
                            <LayersIcon />
                        </ListItemIcon>
                        <ListItemText primary="Map" />
                    </ListItem>
                </Link>
                {user.user_role !== "First Responder" ? (
                    <Link to="/chat" onClick={handleClick}>
                        <ListItem button>
                            <ListItemIcon sx={{ pl: "4px" }}>
                                <Badge color="error" badgeContent={totalUnreadMessages}>
                                    <ForumIcon />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary="Chat" />
                        </ListItem>
                    </Link>
                ) : (
                    <></>
                )}
                {user.user_role == "Admin" || user.user_role == "Admin - Org" ? (
                    <Link to="/manage" onClick={handleClick}>
                        <ListItem button>
                            <ListItemIcon sx={{ pl: "4px" }}>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary="Manage Users" primaryTypographyProps={{ noWrap: true }} />
                        </ListItem>
                    </Link>
                ) : (
                    <></>
                )}
                {user.user_role !== "First Responder" ? (
                    <ListItem sx={{ padding: "0px !important" }}>
                        <Accordion sx={{ width: "100%", display: "grid" }} onChange={handleChange("panel2")} expanded={expanded === "panel2"} disableGutters>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <ListItemIcon sx={{ pl: "4px" }}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                <Link to="/map-settings" onClick={handleClick}>
                                    <ListItem button data-testid="MapSettings" sx={{ pl: "72px" }}>
                                        <ListItemText primary="Manage Settings" />
                                    </ListItem>
                                </Link>
                                <Link to="/drone-settings" onClick={handleClick}>
                                    <ListItem button data-testid="ManageDrones" sx={{ pl: "72px" }}>
                                        <ListItemText primary="Manage Drones" />
                                    </ListItem>
                                </Link>
                                {user.user_role == "Admin" || user.user_role === "Airspace Manager" ? (
                                    <Link to="/hms-settings" onClick={handleClick}>
                                        <ListItem button data-testid="ManageHMS" sx={{ pl: "72px" }}>
                                            <ListItemText primary="Manage HMS" />
                                        </ListItem>
                                    </Link>
                                ) : (
                                    <></>
                                )}
                                {user.user_role == "Admin" || user.user_role === "Operations Manager" || user.user_role === "Airspace Manager" ? (
                                    <Link to="/zca-settings" onClick={handleClick}>
                                        <ListItem button data-testid="ManageConfigs" sx={{ pl: "72px" }}>
                                            <ListItemText primary="Manage Configs" />
                                        </ListItem>
                                    </Link>
                                ) : (
                                    <></>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </ListItem>
                ) : (
                    <></>
                )}
                {user.user_role == "Admin" ? (
                    <Link to="/datalogs" onClick={handleClick}>
                        <ListItem button>
                            <ListItemIcon sx={{ pl: "4px" }}>
                                <MoreIcon />
                            </ListItemIcon>
                            <ListItemText primary="Data Logs" />
                        </ListItem>
                    </Link>
                ) : (
                    <></>
                )}
                {user.user_role == "Admin" && dataLoggingOn == "true" ? (
                    <Link to="/monitoring" onClick={handleClick}>
                        <ListItem button>
                            <ListItemIcon sx={{ pl: "4px" }}>
                                <StorageIcon />
                            </ListItemIcon>
                            <ListItemText primary="Monitoring" />
                        </ListItem>
                    </Link>
                ) : (
                    <></>
                )}
                {user.user_role == "Admin" || user.user_role === "Airspace Manager" ? (
                    <Link to="/hms" onClick={handleClick}>
                        <ListItem button>
                            <ListItemIcon sx={{ pl: "4px" }}>
                                <MonitorHeartIcon />
                            </ListItemIcon>
                            <ListItemText primary="HIMS" />
                        </ListItem>
                    </Link>
                ) : (
                    <></>
                )}
                {user.user_role === "Admin" && dataLoggingOn === "true" ? (
                    <Link to="/airspace-analysis" onClick={handleClick}>
                        <ListItem button>
                            <ListItemIcon sx={{ pl: "4px" }}>
                                <PieChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Airspace Analysis" primaryTypographyProps={{ noWrap: true }} />
                        </ListItem>
                    </Link>
                ) : (
                    <></>
                )}
            </List>
        </Box>
    );
}
