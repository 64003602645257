import React from "react";
import {
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector
} from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';

export function CustomGridToolbar() {
    const getJson = (apiRef) => {
        // Select rows and columns
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        // Format the data. Here we only keep the value
        const data = filteredSortedRowIds.map((id) => {
            const row = {};
            visibleColumnsField.forEach((field) => {
                row[field] = apiRef.current.getCellParams(id, field).value;
            });
            return row;
        });

        return JSON.stringify(data, null, 2);
    };

    const exportBlob = (blob, filename) => {
        // Save the blob in a json file
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        setTimeout(() => {
            URL.revokeObjectURL(url);
        });
    };

    const JsonExportMenuItem = (props) => {
        const apiRef = useGridApiContext();

        const { hideMenu } = props;

        return (
            <MenuItem
                onClick={() => {
                    const jsonString = getJson(apiRef);
                    const blob = new Blob([jsonString], {
                        type: 'text/json',
                    });
                    exportBlob(blob, 'DataGrid_demo.json');

                    // Hide the export menu after the export
                    hideMenu?.();
                }}
            >
                Export JSON
            </MenuItem>
        );
    };

    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem />
            <JsonExportMenuItem />
        </GridToolbarExportContainer>
    );

    return (
        <GridToolbarContainer >
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <CustomExportButton />
        </GridToolbarContainer>
    );
}