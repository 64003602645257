import React, { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";

import { useUserAuth } from "../contexts/authContext";

const MapTileDropdown = ({ mapTileDropdownOpen, setMapTileDropdownOpen, mapTileDropdownAnchorEl, mapContainer }) => {
    const { userMapSettings, mapTileOptions, updateMapSettings } = useUserAuth();

    const [mapTileIndexSetting, setMapTileIndexSetting] = useState(userMapSettings.map_preference);

    const handleTileIndexChange = (index) => {
        setMapTileIndexSetting(index);
        setMapTileDropdownOpen(false);

        const updated_map_settings = {
            ...userMapSettings,
            map_preference: index
        };
        updateMapSettings(updated_map_settings);
    };
    return (
        <Popover
            open={mapTileDropdownOpen}
            anchorEl={mapTileDropdownAnchorEl}
            onClose={() => setMapTileDropdownOpen(false)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            container={mapContainer ? mapContainer : undefined}
        >
            <Box sx={{ p: 2, maxWidth: { xs: "100%", sm: "375px" } }}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                    Map Tiles
                </Typography>

                <Grid container spacing={2}>
                    {mapTileOptions.map(({ title, url }, i) => {
                        return (
                            <Grid key={i} item xs={6} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box
                                    sx={{
                                        height: "80px",
                                        width: "100%",
                                        backgroundImage: `${url}`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        borderRadius: "4px",
                                        outline: mapTileIndexSetting === i ? "1px solid white" : "none",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => handleTileIndexChange(i)}
                                />
                                <Typography variant="caption">{title}</Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Popover>
    );
};

export default MapTileDropdown;
