import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

import { useSocket } from "../contexts/socketContext";
import { useUserAuth } from "../contexts/authContext";

export const CreateConversationDialog = (props) => {
    const [recipients, setRecipients] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState(false);

    const { createConversation, userIdsAndEmails, getUserIdsAndEmails } = useSocket();
    const { user, snackbar, setSnackbar } = useUserAuth();

    useEffect(() => {
        getUserIdsAndEmails();
    }, []);

    const handleCreateConversation = () => {
        const inputRecipient = convertEmail(inputValue);
        const recipientList = inputValue && !recipients.includes(inputRecipient) ? [...recipients, inputRecipient] : recipients;
        if (!recipientList?.length) {
            setError(true);
            return setSnackbar({ children: "Please enter a valid email to message", severity: "error" });
        }

        const idRecipients = [user.id];
        const emailRecipients = [user.email];

        for (const recipient of recipientList) {
            const recipientEmail = recipient.split(" ")[0];
            let foundRecipient = false;

            // Check if recipient is valid. If so, convert to IDs and real email.
            for (const userIdAndEmail of userIdsAndEmails) {
                if (userIdAndEmail.email.split(" ")[0] === recipientEmail) {
                    idRecipients.push(userIdAndEmail.id);
                    emailRecipients.push(recipientEmail);
                    foundRecipient = true;
                    break;
                }
            }

            // If recipient was not found, the user cannot message the email.
            if (!foundRecipient) {
                setError(true);
                return setSnackbar({ children: `${recipientEmail} is not a valid email you can message`, severity: "error" });
            }
        }

        props.setCreateConversationDialogOpen(false);
        createConversation(idRecipients, emailRecipients);
        setRecipients([]);
        setInputValue("");
        setError(false);
    };

    const handleCloseDialog = () => {
        props.setCreateConversationDialogOpen(false);
        setRecipients([]);
    };

    const handleInputChange = (event, value, reason) => {
        setInputValue(value);
        if (reason !== "input" || !value.endsWith(",")) {
            return;
        }
        setInputValue("");

        const newRecipient = convertEmail(value.slice(0, -1));
        if (!recipients.includes(newRecipient)) {
            setRecipients((prev) => [...prev, newRecipient]);
        }
    };

    // Convert to "Email (Organization - Role)" as needed.
    const convertEmail = (email) => {
        const emailPortion = email.trim().split(" ")[0];
        for (const userEmail of userIdsAndEmails.map((u) => u.email)) {
            if (emailPortion === userEmail.split(" ")[0]) {
                return userEmail;
            }
        }
        return emailPortion;
    };

    return (
        <Dialog open={props.createConversationDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle sx={{ width: { xs: "100%", sm: "500px" } }}>Create a Conversation</DialogTitle>
            <DialogContent>
                <Box sx={{ p: "10px" }}>
                    <Autocomplete
                        multiple
                        options={userIdsAndEmails.map((u) => u.email)}
                        value={recipients}
                        inputValue={inputValue}
                        onChange={(e, value) => setRecipients(value)}
                        onInputChange={handleInputChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Please enter an email address"
                                error={snackbar && error}
                                inputProps={{ ...params.inputProps, id: "autocompleteText" }}
                            />
                        )}
                        data-testid="autocomplete"
                        freeSolo
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button data-testid="cancel" onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button data-testid="message" id="message" onClick={handleCreateConversation}>
                    Message
                </Button>
            </DialogActions>
        </Dialog>
    );
};
