import React, { useEffect, useState, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import LayersIcon from "@mui/icons-material/Layers";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCheckedFromSubLayers, getIndeterminateFromSubLayers } from "./mapUtil";

// component requires 5 props
// - viewerRef - reference to the viewer
// - viewerContainerRef - reference to the viewers container
// - layers - the layers to display to the user
// - setLayers - controls the layers state
// - toggleElementsVisibility - function which toggles either an array of elements or an element's visibility

export const MapLayers = (props) => {
    const layersButtonRef = useRef(null);
    const layersDropdownRef = useRef(null);

    useEffect(() => {
        if (props.viewerContainerRef.current) {
            const toolbar = props.viewerContainerRef.current.querySelector("div.cesium-viewer-toolbar");
            const modeButton = props.viewerContainerRef.current.querySelector("span.cesium-sceneModePicker-wrapper");
            layersButtonRef.current.style.display = "unset";

            if (toolbar) {
                toolbar.insertBefore(layersDropdownRef.current, modeButton);
                toolbar.insertBefore(layersButtonRef.current, layersDropdownRef.current);
            }
        }
    }, []);

    const handleToggleLayersDropdown = () => {
        const layersDropdown = layersDropdownRef.current;
        const visibleClass = "cesium-baseLayerPicker-dropDown-visible";

        if (layersDropdown.classList.contains(visibleClass)) {
            layersDropdown.classList.remove(visibleClass);
        } else {
            layersDropdown.classList.add(visibleClass);
        }
    };
    const toggleMainMapLayer = (e, mainLayer) => {
        const checked = e.target.checked;
        const updatedLayers = props.layers.map((layer) => {
            if (layer.name === mainLayer.name) {
                return {
                    ...layer,
                    checked: checked,
                    subLayers: layer.subLayers.map((subLayer) => {
                        return { ...subLayer, checked: checked };
                    })
                };
            } else {
                return layer;
            }
        });
        props.setLayers(updatedLayers);
        props.toggleElementsVisibility(mainLayer.elements, e.target.checked);
    };
    const toggleSubMapLayer = (e, mainLayer, subLayer) => {
        const checked = e.target.checked;
        const updatedLayers = props.layers.map((layer) => {
            if (layer.name === mainLayer.name) {
                const updatedSubLayers = layer.subLayers.map((prevSubLayer) => {
                    if (subLayer.name === prevSubLayer.name) {
                        return {
                            ...prevSubLayer,
                            checked: checked
                        };
                    } else {
                        return prevSubLayer;
                    }
                });
                return {
                    ...layer,
                    subLayers: updatedSubLayers,
                    checked: getCheckedFromSubLayers(updatedSubLayers),
                    indeterminate: getIndeterminateFromSubLayers(updatedSubLayers)
                };
            } else {
                return layer;
            }
        });
        props.setLayers(updatedLayers);
        props.toggleElementsVisibility(subLayer.elements, e.target.checked);
    };
    return (
        <Box>
            <button
                ref={layersButtonRef}
                style={{ display: "none" }}
                className="cesium-button cesium-toolbar-button"
                onClick={handleToggleLayersDropdown}
                title="Map Layers"
            >
                <LayersIcon />
            </button>

            <Box
                ref={layersDropdownRef}
                className="cesium-baseLayerPicker-dropDown"
                style={{
                    position: "absolute",
                    padding: "16px",
                    maxWidth: "275px",
                    maxHeight: "325px",
                    top: "40px"
                }}
            >
                <Box className="cesium-baseLayerPicker-sectionTitle">Volumes</Box>
                <Box className="cesium-baseLayerPicker-section" style={{ overflowY: "auto" }}>
                    {props.layers.map((layer, layerIndex) => {
                        const [expanded, setExpanded] = useState(false);

                        const handleExpandClick = () => {
                            if (layer.subLayers.length > 0) {
                                setExpanded((prev) => !prev);
                            }
                        };
                        return (
                            <Accordion
                                key={layerIndex}
                                disableGutters
                                expanded={layer.subLayers.length > 0 ? expanded : false}
                                sx={{ background: "transparent", boxShadow: "none" }}
                            >
                                <AccordionSummary
                                    expandIcon={layer.subLayers.length > 0 ? <ExpandMoreIcon onClick={handleExpandClick} /> : <></>}
                                    sx={{ ".MuiAccordionSummary-content": { margin: 0 } }}
                                >
                                    <FormControlLabel
                                        label={layer.name}
                                        control={
                                            <Checkbox
                                                checked={layer.checked}
                                                indeterminate={layer.indeterminate}
                                                onChange={(e) => toggleMainMapLayer(e, layer)}
                                                sx={{
                                                    color: layer.color,
                                                    "&.Mui-checked": { color: layer.color }
                                                }}
                                            />
                                        }
                                    />
                                </AccordionSummary>
                                {layer.subLayers.length > 0 ? (
                                    <AccordionDetails sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: 0 }}>
                                        {layer.subLayers.map((subLayer, subLayerIndex) => (
                                            <FormControlLabel
                                                key={subLayerIndex}
                                                label={subLayer.name}
                                                control={<Checkbox checked={subLayer.checked} onChange={(e) => toggleSubMapLayer(e, layer, subLayer)} />}
                                            />
                                        ))}
                                    </AccordionDetails>
                                ) : (
                                    <></>
                                )}
                            </Accordion>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};
