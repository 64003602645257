import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { HMSSystemSettings } from "./hmsSystemSettings";
import { HMSComponentSettings } from "./hmsComponentSettings";

export function HMSSettings() {
    const [value, setValue] = useLocalStorage("userDatalogTab", "0");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", pt: 3, px: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                HIMS Settings
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column" }}>
                <Box sx={{ width: "100%" }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs onChange={handleChange} aria-label="HIMS Settings" variant="scrollable" value={value} scrollButtons allowScrollButtonsMobile>
                                <Tab label="HIMS Systems" value="0" id="systems" />
                                <Tab label="HIMS Components" value="1" id="components" />
                            </Tabs>
                        </Box>
                        <TabPanel value="0">
                            <HMSSystemSettings />
                        </TabPanel>
                        <TabPanel value="1">
                            <HMSComponentSettings />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
        </Grid>
    );
}
