import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function AlertDialog({ bannerAlerts, alertDialogOpen, setAlertDialogOpen }) {
    const getTimePassedFromIso = (iso) => {
        const currentTimestamp = new Date().getTime();
        const isoTimestamp = new Date(iso).getTime();
        const elapsedMilliseconds = currentTimestamp - isoTimestamp;

        if (elapsedMilliseconds < 60000) {
            const seconds = Math.floor(elapsedMilliseconds / 1000);
            return `${seconds}s ago`;
        } else if (elapsedMilliseconds < 3600000) {
            const minutes = Math.floor(elapsedMilliseconds / 60000);
            return `${minutes} min${minutes !== 1 ? "s" : ""} ago`;
        } else if (elapsedMilliseconds < 86400000) {
            const hours = Math.floor(elapsedMilliseconds / 3600000);
            return `${hours} hr${hours !== 1 ? "s" : ""} ago`;
        } else {
            const days = Math.floor(elapsedMilliseconds / 86400000);
            return `${days} day${days !== 1 ? "s" : ""} ago`;
        }
    };
    const getColorFromAlert = (alert) => {
        if (alert.severity === "ADVISORY") {
            return "#29b6f6";
        } else if (alert.severity === "CAUTION") {
            return "#ffa726";
        } else {
            return "#f44336";
        }
    };
    return (
        <Dialog open={alertDialogOpen} onClose={() => setAlertDialogOpen(false)} maxWidth={"md"}>
            <DialogTitle>{"Alerts"}</DialogTitle>
            <DialogContent sx={{ maxHeight: "400px" }}>
                <List dense>
                    {bannerAlerts.map((alert) => {
                        return (
                            <ListItem key={alert.alert_id} sx={{ px: 0 }}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: `${getColorFromAlert(alert)}` }}>
                                        {alert.severity === "WARNING" ? (
                                            <ErrorOutlineIcon />
                                        ) : alert.severity === "CAUTION" ? (
                                            <ReportProblemOutlinedIcon />
                                        ) : (
                                            <InfoOutlinedIcon />
                                        )}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color={getColorFromAlert(alert)}>
                                                {`${alert.severity}: `}
                                            </Typography>
                                            {alert.message}
                                        </React.Fragment>
                                    }
                                    secondary={`${alert.state} - ${getTimePassedFromIso(alert.alert_time)}`}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setAlertDialogOpen(false)}>{"Close"}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;
