import React, { useEffect, useState } from "react";

import ChatIcon from "@mui/icons-material/Chat";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { useUserAuth } from "../contexts/authContext";
import { useEnv } from "../contexts/envContext";
import { useMap } from "../contexts/mapContext";
import { useChat } from "../contexts/chatContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { getOperationEditable, getOperationPriority, canUserEditOrDelete } from "../manager/operations/opUtil";
import { ConvertMetersToFeet, GetColorFromState } from "../util";
import { ListItemText } from "@mui/material";

import * as CONSTANT from "../manager/operations/opConstants";

export const MapFlightCards = (props) => {
    const [operationDetailsVisible, setOperationDetailsVisible] = useState(new Map());
    const [operations, setOperations] = useState([]);
    const [filters, setFilters] = useLocalStorage("flightCardFilters", []);
    const [sortBy, setSortBy] = useLocalStorage("flightCardSort", "Start Time");

    const { user, organizations, userOperationalStates, handleFailedFetch } = useUserAuth();
    const { conversations, createConversation, setSelectedConversationUUID } = useChat();
    const { drones } = useMap();
    const { laancOn } = useEnv();

    useEffect(() => {
        setSelectedConversationUUID(null);
    }, []);

    useEffect(() => {
        let ops = [...props.operations];

        // Filters:
        if (filters.includes("Today's Flights")) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            ops = ops.filter((op) => {
                const startDay = new Date(op.time_start);
                const endDay = new Date(op.time_end);
                startDay.setHours(0, 0, 0, 0);
                endDay.setHours(0, 0, 0, 0);
                return today.getTime() >= startDay.getTime() && today.getTime() <= endDay.getTime();
            });
        }
        if (filters.includes("Active Flights")) {
            ops = ops.filter((op) => op.state === "ACTIVATED" || op.state === "CONTINGENT" || op.state === "NONCONFORMING");
        }
        if (filters.includes("Own Organization")) {
            ops = ops.filter((op) => op.organization_id === user.organization_id);
        }

        // Sorts:
        if (sortBy === "Start Time") {
            ops.sort((a, b) => new Date(a.time_start) - new Date(b.time_start));
        } else if (sortBy === "End Time") {
            ops.sort((a, b) => new Date(a.time_end) - new Date(b.time_end));
        }

        const opDetails = new Map();
        ops.forEach((op) => {
            const value = operationDetailsVisible.get(op.flight_uuid) || false;
            opDetails.set(op.flight_uuid, value);
        });

        setOperations(ops);
        setOperationDetailsVisible(opDetails);
    }, [props.operations, filters, sortBy]);

    const handleWatchButtonClicked = (operation) => {
        const entityId = operation.otd_uuid;
        props.handleWatchEntity(entityId);
    };

    const handleChatButtonClicked = (id, email) => {
        const conversation = conversations.find(({ recipients }) => recipients.includes(id) && recipients.includes(user.id) && recipients.length === 2);
        if (conversation) {
            setSelectedConversationUUID(conversation.conversation_uuid);
        } else {
            createConversation([id, user.id], [email, user.email]);
        }
        props.setConversationDialogOpen(true);
    };

    const handleChangeOperationStatus = (e, op) => {
        if (!confirm("Are you sure you want to modify this operation?")) {
            return;
        }
        let operation = { ...op };
        operation.state = e.target.value;
        operation.updated_user_id = user.id;
        operation.version = operation.version + 1;

        let url = "";
        if (e.target.value === "ENDED") {
            url = "/api/op/delete";
        } else {
            url = "/api/op/updatePublish";
        }

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };
        fetch(url, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleEditButtonClicked = (operation) => {
        props.setEditFlightDialogOpen(true);
        props.setEditFlightVolume(operation);
    };

    const handleDetailsButtonClicked = (operation) => {
        props.setFlightDetailsDialogOpen(true);
        props.setFlightDetailsVolume(operation);
    };

    const handleToggleOperationDetails = ({ flight_uuid }) => {
        const newOperationDetailsVisible = new Map();
        operationDetailsVisible.forEach((value, key) => {
            if (key === flight_uuid) {
                newOperationDetailsVisible.set(key, !value);
            } else newOperationDetailsVisible.set(key, false);
        });
        setOperationDetailsVisible(newOperationDetailsVisible);
    };

    return (
        <Paper sx={{ position: "relative", width: { xs: "100%", sm: "400px" } }}>
            <Card
                sx={{
                    m: "4px",
                    p: "4px",
                    overflow: "unset",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1px",
                    minHeight: "60px"
                }}
            >
                <IconButton onClick={() => props.setFlightCardsOpen(false)}>
                    <ChevronRightIcon />
                </IconButton>
                <FormControl size="small">
                    <InputLabel shrink id="filters-label">
                        Filters
                    </InputLabel>
                    <Select
                        multiple
                        displayEmpty
                        notched
                        value={filters}
                        onChange={(e) => setFilters(e.target.value)}
                        renderValue={(filters) => (
                            <Typography variant="body1" color="text.secondary" sx={{ justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
                                {filters.length} active filter{filters.length !== 1 && "s"}
                            </Typography>
                        )}
                        SelectDisplayProps={{ "data-testid": "filters" }}
                        sx={{ maxHeight: "40px" }}
                        label="Filters"
                        labelId="filters-label"
                    >
                        <MenuItem value="Today's Flights" sx={{ maxHeight: "50px" }}>
                            <Checkbox checked={filters.includes("Today's Flights")} />
                            <ListItemText primary="Today's Flights" />
                        </MenuItem>
                        <MenuItem value="Active Flights" sx={{ maxHeight: "50px" }}>
                            <Checkbox checked={filters.includes("Active Flights")} />
                            <ListItemText primary="Active Flights" />
                        </MenuItem>
                        <MenuItem value="Own Organization" sx={{ maxHeight: "50px" }}>
                            <Checkbox checked={filters.includes("Own Organization")} />
                            <ListItemText primary="Own Organization" />
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ ml: "10px", mr: "5px" }} size="small">
                    <InputLabel shrink id="sort-by-label">
                        Sort By
                    </InputLabel>
                    <Select
                        value={sortBy}
                        notched
                        onChange={(e) => setSortBy(e.target.value)}
                        renderValue={(sortBy) => (
                            <Typography variant="body1" color="text.secondary" sx={{ justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
                                {sortBy}
                            </Typography>
                        )}
                        SelectDisplayProps={{ "data-testid": "sorts" }}
                        sx={{ maxHeight: "40px" }}
                        label="Sort By"
                        labelId="sort-by-label"
                    >
                        <MenuItem value="Start Time" sx={{ maxHeight: "50px" }}>
                            <ListItemText primary="Start Time" />
                        </MenuItem>
                        <MenuItem value="End Time" sx={{ maxHeight: "50px" }}>
                            <ListItemText primary="End Time" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </Card>

            {operations.map((operation) => {
                const expanded = operationDetailsVisible.get(operation.flight_uuid);
                const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
                const isOwnOp = user.id === operation.created_user_id ? true : false;

                const editable = getOperationEditable(operation, user);
                const canChangeStatus = canUserEditOrDelete(operation, user);
                const priority = getOperationPriority(operation);
                const minAlt = ConvertMetersToFeet(operation.volumes[0].altitude_min_agl_m);
                const maxAlt = ConvertMetersToFeet(operation.volumes[0].altitude_max_agl_m);
                const org = organizations?.filter((org) => org.id === operation.organization_id)[0];
                const orgName = org ? org.name : "Unknown";

                const faa_required = operation.faa_approval_required ? true : false;
                const laancState = Object.prototype.hasOwnProperty.call(operation, "laanc") ? operation.laanc.state.replace(/_/g, " ") : "NOT REQUIRED";

                const entityVisible = props.handleCheckFlightASDVisible(operation.otd_uuid);
                const vehicle = drones.find((drone) => drone.vehicle_uuid === operation.vehicle_uuid);
                const start = new Date(operation.time_start).toLocaleString("en-US", options);
                const end = new Date(operation.time_end).toLocaleString("en-US", options);

                let active_alert_volume_alert = false;
                props.activeAlertVolumeConflictionAlerts.forEach((active_alert) => {
                    if (active_alert.flight_uuid === operation.flight_uuid) {
                        active_alert_volume_alert = true;
                    }
                });
                let active_zero_conflict_alerts = [];
                if (props.activeZeroConflictionAlerts.has(operation.otd_uuid)) {
                    active_zero_conflict_alerts = props.activeZeroConflictionAlerts.get(operation.otd_uuid);
                }
                const zc_alerts_contain_warning = active_zero_conflict_alerts.find(({ severity }) => {
                    return severity === "WARNING";
                });
                const pilot =
                    operation.pilot_name && operation.pilot_name.trim() ? operation.pilot_name : operation.pilot_email ? operation.pilot_email : "Unknown";

                const canChat =
                    operation.created_user_id !== user.id &&
                    (operation.organization_id === user.organization_id || user.user_role_id === 4) &&
                    pilot !== "Unknown";

                const laanc_button_invisible =
                    operation.laanc && operation.laanc.state !== CONSTANT.LAANC_RESCINDED_AWAITING && operation.laanc.state !== CONSTANT.LAANC_INVALID_AWAITING
                        ? true
                        : false;

                return (
                    <Card key={operation.flight_uuid} sx={{ m: "4px", overflow: "unset" }}>
                        <CardHeader
                            title={
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography variant="h5" sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", flex: 1, mr: "auto" }}>
                                        {operation.name}
                                    </Typography>

                                    {entityVisible ? (
                                        <Tooltip title="Monitor">
                                            <IconButton aria-label="settings" onClick={() => handleWatchButtonClicked(operation)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <></>
                                    )}
                                    <Tooltip title="View">
                                        <IconButton aria-label="View" onClick={() => props.handleJumpToOperation(operation.flight_uuid)}>
                                            <TravelExploreIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                            subheader={`${start} - ${end}`}
                            subheaderTypographyProps={{ noWrap: true }}
                            sx={{ pb: 1, borderBottom: 1 }}
                        />

                        <CardContent sx={{ py: 1, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 1 }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Vehicle: </b>
                                <Tooltip title={vehicle ? vehicle.name : "Unknown"}>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whitespace: "nowrap"
                                        }}
                                        id={`vehicle-${operation.volumeId}`}
                                    >
                                        {vehicle ? vehicle.name : "Unknown"}
                                    </span>
                                </Tooltip>
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Operator:</b>
                                <Tooltip title={pilot}>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whitespace: "nowrap"
                                        }}
                                        id={`operator-${operation.volumeId}`}
                                    >
                                        {canChat ? (
                                            <IconButton
                                                sx={{ fontSize: "small", height: "20px", width: "20px", overflowY: "visible" }}
                                                onClick={() => handleChatButtonClicked(operation.created_user_id, operation.pilot_email)}
                                            >
                                                <ChatIcon fontSize="inherit" />
                                            </IconButton>
                                        ) : (
                                            <></>
                                        )}
                                        {pilot}
                                    </span>
                                </Tooltip>
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Airspace Status: </b>
                                {operation.conflicted ? (
                                    <Tooltip title="CONFLICTED">
                                        <span
                                            style={{ color: "#CB4C4E", overflow: "hidden", textOverflow: "ellipsis", whitespace: "nowrap" }}
                                            id={`airspaceStatus-${operation.volumeId}`}
                                        >
                                            CONFLICTED
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="CLEAR">
                                        <span
                                            style={{ color: "#5fa052", overflow: "hidden", textOverflow: "ellipsis", whitespace: "nowrap" }}
                                            id={`airspaceStatus-${operation.volumeId}`}
                                        >
                                            CLEAR
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ display: "flex", justifyContent: "space-between", gap: 1, overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                                <b>Alerts: </b>
                                <span
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whitespace: "nowrap"
                                    }}
                                >
                                    <span style={{ color: zc_alerts_contain_warning ? "red" : "yellow" }}>
                                        {active_zero_conflict_alerts.length > 0
                                            ? active_alert_volume_alert
                                                ? `${active_zero_conflict_alerts.length} DAA Alert${active_zero_conflict_alerts.length !== 1 ? "s, " : ", "}`
                                                : `${active_zero_conflict_alerts.length} DAA Alert${active_zero_conflict_alerts.length !== 1 ? "s" : ""}`
                                            : ""}
                                    </span>
                                    <span style={{ color: "red" }}>{active_alert_volume_alert ? "In Alert Volume" : ""}</span>
                                </span>
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <FormControl size="small" sx={{ minWidth: "125px" }} disabled={!canChangeStatus}>
                                <InputLabel id="select-label-status">Op Status</InputLabel>
                                <Select
                                    label="Op Status"
                                    labelId="select-label-status"
                                    value={operation.state}
                                    onChange={(e) => handleChangeOperationStatus(e, operation)}
                                    sx={{ color: GetColorFromState(operation.state, userOperationalStates) }}
                                    inputProps={{ "data-testid": "selectStatus" }}
                                >
                                    <MenuItem value={"ACCEPTED"}>ACCEPTED</MenuItem>
                                    <MenuItem value={"ACTIVATED"}>ACTIVATED</MenuItem>
                                    <MenuItem value={"ENDED"}>ENDED</MenuItem>
                                    <MenuItem value={"CONTINGENT"}>CONTINGENT</MenuItem>
                                    <MenuItem value={"NONCONFORMING"}>NONCONFORMING</MenuItem>
                                </Select>
                            </FormControl>
                            {editable ? (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleEditButtonClicked(operation)}
                                    data-testid="edit"
                                    id={`edit-${operation.volumeId}`}
                                >
                                    Edit
                                </Button>
                            ) : (
                                <></>
                            )}
                            {laancOn === "true" && isOwnOp && faa_required ? (
                                <Badge badgeContent={"!"} color={"error"} invisible={laanc_button_invisible}>
                                    <Button
                                        variant="outlined"
                                        color={laanc_button_invisible ? "primary" : "error"}
                                        onClick={() => handleDetailsButtonClicked(operation)}
                                        data-testid="laanc"
                                    >
                                        LAANC
                                    </Button>
                                </Badge>
                            ) : (
                                <></>
                            )}
                            <IconButton onClick={() => handleToggleOperationDetails(operation)} sx={{ ml: "auto !important" }}>
                                {expanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </CardActions>

                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent sx={{ borderTop: 1, display: "grid", gridTemplateColumns: "1fr", gap: 1 }}>
                                {[
                                    {
                                        title: "Op UUID",
                                        value: operation.flight_uuid,
                                        visible: true
                                    },
                                    {
                                        title: "Priority",
                                        value: priority,
                                        visible: true
                                    },
                                    {
                                        title: "Version",
                                        value: operation.version,
                                        visible: true
                                    },
                                    {
                                        title: "Min Altitude (AGL)",
                                        value: `${minAlt}ft`,
                                        visible: true
                                    },
                                    {
                                        title: "Max Altitude (AGL)",
                                        value: `${maxAlt}ft`,
                                        visible: true
                                    },
                                    {
                                        title: "Organization",
                                        value: orgName,
                                        visible: true
                                    },
                                    {
                                        title: "LAANC State",
                                        value: laancState,
                                        visible: laancOn === "true"
                                    }
                                ].map(({ title, value, visible }, i) =>
                                    visible ? (
                                        <Box
                                            key={i}
                                            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                        >
                                            <Typography variant="body2" color="text.secondary" fontWeight="bold">
                                                {title}:
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {value}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <React.Fragment key={i} />
                                    )
                                )}
                            </CardContent>
                        </Collapse>
                    </Card>
                );
            })}
        </Paper>
    );
};
