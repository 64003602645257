import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useUserAuth } from "../../contexts/authContext";
import { ConvertISOToDate } from "../../util";

export const BypassDetails = (props) => {
    const { user, snackbar } = useUserAuth();
    const [reason, setReason] = useState("");
    const today = new Date();

    return (
        <Box
            sx={{
                p: 2,
                gap: 2,
                width: "375px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Grid container justifyContent="center">
                <Grid item xs={2}>
                    <ReportProblemOutlinedIcon fontSize="large" color="error" />
                </Grid>
                <Grid item xs={10} sx={{ mb: 2 }}>
                    <Typography variant="body1">
                        {
                            "This operation requires FAA approval through LAANC or FAADroneZone and the operator is discouraged from following up directly with Air Traffic by phone to gain approval, as this may result in the request being denied by the FAA. Please enter an explanation below."
                        }
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => {
                            props.setBypassReason(e.target.value);
                            setReason(e.target.value);
                        }}
                        error={snackbar && !reason}
                        sx={{ mt: 0, mb: 0 }}
                        value={reason}
                        variant="outlined"
                        margin="dense"
                        label="Explanation for Bypass"
                        fullWidth
                        id="bypassExplanation"
                        inputProps={{ "data-testid": "bypassExplanation" }}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <TextField value={user.email} label="User" variant="outlined" margin="dense" fullWidth disabled />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <TextField value={ConvertISOToDate(today)} label="Bypass Date/Time" variant="outlined" margin="dense" fullWidth disabled />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BypassDetails;
