import React, { useEffect, useState } from "react";
import { v4 as uuid_v4 } from "uuid";
import dayjs from "dayjs";

import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import Draw, { createBox } from "ol/interaction/Draw";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { getSourceIdsFromUserInput, GetTimezone } from "../util";

import useOpenLayersMap from "../hooks/useOpenLayersMap";

import { useUserAuth } from "../contexts/authContext";

const AirspaceCharacterization = () => {
    const [timeStartDayjs, setTimeStartDayjs] = useState(dayjs().subtract(30, "days"));
    const [timeStartErrorText, setTimeStartErrorText] = useState("");

    const [timeEndDayjs, setTimeEndDayjs] = useState(dayjs());
    const [timeEndErrorText, setTimeEndErrorText] = useState("");

    const [altitudeMinMslFt, setAltitudeMinMslFt] = useState("0");
    const [altitudeMinErrorText, setAltitudeMinErrorText] = useState("");

    const [altitudeMaxMslFt, setAltitudeMaxMslFt] = useState("400");
    const [altitudeMaxErrorText, setAltitudeMaxErrorText] = useState("");

    const [sourceType, setSourceType] = useState("GROUND_BASED_RADAR");
    const [sourceIds, setSourceIds] = useState([]);
    const [sourceId, setSourceId] = useState("");

    const [airspaceBounds, setAirspaceBounds] = useState({ min_lat_deg: "", max_lat_deg: "", min_lon_deg: "", max_lon_deg: "" });
    const [airspaceBoundsErrorText, setAirspaceBoundsErrorText] = useState({ min_lat_deg: "", max_lat_deg: "", min_lon_deg: "", max_lon_deg: "" });
    const [airspaceFeature, setAirspaceFeature] = useState(null);

    const [drawing, setDrawing] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [lastSubmissionText, setLastSubmissionText] = useState("");

    const { user, handleFailedFetch } = useUserAuth();

    const MAP_ID = "airspace-analysis-map";
    const map = useOpenLayersMap(MAP_ID, false);

    useEffect(() => {
        updateLastSubmissionText();
    }, []);

    const updateLastSubmissionText = () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch(`api/airspace-analysis/get_last_submission/${user.id}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((record) => {
                if (!record || !record.date_created) {
                    setLastSubmissionText("You have not made any submissions.");
                    return;
                }
                const date_dayjs = dayjs(record.date_created);
                if (date_dayjs && date_dayjs.isValid()) {
                    const text = date_dayjs.format("MMMM D, YYYY [at] h:mm A");
                    setLastSubmissionText(`Your last request was submitted on ${text}.`);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const handleSourceIdKeyDown = (e) => {
        const value = getSourceIdsFromUserInput(e);
        if (value.length < 1) {
            return;
        }
        if (e.key === " " || e.key === "," || e.key === "Enter") {
            setSourceIds((prev) => [...prev, value]);
            setSourceId("");
        } else {
            setSourceId(value);
        }
    };
    const handleSourceIdBlur = (e) => {
        const value = getSourceIdsFromUserInput(e);
        if (value.length < 1) {
            return;
        }
        setSourceIds((prev) => [...prev, value]);
        setSourceId("");
    };
    const handleSourceIdChange = (e) => {
        const value = getSourceIdsFromUserInput(e);
        setSourceId(value);
    };
    const handleSourceIdsChange = (e, value) => {
        if (typeof sourceId === "string" && sourceId.length > 0) {
            setSourceId("");
        }
        setSourceIds(value);
    };
    const handleClickToDrawButtonClick = () => {
        if (!map) return;

        const vector_source = new VectorSource({
            wrapX: false
        });
        const vector_layer = new VectorLayer({
            source: vector_source
        });
        map.addLayer(vector_layer);

        const draw_interaction = new Draw({
            source: vector_source,
            type: "Circle",
            geometryFunction: createBox()
        });
        map.addInteraction(draw_interaction);
        setDrawing(true);

        draw_interaction.on("drawend", ({ feature }) => {
            map.removeInteraction(draw_interaction);
            setAirspaceFeature(feature);
            setDrawing(false);

            const geometry = feature.getGeometry();
            if (geometry) {
                map.getView().fit(geometry, {
                    padding: [100, 100, 100, 100]
                });
                const coordinates = geometry.getCoordinates();
                if (Array.isArray(coordinates) && coordinates.length > 0 && Array.isArray(coordinates[0])) {
                    let min_lat_deg = Number.MAX_SAFE_INTEGER;
                    let max_lat_deg = Number.MIN_SAFE_INTEGER;

                    let min_lon_deg = Number.MAX_SAFE_INTEGER;
                    let max_lon_deg = Number.MIN_SAFE_INTEGER;

                    coordinates[0].forEach(([longitude, latitude]) => {
                        const lng = parseFloat(longitude.toFixed(4));
                        const lat = parseFloat(latitude.toFixed(4));

                        if (lat < min_lat_deg) {
                            min_lat_deg = lat.toString();
                        }
                        if (lat > max_lat_deg) {
                            max_lat_deg = lat.toString();
                        }
                        if (lng < min_lon_deg) {
                            min_lon_deg = lng.toString();
                        }
                        if (lng > max_lon_deg) {
                            max_lon_deg = lng.toString();
                        }
                    });
                    setAirspaceBounds({
                        min_lat_deg: min_lat_deg,
                        max_lat_deg: max_lat_deg,
                        min_lon_deg: min_lon_deg,
                        max_lon_deg: max_lon_deg
                    });
                }
            }
        });
    };
    const handleDegreeChange = (e) => {
        const { name, value } = e.target;

        let min_lat_deg = airspaceBounds.min_lat_deg;
        let max_lat_deg = airspaceBounds.max_lat_deg;
        let min_lon_deg = airspaceBounds.min_lon_deg;
        let max_lon_deg = airspaceBounds.max_lon_deg;

        setAirspaceBounds((prev) => {
            min_lat_deg = name === "min_lat_deg" ? value : prev.min_lat_deg;
            max_lat_deg = name === "max_lat_deg" ? value : prev.max_lat_deg;
            min_lon_deg = name === "min_lon_deg" ? value : prev.min_lon_deg;
            max_lon_deg = name === "max_lon_deg" ? value : prev.max_lon_deg;

            const min_lat_deg_num = parseFloat(min_lat_deg);
            const max_lat_deg_num = parseFloat(max_lat_deg);
            const min_lon_deg_num = parseFloat(min_lon_deg);
            const max_lon_deg_num = parseFloat(max_lon_deg);

            const updated_coordinates = [
                [
                    [min_lon_deg_num, min_lat_deg_num],
                    [max_lon_deg_num, min_lat_deg_num],
                    [max_lon_deg_num, max_lat_deg_num],
                    [min_lon_deg_num, max_lat_deg_num],
                    [min_lon_deg_num, min_lat_deg_num]
                ]
            ];
            const coordinates_are_valid = updated_coordinates[0].every(([lng, lat]) => {
                return isNaN(lng) === false && isNaN(lat) === false;
            });
            if (map && coordinates_are_valid) {
                if (airspaceFeature) {
                    const geometry = airspaceFeature.getGeometry();
                    if (geometry) {
                        geometry.setCoordinates(updated_coordinates);
                        map.getView().fit(geometry, { padding: [100, 100, 100, 100] });
                    }
                } else {
                    const new_feature = new Feature({
                        geometry: new Polygon(updated_coordinates)
                    });
                    setAirspaceFeature(new_feature);

                    const vector_source = new VectorSource({
                        wrapX: false,
                        features: [new_feature]
                    });
                    const vector_layer = new VectorLayer({
                        source: vector_source
                    });
                    map.addLayer(vector_layer);

                    const geometry = new_feature.getGeometry();
                    if (geometry) {
                        map.getView().fit(geometry, { padding: [100, 100, 100, 100] });
                    }
                }
            } else if (airspaceFeature) {
                handleRemoveAirspaceFeature();
            }
            return {
                min_lat_deg: min_lat_deg,
                max_lat_deg: max_lat_deg,
                min_lon_deg: min_lon_deg,
                max_lon_deg: max_lon_deg
            };
        });
    };
    const handleRemoveAirspaceFeature = () => {
        if (!map) return;

        const layers = map.getLayers().getArray();
        layers.forEach((layer) => {
            if (layer.get("name") === "base") {
                return;
            }
            const source = layer.getSource();
            if (source) {
                source.clear();
            }
            map.removeLayer(layer);
        });
        if (airspaceFeature) {
            setAirspaceFeature(null);
        }
    };
    const handleClearBoundsButtonClick = () => {
        const default_bounds = {
            min_lat_deg: "",
            max_lat_deg: "",
            min_lon_deg: "",
            max_lon_deg: ""
        };
        setAirspaceBounds(default_bounds);
        handleRemoveAirspaceFeature();
    };
    const handleSubmitButtonClick = () => {
        let passes_all_checks = true;

        let min_lat_deg = parseFloat(airspaceBounds.min_lat_deg);
        if (isNaN(min_lat_deg) || min_lat_deg < -90 || min_lat_deg > 90) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, min_lat_deg: "Please enter a valid minimum latitude." };
            });
            passes_all_checks = false;
        } else if (airspaceBoundsErrorText) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, min_lat_deg: "" };
            });
        }
        let max_lat_deg = parseFloat(airspaceBounds.max_lat_deg);
        let latDifference = max_lat_deg - min_lat_deg;
        if (isNaN(max_lat_deg) || max_lat_deg < -90 || max_lat_deg > 90 || max_lat_deg < min_lat_deg || latDifference > 1) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, max_lat_deg: "Please enter a valid latitude no more than 1 degree greater than the minimum latitude." };
            });
            passes_all_checks = false;
        } else if (airspaceBoundsErrorText) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, max_lat_deg: "" };
            });
        }
        let min_lon_deg = parseFloat(airspaceBounds.min_lon_deg);
        if (isNaN(min_lon_deg) || min_lon_deg < -180 || min_lon_deg > 180) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, min_lon_deg: "Please enter a valid minimum longitude." };
            });
            passes_all_checks = false;
        } else if (airspaceBoundsErrorText) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, min_lon_deg: "" };
            });
        }
        let max_lon_deg = parseFloat(airspaceBounds.max_lon_deg);
        let lonDifference = max_lon_deg - min_lon_deg;
        if (isNaN(max_lon_deg) || max_lon_deg < -180 || max_lon_deg > 180 || max_lon_deg < min_lon_deg || lonDifference > 1) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, max_lon_deg: "Please enter a valid longitude no more than 1 degree greater than the minimum longitude." };
            });
            passes_all_checks = false;
        } else if (airspaceBoundsErrorText) {
            setAirspaceBoundsErrorText((prev) => {
                return { ...prev, max_lon_deg: "" };
            });
        }
        let alt_msl_ft_min = parseFloat(altitudeMinMslFt);
        if (isNaN(alt_msl_ft_min) || alt_msl_ft_min < 0) {
            setAltitudeMinErrorText("Please enter a valid altitude.");
            passes_all_checks = false;
        } else if (altitudeMinErrorText) {
            setAltitudeMinErrorText("");
        }
        let alt_msl_ft_max = parseFloat(altitudeMaxMslFt);
        if (isNaN(alt_msl_ft_max) || alt_msl_ft_max < 1 || alt_msl_ft_max < alt_msl_ft_min) {
            setAltitudeMaxErrorText("Please enter a number greater than the minimum altitude.");
            passes_all_checks = false;
        } else if (altitudeMaxErrorText) {
            setAltitudeMaxErrorText("");
        }
        if (!timeStartDayjs || timeStartDayjs.isValid() === false) {
            setTimeStartErrorText("Please enter a valid date.");
            passes_all_checks = false;
        } else if (timeStartErrorText) {
            setTimeStartErrorText("");
        }
        if (!timeEndDayjs || timeEndDayjs.isValid() === false || timeEndDayjs.isBefore(timeStartDayjs) || timeEndDayjs.diff(timeStartDayjs, "days") > 30) {
            setTimeEndErrorText("Please enter a date that is after and within 30 days of the start date.");
            passes_all_checks = false;
        } else if (timeEndErrorText) {
            setTimeEndErrorText("");
        }
        const current_time = dayjs().add(1, "minute");
        if (timeEndDayjs && timeEndDayjs.isAfter(current_time)) {
            setTimeEndErrorText("Date cannot be in the future.");
            passes_all_checks = false;
        } else if (timeEndErrorText) {
            setTimeEndErrorText("");
        }
        if (passes_all_checks === false) {
            return;
        }
        const body = {
            uuid: uuid_v4(),
            user_id: user.id,
            source_type: sourceType,
            time_start: timeStartDayjs.toISOString(),
            time_end: timeEndDayjs.toISOString(),
            lat_min: min_lat_deg,
            lat_max: max_lat_deg,
            lon_min: min_lon_deg,
            lon_max: max_lon_deg,
            alt_msl_ft_min: parseFloat(altitudeMinMslFt),
            alt_msl_ft_max: parseFloat(altitudeMaxMslFt)
        };
        if (Array.isArray(sourceIds) && sourceIds.length > 0) {
            body.source_ids = sourceIds;
        }
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        };
        fetch("/api/airspace-analysis/submit", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                setSubmitted(true);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const handleResetAirspaceCharacterizationForm = () => {
        if (sourceIds.length > 0) {
            setSourceIds([]);
        }
        if (sourceType !== "GROUND_BASED_RADAR") {
            setSourceType("GROUND_BASED_RADAR");
        }
        if (altitudeMinMslFt !== "0") {
            setAltitudeMinMslFt("0");
        }
        if (altitudeMaxMslFt !== "400") {
            setAltitudeMaxMslFt("400");
        }
        const time_start_dayjs = dayjs().subtract(30, "days");
        setTimeStartDayjs(time_start_dayjs);

        const time_end_dayjs = dayjs();
        setTimeEndDayjs(time_end_dayjs);

        handleClearBoundsButtonClick();
        updateLastSubmissionText();
        setSubmitted(false);
    };
    return (
        <Grid container spacing={2} sx={{ p: 3 }} maxWidth={"lg"}>
            <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Airspace Analysis
                    </Typography>

                    <Typography variant="body1" color="gray">
                        {lastSubmissionText}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} md={7}>
                <Paper sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
                    <Typography variant="body1">Sources</Typography>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <FormControl size="small" margin="dense" sx={{ width: "100%" }} disabled={drawing}>
                            <InputLabel id="select-source-type-label">Source Type</InputLabel>
                            <Select value={sourceType} label="Source Type" labelId="select-source-type-label" onChange={(e) => setSourceType(e.target.value)}>
                                {[
                                    "UNKNOWN",
                                    "TELEMETRY",
                                    "ADS_B",
                                    "GROUND_BASED_RADAR",
                                    "AIRBORNE_RADAR",
                                    "ACOUSTIC_SENSOR",
                                    "EO_IR",
                                    "MULTISENSOR_TRACKER",
                                    "REMOTE_ID"
                                ].map((value, i) => (
                                    <MenuItem key={i} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Autocomplete
                            multiple
                            options={[]}
                            value={sourceIds}
                            inputValue={sourceId}
                            onInputChange={handleSourceIdChange}
                            onChange={handleSourceIdsChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Source Ids (optional)"
                                    size="small"
                                    margin="dense"
                                    sx={{ width: "100%" }}
                                    value={sourceId}
                                    onKeyDown={handleSourceIdKeyDown}
                                    onBlur={handleSourceIdBlur}
                                />
                            )}
                            freeSolo
                            disabled={drawing}
                        />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                        <Typography variant="body1">{"Geographical Bounds"}</Typography>

                        {drawing === false && airspaceFeature === null ? (
                            <>
                                <Typography variant="body1">{" - "}</Typography>
                                <Link component="button" variant="body1" onClick={handleClickToDrawButtonClick}>
                                    click to draw
                                </Link>
                            </>
                        ) : drawing === false && airspaceFeature ? (
                            <>
                                <Typography variant="body1">{" - "}</Typography>
                                <Link component="button" variant="body1" onClick={handleClearBoundsButtonClick}>
                                    clear
                                </Link>
                            </>
                        ) : (
                            <></>
                        )}
                    </Box>

                    <Grid container spacing={1}>
                        {[
                            {
                                name: "min_lat_deg",
                                label: "Latitude (min)",
                                value: airspaceBounds.min_lat_deg,
                                onChange: handleDegreeChange,
                                helperText: airspaceBoundsErrorText.min_lat_deg
                            },
                            {
                                name: "max_lat_deg",
                                label: "Latitude (max)",
                                value: airspaceBounds.max_lat_deg,
                                onChange: handleDegreeChange,
                                helperText: airspaceBoundsErrorText.max_lat_deg
                            },
                            {
                                name: "min_lon_deg",
                                label: "Longitude (min)",
                                value: airspaceBounds.min_lon_deg,
                                onChange: handleDegreeChange,
                                helperText: airspaceBoundsErrorText.min_lon_deg
                            },
                            {
                                name: "max_lon_deg",
                                label: "Longitude (max)",
                                value: airspaceBounds.max_lon_deg,
                                onChange: handleDegreeChange,
                                helperText: airspaceBoundsErrorText.max_lon_deg
                            }
                        ].map(({ name, label, value, onChange, helperText }, i) => (
                            <Grid key={i} item xs={6}>
                                <TextField
                                    label={label}
                                    size="small"
                                    margin="dense"
                                    value={value}
                                    onChange={onChange}
                                    fullWidth
                                    InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                                    disabled={drawing}
                                    name={name}
                                    error={helperText.length > 0}
                                    helperText={helperText}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="body1">Altitude Range</Typography>

                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        <TextField
                            label="Altitude Min (MSL)"
                            sx={{ width: "100%" }}
                            onChange={(e) => setAltitudeMinMslFt(e.target.value)}
                            value={altitudeMinMslFt}
                            size="small"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">ft</InputAdornment>
                            }}
                            disabled={drawing}
                            error={altitudeMinErrorText.length > 0}
                            helperText={altitudeMinErrorText}
                        />

                        <TextField
                            label="Altitude Max (MSL)"
                            sx={{ width: "100%" }}
                            onChange={(e) => setAltitudeMaxMslFt(e.target.value)}
                            value={altitudeMaxMslFt}
                            size="small"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">ft</InputAdornment>
                            }}
                            disabled={drawing}
                            error={altitudeMaxErrorText.length > 0}
                            helperText={altitudeMaxErrorText}
                        />
                    </Box>

                    <Typography variant="body1">Date & Time</Typography>

                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label={`Start Time (${GetTimezone()}) *`}
                                value={timeStartDayjs}
                                onChange={(value) => setTimeStartDayjs(value)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        size: "small",
                                        margin: "dense",
                                        error: timeStartErrorText.length > 0,
                                        helperText: timeStartErrorText,
                                        inputProps: { "data-testid": "start_time" }
                                    }
                                }}
                                disabled={drawing}
                            />

                            <DateTimePicker
                                label={`End Time (${GetTimezone()}) *`}
                                value={timeEndDayjs}
                                onChange={(value) => setTimeEndDayjs(value)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        size: "small",
                                        margin: "dense",
                                        error: timeEndErrorText.length > 0,
                                        helperText: timeEndErrorText,
                                        inputProps: { "data-testid": "end_time" }
                                    }
                                }}
                                disabled={drawing}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Button onClick={handleSubmitButtonClick} variant="contained" sx={{ mt: 1 }} disabled={drawing} size="small">
                        Submit for analysis
                    </Button>
                </Paper>
            </Grid>

            <Grid item xs={12} md={5} sx={{ height: "450px", position: "relative" }}>
                <Box id={MAP_ID} />
            </Grid>

            <Dialog open={submitted} onClose={handleResetAirspaceCharacterizationForm} maxWidth={"sm"}>
                <DialogTitle>{"Airspace Characterization Request Submitted"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your request has been submitted. Once your report is generated, an email will be sent to you with with instructions on how to view it.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResetAirspaceCharacterizationForm}>{"Close"}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default AirspaceCharacterization;
