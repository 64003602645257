import dayjs from "dayjs";

export function ConvertISOToDate(iso_date) {
    if (iso_date != null) {
        const date = new Date(iso_date);
        const dateOpts = { day: "2-digit", month: "2-digit", year: "numeric" };
        const timeOpts = { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit", timeZoneName: "short" };

        return date.toLocaleDateString("en-US", dateOpts) + " " + date.toLocaleTimeString(navigator.language, timeOpts);
    } else {
        return "";
    }
}

export function ConvertVerticesToPosition(vertices) {
    let position = [];
    for (const vertice of vertices) {
        position.push(vertice.lng);
        position.push(vertice.lat);
    }

    return position;
}

export function GetColorFromState(state, stateStore) {
    let color = "#3E436F"; //Defaults to Dark Blue

    if (stateStore && state) {
        const selectedState = stateStore.find((selected_state) => selected_state.state == state.toUpperCase());
        color = selectedState ? selectedState.color : color;
    }

    return color;
}

export function ConvertCoordinatesToPosition(coords) {
    let position = [];

    for (const coord of coords) {
        position.push(coord[0]);
        position.push(coord[1]);
    }

    return position;
}

export function ExtractAltFromCoord(coords) {
    const alt = coords[0][2] ? coords[0][2] : 0;
    return alt;
}

export function ConvertCoordinatesToVertices(coords) {
    const vertices = [];
    for (const coord of coords) {
        const vertice = {
            lng: coord[0],
            lat: coord[1]
        };
        vertices.push(vertice);
    }
    return vertices;
}

export function ConvertVerticesToAveragePosition(verticies) {
    let totalLat = 0;
    let totalLng = 0;

    verticies.forEach((element) => {
        totalLat += element.lat;
        totalLng += element.lng;
    });

    let averageLat = totalLat / verticies.length;
    let averageLng = totalLng / verticies.length;

    return { lat: averageLat, lng: averageLng };
}

export function PropertySetter(object, property, value) {
    var keys = property.split(".");
    var propertyName = keys.pop();
    var propertyParent = object;
    while (keys.length > 0) {
        propertyParent = propertyParent[keys.shift()];
    }
    propertyParent[propertyName] = value;

    return object;
}

// Returns object of all the values set by this function -- For Planning Operations.
export function ConvertFeaturesToVolumes(features, tempStartDate, tempEndDate, user) {
    const volumes = [];
    const volumeData = {
        minAlt: 0,
        maxAlt: 0,
        opName: "",
        tempStartDate: tempStartDate,
        tempEndDate: tempEndDate,
        user: user
    };

    for (const feature of features) {
        if (!feature.geometry) {
            continue;
        } else if (feature.geometry.type === "GeometryCollection") {
            // Check for additional Geometry blocks.
            for (const geometry of feature.geometry.geometries) {
                ConvertGeometryToVolume(volumes, volumeData, feature, geometry);
            }
        } else if (feature.geometry.type === "Polygon" || feature.geometry.type === "LineString") {
            ConvertGeometryToVolume(volumes, volumeData, feature, feature.geometry);
        }
    }
    return {
        volumes: volumes,
        minAlt: volumeData.minAlt,
        maxAlt: volumeData.maxAlt,
        opName: volumeData.opName
    };
}

// Converts a single Geometry (of type Polygon or LineString) into a Volume -- For Planning Operations.
function ConvertGeometryToVolume(volumes, volumeData, feature, geometry) {
    if (!geometry || (geometry.type !== "Polygon" && geometry.type !== "LineString")) {
        return;
    }
    const coords = GetGeometryCoordinates(geometry);
    coords.forEach((coord) => {
        volumeData.minAlt = coord[2] < volumeData.minAlt || isNaN(volumeData.minAlt) ? coord[2] : volumeData.minAlt;
        volumeData.maxAlt = coord[2] > volumeData.maxAlt || isNaN(volumeData.maxAlt) ? coord[2] : volumeData.maxAlt;
    });
    let name = feature.properties.name;
    if (name === undefined) {
        name = "";
    }
    volumeData.opName = name;
    volumes.push({
        id: volumes.length,
        name: name,
        color: feature.properties.fill ? feature.properties.fill : "#A9A9A9",
        altitude_upper_hae: ExtractAltFromCoord(coords),
        positions: ConvertCoordinatesToPosition(coords),
        vertices: ConvertCoordinatesToVertices(coords),
        organization_id: volumeData.user.organization_id,
        time_start: volumeData.tempStartDate,
        time_end: volumeData.tempEndDate,
        state: "PLANNING",
        type: "polygon",
        alpha: 0.5
    });
}

// Returns object of volumes, altitude, name -- For Alert Volumes.
export function ConvertFeaturesToAlertVolumes(features) {
    const volumes = [];
    const volumeData = {
        minAlt: 0,
        maxAlt: 0,
        opName: ""
    };

    for (const feature of features) {
        if (!feature.geometry) {
            continue;
        } else if (feature.geometry.type === "GeometryCollection") {
            // Check for additional Geometry blocks.
            for (const geometry of feature.geometry.geometries) {
                ConvertGeometryToAlertVolume(volumes, volumeData, feature, geometry);
            }
        } else if (feature.geometry.type === "Polygon" || feature.geometry.type === "LineString") {
            ConvertGeometryToAlertVolume(volumes, volumeData, feature, feature.geometry);
        }
    }

    return {
        volumes: volumes,
        minAlt: volumeData.minAlt,
        maxAlt: volumeData.maxAlt,
        opName: volumeData.opName
    };
}

// Converts a single Geometry (of type Polygon or LineString) into a Volume -- For Alert Volumes.
function ConvertGeometryToAlertVolume(volumes, volumeData, feature, geometry) {
    if (!geometry || (geometry.type !== "Polygon" && geometry.type !== "LineString")) {
        return;
    }

    const coords = GetGeometryCoordinates(geometry);
    coords.forEach((coord) => {
        volumeData.minAlt = coord[2] < volumeData.minAlt || isNaN(volumeData.minAlt) ? coord[2] : volumeData.minAlt;
        volumeData.maxAlt = coord[2] > volumeData.maxAlt || isNaN(volumeData.maxAlt) ? coord[2] : volumeData.maxAlt;
    });

    volumeData.opName = feature.properties.name;
    volumes.push({
        positions: ConvertCoordinatesToPosition(coords),
        vertices: ConvertCoordinatesToVertices(coords),
        type: "polygon"
    });
}

// Gets the coordinates of a Geometry type -- and closes it if necessary.
function GetGeometryCoordinates(geometry) {
    let coords;
    if (geometry.type == "Polygon") {
        coords = geometry.coordinates[0];
    } else {
        coords = geometry.coordinates;
    }

    // If the Geometry shape is not closed, close it by adding the starter point to the end.
    if (coords[0][0] != coords[coords.length - 1][0] || coords[0][1] != coords[coords.length - 1][1]) {
        coords.push(coords[0]);
    }

    return coords;
}

export function ConvertFeetToMeters(feet) {
    const meters = parseFloat((feet * 0.3048).toFixed(2));
    return meters;
}

export function ConvertMetersToFeet(meters) {
    const feet = parseFloat(Math.round(meters * 3.28084));
    return feet;
}

export function ConvertSpeedToKnots(speed) {
    const knots = parseFloat(Math.round(speed * 1.94384));
    return knots;
}

export function GetVerticalSpeedIndicator(fpm) {
    const indicator = fpm >= 250 ? `↑` : fpm <= -250 ? `↓` : "";
    return indicator;
}

export function IsValidDate(dateString) {
    const time = new Date(dateString).getTime();
    return !!time && !isNaN(time);
}

export function IsInvalidDate(startDate, endDate) {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    return !IsValidDate(startDate) || !IsValidDate(endDate) || start > end;
}

export function DateHasAlreadyPassed(dateString) {
    const start = new Date(dateString).getTime();
    const currentTime = new Date().getTime();
    return !IsValidDate(dateString) || start < currentTime;
}

// Checks if the inputted min/max altitudes are invalid.
export function IsInvalidAltitude(minAltitude, maxAltitude) {
    const min = parseFloat(minAltitude);
    const max = parseFloat(maxAltitude);

    if (isNaN(min) || isNaN(max) || min > max || min < 0 || max <= 0) {
        return true;
    }

    return false;
}

// Converts each entity's volume from feet to meters.
export function ConvertAltitudeToMeters(entities, minAltitude, maxAltitude) {
    const newMinAltitude = parseFloat(minAltitude);
    const newMaxAltitude = parseFloat(maxAltitude);

    entities.forEach((entity) => {
        if (entity.polygon) {
            entity.polygon.height = ConvertFeetToMeters(newMinAltitude || 0);
            entity.polygon.extrudedHeight = ConvertFeetToMeters(newMaxAltitude || 0);
        } else if (entity.ellipse) {
            entity.ellipse.height = ConvertFeetToMeters(newMinAltitude || 0);
            entity.ellipse.extrudedHeight = ConvertFeetToMeters(newMaxAltitude || 0);
        }
    });
}

// Converts a Volume back into the Features format.
export function ConvertVolumesToFeatures(volumes) {
    const features = [];
    for (const volume of volumes) {
        features.push({
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [ConvertVerticesToCoordinates(volume.polygon)]
            }
        });
    }
    return features;
}

export function ConvertVerticesToCoordinates(vertices) {
    const coords = [];
    for (const vertex of vertices) {
        coords.push([vertex.lng, vertex.lat, 0]);
    }
    return coords;
}

export function ConvertNauticalMilesToMeters(nauticalMiles) {
    return nauticalMiles * 1852;
}

export function GetTimezone() {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date();
    const timezoneAbbreviation = Intl.DateTimeFormat(undefined, { timeZone: userTimezone, timeZoneName: "short" })
        .formatToParts(date)
        .find((part) => part.type === "timeZoneName").value;

    return timezoneAbbreviation;
}

export function IsValidLaancCode(code) {
    if (code && code.length === 12 && code.startsWith("CAL")) {
        return true;
    } else {
        return false;
    }
}

export function GetNewEndDate(prev_start, prev_end, new_start) {
    const prev_start_ms = new Date(prev_start).getTime();
    const prev_end_ms = new Date(prev_end).getTime();

    const new_start_ms = new Date(new_start).getTime();
    const duration_ms = prev_end_ms - prev_start_ms;
    return new Date(new_start_ms + duration_ms);
}

export function GetDegreesArrayFromLatLongAndRadius(latitude, longitude, radius) {
    const coordinates = [];
    const numPoints = 36;
    for (let i = 0; i < numPoints; i++) {
        const angle = (360 / numPoints) * i;
        const radians = angle * (Math.PI / 180);

        const latOffset = (radius / 111300) * Math.sin(radians);
        const lonOffset = (radius / (111300 * Math.cos(latitude * (Math.PI / 180)))) * Math.cos(radians);

        const lat = latitude + latOffset;
        const lon = longitude + lonOffset;

        coordinates.push(lon);
        coordinates.push(lat);
    }
    return coordinates;
}

export function getOperationDateFromIsoString(iso) {
    const currentDate = new Date();
    const targetDate = new Date(iso);

    const timeDifference = Math.abs(targetDate.getTime() - currentDate.getTime());
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hoursDifference < 24) {
        let hour = targetDate.getHours();
        const minute = targetDate.getMinutes();

        const amOrPm = hour >= 12 ? "PM" : "AM";
        hour = hour % 12 || 12;
        return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")} ${amOrPm}`;
    } else {
        const month = targetDate.getMonth() + 1;
        const day = targetDate.getDate();
        return `${month}/${day}`;
    }
}

export function getConstraintDurationFromInput(input) {
    if (typeof input === "string") {
        if (input === "") {
            return input;
        } else if (/^[0-9]*$/.test(input) && input.length < 8) {
            return input;
        }
    }
    return undefined;
}

export function getFilteredVolumesFromUserInputs(volumes, start_date, duration_min) {
    const duration = duration_min || 60;
    const startTime = start_date && start_date.isValid() ? start_date.valueOf() : new Date().getTime();
    const endTime = dayjs(startTime).add(duration, "minutes").valueOf();
    return volumes.filter((volume) => {
        const volumeStartTime = volume.time_start ? new Date(volume.time_start).getTime() : null;
        const volumeEndTime = volume.time_end ? new Date(volume.time_end).getTime() : null;
        if (volumeStartTime && volumeEndTime) {
            return startTime <= volumeEndTime && endTime >= volumeStartTime;
        } else {
            return false;
        }
    });
}

export function getSourceIdsFromUserInput(event) {
    if (typeof event.target.value === "string") {
        return event.target.value.trim().replace(/^[,\s]+|[,\s]+$/g, "");
    } else {
        return "";
    }
}
