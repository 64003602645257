import React, { useState, useEffect } from "react";

import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AvatarGroup from "@mui/material/AvatarGroup";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SendIcon from "@mui/icons-material/Send";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Chatbox } from "./chatbox";
import { ConversationContextMenu } from "./conversationContextMenu";
import { CreateConversationDialog } from "./createConversationDialog";
import { getConversationHeaderFromRecipientsAndUser } from "./chatUtil";

import { useUserAuth } from "../contexts/authContext";
import { useChat } from "../contexts/chatContext";

const ConversationList = ({ setMessageInput }) => {
    const [conversationContextMenuUUID, setConversationContextMenuUUID] = useState(null);
    const [conversationContextMenuAnchorEl, setConversationContextMenuAnchorEl] = useState(null);
    const [conversationContextMenuOpen, setConversationContextMenuOpen] = useState(false);
    const [createConversationDialogOpen, setCreateConversationDialogOpen] = useState(false);

    const { chatSoundOn, setChatSoundOn, visibleConversations, setSelectedConversationUUID } = useChat();
    const { user } = useUserAuth();

    const isDesktop = useMediaQuery("(min-width:900px)");

    const handleSelectedConversationChange = (conversationUUID) => {
        setSelectedConversationUUID(conversationUUID);
        setMessageInput("");
    };
    const handleOpenConversationOptions = (e, conversation) => {
        setConversationContextMenuOpen(true);
        setConversationContextMenuAnchorEl(e.currentTarget);
        setConversationContextMenuUUID(conversation.conversation_uuid);
        e.stopPropagation();
    };
    return (
        <Box>
            {isDesktop === false ? (
                <Box>
                    <Toolbar />
                    <Divider />
                </Box>
            ) : (
                <></>
            )}

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: "8px 16px" }}>
                <Typography>Messages</Typography>

                {chatSoundOn === true ? (
                    <IconButton onClick={() => setChatSoundOn(false)}>
                        <VolumeUpIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => setChatSoundOn(true)}>
                        <VolumeOffIcon />
                    </IconButton>
                )}
            </Box>

            {visibleConversations.map((conversation, i) => (
                <Tooltip key={i} title={getConversationHeaderFromRecipientsAndUser(conversation.recipientEmails, user)} placement="top" enterDelay={2000}>
                    <MenuItem
                        data-testid="conversation"
                        className={"cmp-ui-conversation-menu-item"}
                        onClick={() => handleSelectedConversationChange(conversation.conversation_uuid)}
                    >
                        <AvatarGroup
                            max={2}
                            sx={{ width: "60px", justifyContent: "center", mr: "12px", "& .MuiAvatar-root": { width: 30, height: 30, fontSize: 15 } }}
                        >
                            {conversation.recipientEmails.slice(0, -1).map((e, i) => (
                                <Avatar key={i} sx={{ width: 30, height: 30, fontSize: 15 }} />
                            ))}
                        </AvatarGroup>

                        <ListItemText primaryTypographyProps={{ fontWeight: conversation.unreadMessages.length ? "900" : "100", noWrap: true }}>
                            {getConversationHeaderFromRecipientsAndUser(conversation.recipientEmails, user)}
                        </ListItemText>

                        <MoreVertIcon className={"cmp-ui-conversation-menu-item-options"} onClick={(e) => handleOpenConversationOptions(e, conversation, i)} />
                    </MenuItem>
                </Tooltip>
            ))}

            <Divider />

            <MenuItem onClick={() => setCreateConversationDialogOpen(true)}>
                <ListItemIcon>
                    <AddBoxIcon data-testid="createConversation" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create New Message</ListItemText>
            </MenuItem>

            <ConversationContextMenu
                conversationContextMenuUUID={conversationContextMenuUUID}
                conversationContextMenuAnchorEl={conversationContextMenuAnchorEl}
                conversationContextMenuOpen={conversationContextMenuOpen}
                handleSelectedConversationChange={handleSelectedConversationChange}
                setConversationContextMenuOpen={setConversationContextMenuOpen}
            />
            <CreateConversationDialog
                createConversationDialogOpen={createConversationDialogOpen}
                setCreateConversationDialogOpen={setCreateConversationDialogOpen}
            />
        </Box>
    );
};

export const Chat = () => {
    const [messageInput, setMessageInput] = useState("");
    const [mobileOpen, setMobileOpen] = useState(false);
    const [recipients, setRecipients] = useState([]);

    const { user, socket, getOrganizationByID, handleFailedFetch } = useUserAuth();
    const { sendMessage, selectedConversation } = useChat();

    const isDesktop = useMediaQuery("(min-width:900px)");

    useEffect(() => {
        const convo = selectedConversation;
        if (!socket || !convo || !convo.unreadMessages || convo.unreadMessages.length === 0) {
            return;
        }
        // Initially read conversation if message comes through and you are on the chat tab.
        if (!document.hidden) {
            socket.emit("readConversation", convo.conversation_uuid, user.id);
        }
        // If already on chat but in a different window, read conversation when swapping back to the CMP.
        const handleVisibilityChange = () => {
            if (convo?.unreadMessages?.length > 0 && !document.hidden) {
                socket.emit("readConversation", selectedConversation.conversation_uuid, user.id);
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [selectedConversation]);

    useEffect(() => {
        if (!selectedConversation || !selectedConversation.recipients) {
            return;
        }
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("api/user/get_airspace_managers", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const recipients = selectedConversation.recipientEmails.map((recipient) => {
                    const airspaceManager = data.find((airspaceManager) => airspaceManager.email === recipient);
                    if (airspaceManager) {
                        return `${recipient} (${getOrganizationByID(airspaceManager.organization_id).name} - Airspace Manager)`;
                    } else {
                        return recipient;
                    }
                });
                setRecipients(recipients);
            })
            .catch((err) => handleFailedFetch(err));
    }, [selectedConversation?.recipients]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            sendMessage(messageInput, setMessageInput);
        }
    };
    return (
        <Box sx={{ maxWidth: "2000px !important", p: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Chat
            </Typography>

            <Paper sx={{ my: 2, display: "flex", flexDirection: "column", height: "90%" }}>
                <Box sx={{ display: "flex", height: "100%" }}>
                    {isDesktop ? (
                        <Drawer
                            PaperProps={{ sx: { position: "relative", borderRadius: "4px", width: "260px", flexShrink: 0 } }}
                            variant="permanent"
                            anchor="left"
                        >
                            <ConversationList setMessageInput={setMessageInput} />
                        </Drawer>
                    ) : (
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={() => setMobileOpen(false)}
                            ModalProps={{ keepMounted: true }}
                            PaperProps={{ sx: { position: "relative", boxSizing: "border-box", width: "260px" } }}
                        >
                            <ConversationList setMessageInput={setMessageInput} />
                        </Drawer>
                    )}

                    <Box sx={{ p: 2, height: "100%", width: "-webkit-fill-available", position: "relative" }}>
                        <AppBar position="relative">
                            <Toolbar>
                                {isDesktop === false ? (
                                    <IconButton color="inherit" aria-label="open drawer" data-testid="mobile" onClick={() => setMobileOpen((prev) => !prev)}>
                                        <MenuIcon />
                                    </IconButton>
                                ) : (
                                    <></>
                                )}
                                {selectedConversation ? (
                                    <Tooltip title={getConversationHeaderFromRecipientsAndUser(recipients, user)} placement="top" enterDelay={2000}>
                                        <Typography variant="h6" noWrap>
                                            {getConversationHeaderFromRecipientsAndUser(recipients, user)}
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}
                            </Toolbar>
                        </AppBar>

                        {selectedConversation ? (
                            <Box>
                                <Container sx={{ height: "calc(100% - 160px)", overflowY: "scroll", py: "16px", mt: "16px", maxWidth: "100% !important" }}>
                                    <Chatbox messages={selectedConversation.messages} />
                                </Container>

                                <TextField
                                    label="Message"
                                    id="messageInput"
                                    inputProps={{ "data-testid": "messageInput" }}
                                    sx={{ width: "calc(100% - 32px)", position: "absolute", bottom: "16px" }}
                                    onChange={(e) => setMessageInput(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    value={messageInput}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => sendMessage(messageInput, setMessageInput)} id="sendMessage">
                                                    <SendIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};
