import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";
import { useUserAuth } from "../contexts/authContext";
import { ErrorComponent } from "../errorComponent";

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #121212 inset" };

export function Login() {
    const [odotDeployment, setOdotDeployment] = useState("");

    const { statusMessage, login } = useUserAuth();

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("api/env/get/odot", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setOdotDeployment(data))
            .catch((err) => console.error(err));
    }, []);

    const handleLoginCAL = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        login({
            email: data.get("email"),
            password: data.get("password")
        });
    };
    return (
        <Grid item xs={16}>
            <div style={{ justifyContent: "space-around", width: "100%", display: "inline-flex", height: "500px" }}>
                <Box sx={{ justifyContent: "space-around", alignItems: "center", display: "flex", maxWidth: "400px" }}>
                    <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>

                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>

                        <Box component="form" onSubmit={handleLoginCAL} noValidate sx={{ m: 4 }}>
                            <TextField
                                inputProps={{ style: inputStyle, "data-testid": "email" }}
                                label="Email Address"
                                margin="normal"
                                name="email"
                                id="email"
                                fullWidth
                                autoFocus
                                required
                            />
                            <TextField
                                inputProps={{ style: inputStyle, "data-testid": "password" }}
                                autoComplete="current-password"
                                label="Password"
                                margin="normal"
                                name="password"
                                type="password"
                                id="password"
                                fullWidth
                                required
                            />

                            <ErrorComponent statusMessage={statusMessage} />

                            <Button type="submit" fullWidth variant="contained" id="login" sx={{ my: 2 }}>
                                Sign In
                            </Button>

                            {window.location.href === odotDeployment ? (
                                <>
                                    <Divider> Or </Divider>

                                    <Button href="/api/user/login/ohid" type="button" fullWidth variant="contained" sx={{ my: 2 }}>
                                        Continue with OHID
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}

                            <Box
                                className="cmp-ui-login-routes"
                                sx={{
                                    my: 1,
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    justifyContent: { xs: "center", sm: "space-between" },
                                    alignItems: "center"
                                }}
                            >
                                <Link to="/forgot">{"Forgot password?"}</Link>
                                <Link to="/signup">{"Don't have an account? Sign Up"}</Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Grid>
    );
}
