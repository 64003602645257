import React from "react";

import Box from "@mui/material/Box";
import OpenLayersFullScreen from "./olFullscreen";

export default function OpenLayersMap({ map, buttons, children }) {
    return (
        <Box id="map" sx={{ width: "100%", height: "100%", position: "relative" }}>
            {children}
            <Box sx={{ position: "absolute", zIndex: 1, right: "8px", top: "8px", display: "flex", flexDirection: "row", gap: "8px" }}>
                {buttons.map(({ id, name, icon, onClick, visible }, i) =>
                    visible === true ? (
                        <button key={i} id={id} title={name} type="button" onClick={onClick} className="control-button">
                            {icon || name}
                        </button>
                    ) : (
                        <React.Fragment key={i} />
                    )
                )}
                <OpenLayersFullScreen map={map} />
            </Box>
        </Box>
    );
}
