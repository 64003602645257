import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useLocalStorage } from "../hooks/useLocalStorage";
import MapSettings from "./settingsMap";
import SourceSettings from "./settingsSource";
import MiscSettings from "./settingsMisc";

export function Settings() {
    const [tab, setTab] = useLocalStorage("userSettingsTab", "1");

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", p: 3 }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Settings
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column" }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs onChange={handleTabChange} aria-label="Settings" variant="scrollable" value={tab} scrollButtons allowScrollButtonsMobile>
                            <Tab label="Map Settings" value="1" id="map" />
                            <Tab label="Source Settings" value="2" id="source" />
                            <Tab label="Miscellaneous Settings" value="3" id="misc" />
                        </Tabs>
                    </Box>
                    <TabPanel value="1">
                        <MapSettings />
                    </TabPanel>
                    <TabPanel value="2">
                        <SourceSettings />
                    </TabPanel>
                    <TabPanel value="3">
                        <MiscSettings />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Grid>
    );
}

export default Settings;
