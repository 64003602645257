import React, { useState, createContext, useContext } from "react";

export const EnvContext = createContext();

export function useEnv() {
    return useContext(EnvContext);
}

export function EnvProvider({ children }) {
    const [dataLoggingOn, setDataLoggingOn] = useState("false");
    const [odotDeployment, setOdotDeployment] = useState("");
    const [laancOn, setLaancOn] = useState("false");
    const [laancTileServerUrl, setLaancTileServerUrl] = useState("");
    const [baseMapTileOptions, setBaseMapTileOptions] = useState([]);

    const fetchEnvVariables = () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("api/env/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => updateEnvVariables(data))
            .catch((err) => console.error(err));
    };
    const updateEnvVariables = (data) => {
        const data_logging_on = data.DATA_LOGGING_ON;
        setDataLoggingOn(data_logging_on);

        const odot_deployment = data.ODOT_DEPLOYMENT;
        setOdotDeployment(odot_deployment);

        const laancOn = data.LAANC_ON;
        setLaancOn(laancOn);

        const laancTileServerUrl = data.LAANC_TILESERVER_URL;
        setLaancTileServerUrl(laancTileServerUrl);

        try {
            const base_map_tile_options = JSON.parse(data.BASE_MAP_TILE_OPTIONS);
            setBaseMapTileOptions(base_map_tile_options);
        } catch (err) {
            console.error(err);
        }

        // add more here...
    };

    const value = {
        dataLoggingOn,
        odotDeployment,
        laancOn,
        fetchEnvVariables,
        laancTileServerUrl,
        baseMapTileOptions,
        setBaseMapTileOptions
    };

    return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
}
