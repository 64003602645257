import React, { createContext, useContext, useEffect, useState } from "react";

import { BingMaps, TileJSON } from "ol/source";

import { getMapTileOptionIconFromOptionType } from "../map/mapUtil";

import { useUserAuth } from "./authContext";
import { useEnv } from "./envContext";

export const MapContext = createContext();

export function useMap() {
    return useContext(MapContext);
}

export function MapProvider({ children }) {
    const [planningOperations, setPlanningOperations] = useState([]);
    const [publishedOperations, setPublishedOperations] = useState([]);
    const [constraints, setConstraints] = useState([]);
    const [alertVolumes, setAlertVolumes] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [sourceData, setSourceData] = useState(new Map());
    const [colors, setColors] = useState([]);
    const [drones, setDrones] = useState([]);
    const [visibleDrones, setVisibleDrones] = useState([]);
    const [uniqueSources, setUniqueSources] = useState([]);
    const [mapTileOptions, setMapTileOptions] = useState([]);
    const [components, setComponents] = useState([]);

    const { user, socket, handleFailedFetch } = useUserAuth();
    const { baseMapTileOptions } = useEnv();

    useEffect(() => {
        if (socket) {
            socket.emit("getOperations");
            socket.emit("getOperationsPlanning");
            socket.emit("getConstraints");
            socket.emit("getAlertVolumes");
        }
    }, [socket]);

    useEffect(() => {
        if (!socket || !user) {
            return;
        }

        const setupVolumeIds = (volumes) => {
            const volumeCount = {};
            return volumes.map((volume) => {
                const name = volume.name;
                const count = volumeCount[name] ? volumeCount[name] + 1 : 1;
                volumeCount[name] = count;
                return {
                    ...volume,
                    volumeId: `${name}-${count}`
                };
            });
        };
        socket.on("op_plan", (message) => {
            if (user.user_role_id === 0) {
                setPlanningOperations(setupVolumeIds(message));
            } else {
                setPlanningOperations(setupVolumeIds(message.filter((op) => op.organization_id === user.organization_id)));
            }
        });
        socket.on("operation", (message) => {
            setPublishedOperations(setupVolumeIds(message));
        });
        socket.on("constraint", (message) => {
            const visibleConstraints = message.filter((constraint) => {
                if (user.user_role === "Operations Manager" || user.user_role === "Operator" || user.user_role === "Admin - Org") {
                    return constraint.state === "ACCEPTED";
                } else if (user.user_role === "First Responder") {
                    return constraint.created_user_id === user.id;
                } else {
                    return true;
                }
            });
            setConstraints(setupVolumeIds(visibleConstraints));
        });
        socket.on("alert_volume", (message) => {
            const visibleAlerts = message.filter((alert) => {
                if (user.user_role === "Admin") {
                    return true;
                } else if (user.user_role === "Operator") {
                    return alert.created_user_id === user.id;
                } else if (user.user_role !== "First Responder") {
                    return alert.organization_id === user.organization_id;
                } else {
                    return false;
                }
            });
            setAlertVolumes(setupVolumeIds(visibleAlerts));
        });
        return () => {
            if (socket) {
                socket.off("alert_volume");
                socket.off("constraint");
                socket.off("operation");
                socket.off("op_plan");
            }
        };
    }, [socket, user]);

    useEffect(() => {
        const sensorMap = new Map();
        sensors.forEach((sensor) => {
            sensorMap.set(sensor.truth_source, sensor.name);
        });
        setSourceData(sensorMap);
    }, [sensors]);

    useEffect(() => {
        if (Array.isArray(baseMapTileOptions) === false || baseMapTileOptions.length < 1) {
            const default_options = [
                {
                    index: 0,
                    title: "Street (Light)",
                    url: getMapTileOptionIconFromOptionType("LIGHT"),
                    style_url: `https://tileserver-onboarding.calanalytics.com/styles/osm-light/style.json`
                },
                {
                    index: 1,
                    title: "Street (Dark)",
                    url: getMapTileOptionIconFromOptionType("DARK"),
                    style_url: `https://tileserver-onboarding.calanalytics.com/styles/osm-dark/style.json`
                }
            ];
            setMapTileOptions(default_options);
        } else {
            const base_map_tile_options = baseMapTileOptions.map(({ name, icon, type, source, key }, i) => {
                const option = {
                    index: i,
                    title: name,
                    url: getMapTileOptionIconFromOptionType(icon)
                };
                if (type === "TILE_JSON") {
                    option.source = new TileJSON({ url: `${source}`, crossOrigin: "anonymous" });
                } else if (type === "BING_MAPS") {
                    option.source = new BingMaps({ key: key, imagerySet: source });
                } else if (type === "VECTOR_BASEMAP") {
                    option.style_url = source;
                }
                return option;
            });
            setMapTileOptions(base_map_tile_options);
        }
    }, [baseMapTileOptions]);

    useEffect(() => {
        if (!user || (user.user_role !== "Admin" && user.user_role !== "Airspace Manager")) {
            return;
        }

        const fetchComponents = async () => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            };
            await fetch("/api/hms/components/getwithstatus", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setComponents(data))
                .catch((err) => handleFailedFetch(err));
        };

        fetchComponents();
        const interval = setInterval(() => fetchComponents(), 30000);

        return () => {
            setComponents([]);
            clearInterval(interval);
        };
    }, [user]);

    const fetchMapData = () => {
        fetchSensors();
        fetchColors();
        getDrones();
        getUniqueSources();
    };

    const fetchSensors = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/sensors/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setSensors(data))
            .catch((err) => handleFailedFetch(err));
    };

    const fetchColors = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/misc/getColors", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setColors(data))
            .catch((err) => handleFailedFetch(err));
    };

    const getDrones = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("/api/drone/getAll", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                setDrones(data);
                const visibleDrones = data.filter((drone) => {
                    if (user.user_role_id === 0) {
                        return true;
                    } else {
                        return drone.organization_id === user.organization_id;
                    }
                });
                setVisibleDrones(visibleDrones);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const getUniqueSources = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("/api/asd/getUnique", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setUniqueSources(data))
            .catch((err) => handleFailedFetch(err));
    };

    const value = {
        planningOperations,
        publishedOperations,
        constraints,
        alertVolumes,
        sensors,
        sourceData,
        colors,
        drones,
        visibleDrones,
        uniqueSources,
        mapTileOptions,
        components,
        fetchMapData,
        fetchSensors,
        getDrones,
        getUniqueSources
    };

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}
