import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import KML from "ol/format/KML.js";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import Translate from "ol/interaction/Translate";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";

import Circle from "ol/geom/Circle";
import GeometryCollection from "ol/geom/GeometryCollection";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";

import { allModels } from "../../map/mapModels";
import { GetColorFromState } from "../../util";
import { FILL_BLUE, GEOFENCE_STYLE, STROKE_WHITE, getColorFromLaancType } from "./olStyleUtil";

import { circle, lineString, buffer as turf_buffer } from "@turf/turf";
import { extrapolatePosition } from "../../map/mapUtil";
import { createEmpty, extend } from "ol/extent";

const GeoJson = new GeoJSON();
const Kml = new KML();

// Creates a new asset feature using the asset structure.
export function CreateAsset(asset) {
    const assetGeometry = GeoJson.readGeometry(asset.geometry);
    const assetFeature = new Feature(assetGeometry);
    assetFeature.setId(asset.uuid);
    assetFeature.setProperties({ name: asset.name, asset: asset });
    assetFeature.setStyle(
        new Style({
            fill: FILL_BLUE,
            stroke: STROKE_WHITE,
            text: new Text({
                text: asset.name,
                font: "bold 16px sans-serif",
                fill: new Fill({ color: "#FFFFFF" }),
                stroke: new Stroke({ color: "#000000", width: 3 }),
                textAlign: "center",
                textBaseline: "middle",
                offsetX: 0,
                offsetY: 0
            })
        })
    );
    return assetFeature;
}

// Creates a new entity feature using ASD.
export function CreateEntity(entityData) {
    let imageSrc = `../static/2d-models/shapes/${entityData.model}.png`;
    if (entityData.model === "icon") {
        imageSrc = allModels.get(0);
        if (entityData.sourceType === "TELEMETRY") {
            imageSrc = allModels.get(8);
        } else if (entityData.emitterType && allModels.has(entityData.emitterType)) {
            imageSrc = allModels.get(entityData.emitterType);
        }
    } else {
        entityData.size *= 0.75;
    }

    const entityFeature = new Feature(new Point(entityData.coordinates));
    entityFeature.setId(entityData.asd.id);
    entityFeature.setProperties({ name: entityData.asd.label, asd: entityData.asd });
    entityFeature.setStyle(
        new Style({
            image: new Icon({
                src: imageSrc,
                color: entityData.color,
                scale: entityData.size,
                rotation: entityData.rotation
            })
        })
    );
    return entityFeature;
}

// Searches for a layer given its name.
export function GetLayer(map, layerName) {
    if (!map) {
        return null;
    }
    for (const layer of map.getLayers().getArray()) {
        if (layer.get("name") === layerName) {
            return layer;
        }
    }
    return null;
}

// Searches for a source given a layer's name.
export function GetSource(map, layerName) {
    const layer = GetLayer(map, layerName);
    return layer ? layer.getSource() : null;
}

// Searches for a list of features given a layer's name.
export function GetFeatures(map, layerName) {
    const source = GetSource(map, layerName);
    return source ? source.getFeatures() : null;
}

// Searches for a feature given a layer's name and feature's UUID.
export function GetFeature(map, layerName, featureUUID) {
    const source = GetSource(map, layerName);
    return source && featureUUID ? source.getFeatureById(featureUUID) : null;
}

export function GetAllFeaturesWithId(map, layer_name, feature_uuid) {
    const relevant_features = [];

    const source = GetSource(map, layer_name);
    if (source) {
        const all_features = source.getFeatures();

        if (Array.isArray(all_features)) {
            all_features.forEach((feature) => {
                const feature_id = feature.getId();
                if (feature_id.includes(feature_uuid)) {
                    relevant_features.push(feature);
                }
            });
        }
    }
    return relevant_features;
}

// Jumps to the specified feature on the map.
export function JumpToFeature(map, feature) {
    if (!map || !feature) {
        return;
    } else if (feature.get("asd")) {
        map.getView().fit(feature.getGeometry(), { maxZoom: 12 });
    } else if (feature.getGeometry() instanceof GeometryCollection) {
        for (const geo of feature.getGeometry().getGeometries()) {
            if (geo instanceof Polygon || geo instanceof LineString) {
                map.getView().fit(geo, { padding: [200, 200, 200, 200] });
                break;
            }
        }
    } else {
        map.getView().fit(feature.getGeometry(), { padding: [200, 200, 200, 200] });
    }
}

export function JumpToFeatures(map, features) {
    if (features.length === 0) {
        return;
    }
    const extent = createEmpty();
    features.forEach((feature) => {
        extend(extent, feature.getGeometry().getExtent());
    });
    map.getView().fit(extent, {
        maxZoom: 16,
        padding: [200, 200, 200, 200]
    });
}

// Converts a feature into the GeoJSON format.
export function ConvertGeometryToGeoJson(geometry) {
    if (typeof geometry.getRadius === "function") {
        const polygon = circle(geometry.getCenter(), geometry.getRadius(), { units: "degrees", steps: 128 });
        return polygon.geometry;
    } else {
        return GeoJson.writeGeometryObject(geometry);
    }
}

export function GetCoordinatesForSubmissions(geometry) {
    if (typeof geometry.getRadius === "function") {
        return { coordinates: [[geometry.getCenter()]] };
    } else {
        if (geometry instanceof GeometryCollection) {
            const geometries = geometry
                .getGeometries()
                .map((geo) => {
                    if (geo instanceof Polygon) {
                        return GeoJson.writeGeometryObject(geo);
                    }
                    return null;
                })
                .filter(Boolean); // Remove nulls or undefined from the array

            return geometries.length === 1 ? geometries[0] : geometries;
        } else {
            return GeoJson.writeGeometryObject(geometry);
        }
    }
}

// Converts our CMP volumes structure to an array of OL features.
export function ConvertCmpVolumesToFeatures(cmpVolumes, userMapSettings) {
    const features = [];
    for (const cmpVolume of cmpVolumes) {
        const volumes = "volumes" in cmpVolume ? cmpVolume.volumes : cmpVolume.polygons;
        for (let i = 0; i < volumes.length; ++i) {
            const volume = volumes[i];

            let geometry;
            if (volume.polygon) {
                const coordinates = [volume.polygon.map(({ lng, lat }) => [lng, lat])];
                geometry = new Polygon(coordinates);
            } else if (volume.type === "polygon") {
                const coordinates = [volume.vertices.map(({ lng, lat }) => [lng, lat])];
                geometry = new Polygon(coordinates);
            } else if (volume.circle) {
                const center = [volume.circle.center.lng, volume.circle.center.lat];
                const radius = volume.circle.radius_m / 111320;
                geometry = new Circle(center, radius);
            } else if (volume.type === "circle") {
                const center = [volume.lng, volume.lat];
                const radius = volume.radius / 111320;
                geometry = new Circle(center, radius);
            }
            if (!geometry) {
                continue;
            }
            const feature = new Feature({
                geometry: geometry
            });
            feature.set("name", cmpVolume.name);

            if ("flight_uuid" in cmpVolume && "uuid" in cmpVolume === false) {
                feature.setId(`${cmpVolume.flight_uuid}_${i}`);
                feature.set("flight", cmpVolume);
            } else if ("constraint_uuid" in cmpVolume) {
                feature.setId(`${cmpVolume.constraint_uuid}_${i}`);
                feature.set("constraint", cmpVolume);
            } else if ("uuid" in cmpVolume) {
                feature.setId(`${cmpVolume.uuid}_${i}`);
                feature.set("alert_volume", cmpVolume);
            }
            const style = getGeometryStyleFromFeatureAndSettings(feature, userMapSettings);
            feature.setStyle(style);

            features.push(feature);
        }
    }
    return features;
}

export function ConvertLAANCVolumesToFeatures(laancVolumes) {
    const features = [];

    laancVolumes.forEach((volume, index) => {
        let geometry;
        if (volume.polygon) {
            const coordinates = [volume.polygon.map(({ lng, lat }) => [lng, lat])];
            geometry = new Polygon(coordinates);
        } else if (volume.circle) {
            const center = [volume.circle.center.lng, volume.circle.center.lat];
            const radius = volume.circle.radius_m / 111320;
            geometry = new Circle(center, radius);
        }
        if (!geometry) {
            return;
        }
        const feature = new Feature({
            geometry: geometry
        });
        feature.set("name", "Volume " + (index + 1));
        feature.setId("Volume " + (index + 1));
        feature.set("laanc_volume", volume);

        const style = new Style({
            fill: new Fill({
                color: getColorFromLaancType(volume.faa_request_type, 0.5)
            }),
            stroke: new Stroke({
                color: getColorFromLaancType(volume.faa_request_type, 1),
                width: 2
            })
        });
        feature.setStyle(style);
        features.push(feature);

        index += 1;
    });

    return features;
}

// Converts a KML file into an OL feature.
// It will include "name" and "altitudes" properties.
export async function ConvertKmlFileToFeatures(file) {
    return new Promise((resolve, reject) => {
        if (!/(\.kml|\.kmz)$/i.exec(file.name.toLowerCase())) {
            return reject("Please choose a valid KML/KMZ file type.");
        } else if (file.size > 500000) {
            return reject("Please choose a KML file that is less than 500KB.");
        }

        const fileReader = new FileReader();
        //const features = [];
        fileReader.onloadend = (e) => {
            const kmlDoc = new DOMParser().parseFromString(e.target.result, "text/xml");
            const kmlFeatures = Kml.readFeatures(kmlDoc);
            if (!("name" in kmlFeatures[0].getProperties)) {
                kmlFeatures[0].set("name", "KML File");
            }
            kmlFeatures[0].set("altitudes", ExtractAltitudesFromFeatures(kmlFeatures));
            resolve(kmlFeatures);
        };
        fileReader.onerror = () => reject("KML file could not be parsed correctly.");
        fileReader.readAsText(file);
    });
}

// Extracts the minimum and maximum altitudes out of an OL geometry.
export function ExtractAltitudesFromFeatures(features) {
    const altitudes = {
        minAltitude: 0,
        maxAltitude: 0
    };

    const extractAltitudes = (geometry) => {
        if (!geometry || (!(geometry instanceof Polygon) && !(geometry instanceof LineString))) {
            return;
        }
        const coordinates = geometry instanceof Polygon ? geometry.getCoordinates()[0] : geometry.getCoordinates();
        for (const coordinate of coordinates) {
            altitudes.minAltitude = Math.min(altitudes.minAltitude, coordinate[2]);
            altitudes.maxAltitude = Math.max(altitudes.maxAltitude, coordinate[2]);
        }
    };
    features.forEach((feature) => {
        const geometry = feature.getGeometry();
        if (geometry instanceof GeometryCollection) {
            geometry.getGeometries().forEach((geo) => extractAltitudes(geo));
        } else {
            extractAltitudes(geometry);
        }
    });
    return altitudes;
}

// Checks if the map is currently being drawn/modified/snapped on.
export function IsMapBeingDrawnOn(map) {
    for (const interaction of map.getInteractions().getArray()) {
        if (interaction instanceof Draw || interaction instanceof Modify || interaction instanceof Translate || interaction instanceof Snap) {
            return true;
        }
    }
    return false;
}

export function getGeofenceFeatureFromGeometryAndBuffer(geometry, buffer) {
    const coords = geometry.getCoordinates();
    const linestring = lineString(coords);
    const buffered = turf_buffer(linestring, buffer, { units: "meters" });

    const geofence_polygon = new Polygon(buffered.geometry.coordinates);
    const geofence_feature = new Feature({ geometry: geofence_polygon });
    geofence_feature.setStyle(GEOFENCE_STYLE);

    return geofence_feature;
}

export function convertHexColorToRgbaColor(hex, opacity) {
    hex = hex.replace("#", "");

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getGeometryStyleFromColor(color_hex) {
    return new Style({
        stroke: new Stroke({
            color: convertHexColorToRgbaColor(color_hex, 1),
            width: 2
        }),
        fill: new Fill({
            color: convertHexColorToRgbaColor(color_hex, 0.2)
        })
    });
}

export function getGeometryStyleFromFeatureAndSettings(feature, userMapSettings) {
    const properties = feature.getProperties();

    let color_hex = "#000";
    if ("flight" in properties) {
        color_hex = GetColorFromState(properties.flight.state, userMapSettings.op_state_settings);
    } else if ("constraint" in properties) {
        color_hex = GetColorFromState(properties.constraint.state, userMapSettings.op_state_settings);
    } else if ("alert_volume" in properties) {
        color_hex = properties.alert_volume.color_rgb;
    }
    let text = undefined;
    if (userMapSettings.volume_labels) {
        text = new Text({
            text: feature.get("name"),
            font: "12px Calibri,sans-serif",
            fill: new Fill({ color: "#fff" }),
            stroke: new Stroke({ color: "#000", width: 2 }),
            overflow: true
        });
    }
    return new Style({
        stroke: new Stroke({
            color: convertHexColorToRgbaColor(color_hex, 1),
            width: 2
        }),
        fill: new Fill({
            color: convertHexColorToRgbaColor(color_hex, userMapSettings.op_opacity)
        }),
        text: text
    });
}

export function getPredLineCoordinatesFromTrackAndSettings(track, settings) {
    const bearing = track.course_deg;
    const distance = track.ground_speed_mps * settings.pred_line_lead_time;

    const start_lon = track.lon_deg;
    const start_lat = track.lat_deg;

    const [final_lat, final_lon] = extrapolatePosition(start_lat, start_lon, bearing, distance);

    return [
        [track.lon_deg, track.lat_deg],
        [final_lon, final_lat]
    ];
}

export function getPredLineStyleFromTrackAndSettings(track, settings) {
    const source_type_settings = settings.source_type_settings.find(({ source_type }) => {
        return source_type === track.source_type;
    });
    if (source_type_settings && settings.pred_line) {
        return new Style({
            stroke: new Stroke({
                color: source_type_settings.color,
                width: 2
            })
        });
    } else {
        return new Style({
            stroke: null
        });
    }
}

export function getHistLineCoordinatesFromTrack(track) {
    const history_line_coordinates = [];

    for (let i = 0; i < track.history_locations.length; i += 3) {
        const lon = track.history_locations[i];
        const lat = track.history_locations[i + 1];
        history_line_coordinates.push([lon, lat]);
    }
    return history_line_coordinates;
}

export function getHistLineStyleFromTrackAndSettings(track, settings) {
    const source_type_settings = settings.source_type_settings.find(({ source_type }) => {
        return source_type === track.source_type;
    });
    if (source_type_settings && settings.hist_line) {
        return new Style({
            stroke: new Stroke({
                color: source_type_settings.color,
                width: 2,
                lineDash: [5, 5]
            })
        });
    } else {
        return new Style({
            stroke: null
        });
    }
}
export function getTagButtonVisibility(user, feature) {
    if ("asd" in feature.getProperties()) {
        return user.user_role === "Admin" || user.user_role === "Airspace Manager" || user.user_role === "Operator";
    } else {
        return false;
    }
}
export function getPublishConstraintButtonVisibility(user, feature) {
    const properties = feature.getProperties();
    if ("constraint" in properties && properties.constraint.owner === "cmp-ui-online") {
        return properties.constraint.state !== "ACCEPTED" && (user.user_role === "Admin" || user.user_role === "Airspace Manager");
    } else {
        return false;
    }
}
export function getEditConstraintButtonVisibility(user, feature) {
    const properties = feature.getProperties();
    if ("constraint" in properties && properties.constraint.owner === "cmp-ui-online") {
        return user.user_role === "Admin" || user.user_role === "Airspace Manager";
    } else {
        return false;
    }
}
export function getDeleteConstraintButtonVisibility(user, feature) {
    const properties = feature.getProperties();
    if ("constraint" in properties && properties.constraint.owner === "cmp-ui-online") {
        if (user.user_role === "First Responder") {
            return properties.constraint.created_user_id == user.id;
        } else {
            return user.user_role === "Admin" || user.user_role === "Airspace Manager";
        }
    } else {
        return false;
    }
}

export function updateFeaturesZeroConflictionAlerts(feature, new_zero_conflict_alert) {
    if (new_zero_conflict_alert.state === "ACTIVE") {
        const current_zero_conflictions = feature.get("zero_confliction_alerts");
        if (Array.isArray(current_zero_conflictions)) {
            const zero_conflict_already_exists = current_zero_conflictions.find(({ alert_uuid }) => {
                return alert_uuid === new_zero_conflict_alert.alert_uuid;
            });
            if (zero_conflict_already_exists) {
                const updated_zero_conflictions = current_zero_conflictions.map((zero_conflict_alert) => {
                    if (zero_conflict_alert.alert_uuid === new_zero_conflict_alert.alert_uuid) {
                        return new_zero_conflict_alert;
                    } else {
                        return zero_conflict_alert;
                    }
                });
                feature.set("zero_confliction_alerts", updated_zero_conflictions);
            } else {
                feature.set("zero_confliction_alerts", [...current_zero_conflictions, new_zero_conflict_alert]);
            }
        } else {
            feature.set("zero_confliction_alerts", [new_zero_conflict_alert]);
        }
    } else if (new_zero_conflict_alert.state === "EXPIRED") {
        const current_zero_conflictions = feature.get("zero_confliction_alerts");
        if (Array.isArray(current_zero_conflictions)) {
            const updated_zero_conflict_alerts = current_zero_conflictions.filter(({ alert_uuid }) => {
                return alert_uuid !== new_zero_conflict_alert.alert_uuid;
            });
            if (updated_zero_conflict_alerts.length === 0) {
                feature.unset("zero_confliction_alerts");
            } else {
                feature.set("zero_confliction_alerts", updated_zero_conflict_alerts);
            }
        }
    }
}

// create a feature for zca line
export function createZeroConflictionLineFeature(ownship_feature, intruder_feature, zero_conflict_message) {
    const coordinates = getZeroConflictionLineCoordinates(ownship_feature, intruder_feature);
    const lineString = new LineString(coordinates);

    const feature = new Feature({
        geometry: lineString
    });
    const style = getZeroConflictionLineStyleFromZeroConflictMessage(zero_conflict_message, ownship_feature);
    feature.setStyle(style);

    const id = `${ownship_feature.getId()}_${intruder_feature.getId()}`;
    feature.setId(id);

    const ownship_asd_message = ownship_feature.get("asd");
    if (ownship_asd_message) {
        feature.set("asd", ownship_asd_message);
    }
    return feature;
}

// get coordinates to update the location of the zca line
export function getZeroConflictionLineCoordinates(ownship_feature, intruder_feature) {
    const ownship_coordinates = ownship_feature.getGeometry().getCoordinates();
    const intruder_coordinates = intruder_feature.getGeometry().getCoordinates();
    return [ownship_coordinates, intruder_coordinates];
}

// gets style for the line
export function getZeroConflictionLineStyleFromZeroConflictMessage(zero_conflict_message, ownship_feature) {
    let color_rgb = "255, 235, 0";
    if (zero_conflict_message.severity === "WARNING") {
        color_rgb = "200, 0, 0";
    }
    const ownship_style = ownship_feature.getStyle();
    if (Array.isArray(ownship_style)) {
        const first_style = ownship_style[0];
        if (first_style && first_style.getImage()) {
            return new Style({
                stroke: new Stroke({
                    color: `rgba(${color_rgb}, .8)`,
                    width: 2
                })
            });
        }
    }
    return new Style({
        stroke: undefined
    });
}

export function updateZeroConflictLineFeature(map, feature) {
    const zero_conflict_alerts = feature.get("zero_confliction_alerts");
    if (Array.isArray(zero_conflict_alerts)) {
        zero_conflict_alerts.forEach((zero_conflict_alert) => {
            const ownship_id = zero_conflict_alert.ownship_track.id;
            const ownship_feature = GetFeature(map, "asd", ownship_id);

            const intruder_id = zero_conflict_alert.intruder_track.id;
            const intruder_feature = GetFeature(map, "asd", intruder_id);

            const feature_id = `${ownship_id}_${intruder_id}`;
            const zero_conflict_line_feature = GetFeature(map, "asd", feature_id);

            if (zero_conflict_line_feature && intruder_feature && ownship_feature) {
                const updated_coordinates = getZeroConflictionLineCoordinates(ownship_feature, intruder_feature);
                zero_conflict_line_feature.getGeometry().setCoordinates(updated_coordinates);

                const ownship_asd_message = ownship_feature.get("asd");
                if (ownship_asd_message) {
                    zero_conflict_line_feature.set("asd", ownship_asd_message);
                }
            }
        });
    }
}
