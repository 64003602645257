import React, { useEffect, useRef, useState } from "react";
import { ConvertISOToDate } from "../util";
import { Cartesian3, Cartographic, Color, Math, SceneMode, ScreenSpaceEventType } from "cesium";
import { Viewer } from "resium";
import { getSystemColorFromType, getVolumeAltitudeFromType } from "./hmsUtil";
import { useUserAuth } from "../contexts/authContext";
import { useEnv } from "../contexts/envContext";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// component takes in 2 props
// - mapSystem - system to display
// - setMapSystem - sets the system to display

const HmsMapPreview = ({ mapSystem, setMapSystem }) => {
    const viewerRef = useRef(null);
    const cursorLocationRef = useRef(null);

    const [open, setOpen] = useState(false);

    const { providerViewModels } = useEnv();
    const { userMapSettings } = useUserAuth();

    useEffect(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        if (open === true && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            viewer.navigationHelpButton.viewModel.showInstructions = false;
            viewer.scene.screenSpaceCameraController.enableTilt = false;
            viewer.scene.globe.depthTestAgainstTerrain = false;
            viewer.scene.requestRenderMode = true;
            viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

            if (userMapSettings.map_preference >= 0 && userMapSettings.map_preference < providerViewModels.length) {
                viewer.baseLayerPicker.viewModel.selectedImagery = providerViewModels[userMapSettings.map_preference];
            }
            if (userMapSettings.map_scene_mode === "COLUMBUS_VIEW") {
                viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
            } else if (userMapSettings.map_scene_mode === "SCENE2D") {
                viewer.scene.mode = SceneMode.SCENE2D;
            }
            viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                viewer.camera.flyTo({
                    destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
                });
            });
            viewer.scene.morphComplete.addEventListener(() => {
                setTimeout(() => {
                    viewer.camera.flyTo({
                        destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
                    });
                }, [500]);
            });
            viewer.screenSpaceEventHandler.setInputAction(({ endPosition }) => {
                const cartesian = viewer.camera.pickEllipsoid(endPosition, viewer.scene.globe.ellipsoid);
                if (cartesian) {
                    const { longitude, latitude } = Cartographic.fromCartesian(cartesian);
                    cursorLocationRef.current.innerHTML = `( ${Math.toDegrees(latitude)}, ${Math.toDegrees(longitude)} )`;
                }
            }, ScreenSpaceEventType.MOUSE_MOVE);
            handleDrawSystems();
        }
    }, [open]);

    const handleDrawSystems = () => {
        const viewer = viewerRef.current.cesiumElement;
        const entities = [];
        mapSystem.status_areas.forEach(({ polygons, status_type }) => {
            const color = getSystemColorFromType(status_type);
            const statusAreaPositions = polygons.reduce((acc, polygon) => {
                const positions = [];
                polygon.forEach(({ lat, lng }) => {
                    return positions.push(lng, lat);
                });
                return [...acc, ...positions];
            }, []);
            const statusArea = {
                name: mapSystem.name.toUpperCase().replace(/_/g, " "),
                polygon: {
                    height: getVolumeAltitudeFromType(status_type),
                    hierarchy: Cartesian3.fromDegreesArray(statusAreaPositions),
                    material: Color.fromCssColorString(color).withAlpha(0.9),
                    outline: true,
                    outlineColor: Color.fromCssColorString(color)
                },
                description: `
                    <p>Status Area</p>
                    <p>Status: ${status_type}</p>
                    <p>Date Reported: ${ConvertISOToDate(mapSystem.date_reported)}</p>
                `
            };
            const systemAreaEntity = viewer.entities.add(statusArea);
            entities.push(systemAreaEntity);
        });
        viewer.zoomTo(entities);
    };

    return (
        <Dialog open={true} onClose={() => setMapSystem(null)} maxWidth="md" fullWidth>
            <DialogTitle>{mapSystem.name.toUpperCase().replace(/_/g, " ")}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1, pb: 0 }}>
                <Box sx={{ position: "relative", height: "450px", mb: 1 }}>
                    <Viewer ref={viewerRef} imageryProviderViewModels={providerViewModels} animation={false} timeline={false} full />
                    <Typography ref={cursorLocationRef} variant="caption" sx={{ position: "absolute", right: "35px", bottom: "5px" }} />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Status: </b>
                    <span style={{ color: getSystemColorFromType(mapSystem.status) }}>{mapSystem.status}</span>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Date Reported: </b>
                    <span>{ConvertISOToDate(mapSystem.date_reported)}</span>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Total Components: </b>
                    <span>{mapSystem.hms_components ? mapSystem.hms_components.length : 0}</span>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setMapSystem(null)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default HmsMapPreview;
