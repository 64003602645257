import React, { useEffect, useState } from "react";
import DataObjectIcon from "@mui/icons-material/DataObject";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { DataGrid } from "@mui/x-data-grid";
import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";
import { JsonDialog } from "./jsonDialog";

export function ErrorLog() {
    const [jsonOpen, setJsonOpen] = useState(false);
    const [selectedJson, setSelectedJson] = useState({});
    const [sortModel, setSortModel] = useState([{ field: "date_sent", sort: "desc" }]);

    const [levelFilterOn, setLevelFilterOn] = useState(true);
    const [allErrors, setAllErrors] = useState([]);
    const [filteredErrors, setFilteredErrors] = React.useState([]);

    const { handleFailedFetch } = useUserAuth();

    const columns = [
        {
            field: "json",
            headerName: "",
            flex: 1,
            midWidth: 50,
            maxWidth: 50,
            display: "flex",
            renderCell: (params) => {
                return (
                    <IconButton size="medium" onClick={() => handleJsonOpen(params.row)}>
                        <DataObjectIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "date_sent",
            headerName: "Date",
            minWidth: 200,
            maxWidth: 200,
            type: "dateTime",
            flex: 1,
            display: "flex",
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "service",
            headerName: "Service",
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            display: "flex"
        },
        {
            field: "level",
            headerName: "Level",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            display: "flex",
            renderHeader: () => (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "12px" }}>
                    <strong>{"Level "}</strong>
                    <IconButton size="medium" onClick={handleLevelFilterClick}>
                        {levelFilterOn === true ? <FilterAltIcon /> : <FilterAltOffIcon />}
                    </IconButton>
                </Box>
            ),
            disableColumnMenu: true
        },
        {
            field: "message",
            headerName: "Message",
            flex: 1,
            minWidth: 300
        }
    ];

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        const fetchData = async () => {
            await fetch("api/errors/get", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => {
                    if (levelFilterOn === true) {
                        const filtered = data.filter((error) => {
                            return error.level !== "WARNING";
                        });
                        setFilteredErrors(filtered);
                    }
                    setAllErrors(data);
                })
                .catch((err) => handleFailedFetch(err));
        };
        fetchData();

        return () => {
            setFilteredErrors([]);
            setAllErrors([]);
        };
    }, []);

    const handleJsonOpen = (row) => {
        setSelectedJson(row);
        setJsonOpen(true);
    };
    const handleLevelFilterClick = () => {
        setLevelFilterOn((prev) => {
            const new_value = !prev;
            if (new_value === true) {
                const filtered = allErrors.filter((error) => {
                    return error.level !== "WARNING";
                });
                setFilteredErrors(filtered);
            } else {
                setFilteredErrors(allErrors);
            }
            return new_value;
        });
    };
    return (
        <Box sx={{ height: 578 }}>
            <DataGrid
                getRowHeight={() => "auto"}
                disableVirtualization
                rows={filteredErrors}
                columns={columns}
                disableRowSeletionOnClick
                getRowId={(row) => row.id}
                pagination
                slots={{ pagination: CustomPagination }}
                initialState={{ pagination: { paginationModel: { pageSize: 9 } } }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
            />
            {jsonOpen ? <JsonDialog jsonOpen={jsonOpen} setJsonOpen={setJsonOpen} selectedJson={selectedJson}></JsonDialog> : <></>}
        </Box>
    );
}
