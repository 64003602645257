import React, { Suspense, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import LayersIcon from "@mui/icons-material/Layers";
import SettingsIcon from "@mui/icons-material/Settings";
import StyleIcon from "@mui/icons-material/Style";
import MapIcon from "@mui/icons-material/Map";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import Feature from "ol/Feature";
import Polygon, { circular } from "ol/geom/Polygon";
import Draw from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";

import LAANCDetailsDialog from "../manager/operations/laancDetailsDialog";

import { ConversationDialog } from "../chat/conversationDialog";
import { useUserAuth } from "../contexts/authContext";
import { useMap } from "../contexts/mapContext";

import { ConstraintCircleCreateDialog } from "../manager/constraints/constraintCircleCreateDialog";
import { ConstraintDraw } from "../manager/constraints/constraintDraw";
import FlightCreator from "../manager/operations/flightCreator";
import { EntityEditDialog } from "./entityEditDialog";
import { ConstraintEdit } from "../manager/constraints/constraintEdit";

import { MapFlightCards } from "../map/mapFlightCards";
import { MapSettingsDropdown } from "../map/mapSettingsDropdown";
import { MapLayersDropdown } from "./mapLayersDropdown";
import { EntityWatchToolbar } from "./entityWatchToolbar";
import MapTileDropdown from "./mapTileDropdown";
import MapInfoCard from "./mapInfoCard";
import MapFullScreen from "./mapFullScreen";

import {
    getGeometryStyleFromColor,
    getGeometryStyleFromFeatureAndSettings,
    getHistLineCoordinatesFromTrack,
    getPredLineCoordinatesFromTrackAndSettings,
    getZeroConflictionLineStyleFromZeroConflictMessage,
    ConvertCmpVolumesToFeatures,
    createZeroConflictionLineFeature,
    updateFeaturesZeroConflictionAlerts,
    GetFeature,
    GetFeatures,
    GetLayer,
    GetSource,
    JumpToFeatures,
    GetAllFeaturesWithId,
    updateZeroConflictLineFeature,
    GetLaancAirspaceStyle,
    getAlertVolumeConflictionAlertStyle,
    getAsdFeatureStyleFromLayersAndSettings,
    getLabelTextFromFeatureAndUser,
    getDpzCoordinatesFromTrack,
    JumpToFeature,
    getUpdatedZeroConflictionAlertsFromZeroConflictMessage,
    getIsNewZeroConflictionAlert,
    getIsHigherSeverityZeroConflictionAlert
} from "../map/mapUtil";

import useOpenLayersMap from "../hooks/useOpenLayersMap";
import { useLocalStorage } from "../hooks/useLocalStorage";
import useAirspaceLayer from "../hooks/useAirspaceLayer";
import useWeatherLayer from "../hooks/useWeatherLayer";
import useOpenLayersGeocoder from "../hooks/useGeocoder";

import alert_volume_conflict_audio from "../../public/static/files/alert_volume_conflict_audio.mp3";
import zero_conflict_caution_audio from "../../public/static/files/zero_conflict_caution_audio.mp3";
import zero_conflict_warning_audio from "../../public/static/files/zero_conflict_warning_audio.mp3";

const MapComponent = () => {
    const MAP_ID = "main-map";

    const [createConstraintDialogOpen, setCreateConstraintDialogOpen] = useState(false);
    const [createConstraintVertices, setCreateConstraintVertices] = useState([]);

    const [firstResponderConstraintDialogOpen, setFirstResponderConstraintDialogOpen] = useState(false);
    const [firstResponderConstraintCenter, setFirstResponderConstraintCenter] = useState(false);
    const [drawingFirstResponderConstraint, setDrawingFirstResponderConstraint] = useState(false);

    const [flightDetailsVolume, setFlightDetailsVolume] = useState(false);
    const [flightDetailsDialogOpen, setFlightDetailsDialogOpen] = useState(false);

    const [flightCardsOpen, setFlightCardsOpen] = useLocalStorage("flight-cards-open", false);

    const [asdWatchToolbarOpen, setAsdWatchToolbarOpen] = useLocalStorage("watch-toolbar-open", false);
    const [asdWatchToolbarData, setAsdWatchToolbarData] = useLocalStorage("watch-toolbar-data", {
        track_id: "",
        callsign: "",
        track_angle: "",
        ground_speed_kn: "",
        alt_hae_ft: "",
        alt_agl_ft: "",
        alt_msl_ft: "",
        zero_conflict_alerts: [],
        alert_conflict_alert: null
    });

    const [audioAlertsEnabled, setAudioAlertsEnabled] = useLocalStorage("audio-alerts-enabled", false);

    const [activeZeroConflictionAlerts, setActiveZeroConflictionAlerts] = useState(new Map());
    const [activeAlertVolumeConflictionAlerts, setActiveAlertVolumeConflictionAlerts] = useState(new Map());
    const activeZeroConflictionAlertsRef = useRef(activeZeroConflictionAlerts);

    const [conversationDialogOpen, setConversationDialogOpen] = useState(false);
    const [editFlightVolume, setEditFlightVolume] = useState(false);
    const [editFlightOpen, setEditFlightOpen] = useState(false);

    const [mapSettingsOpen, setMapSettingsOpen] = useState(false);
    const [mapSettingsAnchorEl, setMapSettingsAnchorEl] = useState(null);

    const [mapLayersOpen, setMapLayersOpen] = useState(false);
    const [mapLayersAnchorEl, setMapLayersAnchorEl] = useState(null);

    const [mapTileDropdownOpen, setMapTileDropdownOpen] = useState(false);
    const [mapTileDropdownAnchorEl, setMapTileDropdownAnchorEl] = useState(null);

    const [constraintEditDialogOpen, setConstraintEditDialogOpen] = useState(false);
    const [constraintEditEntity, setConstraintEditEntity] = useState(null);

    const [asdEditDialogOpen, setAsdEditDialogOpen] = useState(false);
    const [asdEditEntity, setAsdEditEntity] = useState(null);

    const [userMapLayers, setUserMapLayers] = useLocalStorage("userMapLayers", {
        altitude_range: [0, 18000],
        nar_tracks: true,
        surveillance_sources: true,
        surveillance_sources_list: [],
        weather_radar: false,
        airspaces: [
            { local_type: "CLASS_B", visible: true },
            { local_type: "CLASS_C", visible: true },
            { local_type: "CLASS_D", visible: true },
            { local_type: "CLASS_E2", visible: true }
        ],
        operations: true,
        constraints: true,
        alert_volumes: true,
        radar_footprint: true
    });

    const [mapContainer, setMapContainer] = useState(null);
    const map_wrapper_ref = useRef(null);

    const { userMapSettings, user, socket, snackbar, setSnackbar } = useUserAuth();
    const { publishedOperations, constraints, alertVolumes, sensors, getDrones } = useMap();

    const map_location_ref = useRef(null);
    const hovered_asd_features = useRef([]);
    const volumeUuidToZoomTo = useRef("");

    const location = useLocation();
    const navigate = useNavigate();

    const map = useOpenLayersMap(MAP_ID, editFlightOpen);

    useAirspaceLayer(map, userMapLayers.airspaces);
    useOpenLayersGeocoder(map);
    useWeatherLayer(map);

    useEffect(() => {
        getDrones();
        setUserMapLayers((prev) => ({ ...prev, surveillance_sources_list: [] }));
    }, []);

    useEffect(() => {
        if (location.state && location.state.volumeUuidToZoomTo) {
            volumeUuidToZoomTo.current = location.state.volumeUuidToZoomTo;
        } else {
            volumeUuidToZoomTo.current = "";
        }
    }, [location]);

    useEffect(() => {
        if (!map) {
            return;
        }

        const layer_names = ["asd", "flights", "constraints", "alerts", "sensors"];
        layer_names.forEach((layer_name) => {
            const vector_layer = new VectorLayer({
                source: new VectorSource(),
                zIndex: 10,
                properties: {
                    name: layer_name,
                    selectable: true
                },
                updateWhileAnimating: layer_name === "asd" ? true : false,
                updateWhileInteracting: layer_name === "asd" ? true : false
            });
            if (layer_name === "asd") {
                vector_layer.setZIndex(15);
            }
            map.addLayer(vector_layer);
        });

        // Set initial map layer visibility.
        GetLayer(map, "asd")?.setVisible(userMapLayers.surveillance_sources);
        GetLayer(map, "weather")?.setVisible(userMapLayers.weather_radar);
        GetLayer(map, "flights")?.setVisible(userMapLayers.operations);
        GetLayer(map, "constraints")?.setVisible(userMapLayers.constraints);
        GetLayer(map, "alerts")?.setVisible(userMapLayers.alert_volumes);
        GetLayer(map, "sensors")?.setVisible(userMapLayers.radar_footprint);
    }, [map]);

    useEffect(() => {
        if (!map) {
            return;
        }

        const interval = setInterval(() => {
            const dateNow = Date.now();
            const expirationTime = 10 * 1000;

            // ASD Expiration
            const source = GetSource(map, "asd");
            source?.getFeatures().forEach((feature) => {
                if (dateNow - feature.get("last_asd_update_ms") > expirationTime) {
                    source.removeFeature(feature);
                }
            });

            // Zero Conflict Alert Expiration
            activeZeroConflictionAlertsRef.current.forEach((alerts) =>
                alerts.forEach((alert) => {
                    if (dateNow - new Date(alert.timestamp).getTime() > expirationTime) {
                        const expirationMessage = { ...alert, state: "EXPIRED" };
                        handleZeroConflictionMessage(expirationMessage);
                    }
                })
            );

            // Alert Volume Confliction Alert Expiration
            setActiveAlertVolumeConflictionAlerts((prev) => {
                const alerts = new Map(prev);
                alerts.forEach((alert, uuid) => {
                    if (dateNow - new Date(alert.time_of_alert).getTime() > expirationTime) {
                        GetFeature(map, "asd", uuid)?.unset("alert_volume_confliction_alert");
                        setAsdWatchToolbarData((prev) => (prev.track_id === uuid ? { ...prev, alert_conflict_alert: null } : prev));
                        alerts.delete(uuid);
                    }
                });
                return alerts;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [map]);

    useEffect(() => {
        if (!map) {
            return;
        }

        let timeout;
        const handlePointerMove = ({ originalEvent, pixel }) => {
            const coordinates = map.getEventCoordinate(originalEvent);
            if (map_location_ref.current) {
                map_location_ref.current.innerHTML = `( ${coordinates[1]}, ${coordinates[0]} )`;
            }

            // Ignore all non-hover events.
            if (userMapSettings.label_visible !== "off") {
                return;
            }

            hovered_asd_features.current.forEach((feature) => {
                feature.set("show_label", false);
                feature.getStyle()?.forEach((style) => style.setText(undefined));
            });
            hovered_asd_features.current = [];

            clearTimeout(timeout);
            timeout = setTimeout(() => {
                map.forEachFeatureAtPixel(pixel, (feature, layer) => {
                    if (!feature.get("asd") || layer !== GetLayer(map, "asd")) {
                        return;
                    }

                    const style = feature.getStyle();
                    if (Array.isArray(style) && style[0].getImage()) {
                        feature.set("show_label", true);
                        feature.setStyle([...style, new Style({ text: getLabelTextFromFeatureAndUser(feature, user) })]);
                        hovered_asd_features.current.push(feature);
                    }
                });
            }, 250);
        };
        map.on("pointermove", handlePointerMove);

        return () => {
            clearTimeout(timeout);
            map.un("pointermove", handlePointerMove);
        };
    }, [map, userMapSettings]);

    // update features when the map settings change
    useEffect(() => {
        GetLayer(map, "base")?.setOpacity(userMapSettings.brightness || 0);
        GetLayer(map, "weather")?.setOpacity(userMapSettings.weather_opacity || 0);
        GetFeatures(map, "asd")?.forEach((f) => f.setStyle(getAsdFeatureStyleFromLayersAndSettings(f, userMapLayers, userMapSettings, user)));
        ["flights", "constraints", "alerts"].forEach((volume) => {
            GetFeatures(map, volume)?.forEach((f) => f.setStyle(getGeometryStyleFromFeatureAndSettings(f, userMapSettings)));
        });
    }, [map, userMapSettings, userMapLayers]);

    // draw radar footprints
    useEffect(() => {
        const sensor_source = GetSource(map, "sensors");
        if (!sensor_source) {
            return;
        }

        sensor_source.clear();
        sensors.forEach((sensor) => {
            if (isNaN(sensor.lat) || isNaN(sensor.lon) || isNaN(sensor.radius)) {
                return;
            }

            const feature = new Feature({
                geometry: circular([sensor.lon, sensor.lat], sensor.radius, 128),
                name: sensor.name,
                sensor,
                truth_source: sensor.truth_source
            });
            feature.setStyle(getGeometryStyleFromColor("#000000"));
            sensor_source.addFeature(feature);
        });
    }, [map, sensors]);

    useEffect(() => {
        const layers = [
            { layer_name: "flights", volumes: publishedOperations },
            { layer_name: "constraints", volumes: constraints },
            { layer_name: "alerts", volumes: alertVolumes }
        ];
        layers.map(({ layer_name, volumes }) => {
            const source = GetSource(map, layer_name);
            if (!source) {
                return;
            }

            const features = ConvertCmpVolumesToFeatures(volumes, userMapSettings);
            source.clear();
            source.addFeatures(features);

            // remove labels for alert volumes that are associated with an operation
            features.filter((f) => f.get("alert_volume")?.flight_uuid).forEach((f) => f.getStyle().setText(undefined));

            if (layer_name !== "constraints" || !volumeUuidToZoomTo.current) {
                return;
            }

            // constraint notification jumping
            const feature_to_zoom_to = features.find((f) => f.getId().includes(volumeUuidToZoomTo.current));
            if (feature_to_zoom_to) {
                JumpToFeature(map, feature_to_zoom_to);
            } else {
                setSnackbar({ children: "Constraint has been removed", severity: "error" });
            }
            volumeUuidToZoomTo.current = "";
            navigate("/map", { state: { volumeUuidToZoomTo: "" } });
        });
    }, [map, publishedOperations, constraints, alertVolumes]);

    useEffect(() => {
        const asdSource = GetSource(map, "asd");
        if (!asdSource) {
            return;
        }

        const handleAsdMessage = (message) => {
            const last_asd_update_ms = new Date(message.timestamp).getTime();
            const tracks = [
                { id: `${message.id}`, coordinates: [message.lon_deg, message.lat_deg] },
                { id: `${message.id}_pl`, coordinates: getPredLineCoordinatesFromTrackAndSettings(message, userMapSettings) },
                { id: `${message.id}_hl`, coordinates: getHistLineCoordinatesFromTrack(message) },
                { id: `${message.id}_dpz`, coordinates: getDpzCoordinatesFromTrack(message) }
            ];
            tracks.forEach(({ id, coordinates }, i) => {
                const feature = asdSource.getFeatureById(id);
                if (feature) {
                    feature.getGeometry().setCoordinates(coordinates);
                    feature.setProperties({ asd: message, last_asd_update_ms });
                    feature.setStyle(getAsdFeatureStyleFromLayersAndSettings(feature, userMapLayers, userMapSettings, user));

                    // update zero confliction lines if applicable
                    if (i === 0) {
                        updateZeroConflictLineFeature(map, feature);
                    }
                    return;
                }

                let geometry = new Point(coordinates);
                if (id.includes("_pl") || id.includes("_hl")) {
                    geometry = new LineString(coordinates);
                } else if (id.includes("_dpz")) {
                    geometry = new Polygon(coordinates);
                }

                const new_feature = new Feature({ geometry, name: message.label, asd: message, last_asd_update_ms });
                new_feature.setId(id);
                new_feature.setStyle(getAsdFeatureStyleFromLayersAndSettings(new_feature, userMapLayers, userMapSettings, user));
                asdSource.addFeature(new_feature);
            });

            // update map layer display
            const source = userMapSettings.source_mapping[message.source_id];
            const sourceLabel = !source ? message.source_id : source.visibility ? source.identifier || message.source_id : null;
            if (sourceLabel && !userMapLayers.surveillance_sources_list.find(({ label }) => label === sourceLabel)) {
                handleMapLayersChange("surveillance_sources_list", { label: sourceLabel });
            }

            // update watch toolbar if applicable
            if (message.id === asdWatchToolbarData.track_id) {
                setAsdWatchToolbarData((prev) => ({
                    ...prev,
                    callsign: message.callsign[user.organization_id] || message.label,
                    track_angle: message.course_deg,
                    ground_speed_kn: message.ground_speed_kn,
                    alt_agl_ft: message.alt_agl_ft,
                    alt_msl_ft: message.alt_msl_ft,
                    alt_hae_ft: message.alt_hae_ft
                }));
            }
        };

        socket.on("asd", (messages) => messages.forEach(handleAsdMessage));
        socket.on("zero_conflict", handleZeroConflictionMessage);
        socket.on("airspace_alerting_conflicts", (message) => {
            if (alertVolumes.find(({ uuid }) => uuid === message.volume_id)) {
                handleAlertVolumeConflictionMessage(message);
            }
        });

        return () => {
            socket.off("asd");
            socket.off("zero_conflict");
            socket.off("airspace_alerting_conflicts");
        };
    }, [map, activeZeroConflictionAlerts, activeAlertVolumeConflictionAlerts, asdWatchToolbarData, userMapSettings, userMapLayers, user, alertVolumes]);

    const handleZeroConflictionMessage = (message) => {
        if (!map) {
            return;
        }

        const ownship_id = message.ownship_track.id;
        const intruder_id = message.intruder_track.id;
        const zc_line_id = `${ownship_id}_${intruder_id}`;

        const ownship_feature = GetFeature(map, "asd", ownship_id);
        const intruder_feature = GetFeature(map, "asd", intruder_id);
        const zc_line_feature = GetFeature(map, "asd", zc_line_id);

        // update ownship and intruder feature styles
        [ownship_feature, intruder_feature].forEach((feature) => {
            if (feature) {
                updateFeaturesZeroConflictionAlerts(feature, message);
                feature.setStyle(getAsdFeatureStyleFromLayersAndSettings(feature, userMapLayers, userMapSettings, user));
            }
        });

        // create or update the line
        const asd_vector_source = GetSource(map, "asd");
        if (ownship_feature && intruder_feature && asd_vector_source) {
            if (message.state === "ACTIVE") {
                if (zc_line_feature) {
                    const updated_zc_line_style = getZeroConflictionLineStyleFromZeroConflictMessage(message, ownship_feature);
                    zc_line_feature.setStyle(updated_zc_line_style);
                } else {
                    const new_zc_line_feature = createZeroConflictionLineFeature(ownship_feature, intruder_feature, message);
                    asd_vector_source.addFeature(new_zc_line_feature);
                }
            } else if (message.state === "EXPIRED" && zc_line_feature) {
                asd_vector_source.removeFeature(zc_line_feature);
            }
        }
        // remove line if ownship or intruder no longer exists
        if (asd_vector_source && zc_line_feature && (!ownship_feature || !intruder_feature)) {
            asd_vector_source.removeFeature(zc_line_feature);
        }
        // update state
        setActiveZeroConflictionAlerts((prev) => {
            if (asdWatchToolbarData.track_id === ownship_id || asdWatchToolbarData.track_id === intruder_id) {
                let is_new_alert = getIsNewZeroConflictionAlert(prev, message);
                let is_higher_severity_alert = getIsHigherSeverityZeroConflictionAlert(prev, message);

                if (is_new_alert || is_higher_severity_alert) {
                    playZeroConflictionSound(message);
                }
            }
            const updated_zero_conflict_alerts = getUpdatedZeroConflictionAlertsFromZeroConflictMessage(prev, message);
            activeZeroConflictionAlertsRef.current = updated_zero_conflict_alerts;

            if (asdWatchToolbarData.track_id === ownship_id || asdWatchToolbarData.track_id === intruder_id) {
                const updated_watch_toolbar_alerts = updated_zero_conflict_alerts.get(ownship_id) || [];
                setAsdWatchToolbarData((prev) => ({ ...prev, zero_conflict_alerts: updated_watch_toolbar_alerts }));
            }
            return updated_zero_conflict_alerts;
        });
    };

    const handleAlertVolumeConflictionMessage = (message) => {
        if (!map) {
            return;
        }

        const vehicle_id = message.track_id;
        const vehicle_feature = GetFeature(map, "asd", vehicle_id);
        const active_alerts = new Map(activeAlertVolumeConflictionAlerts);

        // set feature property to indicate alert, update style if needed
        if (vehicle_feature) {
            if (!vehicle_feature.get("alert_volume_confliction_alert") && Array.isArray(vehicle_feature.getStyle())) {
                vehicle_feature.setStyle([...vehicle_feature.getStyle(), getAlertVolumeConflictionAlertStyle(vehicle_feature, userMapLayers)]);
            }
            vehicle_feature.set("alert_volume_confliction_alert", message);
        }

        // set watch toolbar data, send sound alert if new
        if (asdWatchToolbarData.track_id === vehicle_id) {
            if (!active_alerts.has(vehicle_id)) {
                playAlertVolumeConflictionSound();
            }
            setAsdWatchToolbarData({ ...asdWatchToolbarData, alert_conflict_alert: message });
        }

        active_alerts.set(vehicle_id, message);
        setActiveAlertVolumeConflictionAlerts(active_alerts);
    };

    const handleHomeButtonClick = () => {
        if (map && map.getView()) {
            map.getView().setCenter([userMapSettings.longitude, userMapSettings.latitude]);
            map.getView().setZoom(12);
        }
    };

    const handleCreateConstraintButtonClick = () => {
        const draw = new Draw({
            source: new VectorSource({ wrapX: false }),
            type: "Polygon"
        });
        draw.on("drawend", ({ feature }) => {
            const coordinates = feature.getGeometry().getCoordinates();
            const vertices = coordinates[0].map((arr) => ({ lng: arr[0], lat: arr[1] }));
            setCreateConstraintVertices([{ id: 0, vertices }]);
            setCreateConstraintDialogOpen(true);
            map.removeInteraction(draw);
        });
        map.addInteraction(draw);
    };

    const handleFirstResponderConstraintButtonClick = () => {
        const draw = new Draw({
            source: new VectorSource({ wrapX: false }),
            type: "Point"
        });
        draw.on("drawend", ({ feature }) => {
            const coordinates = feature.getGeometry().getCoordinates();
            const center = { lon_deg: coordinates[0], lat_deg: coordinates[1] };
            setFirstResponderConstraintCenter(center);
            setFirstResponderConstraintDialogOpen(true);
            setDrawingFirstResponderConstraint(false);
            map.removeInteraction(draw);
        });
        setDrawingFirstResponderConstraint(true);
        map.addInteraction(draw);
    };

    const handleToggleLayersButtonClick = (e) => {
        setMapLayersAnchorEl(e.currentTarget);
        setMapLayersOpen((prev) => !prev);
    };

    const handleSettingsButtonClick = (e) => {
        setMapSettingsOpen((prev) => !prev);
        setMapSettingsAnchorEl(e.currentTarget);
    };

    const handleWatchEntityButtonClick = (asd_uuid) => {
        const opened = !asdWatchToolbarOpen;
        setAsdWatchToolbarOpen(opened);

        if (!opened) {
            setAsdWatchToolbarData({
                track_id: "",
                callsign: "",
                track_angle: "",
                ground_speed_kn: "",
                alt_agl_ft: "",
                alt_msl_ft: "",
                alt_hae_ft: "",
                zero_conflict_alerts: [],
                alert_conflict_alert: null
            });
            return;
        }

        const feature = GetFeature(map, "asd", asd_uuid);
        if (feature && feature.get("asd")) {
            const asd = feature.get("asd");
            setAsdWatchToolbarData({
                track_id: asd.id,
                callsign: asd?.callsign[user.organization_id] || asd.label,
                track_angle: asd.course_deg,
                ground_speed_kn: asd.ground_speed_kn,
                alt_agl_ft: asd.alt_agl_ft,
                alt_msl_ft: asd.alt_msl_ft,
                alt_hae_ft: asd.alt_hae_ft,
                zero_conflict_alerts: activeZeroConflictionAlerts.get(asd_uuid) || [],
                alert_conflict_alert: activeAlertVolumeConflictionAlerts.get(asd_uuid) || null
            });
        }
    };

    const handleCheckFlightASDVisible = (asd_uuid) => {
        return !!GetFeature(map, "asd", asd_uuid);
    };

    const handleConstraintEditButtonClick = (feature) => {
        setConstraintEditEntity({ constraint: feature.get("constraint") });
        setConstraintEditDialogOpen(true);
    };

    const handleTagButtonClick = (feature) => {
        const asd = feature.get("asd");
        const asd_edit_entity = {
            id: asd.id,
            name: asd.callsign?.[user.organization_id] || feature.get("name"),
            flight_uuid: asd.flight_uuid,
            show_dpz: asd.show_dpz?.[user.organization_id] || false,
            daa_config_uuid: asd.daa_configs?.[user.organization_id] || "",
            history_locations: asd.history_locations
        };
        setAsdEditEntity(asd_edit_entity);
        setAsdEditDialogOpen(true);
    };

    const handleJumpToOperationButtonClick = (flight_uuid) => {
        JumpToFeatures(map, GetAllFeaturesWithId(map, "flights", flight_uuid));
    };

    const handleMapLayersChange = (setting, value) => {
        const mapLayers = { ...userMapLayers };
        const toggleSettings = ["surveillance_sources", "weather_radar", "operations", "constraints", "alert_volumes", "radar_footprint"];

        if (toggleSettings.includes(setting)) {
            const visibility = !mapLayers[setting];
            GetLayer(map, value)?.setVisible(visibility);
            mapLayers[setting] = visibility;
        } else if (setting === "surveillance_sources_list") {
            mapLayers[setting].push(value);
        } else if (setting === "airspaces") {
            const airspaces = mapLayers[setting];
            if (value) {
                const airspace = airspaces.find(({ local_type }) => local_type === value);
                airspace.visible = !airspace.visible;
            } else {
                const visibility = !airspaces[0].visible;
                airspaces.forEach((airspace) => (airspace.visible = visibility));
            }
            GetLayer(map, "LAANC_AIRSPACE")?.setStyle((feature) => GetLaancAirspaceStyle(feature, airspaces));
        } else {
            mapLayers[setting] = value;
        }

        setUserMapLayers(mapLayers);
    };

    const handleShowEntityDpz = (message) => {
        const feature = GetFeature(map, "asd", message.id);
        if (feature && feature.get("asd")) {
            const asd = feature.get("asd");
            const updated_asd = {
                ...asd,
                flight_uuid: message.flight_uuid,
                callsign: { ...message.callsign, [user.organization_id]: message.callsign },
                show_dpz: { ...message.show_dpz, [user.organization_id]: message.show_dpz },
                daa_configs: { ...message.daa_configs, [user.organization_id]: message.alerting_params_uuid }
            };
            feature.set("asd", updated_asd);
            feature.setStyle(getAsdFeatureStyleFromLayersAndSettings(feature, userMapLayers, userMapSettings, user));
        }
    };

    const playZeroConflictionSound = (zero_conflict_message) => {
        if (!audioAlertsEnabled) {
            return;
        } else if (zero_conflict_message.severity === "CAUTION") {
            new Audio(zero_conflict_caution_audio).play();
        } else if (zero_conflict_message.severity === "WARNING") {
            new Audio(zero_conflict_warning_audio).play();
        }
    };

    const playAlertVolumeConflictionSound = () => {
        if (audioAlertsEnabled) {
            new Audio(alert_volume_conflict_audio).play();
        }
    };

    const handleMapTileDropdownButtonClick = (e) => {
        setMapTileDropdownOpen((prev) => !prev);
        setMapTileDropdownAnchorEl(e.currentTarget);
    };

    const handleFullScreenButtonClick = () => {
        try {
            if (document.fullscreenElement) {
                document.exitFullscreen();
                return;
            } else if (!map_wrapper_ref.current) {
                return;
            }

            map_wrapper_ref.current.requestFullscreen();
            if (!mapContainer) {
                setMapContainer(map_wrapper_ref.current);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const handleNarTrackSettingChange = (e) => {
        const updated_map_layers = {
            ...userMapLayers,
            nar_tracks: e.target.checked
        };
        setUserMapLayers(updated_map_layers);
    };
    return editFlightOpen ? (
        <FlightCreator
            setFlightCreatorOpen={setEditFlightOpen}
            flightUUID={editFlightVolume.flight_uuid}
            publishedFlight={true}
            flightState={editFlightVolume.state}
        />
    ) : (
        <Box ref={map_wrapper_ref} sx={{ width: "100%", position: "relative", overflowX: "hidden" }}>
            <Box sx={{ display: "flex", flex: 1, height: "100%" }}>
                <Box sx={{ flex: 1, position: "relative", height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <EntityWatchToolbar
                        asdWatchToolbarOpen={asdWatchToolbarOpen}
                        asdWatchToolbarData={asdWatchToolbarData}
                        handleWatchEntityButtonClick={handleWatchEntityButtonClick}
                        audioAlertsEnabled={audioAlertsEnabled}
                        setAudioAlertsEnabled={setAudioAlertsEnabled}
                    />
                    <Box sx={{ flex: 1, position: "relative" }}>
                        <MapFullScreen
                            map_id={MAP_ID}
                            map={map}
                            buttons={[
                                {
                                    id: "viewHome",
                                    name: "View Home",
                                    visible: true,
                                    icon: <HomeIcon />,
                                    onClick: handleHomeButtonClick
                                },
                                {
                                    id: "createConstraint",
                                    name: "Create Constraint",
                                    visible: user.user_role === "Admin" || user.user_role === "Airspace Manager",
                                    icon: <EditIcon />,
                                    onClick: handleCreateConstraintButtonClick
                                },
                                {
                                    id: "toggleLayers",
                                    name: "Toggle Layers",
                                    visible: true,
                                    icon: <LayersIcon />,
                                    onClick: handleToggleLayersButtonClick
                                },
                                {
                                    id: "flightCards",
                                    name: "Flight Cards",
                                    visible: user.user_role !== "First Responder",
                                    icon: <StyleIcon />,
                                    onClick: () => setFlightCardsOpen((prev) => !prev)
                                },
                                {
                                    id: "editSettings",
                                    name: "Edit Settings",
                                    visible: true,
                                    icon: <SettingsIcon />,
                                    onClick: handleSettingsButtonClick
                                },
                                {
                                    id: "toggleMapTiles",
                                    name: "Map Tiles",
                                    visible: true,
                                    icon: <MapIcon />,
                                    onClick: handleMapTileDropdownButtonClick
                                },
                                {
                                    id: "toggleFullscreen",
                                    name: document.fullscreenElement ? "Exit Fullscreen" : "Enter Fullscreen",
                                    visible: true,
                                    icon: document.fullscreenElement ? <CloseIcon /> : <OpenInFullIcon />,
                                    onClick: handleFullScreenButtonClick
                                }
                            ]}
                        >
                            <MapInfoCard
                                map={map}
                                showButtons={true}
                                handleWatchEntityButtonClick={handleWatchEntityButtonClick}
                                handleTagButtonClick={handleTagButtonClick}
                                handleEditConstraintButtonClick={handleConstraintEditButtonClick}
                                airspaceSubLayers={userMapLayers.airspaces}
                                mapContainer={mapContainer}
                            />
                        </MapFullScreen>
                        <Typography
                            ref={map_location_ref}
                            variant="caption"
                            sx={{ position: "absolute", right: "0.5em", bottom: ".5em", zIndex: 1, textShadow: "0 0 10px #000" }}
                        />
                    </Box>
                </Box>
                <Collapse in={flightCardsOpen} orientation="horizontal" unmountOnExit sx={{ height: "100%", overflowY: "auto" }}>
                    <MapFlightCards
                        operations={publishedOperations}
                        flightDetailsDialogOpen={flightDetailsDialogOpen}
                        setFlightDetailsVolume={setFlightDetailsVolume}
                        setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                        setConversationDialogOpen={setConversationDialogOpen}
                        setEditFlightVolume={setEditFlightVolume}
                        setEditFlightDialogOpen={setEditFlightOpen}
                        handleWatchEntity={handleWatchEntityButtonClick}
                        handleCheckFlightASDVisible={handleCheckFlightASDVisible}
                        setFlightCardsOpen={setFlightCardsOpen}
                        handleJumpToOperation={handleJumpToOperationButtonClick}
                        flightCardsOpen={flightCardsOpen}
                        activeAlertVolumeConflictionAlerts={activeAlertVolumeConflictionAlerts}
                        activeZeroConflictionAlerts={activeZeroConflictionAlerts}
                    />
                </Collapse>
            </Box>
            {asdEditDialogOpen && (
                <EntityEditDialog
                    operations={publishedOperations}
                    asdEditDialogOpen={asdEditDialogOpen}
                    setASDEditDialogOpen={setAsdEditDialogOpen}
                    asdEditEntity={asdEditEntity}
                    handleShowEntityDpz={handleShowEntityDpz}
                    mapContainer={mapContainer}
                />
            )}
            {constraintEditDialogOpen && (
                <ConstraintEdit
                    snackbar={snackbar}
                    constraintEditEntity={constraintEditEntity}
                    constraintEditDialogOpen={constraintEditDialogOpen}
                    setSnackbar={setSnackbar}
                    setConstraintEditDialogOpen={setConstraintEditDialogOpen}
                    mapContainer={mapContainer}
                />
            )}
            {createConstraintDialogOpen && (
                <ConstraintDraw
                    snackbar={snackbar}
                    constraintCreateVertices={createConstraintVertices}
                    constraintCreateDialogOpen={createConstraintDialogOpen}
                    setSnackbar={setSnackbar}
                    setConstraintCreateDialogOpen={setCreateConstraintDialogOpen}
                    mapContainer={mapContainer}
                />
            )}
            {firstResponderConstraintDialogOpen && (
                <ConstraintCircleCreateDialog
                    snackbar={snackbar}
                    constraintCircleCenterPoint={firstResponderConstraintCenter}
                    constraintCircleCreateDialogOpen={firstResponderConstraintDialogOpen}
                    setConstraintCircleCreateDialogOpen={setFirstResponderConstraintDialogOpen}
                    setSnackbar={setSnackbar}
                    mapContainer={mapContainer}
                />
            )}
            {user.user_role === "First Responder" && (
                <Fab
                    color="primary"
                    onClick={handleFirstResponderConstraintButtonClick}
                    disabled={drawingFirstResponderConstraint}
                    sx={{ position: "absolute", bottom: "40px", right: "10px", zIndex: "unset" }}
                    id="firstResponderConstraint"
                >
                    <AddIcon />
                </Fab>
            )}
            {flightDetailsDialogOpen && (
                <Suspense fallback={<></>}>
                    <LAANCDetailsDialog
                        flightDetailsVolume={flightDetailsVolume}
                        flightDetailsDialogOpen={flightDetailsDialogOpen}
                        publishedOperations={publishedOperations}
                        setFlightDetailsVolume={setFlightDetailsVolume}
                        setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                        mapContainer={mapContainer}
                    />
                </Suspense>
            )}
            {conversationDialogOpen && (
                <ConversationDialog
                    conversationDialogOpen={conversationDialogOpen}
                    setConversationDialogOpen={setConversationDialogOpen}
                    mapContainer={mapContainer}
                />
            )}
            {mapSettingsOpen && (
                <MapSettingsDropdown
                    mapSettingsOpen={mapSettingsOpen}
                    setMapSettingsOpen={setMapSettingsOpen}
                    mapSettingsAnchorEl={mapSettingsAnchorEl}
                    setSnackbar={setSnackbar}
                    mapContainer={mapContainer}
                />
            )}
            <MapLayersDropdown
                mapLayersOpen={mapLayersOpen}
                setMapLayersOpen={setMapLayersOpen}
                mapLayersAnchorEl={mapLayersAnchorEl}
                altitudeLayerRange={userMapLayers.altitude_range}
                handleAltitudeLayerRangeChanged={(e) => handleMapLayersChange("altitude_range", e.target.value)}
                narChecked={userMapLayers.nar_tracks}
                handleNarTrackSettingChange={handleNarTrackSettingChange}
                sections={[
                    {
                        title: "General",
                        layers: [
                            {
                                label: "Surveillance Sources",
                                checked: userMapLayers.surveillance_sources,
                                handleLayerClick: () => handleMapLayersChange("surveillance_sources", "asd"),
                                sublayers: userMapLayers.surveillance_sources_list
                            },
                            {
                                label: "Weather Radar",
                                checked: userMapLayers.weather_radar,
                                handleLayerClick: () => handleMapLayersChange("weather_radar", "weather"),
                                sublayers: []
                            }
                        ]
                    },
                    {
                        title: "Volumes",
                        layers: [
                            {
                                label: "Airspaces",
                                checked: userMapLayers.airspaces.every(({ visible }) => visible),
                                indeterminate: !userMapLayers.airspaces.every(({ visible }) => visible === userMapLayers.airspaces[0].visible),
                                handleLayerClick: () => handleMapLayersChange("airspaces"),
                                sublayers: userMapLayers.airspaces.map(({ local_type, visible }) => {
                                    return {
                                        label: local_type,
                                        checked: visible,
                                        handleSublayerClick: () => handleMapLayersChange("airspaces", local_type)
                                    };
                                })
                            },
                            {
                                label: "Operations",
                                checked: userMapLayers.operations,
                                handleLayerClick: () => handleMapLayersChange("operations", "flights"),
                                sublayers: []
                            },
                            {
                                label: "Constraints",
                                checked: userMapLayers.constraints,
                                handleLayerClick: () => handleMapLayersChange("constraints", "constraints"),
                                sublayers: []
                            },
                            {
                                label: "Alert Volumes",
                                checked: userMapLayers.alert_volumes,
                                handleLayerClick: () => handleMapLayersChange("alert_volumes", "alerts"),
                                sublayers: []
                            },
                            {
                                label: "Radar Footprint",
                                checked: userMapLayers.radar_footprint,
                                handleLayerClick: () => handleMapLayersChange("radar_footprint", "sensors"),
                                sublayers: []
                            }
                        ]
                    }
                ]}
                mapContainer={mapContainer}
            />
            <MapTileDropdown
                mapTileDropdownOpen={mapTileDropdownOpen}
                setMapTileDropdownOpen={setMapTileDropdownOpen}
                mapTileDropdownAnchorEl={mapTileDropdownAnchorEl}
                mapContainer={mapContainer}
            />
        </Box>
    );
};

export default MapComponent;
