import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import BannerAlertList from "./bannerAlerts";
import InfoDialog from "./infoDialog";
import AlertDialog from "./alertDialog";

import { useNavigate } from "react-router-dom";
import { LeftNavigation } from "./leftNavigation";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { useSocket } from "./contexts/socketContext";
import { useUserAuth } from "./contexts/authContext";
import { useMap } from "./contexts/mapContext";
import { useEnv } from "./contexts/envContext";

const drawerWidth = 240;

export function App() {
    const { fetchEnvVariables, providerViewModels, setProviderViewModels } = useEnv();
    const { fetchMapData } = useMap();
    const { socket, setSocket } = useSocket();
    const {
        user,
        logout,
        setUser,
        snackbar,
        setSnackbar,
        userMapSettings,
        userAirspaceSettings,
        userOperationalStates,
        unreadNotifications,
        handleFailedFetch,
        setStatusMessage,
        getNotifications,
        getMapSettings
    } = useUserAuth();

    const [navigationOpen, setNavigationOpen] = useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    const [bannerOpen, setBannerOpen] = useState(false);
    const [bannerAlerts, setBannerAlerts] = useState([]);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    const socketRef = useRef(null);
    const notificationTimerRef = useRef(null);

    const navigateTo = useNavigate();
    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        fetchEnvVariables();
    }, []);

    useEffect(() => {
        const updateHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty("--app-height", `${window.innerHeight}px`);
        };
        window.addEventListener("resize", updateHeight);
        updateHeight();

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    });

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch("api/user/get/session", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => handleFetchedData(data))
            .catch((err) => handleFailedFetch(err));

        // disconnect the socket when the user closes the tab or window
        document.addEventListener("beforeunload", handleDisconnect);

        return () => {
            clearInterval(notificationTimerRef.current);
            handleDisconnect();
            document.removeEventListener("beforeunload", handleDisconnect);
        };
    }, []);

    const handleDisconnect = () => {
        if (socketRef.current !== null) {
            socketRef.current.disconnect();
            socketRef.current = null;
            setSocket(null);
        }
    };
    const handleFetchedData = (data) => {
        const auth = data.auth;
        const returnedUser = data.user;
        const message = data.message;

        if (!auth) {
            setStatusMessage(message);
            return navigateTo("/login");
        }

        const newSocket = io.connect();
        newSocket.on("connect", () => {
            newSocket.emit("storeUserChatSocket", returnedUser);
            newSocket.emit("getUiAlerts", returnedUser);

            newSocket.on("ui_alerts", (message) => {
                const sorted_alerts = message.sort((a, b) => {
                    return new Date(b.alert_time).getTime() - new Date(a.alert_time).getTime();
                });
                setBannerAlerts((prev) => {
                    if (prev && prev.length > 0 && sorted_alerts && sorted_alerts.length > 0) {
                        const prev_most_recent_time = new Date(prev[0].alert_time).getTime();
                        const new_most_recent_time = new Date(sorted_alerts[0].alert_time).getTime();

                        // only open the banner in the event that an alert is added or updated (most recent alert has a different timestamp and there are the same or more amount of alerts)
                        if (prev_most_recent_time !== new_most_recent_time && sorted_alerts.length >= prev.length) {
                            if (sorted_alerts.length > 0 && sorted_alerts[0].display_type === "BANNER") {
                                setBannerOpen(true);
                            }
                        }
                    } else if (sorted_alerts.length > 0 && sorted_alerts[0].display_type === "BANNER") {
                        setBannerOpen(true);
                    }
                    return sorted_alerts;
                });
            });
            socketRef.current = newSocket;
            setSocket(newSocket);
        });

        setUser(returnedUser);
        getMapSettings(returnedUser, fetchMapData);
        getNotifications(returnedUser);

        notificationTimerRef.current = setInterval(() => {
            getNotifications(returnedUser);
        }, 30000);
    };
    const handleLogoutClick = () => {
        logout();
        setProviderViewModels([]);
    };
    return (
        <Box>
            {!user || !userMapSettings || !userOperationalStates || !userAirspaceSettings || !socket || providerViewModels.length === 0 ? (
                <Paper>
                    <Backdrop open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper>
            ) : (
                <Box>
                    <Box sx={{ display: "flex" }}>
                        <AppBar
                            position="fixed"
                            sx={{
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                transition: (theme) =>
                                    theme.transitions.create(["width", "margin"], {
                                        easing: theme.transitions.easing.sharp,
                                        duration: theme.transitions.duration.leavingScreen
                                    }),
                                ...(navigationOpen && {
                                    ml: { md: `${drawerWidth}px` },
                                    width: { md: `calc(100% - ${drawerWidth}px)` },
                                    transition: (theme) =>
                                        theme.transitions.create(["width", "margin"], {
                                            easing: theme.transitions.easing.sharp,
                                            duration: theme.transitions.duration.enteringScreen
                                        })
                                })
                            }}
                        >
                            <Toolbar sx={{ px: 3, position: "relative" }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={() => setNavigationOpen((prev) => !prev)}
                                    sx={{ mr: "36px" }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                    CMP UI Dashboard
                                </Typography>
                                <IconButton color="inherit" onClick={() => setInfoDialogOpen(true)}>
                                    <Badge color="secondary">
                                        <InfoIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton color="inherit" onClick={() => setBannerOpen((prev) => !prev)}>
                                    <Badge color="error" badgeContent={bannerAlerts.length}>
                                        <WarningAmberIcon />
                                    </Badge>
                                </IconButton>
                                <RouterLink to="/notifications">
                                    <IconButton color="inherit">
                                        <Badge color="error" badgeContent={unreadNotifications.length}>
                                            <NotificationsIcon sx={{ color: "#fff" }} />
                                        </Badge>
                                    </IconButton>
                                </RouterLink>
                                <RouterLink to="/account">
                                    <IconButton color="inherit" sx={{ color: "#fff" }}>
                                        <Badge color="secondary">
                                            <AccountCircleIcon />
                                        </Badge>
                                    </IconButton>
                                </RouterLink>
                                <IconButton color="inherit" onClick={handleLogoutClick}>
                                    <Badge color="secondary">
                                        <LogoutIcon />
                                    </Badge>
                                </IconButton>
                            </Toolbar>
                        </AppBar>

                        <Box component="nav" aria-label="menu items">
                            {/* Mobile Navigation */}
                            <Drawer
                                variant="temporary"
                                open={navigationOpen}
                                onClose={() => setNavigationOpen((prev) => !prev)}
                                ModalProps={{ keepMounted: true }}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
                                }}
                            >
                                <Toolbar />
                                <Divider />
                                <LeftNavigation open={navigationOpen} setOpen={setNavigationOpen} />
                            </Drawer>

                            {/* Desktop Navigation */}
                            <Drawer
                                variant="permanent"
                                sx={{
                                    display: { xs: "none", md: "block" },
                                    "& .MuiDrawer-paper": {
                                        position: "relative",
                                        whiteSpace: "nowrap",
                                        width: drawerWidth,
                                        transition: (theme) =>
                                            theme.transitions.create("width", {
                                                easing: theme.transitions.easing.sharp,
                                                duration: theme.transitions.duration.enteringScreen
                                            }),
                                        boxSizing: "border-box",
                                        ...(!navigationOpen && {
                                            overflowX: "hidden",
                                            transition: (theme) =>
                                                theme.transitions.create("width", {
                                                    easing: theme.transitions.easing.sharp,
                                                    duration: theme.transitions.duration.leavingScreen
                                                }),
                                            width: (theme) => ({ xs: theme.spacing(7), md: theme.spacing(9) })
                                        })
                                    }
                                }}
                                open={navigationOpen}
                            >
                                <Toolbar />
                                <Divider />
                                <LeftNavigation open={navigationOpen} setOpen={setNavigationOpen} />
                            </Drawer>
                        </Box>

                        <Box
                            component="main"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: (theme) => theme.palette.grey[900],
                                flexGrow: 1,
                                height: "100vh",
                                overflow: "hidden"
                            }}
                        >
                            <Toolbar />

                            <BannerAlertList
                                bannerAlerts={bannerAlerts}
                                bannerOpen={bannerOpen}
                                setBannerOpen={setBannerOpen}
                                navigationOpen={navigationOpen}
                                setAlertDialogOpen={setAlertDialogOpen}
                            />

                            <Box sx={{ flex: 1, display: "flex", overflowY: "auto" }}>
                                <Outlet />
                            </Box>
                        </Box>
                    </Box>

                    {alertDialogOpen === true && bannerAlerts.length > 0 ? (
                        <AlertDialog bannerAlerts={bannerAlerts} alertDialogOpen={alertDialogOpen} setAlertDialogOpen={setAlertDialogOpen} />
                    ) : (
                        <></>
                    )}

                    {infoDialogOpen === true ? <InfoDialog infoDialogOpen={infoDialogOpen} setInfoDialogOpen={setInfoDialogOpen} /> : <></>}

                    {!!snackbar && (
                        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                            <Alert {...snackbar} onClose={handleCloseSnackbar} />
                        </Snackbar>
                    )}
                </Box>
            )}
        </Box>
    );
}
