import DataObjectIcon from "@mui/icons-material/DataObject";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";
import { JsonDialog } from "./jsonDialog";

export function EmailLog() {
    const { handleFailedFetch } = useUserAuth();
    const [emailData, setEmailData] = useState([]);
    const [sortModel, setSortModel] = useState([
        {
            field: "date_sent",
            sort: "desc"
        }
    ]);

    const [jsonOpen, setJsonOpen] = useState(false);
    const [selectedJson, setSelectedJson] = useState({});

    const columns = [
        {
            field: "json",
            headerName: "",
            flex: 1,
            midWidth: 50,
            maxWidth: 50,
            display: "flex",
            renderCell: (params) => {
                return (
                    <IconButton size="medium" onClick={() => handleJsonOpen(params.row)}>
                        <DataObjectIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "email_to",
            headerName: "To",
            flex: 1,
            minWidth: 100
        },
        {
            field: "email_from",
            headerName: "From",
            flex: 1,
            minWidth: 100
        },
        {
            field: "email_subject",
            headerName: "Subject",
            flex: 1,
            minWidth: 200
        },
        {
            field: "flight_uuid",
            headerName: "Flight UUID",
            flex: 1,
            minWidth: 300
        },
        {
            field: "date_sent",
            headerName: "Date Sent",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        }
    ];

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        const fetchData = async () => {
            await fetch("api/emails/get", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setEmailData(data))
                .catch((err) => handleFailedFetch(err));
        };
        fetchData();

        return () => setEmailData([]);
    }, []);

    const handleJsonOpen = (row) => {
        setSelectedJson(row);
        setJsonOpen(true);
    };

    return (
        <Box sx={{ height: 578 }}>
            <DataGrid
                disableVirtualization
                rows={emailData}
                columns={columns}
                disableRowSeletionOnClick
                getRowId={(row) => row.id}
                pagination
                slots={{ pagination: CustomPagination }}
                initialState={{ pagination: { paginationModel: { pageSize: 9 } } }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
            />
            {jsonOpen ? <JsonDialog jsonOpen={jsonOpen} setJsonOpen={setJsonOpen} selectedJson={selectedJson}></JsonDialog> : <></>}
        </Box>
    );
}
