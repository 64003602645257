import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import HeightIcon from "@mui/icons-material/Height";
import BadgeIcon from "@mui/icons-material/Badge";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ConvertCoordinatesToVertices, ConvertFeetToMeters, GetTimezone, IsInvalidAltitude, IsInvalidPhoneNumber } from "../util";
import { DateHasAlreadyPassed, ConvertMetersToFeet } from "../util";
import { canEditFlight, canPublishByState, canSubmitToLAANC, isFlightBlocked } from "../manager/operations/opUtil";
import { updateSafetyJustification, validateFlightTimes } from "../manager/operations/opUtil";
import { v4 as uuid_v4 } from "uuid";

import { LAANCWorkflowDetails } from "../manager/operations/laancWorkflowDetails";
import { BypassDetails } from "../manager/operations/bypassDetails";
import { useMap } from "../contexts/mapContext";
import { useUserAuth } from "../contexts/authContext";
import { useSocket } from "../contexts/socketContext";
import { useEnv } from "../contexts/envContext";

import * as CONSTANT from "../manager/operations/opConstants";
import { MapDrawComponent } from "../map/mapDrawComponent";

const SubmissionMapping = {
    PLANNING: "Submitting...",
    LAANC: "LAANC..."
};

const FlightCreator = (props) => {
    const [drawType, setDrawType] = useState("Polygon");
    const [canEdit, setCanEdit] = useState(true);
    const [currentEditVolumes, setCurrentEditVolumes] = useState(null);

    const [flightGeojson, setFlightGeojson] = useState(null);
    const [flightUUID, setFlightUUID] = useState(props.flightUUID);
    const [flightWaypoints, setFlightWaypoints] = useState([]);

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [priority, setPriority] = useState(0);

    const [vehicleUUID, setVehicleUUID] = useState("");
    const [vehicleError, setVehicleError] = useState("");

    const [legalRuleset, setLegalRuleset] = useState("PART_107");
    const [los, setLos] = useState("LOS");

    const [buffer, setBuffer] = useState(100);
    const [bufferError, setBufferError] = useState("");

    const [radius, setRadius] = useState(0);
    const [radiusError, setRadiusError] = useState("");

    const [minAltitudeFt, setMinAltitudeFt] = useState(0);
    const [maxAltitudeFt, setMaxAltitudeFt] = useState(400);
    const [altitudeError, setAltitudeError] = useState("");

    const [startDate, setStartDate] = useState(dayjs().add(15, "minutes").set("seconds", 0));
    const [endDate, setEndDate] = useState(dayjs().add(75, "minutes").set("seconds", 0));

    const [dateError, setDateError] = useState("");

    const [durationMin, setDurationMin] = useState("60");
    const [durationError, setDurationError] = useState("");

    const [pilotPhoneNumber, setPilotPhoneNumber] = useState("");
    const [pilotPhoneNumberError, setPilotPhoneNumberError] = useState("");

    const [cmpFlight, setCmpFlight] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(SubmissionMapping.PLANNING);

    const [laancVolumes, setLAANCVolumes] = useState(null);
    const [selectedLaancVolume, setSelectedLaancVolume] = useState(null);
    const [bypassBlock, setBypassBlock] = useState(false);
    const [bypassReason, setBypassReason] = useState(null);

    const [safetyJustification, setSafetyJustification] = useState("");
    const [mobileFlightDrawerOpen, setMobileFlightDrawerOpen] = useState(false);

    const { visibleDrones } = useMap();
    const { user, setSnackbar, handleFailedFetch, updateInfo } = useUserAuth();
    const { planningOperations, publishedOperations, constraints, socket } = useSocket();
    const { laancOn } = useEnv();

    const [filteredConstraints, setFilteredConstraints] = useState([]);
    const [filteredPublishedFlights, setFilteredPublishedFlights] = useState([]);

    const isDesktop = useMediaQuery("(min-width:900px)");

    useEffect(() => {
        if (user) {
            setPilotPhoneNumber(user.phone_number || "");
        }
        if (visibleDrones.length > 0) {
            const first_vehicle_uuid = visibleDrones[0].vehicle_uuid;
            setVehicleUUID(first_vehicle_uuid);
        }
    }, []);

    useEffect(() => {
        //Filter the Constraints and Flights when the end date changes
        setFilteredConstraints(filterVolumesByDate(constraints));
        setFilteredPublishedFlights(filterVolumesByDate(publishedOperations.filter((flight) => flight.flight_uuid !== flightUUID)));
    }, [endDate, constraints, publishedOperations]);

    useEffect(() => {
        const flight = publishedOperations.filter((flight) => {
            return flight.flight_uuid === flightUUID;
        })[0];

        if (!flight) {
            return;
        } else {
            //This portion should only be used during EDIT
            setCmpFlight(flight);
            populateExistingFields(flight);

            //Remove off-nominal volumes
            const edit_volumes = flight.volumes.map((volume) => {
                if (!volume.off_nominal) return volume;
            });

            flight.volumes = edit_volumes;
            setCurrentEditVolumes(flight);
        }
    }, [publishedOperations]);

    useEffect(() => {
        const flight = planningOperations.filter((flight) => {
            return flight.flight_uuid === flightUUID;
        })[0];

        if (!flight) return;

        setCmpFlight(flight);
        populateExistingFields(flight);

        if (laancOn == "false") {
            switch (flight.state) {
                case CONSTANT.DECONFLICTION_CLEAR_STATUS:
                    setSubmitting(true);
                    if (cmpFlight) handlePublishFlight();
                    break;
                case CONSTANT.DECONFLICTION_BLOCKED_STATUS:
                    setSnackbar({
                        children: "Your Flight currently conflicts with another published Flight or Constraint, please update your flight to continue.",
                        severity: "error"
                    });
                    setSubmissionStatus(SubmissionMapping.PLANNING);
            }
            setCurrentEditVolumes(flight);
        } else if (flight.laanc != null) {
            const newLaancVolumes = flight.laanc.volumes.map((volume, i) => {
                return { ...volume, name: `Volume ${i + 1}`, rowIndex: i + 1 };
            });
            setLAANCVolumes(newLaancVolumes);
            setSubmissionStatus(SubmissionMapping.LAANC);
            setCanEdit(false);
            setCurrentEditVolumes(null);
        } else {
            setCurrentEditVolumes(flight);
        }
    }, [planningOperations]);

    // listen for edits to the operation
    useEffect(() => {
        socket.on("op_edit", (message) => {
            const flight = message;
            if (flight.flight_uuid !== flightUUID) return;

            if (flight.state === CONSTANT.DECONFLICTION_BLOCKED_STATUS) {
                if (laancOn === "true") {
                    flight.state = props.flightState;
                    publishFlight(flight);
                    return;
                } else {
                    alert("Your updated flight is conflicted, please update before proceeding.");
                    return;
                }
            } else if (flight.state === CONSTANT.LAANC_REQUIRED_STATUS) {
                return alert("This operation requires FAA Approval. If you wish to proceed, delete it and create another or modify your operational volume.");
            } else if (flight.state === CONSTANT.PRECHECK_BLOCKED_STATUS) {
                return alert("Your updated flight is blocked, please update before proceeding.");
            } else if (flight.state === CONSTANT.LAANC_NOT_REQUIRED_STATUS) {
                flight.state = props.flightState;
                publishFlight(flight);
            } else if (laancOn === "false" && flight.state === CONSTANT.DECONFLICTION_CLEAR_STATUS) {
                flight.state = props.flightState;
                publishFlight(flight);
            } else if (flight.state !== CONSTANT.DECONFLICTION_CHECK_STATUS && flight.state !== CONSTANT.DECONFLICTION_CLEAR_STATUS) {
                return alert("Unable to update this operation.");
            }
        });
        return () => {
            socket.off("op_edit");
        };
    }, []);

    const populateExistingFields = (flight) => {
        const startDate = dayjs(flight.time_start);
        const endDate = dayjs(flight.time_end);
        const flightDurationMin = endDate.diff(startDate, "minute");

        setName(flight.name);
        setStartDate(dayjs(flight.time_start));
        setDurationMin(flightDurationMin);
        setEndDate(dayjs(flight.time_start) + flightDurationMin * 60 * 1000);
        setPriority(flight.priority);
        setPilotPhoneNumber(flight.pilot_phone_number);
        setVehicleUUID(flight.vehicle_uuid);
        setLos(flight.bvlos ? "BVLOS" : "LOS");
        setLegalRuleset(flight.part_107 ? "PART_107" : "44809");
        setFlightWaypoints(flight.waypoints);

        flight.volumes.forEach((volume) => {
            if (volume.circle) {
                const radius = ConvertMetersToFeet(volume.circle.radius_m);
                setRadius(radius);
                setDrawType("Circle");
            } else if (volume.polygon) {
                setDrawType("Polygon");
            }
            setMinAltitudeFt(ConvertMetersToFeet(volume.altitude_min_agl_m));
            setMaxAltitudeFt(ConvertMetersToFeet(volume.altitude_max_agl_m));
        });
    };

    const handlePilotPhoneNumberChange = (e) => {
        let input = e.target.value.replace(/\D/g, "");
        if (input.length > 0) {
            input = "(" + input;
        }
        if (input.length > 3) {
            input = input.slice(0, 4) + ") " + input.slice(4, 11);
        }
        if (input.length > 6) {
            input = input.slice(0, 9) + "-" + input.slice(9);
        }
        setPilotPhoneNumber(input);
    };

    const handleDurationFieldChange = (e) => {
        if (e.target.value === "" || /^[0-9]+$/.test(e.target.value)) {
            const newEndDate = startDate + e.target.value * 60 * 1000;
            setDurationMin(e.target.value);
            setEndDate(newEndDate);
        }
    };

    const handleRadiusChange = (e) => {
        const new_radius_value = e.target.value;
        setRadius(new_radius_value);
    };

    const handleBufferChange = (e) => {
        const new_buffer_value = e.target.value;
        setBuffer(new_buffer_value);
    };

    const checkInputFieldsAreValid = () => {
        const prechecks = [
            {
                passed: flightGeojson && flightGeojson.length,
                prevErrorMessage: "",
                newErrorMessage: "",
                setErrorMessage: () => setSnackbar({ children: "Please create a valid flight volume to continue.", severity: "error" })
            },
            {
                passed: name && name.trim(),
                prevErrorMessage: nameError,
                newErrorMessage: "Please enter a flight name.",
                setErrorMessage: (value) => setNameError(value)
            },
            {
                passed: vehicleUUID,
                prevErrorMessage: vehicleError,
                newErrorMessage: "Please select a vehicle for your flight.",
                setErrorMessage: (value) => setVehicleError(value)
            },
            {
                passed: drawType === "Waypoints" ? buffer && buffer > 0 : true,
                prevErrorMessage: bufferError,
                newErrorMessage: "Please enter a buffer value greater than 0.",
                setErrorMessage: (value) => setBufferError(value)
            },
            {
                passed: drawType === "Circle" ? radius && radius > 0 : true,
                prevErrorMessage: radiusError,
                newErrorMessage: "Please enter a radius value greater than 0.",
                setErrorMessage: (value) => setRadiusError(value)
            },
            {
                passed: IsInvalidAltitude(minAltitudeFt, maxAltitudeFt) === false,
                prevErrorMessage: altitudeError,
                newErrorMessage: "Please enter a valid min and max altitude.",
                setErrorMessage: (value) => setAltitudeError(value)
            },
            {
                passed: startDate.isValid() && parseFloat(durationMin) && parseFloat(durationMin) > 0,
                prevErrorMessage: dateError,
                newErrorMessage: "Please enter a valid start date and duration.",
                setErrorMessage: (value) => setDateError(value)
            },
            {
                passed: props.publishedFlight == false ? !DateHasAlreadyPassed(startDate) : true,
                prevErrorMessage: dateError,
                newErrorMessage: "Start Date cannot be in the past.",
                setErrorMessage: (value) => setDateError(value)
            },
            {
                passed: durationMin > 1,
                prevErrorMessage: durationError,
                newErrorMessage: "Flight duration must be greater than 1 minute.",
                setErrorMessage: (value) => setDurationError(value)
            },
            {
                passed: durationMin <= 720,
                prevErrorMessage: durationError,
                newErrorMessage: "Flight duration must be shorter than 12 hours.",
                setErrorMessage: (value) => setDurationError(value)
            },
            {
                passed: IsInvalidPhoneNumber(pilotPhoneNumber) === false,
                prevErrorMessage: pilotPhoneNumberError,
                newErrorMessage: "Please enter a valid phone number.",
                setErrorMessage: (value) => setPilotPhoneNumberError(value)
            }
        ];
        for (let i = 0; i < prechecks.length; i++) {
            const precheck = prechecks[i];
            if (precheck.passed && precheck.prevErrorMessage) {
                precheck.setErrorMessage("");
            } else if (!precheck.passed) {
                precheck.setErrorMessage(precheck.newErrorMessage);
                setSubmitting(false);
                setSubmissionStatus(SubmissionMapping.PLANNING);
                return false;
            }
        }
        return true;
    };

    const handleSubmitFlightForPlanning = () => {
        const fields_are_valid = checkInputFieldsAreValid();
        if (!fields_are_valid) return;

        if (props.publishedFlight) {
            handleSubmitEditPublished();
        } else {
            let pilotUUID = user.pilot_uuid;
            if (!pilotUUID) {
                pilotUUID = uuid_v4();
                const updatedUser = { ...user, pilot_uuid: pilotUUID };
                updateInfo(updatedUser);
                socket.emit("storeUserChatSocket", updatedUser);
            }
            if (!user.phone_number) {
                updateInfo({ ...user, phone_number: pilotPhoneNumber });
            }

            const altitude_min_agl_m = ConvertFeetToMeters(parseFloat(minAltitudeFt));
            const altitude_max_agl_m = ConvertFeetToMeters(parseFloat(maxAltitudeFt));
            const endDate = startDate.add(durationMin, "minutes");
            const radius_m = ConvertFeetToMeters(radius);

            const flight_volumes = flightGeojson.map((coords) => ({
                flight_uuid: flightUUID,
                name: name,
                vertices: ConvertCoordinatesToVertices(coords),
                type: drawType,
                organization_id: user.organization_id,
                altitude_min_agl_m: altitude_min_agl_m,
                altitude_max_agl_m: altitude_max_agl_m,
                radius: radius_m,
                time_start: new Date(startDate).toISOString(),
                time_end: new Date(endDate).toISOString(),
                created_user_id: user.id,
                priority: priority,
                pilot_uuid: pilotUUID,
                pilot_email: user.email,
                pilot_name: user.first_name + " " + user.last_name,
                pilot_phone_number: pilotPhoneNumber,
                vehicle_uuid: vehicleUUID,
                bvlos: los === "BVLOS",
                part_107: legalRuleset === "PART_107",
                state: CONSTANT.DECONFLICTION_CHECK_STATUS,
                waypoints: flightWaypoints
            }));
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(flight_volumes)
            };

            fetch("/api/op/planning", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((flight) => {
                    setCmpFlight(flight);
                    setSubmitting(false);
                    setFlightUUID(flight.flight_uuid);
                })
                .catch((err) => handleFailedFetch(err));
        }
    };

    const handleSubmitEditPublished = () => {
        const altitude_min_agl_m = ConvertFeetToMeters(parseFloat(minAltitudeFt));
        const altitude_max_agl_m = ConvertFeetToMeters(parseFloat(maxAltitudeFt));
        const radius_m = ConvertFeetToMeters(radius);

        const flight = { ...cmpFlight };
        flight.time_start = new Date(startDate).toISOString();
        flight.time_end = new Date(endDate).toISOString();
        flight.name = name;
        flight.priority = priority;
        flight.vehicle_uuid = vehicleUUID;
        flight.state = CONSTANT.DECONFLICTION_CHECK_STATUS;
        flight.waypoints = flightWaypoints;
        flight.part_107 = legalRuleset === "PART_107";
        flight.bvlos = los === "BVLOS";
        flight.version = parseInt(flight.version) + 1;
        flight.updated_user_id = user.id;

        const newFlightVolumes = [];
        flightGeojson.forEach((coords) => {
            const vertices = ConvertCoordinatesToVertices(coords);
            const newFlightVolume = {
                time_start: new Date(startDate).toISOString(),
                time_end: new Date(startDate).toISOString(),
                altitude_min_agl_m: altitude_min_agl_m,
                altitude_max_agl_m: altitude_max_agl_m
            };
            if (drawType === "circle") {
                newFlightVolume.circle = {
                    center: {
                        lng: vertices[0].lng,
                        lat: vertices[0].lat
                    },
                    radius_m: radius_m
                };
            } else {
                newFlightVolume.polygon = vertices;
            }
            newFlightVolumes.push(newFlightVolume);
        });
        flight.volumes = newFlightVolumes;
        setCmpFlight(flight);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(flight)
        };
        fetch("/api/op/edit", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((flight) => {
                setSubmitting(false);
                setCmpFlight(flight);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handlePublishFlight = () => {
        let bypassJSON;
        if (bypassBlock) {
            if (!bypassReason) return setSnackbar({ children: "Please enter bypass reason", severity: "error" });

            bypassJSON = {
                timestamp: new Date().toISOString(),
                explanation: bypassReason
            };
        }

        const flight = { ...cmpFlight };
        flight.state = CONSTANT.ACCEPTED_STATUS;
        flight.version = 1;
        flight.block_override = bypassJSON;

        //update any Further Coordination volumes with the optional Safety Justification
        updateSafetyJustification(flight, safetyJustification);
        publishFlight(flight);
    };

    const publishFlight = (flight) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify([flight])
        };
        fetch("/api/op/publish", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                setSubmitting(false);
                setSubmissionStatus(SubmissionMapping.COMPLETE);
                props.setFlightCreatorOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handleSubmitToLAANC = () => {
        if (!validateFlightTimes(startDate, endDate, false, setSnackbar)) {
            return;
        }

        const operation = { ...cmpFlight };
        operation.state = CONSTANT.FAA_APPROVAL_CHECK_STATUS;

        //update any Further Coordination volumes with the optional Safety Justification
        updateSafetyJustification(operation, safetyJustification);

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };
        fetch("/api/op/deconflict", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handlePriorityChange = (e) => {
        const new_priority = e.target.value;
        setPriority(new_priority);
    };

    const handleMinAltitudeChange = (e) => {
        const new_min_alt_ft = e.target.value;
        setMinAltitudeFt(new_min_alt_ft);
    };

    const handleMaxAltitudeChange = (e) => {
        const new_max_alt_ft = e.target.value;
        setMaxAltitudeFt(new_max_alt_ft);
    };

    const handleStartDateChange = (value) => {
        setStartDate(value);
        setEndDate(value + durationMin * 60 * 1000);
    };

    const handleUpdateVolumeState = (id, state) => {
        const updatedOperation = { ...cmpFlight };

        updatedOperation.laanc.volumes.forEach((volume) => {
            if (volume.id === id) {
                volume.state = state;
            }
        });

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedOperation)
        };

        fetch("/api/op/deconflict", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleLaancVolumeSelected = (volume_id) => {
        setSelectedLaancVolume(volume_id);
    };

    const handleClose = () => {
        props.setFlightCreatorOpen(false);
    };

    const handleReturnToEdit = () => {
        setCanEdit(true);
        setSubmissionStatus(SubmissionMapping.PLANNING);
        setBypassBlock(false);
        setCurrentEditVolumes(cmpFlight);
    };

    const filterVolumesByDate = (volumes) => {
        const filteredVolumes = volumes.filter((volume) => {
            const volumeStart = new Date(volume.time_start).getTime();
            const volumeEnd = new Date(volume.time_end).getTime();
            return new Date(startDate).getTime() <= volumeEnd && new Date(endDate).getTime() >= volumeStart;
        });
        return filteredVolumes;
    };

    const SubmitToLAANCButton = () => (
        <Button fullWidth onClick={handleSubmitToLAANC} id="submitToLAANC" data-testid="submitToLaancButton" variant="contained">
            Submit to LAANC
        </Button>
    );

    const EditButton = () => (
        <Button fullWidth onClick={handleReturnToEdit} variant="contained">
            Edit Flight
        </Button>
    );

    const PublishButton = () => (
        <Button fullWidth onClick={handlePublishFlight} id="publish" data-testid="publishButton" variant="contained">
            Publish
        </Button>
    );

    const ProceedWithoutLAANCButton = () => (
        <Button fullWidth onClick={() => setBypassBlock(true)} id="proceedWithoutLaanc" data-testid="proceedWithoutLaancButton" variant="contained">
            Proceed without LAANC
        </Button>
    );

    const CloseButton = () => (
        <Button fullWidth variant="text" onClick={handleClose}>
            Close
        </Button>
    );

    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            <Collapse in={isDesktop || mobileFlightDrawerOpen} orientation="horizontal">
                <Box sx={{ p: 2, width: { xs: "100%", md: "375px" }, height: "100%", overflowY: "auto" }}>
                    <Button
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={() => setMobileFlightDrawerOpen(false)}
                        sx={{ display: isDesktop ? "none" : "flex", flexDirection: "row", marginLeft: "auto" }}
                    >
                        Back to map
                    </Button>

                    {submissionStatus === SubmissionMapping.PLANNING ? (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, overflowY: "auto", flexShrink: 0 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <BadgeIcon fontSize="small" />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Flight Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        error={nameError.length > 0}
                                        helperText={nameError}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <PriorityHighIcon fontSize="small" />
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Flight Priority"
                                        value={priority}
                                        onChange={handlePriorityChange}
                                    >
                                        {[
                                            { id: "optionNoPriority", title: "0 - No Priority/Personal/Hobbyist", value: CONSTANT.PERSONAL_TYPE },
                                            { id: "optionTraining", title: "10 - Training", value: CONSTANT.TRAINING_TYPE },
                                            { id: "optionCommercial", title: "20 - Commercial", value: CONSTANT.COMMERCIAL_TYPE },
                                            { id: "optionPublicSafety", title: "30 - Public Safety", value: CONSTANT.PUBLIC_SAFETY_TYPE },
                                            { id: "optionEmergency", title: "40 - Emergency", value: CONSTANT.EMERGENCY_TYPE }
                                        ].map(({ id, title, value }, i) => (
                                            <MenuItem key={i} value={value} id={id}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <AirplanemodeActiveIcon fontSize="small" />
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Flight Vehicle"
                                        value={vehicleUUID}
                                        onChange={(e) => setVehicleUUID(e.target.value)}
                                        error={vehicleError.length > 0}
                                        helperText={vehicleError}
                                    >
                                        {visibleDrones.map((drone, i) => (
                                            <MenuItem key={i} value={drone.vehicle_uuid} id={drone.vehicle_uuid}>
                                                {drone.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <FlightTakeoffIcon fontSize="small" sx={{ mt: "18px" }} />
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Ruleset
                                        </Typography>
                                        <ToggleButtonGroup
                                            exclusive
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            value={legalRuleset}
                                            onChange={(e) => setLegalRuleset(e.target.value)}
                                            color="primary"
                                        >
                                            <ToggleButton value="PART_107">PART 107</ToggleButton>
                                            <ToggleButton value="SECTION_44809">SECTION 44809</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, mt: "5px" }}>
                                    <RemoveRedEyeIcon fontSize="small" sx={{ mt: "18px" }} />
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Line of Sight
                                        </Typography>
                                        <ToggleButtonGroup
                                            exclusive
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            value={los}
                                            onChange={(e) => setLos(e.target.value)}
                                            color="primary"
                                        >
                                            <ToggleButton value="LOS">LOS</ToggleButton>
                                            <ToggleButton value="BVLOS">BVLOS</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="body1">Flight Area</Typography>
                                {drawType === "Waypoints" && (
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                        <PolylineOutlinedIcon fontSize="small" />
                                        <TextField
                                            type="number"
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            label="Buffer"
                                            value={buffer}
                                            onChange={handleBufferChange}
                                            error={bufferError.length > 0}
                                            helperText={bufferError}
                                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Box>
                                )}
                                {drawType === "Circle" && (
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                        <PlaceOutlinedIcon fontSize="small" />
                                        <TextField
                                            type="number"
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            label="Radius"
                                            value={radius}
                                            onChange={handleRadiusChange}
                                            error={radiusError.length > 0}
                                            helperText={radiusError}
                                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Box>
                                )}
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <HeightIcon fontSize="small" />
                                    <TextField
                                        type="number"
                                        size="small"
                                        margin="dense"
                                        label="Min Alt (AGL)"
                                        value={minAltitudeFt}
                                        onChange={handleMinAltitudeChange}
                                        error={altitudeError.length > 0}
                                        helperText={altitudeError}
                                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    <TextField
                                        type="number"
                                        size="small"
                                        margin="dense"
                                        label="Max Alt (AGL)"
                                        value={maxAltitudeFt}
                                        onChange={handleMaxAltitudeChange}
                                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="body1">Date & Time</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                        <AccessTimeIcon fontSize="small" />
                                        <DateTimePicker
                                            label={`Start Time (${GetTimezone()}) *`}
                                            value={dayjs(startDate)}
                                            onChange={handleStartDateChange}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: "small",
                                                    margin: "dense",
                                                    error: dateError.length > 0,
                                                    helperText: dateError
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                        <TimelapseIcon fontSize="small" />
                                        <TextField
                                            label="Duration"
                                            size="small"
                                            margin="dense"
                                            value={durationMin}
                                            onChange={handleDurationFieldChange}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mins</InputAdornment>
                                            }}
                                            error={durationError.length > 0}
                                            helperText={durationError}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="body1">Pilot Details</Typography>

                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                                    <PhoneIcon fontSize="small" />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        margin="dense"
                                        label="Pilot Phone Number"
                                        value={pilotPhoneNumber}
                                        onChange={handlePilotPhoneNumberChange}
                                        error={pilotPhoneNumberError.length > 0}
                                        helperText={pilotPhoneNumberError}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                {submissionStatus === SubmissionMapping.PLANNING ? (
                                    <LoadingButton
                                        fullWidth
                                        variant="contained"
                                        loading={submitting}
                                        loadingIndicator={submissionStatus}
                                        onClick={handleSubmitFlightForPlanning}
                                    >
                                        {"Submit Flight"}
                                    </LoadingButton>
                                ) : (
                                    <></>
                                )}
                                <CloseButton />
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            {bypassBlock ? (
                                <BypassDetails setBypassReason={setBypassReason} />
                            ) : (
                                <LAANCWorkflowDetails
                                    state={cmpFlight.state}
                                    advisories={cmpFlight.advisories}
                                    key={cmpFlight.state}
                                    volumes={laancVolumes}
                                    handleUpdateVolume={handleUpdateVolumeState}
                                    handleLaancVolumeSelected={handleLaancVolumeSelected}
                                    setSafetyJustification={setSafetyJustification}
                                />
                            )}

                            {canPublishByState(cmpFlight.state, laancOn) ? <PublishButton /> : null}
                            {isFlightBlocked(cmpFlight.state) ? bypassBlock ? <PublishButton /> : <ProceedWithoutLAANCButton /> : null}
                            {canEditFlight(cmpFlight.state) ? <EditButton /> : null}
                            {canSubmitToLAANC(cmpFlight.state, laancOn) && !bypassBlock ? <SubmitToLAANCButton /> : null}
                            <CloseButton />
                        </Box>
                    )}
                </Box>
            </Collapse>

            <Box sx={{ flex: 1, position: "relative", overflow: "hidden" }}>
                <MapDrawComponent
                    laancVolumes={laancVolumes}
                    publishedFlights={filteredPublishedFlights}
                    constraints={filteredConstraints}
                    currentEditVolumes={currentEditVolumes}
                    changeDrawType={setDrawType}
                    canEdit={canEdit}
                    radius={radius}
                    setRadius={setRadius}
                    buffer={buffer}
                    setVolumeGeojson={setFlightGeojson}
                    setVolumeWaypoints={setFlightWaypoints}
                    selectedLAANCVolume={selectedLaancVolume}
                    setMinAltitude={setMinAltitudeFt}
                    setMaxAltitude={setMaxAltitudeFt}
                    setName={setName}
                    setSnackbar={setSnackbar}
                    setMobileFlightDrawerOpen={setMobileFlightDrawerOpen}
                />
                <Fab
                    size="medium"
                    color="primary"
                    onClick={() => setMobileFlightDrawerOpen(true)}
                    sx={{
                        display: isDesktop || mobileFlightDrawerOpen ? "none" : "block",
                        position: "absolute",
                        bottom: 8,
                        left: 8
                    }}
                >
                    <KeyboardArrowRightIcon />
                </Fab>
            </Box>
        </Box>
    );
};

export default FlightCreator;
