import { useEffect, useState } from "react";
import { Color, HorizontalOrigin, LabelCollection, LabelStyle, PolylineColorAppearance, Primitive, VerticalOrigin, DistanceDisplayCondition } from "cesium";

const primitiveTypes = [
    { id: 0, name: "airspaces" },
    { id: 1, name: "facilityMap" },
    { id: 2, name: "stadiums" },
    { id: 3, name: "national" },
    { id: 4, name: "prohibited" },
    { id: 5, name: "restricted" },
    { id: 6, name: "military" },
    { id: 7, name: "warning" },
    { id: 8, name: "alert" },
    { id: 9, name: "tfr" }
];

export function usePrimitive(viewer, component, type, primitive, labelCollection = null) {
    const [outlines, setOutlines] = useState(null);
    const [labels, setLabels] = useState(null);

    useEffect(() => {
        if (viewer && component && primitive) {
            const primitiveType = primitiveTypes.find(({ id }) => {
                return id === type;
            });
            if (primitiveType !== undefined && component.volumes[primitiveType.name] === true) {
                handleAddPrimitive();
            }
        }
    }, [viewer, component, primitive]);

    useEffect(() => {
        if (viewer && component && labelCollection) {
            const primitiveType = primitiveTypes.find(({ id }) => {
                return id === type;
            });
            if (primitiveType !== undefined && component.volumes[primitiveType.name] === true) {
                handleAddLabels();
            }
        }
    }, [viewer, component, labelCollection]);

    const handleAddPrimitive = () => {
        if (outlines !== null) {
            viewer.scene.primitives.remove(outlines);
        }
        const outlineClone = new Primitive({
            show: primitive.show,
            geometryInstances: primitive.geometryInstances,
            appearance: new PolylineColorAppearance({
                translucent: false
            }),
            allowPicking: false,
            vertexCacheOptimize: true
        });
        outlineClone.color = primitive.color;
        viewer.scene.primitives.add(outlineClone);
        setOutlines(outlineClone);
    };
    const handleAddLabels = () => {
        if (labels !== null) {
            viewer.scene.primitives.remove(labels);
        }
        const labelClone = new LabelCollection();
        for (var i = 0; i < labelCollection.length; i++) {
            const label = labelCollection.get(i);
            labelClone.add({
                text: label.text,
                position: label.position,
                font: "bold 16px sans-serif",
                distanceDisplayCondition: new DistanceDisplayCondition(0.0e6, 0.0015e7),
                outlineWidth: 3,
                fillColor: Color.WHITE,
                outlineColor: Color.BLACK,
                style: LabelStyle.FILL_AND_OUTLINE,
                horizontalOrigin: HorizontalOrigin.CENTER,
                verticalOrigin: VerticalOrigin.CENTER
            });
        }
        viewer.scene.primitives.add(labelClone);
        setLabels(labelClone);
    };
    return [outlines, labels];
}
