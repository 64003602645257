import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";

import { Alerts } from "./alerts/alerts";
import { Constraints } from "./constraints/constraints";
import { Operations } from "./operations/operations";

import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../contexts/authContext";
import { useLocalStorage } from "../hooks/useLocalStorage";

export function Manager() {
    const [tab, setTab] = useLocalStorage("userManagerTab", "1");
    const { user } = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.user_role === "First Responder") navigate("/map");
    }, []);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Grid item xs={16} sx={{ width: "100%", p: 3 }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Airspace Manager
            </Typography>
            <Paper sx={{ p: 2, pb: 0, mt: 2, display: "flex", flexDirection: "column" }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            <Tab label="Operations" value="1" id="operations" />
                            <Tab label="Constraints" value="2" id="constraints" />
                            <Tab label="Alert Volumes" value="3" id="alertVolumes" />
                        </Tabs>
                    </Box>

                    {/* Operations Manager */}
                    <TabPanel sx={{ pb: 0 }} value="1">
                        <Operations />
                    </TabPanel>

                    {/* Constraints Manager */}
                    <TabPanel sx={{ pb: 0 }} value="2">
                        <Constraints />
                    </TabPanel>

                    {/* Alert Volumes Manager */}
                    <TabPanel sx={{ pb: 0 }} value="3">
                        <Alerts />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Grid>
    );
}
