import React, { useEffect, useState } from "react";

import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AirplaneIcon from "../../public/static/2d-models/airport/AirplaneIcon.svg";

import MVT from "ol/format/MVT.js";
import VectorTileLayer from "ol/layer/VectorTile.js";
import VectorTileSource from "ol/source/VectorTile.js";

import { getBorderFromMapLayerOption } from "./mapUtil";
import { GetLaancAirspaceStyle, GetLaancStyle, getSpecialUseAirspaceStyle, GetLayer } from "../map/mapUtil";

import { useEnv } from "../contexts/envContext";

const MapLaancLayersDropdown = ({ map, mapLayersOpen, setMapLayersOpen, mapLayersAnchorEl, setMapLayersAnchorEl, layers }) => {
    const [operationVectorLayerVisible, setOperationVectorLayerVisible] = useState(true);
    const [constraintVectorLayerVisible, setConstraintVectorLayerVisible] = useState(true);
    const [alertVectorLayerVisible, setAlertVectorLayerVisible] = useState(true);

    const [airspaceLayersOpen, setAirspaceLayersOpen] = useState(false);
    const [airspaceVectorLayerVisible, setAirspaceVectorLayerVisible] = useState(true);
    const [airspaceSubLayers, setAirspaceSublayers] = useState([
        { local_type: "CLASS_B", visible: true, border: "2px solid #0087bf" },
        { local_type: "CLASS_C", visible: true, border: "2px solid #9d6b9e" },
        { local_type: "CLASS_D", visible: true, border: "2px dotted #058eb8" },
        { local_type: "CLASS_E2", visible: true, border: "2px dotted #9e6e9d" }
    ]);
    const [facilityMapVectorLayerVisible, setFacilityMapVectorLayerVisible] = useState(true);
    const [stadiumVectorLayerVisible, setStadiumVectorLayerVisible] = useState(true);
    const [nationalSecurityNoFlyZoneVectorLayerVisible, setNationalSecurityNoFlyZoneVectorLayerVisible] = useState(true);

    const [specialUseAirspaceLayers, setSpecialUseAirspaceLayers] = useState([
        { title: "Prohibited Airspaces", type_code: "P", visible: true },
        { title: "Restricted Airspaces", type_code: "R", visible: true },
        { title: "Military Operation Areas", type_code: "MOA", visible: true },
        { title: "Warning Areas", type_code: "W", visible: true },
        { title: "Alert Areas", type_code: "A", visible: true }
    ]);
    const [tfrVectorLayerVisible, setTfrVectorLayerVisible] = useState(true);
    const [airportVectorLayerVisible, setAirportVectorLayerVisible] = useState(true);
    const [dcFrzSfraVectorLayerVisible, setDcFrzSfraVectorLayerVisible] = useState(true);

    const { laancTileServerUrl } = useEnv();

    // initialize layers
    useEffect(() => {
        if (!map) {
            return;
        }
        const laanc_tilesets = [
            { tileset_id: "LAANC_AIRSPACE", min_zoom: 0, max_zoom: 14 },
            { tileset_id: "UASFM", min_zoom: 0, max_zoom: 14 },
            { tileset_id: "FT_NSUFR", min_zoom: 0, max_zoom: 14 },
            { tileset_id: "PT_NSUFR", min_zoom: 0, max_zoom: 14 },
            { tileset_id: "SU_AIRSPACE", min_zoom: 0, max_zoom: 13 },
            { tileset_id: "STADIUMS", min_zoom: 0, max_zoom: 14 },
            { tileset_id: "FAA_TFR", min_zoom: 0, max_zoom: 14 },
            { tileset_id: "AIRPORTS", min_zoom: 9, max_zoom: 14 },
            { tileset_id: "DC_FRZ_SFRA", min_zoom: 0, max_zoom: 14 }
        ];
        laanc_tilesets.forEach(({ tileset_id, min_zoom, max_zoom }) => {
            if (tileset_id === "LAANC_AIRSPACE" && !layers?.airspaces) {
                return;
            } else if (tileset_id !== "LAANC_AIRSPACE" && !layers?.laanc) {
                return;
            }

            let style = undefined;
            if (tileset_id === "LAANC_AIRSPACE") {
                style = (feature) => GetLaancAirspaceStyle(feature, airspaceSubLayers);
            } else {
                style = (feature) => GetLaancStyle(feature);
            }
            const source = new VectorTileSource({
                format: new MVT(),
                url: `${laancTileServerUrl}/data/${tileset_id}/{z}/{x}/{y}.pbf?time=${new Date().getTime()}`,
                maxZoom: max_zoom,
                minZoom: min_zoom
            });
            const layer = new VectorTileLayer({
                source: source,
                style: style,
                minZoom: min_zoom,
                properties: { name: tileset_id },
                zIndex: 1
            });
            map.addLayer(layer);
        });
    }, [map]);

    const handleAirspaceLayerCheckboxClick = (local_types, value) => {
        const updated_sublayers = airspaceSubLayers.map((setting) => {
            if (local_types.includes(setting.local_type)) {
                return {
                    ...setting,
                    visible: value
                };
            } else {
                return setting;
            }
        });
        setAirspaceSublayers(updated_sublayers);

        if (updated_sublayers.every(({ visible }) => visible === updated_sublayers[0].visible)) {
            setAirspaceVectorLayerVisible(updated_sublayers[0].visible);
        }
        const airspaces = GetLayer(map, "LAANC_AIRSPACE");
        airspaces.setStyle((feature) => {
            return GetLaancAirspaceStyle(feature, updated_sublayers);
        });
    };
    const handleLaancLayerCheckboxClick = (layer_name, type_code, updated_visibility) => {
        if (layer_name.includes("flights")) {
            setOperationVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        } else if (layer_name.includes("constraints")) {
            setConstraintVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        } else if (layer_name.includes("alerts")) {
            setAlertVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        } else if (layer_name.includes("UASFM")) {
            setFacilityMapVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        } else if (layer_name.includes("STADIUMS")) {
            setStadiumVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        } else if (layer_name.includes("NSUFR")) {
            setNationalSecurityNoFlyZoneVectorLayerVisible(updated_visibility);
            handleToggleNationalSecurityNoFlyZoneLayerVisibility(updated_visibility);
        } else if (layer_name.includes("SU_AIRSPACE")) {
            const updated_su_airspace_layers = specialUseAirspaceLayers.map((layer) => {
                if (layer.type_code === type_code) {
                    return { ...layer, visible: updated_visibility };
                } else {
                    return layer;
                }
            });
            setSpecialUseAirspaceLayers(updated_su_airspace_layers);
            handleToggleSpecialUseAirspaceLayerVisibility(updated_su_airspace_layers);
        } else if (layer_name.includes("FAA_TFR")) {
            setTfrVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        } else if (layer_name.includes("AIRPORTS")) {
            setAirportVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        } else if (layer_name.includes("DC_FRZ_SFRA")) {
            setDcFrzSfraVectorLayerVisible(updated_visibility);
            handleToggleLayerVisibility(layer_name, updated_visibility);
        }
    };
    const handleToggleLayerVisibility = (layer_name, visibility) => {
        const layer = GetLayer(map, layer_name);
        if (layer) {
            layer.setVisible(visibility);
        }
    };
    const handleToggleNationalSecurityNoFlyZoneLayerVisibility = (visibility) => {
        const full_time_nsufr = GetLayer(map, "FT_NSUFR");
        if (full_time_nsufr) {
            full_time_nsufr.setVisible(visibility);
        }
        const part_time_nsufr = GetLayer(map, "PT_NSUFR");
        if (full_time_nsufr) {
            part_time_nsufr.setVisible(visibility);
        }
    };
    const handleToggleSpecialUseAirspaceLayerVisibility = (updated_su_airspace_layers) => {
        const su_airspaces = GetLayer(map, "SU_AIRSPACE");
        if (su_airspaces) {
            su_airspaces.setStyle((feature) => {
                return getSpecialUseAirspaceStyle(feature, updated_su_airspace_layers);
            });
        }
    };
    const handleCloseLaancLayersDropdown = () => {
        setMapLayersOpen(false);
        setMapLayersAnchorEl(null);
    };
    return (
        <Popover
            open={mapLayersOpen}
            anchorEl={mapLayersAnchorEl}
            onClose={handleCloseLaancLayersDropdown}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <List dense>
                {[
                    {
                        id: "flights",
                        title: "Operations",
                        checked: operationVectorLayerVisible,
                        visible: layers?.operations
                    },
                    {
                        id: "constraints",
                        title: "Constraints",
                        checked: constraintVectorLayerVisible,
                        visible: layers?.constraints
                    },
                    {
                        id: "alerts",
                        title: "Alert Volumes",
                        checked: alertVectorLayerVisible,
                        visible: layers?.alerts
                    },
                    {
                        id: "LAANC_AIRSPACE",
                        title: "Airspaces",
                        checked: airspaceSubLayers.every(({ visible }) => visible),
                        visible: layers?.airspaces
                    },
                    {
                        id: "UASFM",
                        title: "Facility Maps",
                        checked: facilityMapVectorLayerVisible,
                        visible: layers?.laanc
                    },
                    {
                        id: "STADIUMS",
                        title: "Stadiums",
                        checked: stadiumVectorLayerVisible,
                        visible: layers?.laanc
                    },
                    {
                        id: "NSUFR",
                        title: "National Security UAS Flight Restrictions",
                        checked: nationalSecurityNoFlyZoneVectorLayerVisible,
                        visible: layers?.laanc
                    },
                    ...specialUseAirspaceLayers.map(({ title, type_code, visible }) => {
                        return {
                            id: "SU_AIRSPACE",
                            title: title,
                            checked: visible,
                            visible: layers?.laanc,
                            type_code: type_code
                        };
                    }),
                    {
                        id: "FAA_TFR",
                        title: "Temporary Flight Restrictions",
                        checked: tfrVectorLayerVisible,
                        visible: layers?.laanc
                    },
                    {
                        id: "AIRPORTS",
                        title: "Airports",
                        checked: airportVectorLayerVisible,
                        visible: layers?.laanc
                    },
                    {
                        id: "DC_FRZ_SFRA",
                        title: "DC FRZ SFRA",
                        checked: dcFrzSfraVectorLayerVisible,
                        visible: layers?.laanc
                    }
                ].map((option, i) => {
                    if (!option.visible) {
                        return;
                    }
                    const border = getBorderFromMapLayerOption(option);
                    return option.title !== "Airspaces" ? (
                        <ListItem
                            key={i}
                            dense
                            secondaryAction={
                                <Checkbox
                                    checked={option.checked}
                                    onChange={() => handleLaancLayerCheckboxClick(option.id, option.type_code, !option.checked)}
                                    size="small"
                                />
                            }
                            sx={{ padding: "8px 48px 8px 8px" }}
                        >
                            <ListItemIcon sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {option.title !== "Airports" ? (
                                    <Box sx={{ height: "20px", width: "20px", border: border }} />
                                ) : (
                                    <img src={`${AirplaneIcon}`} alt="Airplane Icon" />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={option.title} />
                        </ListItem>
                    ) : (
                        <React.Fragment key={i}>
                            <ListItem
                                dense
                                secondaryAction={
                                    <Box sx={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", justifyContent: "center" }}>
                                        <Checkbox
                                            size="small"
                                            checked={option.checked}
                                            indeterminate={!airspaceSubLayers.every(({ visible }) => visible === airspaceSubLayers[0].visible)}
                                            onChange={() =>
                                                handleAirspaceLayerCheckboxClick(["CLASS_B", "CLASS_C", "CLASS_D", "CLASS_E2"], !airspaceVectorLayerVisible)
                                            }
                                        />
                                        {airspaceLayersOpen ? (
                                            <ExpandLessIcon onClick={() => setAirspaceLayersOpen(false)} />
                                        ) : (
                                            <ExpandMoreIcon onClick={() => setAirspaceLayersOpen(true)} />
                                        )}
                                    </Box>
                                }
                                sx={{ padding: "8px 48px 8px 8px" }}
                            >
                                <ListItemIcon sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Box sx={{ height: "20px", width: "20px", border: border }} />
                                </ListItemIcon>
                                <ListItemText id={option.id} primary={option.title} />
                            </ListItem>
                            <Collapse in={airspaceLayersOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {airspaceSubLayers.map(({ local_type, visible, border }, index) => (
                                        <ListItem
                                            key={index}
                                            dense
                                            sx={{ padding: "8px 48px 8px 8px" }}
                                            secondaryAction={
                                                <Checkbox
                                                    checked={visible}
                                                    onChange={() => handleAirspaceLayerCheckboxClick([local_type], !visible)}
                                                    size="small"
                                                />
                                            }
                                        >
                                            <ListItemIcon sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Box sx={{ height: "20px", width: "20px", border: border }} />
                                            </ListItemIcon>
                                            <ListItemText primary={local_type} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    );
                })}
            </List>
        </Popover>
    );
};

export default MapLaancLayersDropdown;
