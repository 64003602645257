import ClearIcon from "@mui/icons-material/Clear";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import ModeOutlined from "@mui/icons-material/ModeOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";

import { kml } from "@tmcw/togeojson";
import { buffer, circle, featureCollection, lineString, polygon } from "@turf/turf";
import { CallbackProperty, Cartesian3, Cartographic, Color, ColorMaterialProperty, HeightReference, Math } from "cesium";
import { Matrix4, PolygonHierarchy, SceneMode, ScreenSpaceEventHandler, ScreenSpaceEventType, defined, subscribeAndEvaluate } from "cesium";
import { Viewer } from "resium";

import { handleCreateEditPoint } from "../manager/operations/opUtil";
import { ConvertFeaturesToVolumes, ConvertFeetToMeters, ConvertMetersToFeet } from "../util";
import { MapContextMenu } from "./mapContextMenu";
import { MapLayers } from "./mapLayers";
import {
    createLAANCVolumeEntity,
    createPoint,
    createVolumeEntity,
    formatAlertVolumeToDraw,
    formatConstraintToDraw,
    formatOperationToDraw,
    isDuplicatePosition
} from "./mapUtil";
import { getActiveDrawMessageFromMethod, getAlertColorFromId, getCheckedFromSubLayers, getIndeterminateFromSubLayers } from "./mapUtil";
import { pickPosition, zoomToEntities } from "./mapUtil";
import { useUserAuth } from "../contexts/authContext";
import { useEnv } from "../contexts/envContext";
import { useMap } from "../contexts/mapContext";
import { usePrimitive } from "../hooks/usePrimitive";
import * as TEST_UTIL from "../testUtil";

const components = [
    {
        type: -1,
        name: "Map Draw",
        description: "Used for unit testing",
        volumes: {
            operations: false,
            constraints: false,
            alerts: false,
            airspaces: false,
            facilityMap: false,
            stadiums: false,
            national: false,
            prohibited: false,
            restricted: false,
            military: false,
            warning: false,
            alert: false,
            tfr: false
        },
        methods: [
            { method: "Upload", icon: <FileUploadOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Automatic", icon: <HdrAutoIcon sx={{ width: "100%" }} /> },
            { method: "Waypoints", icon: <PolylineOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Circle", icon: <PlaceOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Polygon", icon: <ModeOutlined sx={{ width: "100%" }} /> },
            { method: "Clear", icon: <ClearIcon sx={{ width: "100%" }} /> }
        ]
    },
    {
        type: 0,
        name: "Create Flight",
        description: "Map used to create flights",
        volumes: {
            operations: true,
            constraints: true,
            alerts: false,
            airspaces: true,
            facilityMap: true,
            stadiums: true,
            national: true,
            prohibited: true,
            restricted: true,
            military: true,
            warning: true,
            alert: true,
            tfr: true
        },
        methods: [
            { method: "Upload", icon: <FileUploadOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Waypoints", icon: <PolylineOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Circle", icon: <PlaceOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Polygon", icon: <ModeOutlined sx={{ width: "100%" }} /> },
            { method: "Clear", icon: <ClearIcon sx={{ width: "100%" }} /> }
        ]
    },
    {
        type: 1,
        name: "Edit Flight",
        description: "Map used to edit flights",
        volumes: {
            operations: true,
            constraints: true,
            alerts: false,
            airspaces: true,
            facilityMap: true,
            stadiums: true,
            national: true,
            prohibited: true,
            restricted: true,
            military: true,
            warning: true,
            alert: true,
            tfr: true
        },
        methods: [
            { method: "Upload", icon: <FileUploadOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Waypoints", icon: <PolylineOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Circle", icon: <PlaceOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Polygon", icon: <ModeOutlined sx={{ width: "100%" }} /> },
            { method: "Clear", icon: <ClearIcon sx={{ width: "100%" }} /> }
        ]
    },
    {
        type: 2,
        name: "Create Constraint",
        description: "Map used to create constraints",
        volumes: {
            operations: false,
            constraints: true,
            alerts: false,
            airspaces: true,
            facilityMap: false,
            stadiums: false,
            national: false,
            prohibited: false,
            restricted: false,
            military: false,
            warning: false,
            alert: false,
            tfr: false
        },
        methods: [
            { method: "Upload", icon: <FileUploadOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Circle", icon: <PlaceOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Polygon", icon: <ModeOutlined sx={{ width: "100%" }} /> },
            { method: "Clear", icon: <ClearIcon sx={{ width: "100%" }} /> }
        ]
    },
    {
        type: 3,
        name: "Create Alert",
        description: "Map used to create alerts",
        volumes: {
            operations: true,
            constraints: false,
            alerts: true,
            airspaces: true,
            facilityMap: false,
            stadiums: false,
            national: false,
            prohibited: false,
            restricted: false,
            military: false,
            warning: false,
            alert: false,
            tfr: false
        },
        methods: [
            { method: "Upload", icon: <FileUploadOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Automatic", icon: <HdrAutoIcon sx={{ width: "100%" }} /> },
            { method: "Circle", icon: <PlaceOutlinedIcon sx={{ width: "100%" }} /> },
            { method: "Polygon", icon: <ModeOutlined sx={{ width: "100%" }} /> },
            { method: "Clear", icon: <ClearIcon sx={{ width: "100%" }} /> }
        ]
    }
];

export const MapDraw = (props) => {
    const laancEntities = useRef([]);
    const cursorEntities = useRef([]);
    const createdEntities = useRef([]);
    const createdWaypointHierarchy = useRef([]);
    const createdAlertEntities = useRef([]);

    const minimumAltitude = useRef(0);
    const maximumAltitude = useRef(0);
    const radius = useRef(0);
    const waypointBuffer = useRef(100);

    const editPoints = useRef([]);
    const editPointPositions = useRef(new Map());
    const editEntitiesHierarchy = useRef(new Map());

    const viewerRef = useRef(null);
    const viewerContainerRef = useRef(null);
    const cursorLocationRef = useRef(null);
    const fileUploadRef = useRef(null);
    const createHandlerRef = useRef(null);
    const editHanderRef = useRef(null);

    const [component, setComponent] = useState(null);
    const [isInitialVolume, setIsInitialVolume] = useState(true);
    const [viewerState, setViewerState] = useState(null);
    const [layers, setLayers] = useState([]);

    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [contextMenuEntities, setContextMenuEntities] = useState([]);
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState(null);

    const { airspacePrimitiveClassA, airspacePrimitiveClassB, airspacePrimitiveClassC, airspacePrimitiveClassD, airspacePrimitiveModeC } = useMap();
    const { airspacePrimitiveClassE2, airspacePrimitiveClassE3, airspacePrimitiveClassE4, airspacePrimitiveClassE5, colors, tfrAreasPrimitive } = useMap();
    const { stadiumsPrimitive, stadiumLabelCollection, nationalSecurityNoFlyPrimitive, prohibitedAirspacePrimitive, restrictedAirspacePrimitive } = useMap();
    const { facilityMapPrimitive, facilityMapLabelCollection, militaryOperationAreasPrimitive, warningAreasPrimitive, alertAreasPrimitive } = useMap();
    const { user, userMapSettings, userOperationalStates, setSnackbar, updateMapSettings } = useUserAuth();
    const { providerViewModels } = useEnv();

    const [operationEntities, setOperationEntities] = useState([]);
    const [constraintEntities, setConstraintEntities] = useState([]);
    const [alertEntities, setAlertEntities] = useState([]);

    const [airspacesClassA] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassA);
    const [airspacesClassB] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassB);
    const [airspacesClassC] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassC);
    const [airspacesClassD] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassD);
    const [airspacesClassE2] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE2);
    const [airspacesClassE3] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE3);
    const [airspacesClassE4] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE4);
    const [airspacesClassE5] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE5);
    const [airspacesModeC] = usePrimitive(viewerState, component, 0, airspacePrimitiveModeC);

    const [facilityMaps, facilityMapLabels] = usePrimitive(viewerState, component, 1, facilityMapPrimitive, facilityMapLabelCollection);
    const [stadiums, stadiumLabels] = usePrimitive(viewerState, component, 2, stadiumsPrimitive, stadiumLabelCollection);
    const [noFlyZones] = usePrimitive(viewerState, component, 3, nationalSecurityNoFlyPrimitive);
    const [prohibitedAirspaces] = usePrimitive(viewerState, component, 4, prohibitedAirspacePrimitive);
    const [restrictedAirspaces] = usePrimitive(viewerState, component, 5, restrictedAirspacePrimitive);
    const [militaryOperationAreas] = usePrimitive(viewerState, component, 6, militaryOperationAreasPrimitive);
    const [warningAreas] = usePrimitive(viewerState, component, 7, warningAreasPrimitive);
    const [alertAreas] = usePrimitive(viewerState, component, 8, alertAreasPrimitive);
    const [tfrAreas] = usePrimitive(viewerState, component, 9, tfrAreasPrimitive);

    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            setViewerState(viewerRef.current.cesiumElement);
        }
    }, [component]);

    useEffect(() => {
        const found = components.find(({ type }) => type === props.type);
        if (found) {
            setComponent(found);

            // Solely for unit tests so that the volumes are not empty.
            if (props.type === -1) {
                props.setFinalVolumes(TEST_UTIL.testingVolumes);
            }
        }
        return () => {
            if (viewerRef.current && viewerRef.current.cesiumElement) {
                const canvas = viewerRef.current.cesiumElement.scene.canvas;
                const gl = canvas.getContext("webgl") || canvas.getContext("webgl2");
                if (gl) {
                    const loseContextExtension = gl.getExtension("WEBGL_lose_context");
                    if (loseContextExtension) {
                        loseContextExtension.loseContext();
                    }
                }
            }
        };
    }, []);

    useEffect(() => {
        const component = components.find(({ type }) => type === props.type);
        if (component) {
            handleCreateLayersFromComponent(component);
        }
    }, [facilityMaps, stadiums, noFlyZones, prohibitedAirspaces, restrictedAirspaces, militaryOperationAreas, warningAreas, alertAreas, tfrAreas]);

    // if you are editing an existing volume, draw it and set editable
    useEffect(() => {
        if (component && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const editVolume = props.editVolume;
            if (component.type === 1 && props.editVolume && isInitialVolume) {
                handleRemoveEntities(createdEntities.current);
                handleRemoveEntities(editPoints.current);
                handleRemoveEntities(laancEntities.current);

                createdEntities.current = [];
                editPoints.current = [];
                laancEntities.current = [];

                const minAltitudeAglMeters = editVolume.volumes[0].altitude_min_agl_m;
                const maxAltitudeAglMeters = editVolume.volumes[0].altitude_max_agl_m;

                minimumAltitude.current = minAltitudeAglMeters;
                maximumAltitude.current = maxAltitudeAglMeters;

                editVolume.volumes.forEach((volume, index) => {
                    const operationToDraw = formatOperationToDraw(editVolume, index, volume, userOperationalStates);
                    operationToDraw.altitude_lower_hae = minAltitudeAglMeters;
                    operationToDraw.altitude_upper_hae = maxAltitudeAglMeters;

                    const operationVolume = createVolumeEntity(operationToDraw, userMapSettings.op_opacity);
                    const operationEntity = viewer.entities.add(operationVolume);
                    createdEntities.current.push(operationEntity);
                });
                if (props.canEdit === true) {
                    if (editVolume.volumes[0] && editVolume.volumes[0].circle) {
                        handleSetEntitiesEditable(1, viewer, createdEntities.current, true);
                    } else if (editVolume.volumes[0] && editVolume.volumes[0].polygon) {
                        handleSetEntitiesEditable(0, viewer, createdEntities.current, true);
                    }
                }
            }
        }
    }, [component, props.editVolume, props.canEdit]);

    // update refs/entities after prop updates
    useEffect(() => {
        const minAltitudeFeet = parseFloat(props.minAltitude);
        const maxAltitudeFeet = parseFloat(props.maxAltitude);

        if (isNaN(minAltitudeFeet) === false && isNaN(maxAltitudeFeet) === false) {
            minimumAltitude.current = ConvertFeetToMeters(minAltitudeFeet);
            maximumAltitude.current = ConvertFeetToMeters(maxAltitudeFeet);

            handleUpdateEntitiesAltitudes(createdEntities.current, minimumAltitude.current, maximumAltitude.current);
            handleUpdateEntitiesAltitudes(editPoints.current, minimumAltitude.current, maximumAltitude.current);
        }
    }, [props.minAltitude, props.maxAltitude]);

    useEffect(() => {
        const newRadiusFeet = parseFloat(props.radius);
        if (newRadiusFeet) {
            const newRadiusMeters = ConvertFeetToMeters(newRadiusFeet);
            radius.current = newRadiusMeters;
            createdEntities.current.forEach((entity) => {
                if (entity.ellipse) {
                    entity.ellipse.semiMinorAxis = newRadiusMeters;
                    entity.ellipse.semiMajorAxis = newRadiusMeters;
                }
            });
            handleUpdateFinalVolumes();
        }
    }, [props.radius]);

    useEffect(() => {
        const newWaypointBuffer = parseFloat(props.waypointBuffer);
        waypointBuffer.current = newWaypointBuffer;
        if (newWaypointBuffer && newWaypointBuffer > 0) {
            createdEntities.current.forEach((entity) => {
                const hierarchy = handleGetPolygonHierarchyFromWaypoints(newWaypointBuffer);
                editEntitiesHierarchy.current.set(entity.id, hierarchy);
            });
            handleUpdateFinalVolumes();
        }
    }, [props.waypointBuffer]);

    useEffect(() => {
        if (props.activeDrawMethod === "Automatic" && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const bufferId = props.automaticBufferId;
            const bufferValue = parseFloat(props.automaticBuffer) || 0;
            const material = getAlertColorFromId(props.alertColorId, colors);

            handleRemoveEntities(createdEntities.current);
            createdEntities.current = [];

            if (bufferId) {
                const entities = operationEntities.filter((entity) => {
                    let color = Color.WHITE;
                    let found = false;
                    if (entity.uuid === bufferId) {
                        color = Color.YELLOW;
                        found = true;
                    }
                    if (entity.polygon) {
                        entity.polygon.outlineColor = color;
                    } else {
                        entity.ellipse.outlineColor = color;
                    }
                    return found;
                });
                const minAltitudeFeet = parseFloat(props.minAltitude || 0);
                const maxAltitudeFeet = parseFloat(props.maxAltitude || 0);
                const buffered = [];
                entities.forEach(() => {
                    const bufferedEntity = viewer.entities.add({
                        polygon: {
                            material: new ColorMaterialProperty(material),
                            height: ConvertFeetToMeters(minAltitudeFeet),
                            extrudedHeight: ConvertFeetToMeters(maxAltitudeFeet)
                        }
                    });
                    buffered.push(bufferedEntity);
                });
                createdEntities.current = buffered;
                handleAutomaticBufferChange(entities, buffered, bufferValue);
                viewer.scene.requestRender();
            }
        }
    }, [props.activeDrawMethod, props.automaticBufferId, props.automaticBuffer]);

    useEffect(() => {
        if (props.canEdit === false) {
            handleRemoveEditFunctionality();
        }
    }, [props.canEdit]);

    useEffect(() => {
        if (component && props.laancVolumes && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            laancEntities.current.forEach((entity) => {
                viewer.entities.remove(entity);
            });
            laancEntities.current = [];
            props.laancVolumes.forEach((volume, index) => {
                const laancVolume = createLAANCVolumeEntity(volume, index);
                const laancEntity = viewer.entities.add(laancVolume);
                laancEntities.current.push(laancEntity);
            });
        }
    }, [component, props.laancVolumes]);

    useEffect(() => {
        if (component && (component.type === 0 || component.type === 1)) {
            const viewer = viewerRef.current.cesiumElement;
            if (viewerRef.current && viewerRef.current.cesiumElement) {
                if (props.selectedLaancVolumeNames !== undefined && props.selectedLaancVolumeNames.length > 0) {
                    const selectedName = props.selectedLaancVolumeNames[0];
                    const entity = laancEntities.current.find(({ name }) => {
                        return name === selectedName;
                    });
                    if (entity) {
                        viewer.selectedEntity = entity;
                    } else {
                        viewer.selectedEntity = undefined;
                    }
                } else {
                    viewer.selectedEntity = undefined;
                }
            }
        }
    }, [component, props.selectedLaancVolumeNames]);

    useEffect(() => {
        if (component && component.type === 3 && colors) {
            handleUpdateAlertEntityColors(createdEntities.current);
        } else if (colors) {
            handleUpdateAlertEntityColors(createdAlertEntities.current);
        }
    }, [component, props.alertColorId, colors]);

    useEffect(() => {
        const minAltitudeFeet = parseFloat(props.alertMinAltitude);
        const maxAltitudeFeet = parseFloat(props.alertMaxAltitude);

        if (isNaN(minAltitudeFeet) === false && isNaN(maxAltitudeFeet) === false) {
            minimumAltitude.current = ConvertFeetToMeters(minAltitudeFeet);
            maximumAltitude.current = ConvertFeetToMeters(maxAltitudeFeet);
        }
    }, [props.alertMinAltitude, props.alertMaxAltitude]);

    useEffect(() => {
        if (props.alertVolumes && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            createdAlertEntities.current.forEach((entity) => {
                viewer.entities.remove(entity);
            });
            const entities = [];
            props.alertVolumes.forEach((volume) => {
                const color = getAlertColorFromId(props.alertColorId, colors);
                const hierarchy = volume.vertices.reduce((acc, { lng, lat }) => {
                    return [...acc, lng, lat];
                }, []);
                const entity = viewer.entities.add({
                    polygon: {
                        material: ColorMaterialProperty(color),
                        hierarchy: Cartesian3.fromDegreesArray(hierarchy)
                    }
                });
                entities.push(entity);
            });
            createdAlertEntities.current = entities;
            handleUpdateAlertEntityColors(createdAlertEntities.current);
            viewer.scene.requestRender();
        }
    }, [component, props.alertColorId, props.alertVolumes]);

    // initialize viewer settings
    useEffect(() => {
        if (component && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const sceneMode = userMapSettings.map_scene_mode;
            const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

            handler.setInputAction(({ endPosition }) => {
                const cartesian = viewer.camera.pickEllipsoid(endPosition, viewer.scene.globe.ellipsoid);
                if (cartesian) {
                    const cartographic = Cartographic.fromCartesian(cartesian);
                    const longitudeString = Math.toDegrees(cartographic.longitude);
                    const latitudeString = Math.toDegrees(cartographic.latitude);
                    cursorLocationRef.current.innerHTML = `( ${latitudeString}, ${longitudeString} )`;
                }
            }, ScreenSpaceEventType.MOUSE_MOVE);

            if (sceneMode === "SCENE3D") {
                viewer.scene.mode = SceneMode.SCENE3D;
            } else if (sceneMode === "COLUMBUS_VIEW") {
                viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
            } else if (sceneMode === "SCENE2D") {
                viewer.scene.mode = SceneMode.SCENE2D;
            }
            if (userMapSettings.map_preference >= 0 && userMapSettings.map_preference < providerViewModels.length) {
                viewer.baseLayerPicker.viewModel.selectedImagery = providerViewModels[userMapSettings.map_preference];
            }
            viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
            viewer.scene.screenSpaceCameraController.enableTilt = false;
            viewer.scene.globe.depthTestAgainstTerrain = false;
            viewer.scene.requestRenderMode = true;
            viewer.selectedEntityChanged.addEventListener(handleSelectedEntityChanged);
            viewer.scene.sun.show = false;
            viewer.scene.skyBox.show = false;

            viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                viewer.camera.flyTo({
                    destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
                });
            });
            viewer.scene.morphComplete.addEventListener(() => {
                setTimeout(() => {
                    viewer.camera.flyTo({
                        destination: Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude, 200000.0)
                    });
                }, [500]);
            });
            viewer.scene.globe.tileLoadProgressEvent.addEventListener((loadingTiles) => {
                if (loadingTiles === 0 && viewer.scene.globe.tilesLoaded) {
                    document.body.setAttribute("map-loaded", "true");
                }
            });

            subscribeAndEvaluate(viewer.baseLayerPicker.viewModel, "selectedImagery", (selectedImageryProviderViewModel) => {
                for (let i = 0; i < viewer.imageryLayers.length; i++) {
                    const layer = viewer.imageryLayers.get(i);
                    if (layer !== undefined && layer.isBaseLayer()) {
                        layer.brightness = userMapSettings.brightness;
                    }
                }
                const index = providerViewModels.findIndex((imageryLayer) => {
                    return imageryLayer.name === selectedImageryProviderViewModel.name;
                });
                if (index !== userMapSettings.map_preference) {
                    const imagerySettings = {
                        ...userMapSettings,
                        map_preference: index
                    };
                    updateMapSettings(imagerySettings);
                }
            });
            if (createdEntities.current.length) {
                zoomToEntities(viewer, createdEntities.current);
            } else {
                viewer.camera.lookAt(Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude), new Cartesian3(0.0, 0.0, 200000.0));
                viewer.camera.lookAtTransform(Matrix4.IDENTITY);
            }
        }

        return () => {
            document.body.removeAttribute("map-loaded");
        };
    }, [component]);

    // update airspaces for map layers
    useEffect(() => {
        if (component && viewerRef.current && viewerRef.current.cesiumElement) {
            const { volumes } = component;
            if (volumes.airspaces === true) {
                const allAirspaces = [
                    airspacesClassA,
                    airspacesClassB,
                    airspacesClassC,
                    airspacesClassD,
                    airspacesClassE2,
                    airspacesClassE3,
                    airspacesClassE4,
                    airspacesClassE5,
                    airspacesModeC
                ];
                const populated = allAirspaces.every((airspace) => {
                    return airspace !== null;
                });
                if (populated === true) {
                    const subLayers = [
                        { name: "CLASS_A", checked: airspacesClassA.show, elements: airspacesClassA },
                        { name: "CLASS_B", checked: airspacesClassB.show, elements: airspacesClassB },
                        { name: "CLASS_C", checked: airspacesClassC.show, elements: airspacesClassC },
                        { name: "CLASS_D", checked: airspacesClassD.show, elements: airspacesClassD },
                        { name: "CLASS_E2", checked: airspacesClassE2.show, elements: airspacesClassE2 },
                        { name: "CLASS_E3", checked: airspacesClassE3.show, elements: airspacesClassE3 },
                        { name: "CLASS_E4", checked: airspacesClassE4.show, elements: airspacesClassE4 },
                        { name: "CLASS_E5", checked: airspacesClassE5.show, elements: airspacesClassE5 },
                        { name: "MODE C", checked: airspacesModeC.show, elements: airspacesModeC }
                    ];
                    handleUpdateElementsInLayer(4, allAirspaces, subLayers);
                }
            }
        }
    }, [
        component,
        airspacesClassA,
        airspacesClassB,
        airspacesClassC,
        airspacesClassD,
        airspacesClassE2,
        airspacesClassE3,
        airspacesClassE4,
        airspacesClassE5,
        airspacesModeC
    ]);

    // update facility maps/stadiums/prohibited/restricted airspaces for map layers
    useEffect(() => {
        if (component && viewerRef.current && viewerRef.current.cesiumElement) {
            const { volumes } = component;
            if (facilityMaps && volumes.facilityMap === true) {
                handleUpdateElementsInLayer(5, [facilityMaps, facilityMapLabels]);
            }
            if (stadiums && volumes.stadiums === true) {
                handleUpdateElementsInLayer(6, [stadiums, stadiumLabels]);
            }
            if (noFlyZones && volumes.national === true) {
                handleUpdateElementsInLayer(7, noFlyZones);
            }
            if (prohibitedAirspaces && volumes.prohibited === true) {
                handleUpdateElementsInLayer(8, prohibitedAirspaces);
            }
            if (restrictedAirspaces && volumes.restricted === true) {
                handleUpdateElementsInLayer(9, restrictedAirspaces);
            }
            if (militaryOperationAreas && volumes.military === true) {
                handleUpdateElementsInLayer(10, militaryOperationAreas);
            }
            if (warningAreas && volumes.warning === true) {
                handleUpdateElementsInLayer(11, warningAreas);
            }
            if (alertAreas && volumes.alert === true) {
                handleUpdateElementsInLayer(12, alertAreas);
            }
            if (tfrAreas && volumes.tfr === true) {
                handleUpdateElementsInLayer(13, tfrAreas);
            }
        }
    }, [component, facilityMaps, stadiums, noFlyZones, prohibitedAirspaces, restrictedAirspaces, militaryOperationAreas, warningAreas, alertAreas, tfrAreas]);

    // draw relevant operations/constraints/alerts if active during start/end times
    useEffect(() => {
        if (component && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const startTime = new Date(props.start).getTime();
            const endTime = new Date(props.end).getTime();
            const filterByDate = (volume) => {
                const volumeStart = new Date(volume.time_start).getTime();
                const volumeEnd = new Date(volume.time_end).getTime();
                if (startTime <= volumeEnd && endTime >= volumeStart) {
                    return true;
                }
                return false;
            };
            if (component.volumes.operations && props.operations) {
                operationEntities.forEach((entity) => {
                    viewer.entities.remove(entity);
                });
                const operations = props.operations.filter((operation) => filterByDate(operation));
                const operationLayer = layers.find(({ name }) => {
                    return name === "Operations";
                });
                const newOperationEntities = [];
                operations.forEach((operation) => {
                    if (props.editVolume && props.editVolume.flight_uuid === operation.flight_uuid) {
                        return;
                    }
                    operation.volumes.forEach((volume, index) => {
                        const operationToDraw = formatOperationToDraw(operation, index, volume, userOperationalStates);
                        const operationVolume = createVolumeEntity(operationToDraw, userMapSettings.op_opacity);
                        if (operationLayer !== undefined) {
                            operationVolume.show = operationLayer.checked;
                        }
                        const operationEntity = viewer.entities.add(operationVolume);
                        newOperationEntities.push(operationEntity);
                    });
                });
                handleUpdateElementsInLayer(1, newOperationEntities);
                setOperationEntities(newOperationEntities);
            }
            if (component.volumes.constraints && props.constraints) {
                constraintEntities.forEach((entity) => {
                    viewer.entities.remove(entity);
                });
                const constraints = props.constraints.filter((constraint) => filterByDate(constraint));
                const constraintLayer = layers.find(({ name }) => {
                    return name === "Constraints";
                });
                const newConstraintEntities = [];
                constraints.forEach((constraint) => {
                    constraint.volumes.forEach((volume, index) => {
                        const constraintToDraw = formatConstraintToDraw(constraint, index, volume, userOperationalStates);
                        const constraintVolume = createVolumeEntity(constraintToDraw, userMapSettings.op_opacity);
                        if (constraintLayer !== undefined) {
                            constraintVolume.show = constraintLayer.checked;
                        }
                        const constraintEntity = viewer.entities.add(constraintVolume);
                        newConstraintEntities.push(constraintEntity);
                    });
                });
                handleUpdateElementsInLayer(2, newConstraintEntities);
                setConstraintEntities(newConstraintEntities);
            }
            if (component.volumes.alerts && props.alerts) {
                alertEntities.forEach((entity) => {
                    viewer.entities.remove(entity);
                });
                const alertVolumes = props.alerts.filter((alert) => filterByDate(alert));
                const alertVolumeLayer = layers.find(({ name }) => {
                    return name === "Alert Volumes";
                });
                const newAlertVolumeEntities = [];
                alertVolumes.forEach((alert) => {
                    if (alert.polygons) {
                        alert.polygons.forEach((polygon) => {
                            const alertVolume = formatAlertVolumeToDraw(alert, polygon, userMapSettings.op_opacity);
                            if (alertVolumeLayer !== undefined) {
                                alertVolume.show = alertVolumeLayer.checked;
                            }
                            const alertEntity = viewer.entities.add(alertVolume);
                            newAlertVolumeEntities.push(alertEntity);
                        });
                    }
                });
                handleUpdateElementsInLayer(3, newAlertVolumeEntities);
                setAlertEntities(newAlertVolumeEntities);
            }
            viewer.scene.requestRender();
        }
    }, [component, props.start, props.end, props.operations, props.constraints, props.alerts]);

    useEffect(() => {
        if (component && viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(({ position }) => {
                handleSetSelectedEntity(undefined);
                const pickedEntities = viewer.scene.drillPick(position);
                if (!defined(pickedEntities)) {
                    return;
                }
                const filteredEntities = pickedEntities.map((entity) => entity.id);
                const finalEntities = filteredEntities.filter((entity, index) => {
                    return laancEntities.current.includes(entity) && filteredEntities.indexOf(entity) === index;
                });
                if (finalEntities.length > 1) {
                    const xOffset = viewerContainerRef.current.getBoundingClientRect().left;
                    const yOffset = viewerContainerRef.current.getBoundingClientRect().top;
                    const virtualElement = {
                        getBoundingClientRect: () => ({
                            width: 0,
                            height: 0,
                            top: yOffset + position.y,
                            right: xOffset + position.x,
                            bottom: yOffset + position.y,
                            left: xOffset + position.x
                        })
                    };
                    setContextMenuOpen(true);
                    setContextMenuEntities(finalEntities);
                    setContextMenuAnchorEl(virtualElement);
                } else if (finalEntities.length === 1) {
                    handleSetSelectedEntity(finalEntities[0]);
                }
            }, ScreenSpaceEventType.LEFT_CLICK);
        }
    }, [component]);

    // handle drawing method change
    const handleActiveDrawMethodChange = (e, value) => {
        if (!value) {
            return;
        } else if (value === "Clear") {
            props.setFinalVolumes([]);
        }
        props.setActiveDrawMethod(value);

        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            let entityColor = Color.WHITE.withAlpha(0.7);
            if (component && component.type === 3 && colors) {
                entityColor = getAlertColorFromId(props.alertColorId, colors);
            }
            if (props.flightCanBePublished === true && props.setFlightCanBePublished) {
                props.setFlightCanBePublished(false);
            }
            handleResetDrawingData();
            setIsInitialVolume(false);

            if (value === "Clear" || value === "Automatic") {
                return;
            }
            const cursorEntity = viewer.entities.add({
                id: "cursor",
                point: { color: entityColor, pixelSize: 5 }
            });
            const waypointHierarchy = [];
            const polygonHierarchy = [];

            createHandlerRef.current = new ScreenSpaceEventHandler(viewer.scene.canvas);
            props.setDisabled(true);

            createHandlerRef.current.setInputAction(({ endPosition }) => {
                const mouseMovePosition = pickPosition(viewer, endPosition);
                if (defined(mouseMovePosition)) {
                    cursorEntity.position = mouseMovePosition;
                    if (value === "Polygon") {
                        polygonHierarchy.pop();
                        polygonHierarchy.push(mouseMovePosition);
                    }
                    if (value === "Waypoints") {
                        waypointHierarchy.pop();
                        waypointHierarchy.push(mouseMovePosition);
                    }
                    viewer.scene.requestRender();
                }
            }, ScreenSpaceEventType.MOUSE_MOVE);

            if (value === "Upload") {
                fileUploadRef.current.click();
                createHandlerRef.current.destroy();
                props.setDisabled(false);
            } else if (value === "Waypoints") {
                const waypointEntity = viewer.entities.add({
                    polyline: {
                        material: entityColor,
                        positions: new CallbackProperty(() => waypointHierarchy, false)
                    }
                });
                createHandlerRef.current.setInputAction(({ position }) => {
                    if (position.clientX != 0 && position.clientY != 0) {
                        const leftClickPosition = pickPosition(viewer, position);
                        if (defined(leftClickPosition)) {
                            const floatingPoint = createPoint(viewer, leftClickPosition);
                            cursorEntities.current.push(floatingPoint);
                            waypointHierarchy.push(leftClickPosition);
                        }
                    }
                }, ScreenSpaceEventType.LEFT_CLICK);
                createHandlerRef.current.setInputAction(() => {
                    if (waypointHierarchy.length < 3) {
                        alert("Please enter at least two positions before continuing");
                        return;
                    }
                    viewer.entities.remove(waypointEntity);
                    waypointHierarchy.pop();
                    const finalWaypointEntity = viewer.entities.add({
                        polygon: {
                            height: minimumAltitude.current,
                            extrudedHeight: maximumAltitude.current,
                            material: entityColor,
                            heightReference: HeightReference.CLAMP_TO_GROUND
                        }
                    });
                    createdWaypointHierarchy.current = waypointHierarchy;
                    const hierarchy = handleGetPolygonHierarchyFromWaypoints(waypointBuffer.current);
                    editEntitiesHierarchy.current.set(finalWaypointEntity.id, hierarchy);
                    finalWaypointEntity.polygon.hierarchy = new CallbackProperty(() => {
                        return new PolygonHierarchy(editEntitiesHierarchy.current.get(finalWaypointEntity.id));
                    }, false);
                    createdEntities.current = [finalWaypointEntity];
                    createHandlerRef.current.destroy();
                    props.setDisabled(false);

                    if (props.setWaypointHierarchy) {
                        const wpHierarchy = waypointHierarchy.map((position) => {
                            const cartographic = Cartographic.fromCartesian(position);
                            const lng = Math.toDegrees(cartographic.longitude);
                            const lat = Math.toDegrees(cartographic.latitude);
                            return { lat: lat, lng: lng };
                        });
                        props.setWaypointHierarchy(wpHierarchy);
                    }
                    handleSetEntitiesEditable(2, viewer, createdEntities.current, true);
                    handleUpdateFinalVolumes();
                }, ScreenSpaceEventType.RIGHT_CLICK);
            } else if (value === "Circle") {
                createHandlerRef.current.setInputAction(({ position }) => {
                    if (position.clientX != 0 && position.clientY != 0) {
                        const leftClickPosition = pickPosition(viewer, position);
                        if (defined(leftClickPosition)) {
                            const circleEntity = viewer.entities.add({
                                ellipse: {
                                    material: entityColor,
                                    semiMinorAxis: radius.current,
                                    semiMajorAxis: radius.current,
                                    height: minimumAltitude.current,
                                    extrudedHeight: maximumAltitude.current
                                },
                                position: leftClickPosition
                            });
                            createdEntities.current = [circleEntity];
                            createHandlerRef.current.destroy();
                            props.setDisabled(false);

                            handleSetEntitiesEditable(1, viewer, createdEntities.current, true);
                            handleUpdateFinalVolumes();
                        }
                    }
                }, ScreenSpaceEventType.LEFT_CLICK);
            } else if (value === "Polygon") {
                const polygonEntity = viewer.entities.add({
                    polygon: {
                        material: new ColorMaterialProperty(entityColor),
                        hierarchy: new CallbackProperty(() => new PolygonHierarchy(polygonHierarchy), false),
                        height: 0
                    }
                });
                createHandlerRef.current.setInputAction(({ position }) => {
                    if (position.clientX != 0 && position.clientY != 0) {
                        const mouseMovePosition = polygonHierarchy.pop();
                        const leftClickPosition = pickPosition(viewer, position);
                        if (isDuplicatePosition(polygonHierarchy, leftClickPosition)) {
                            if (mouseMovePosition) {
                                polygonHierarchy.push(mouseMovePosition);
                            }
                            return;
                        }

                        const floatingPoint = createPoint(viewer, leftClickPosition);
                        cursorEntities.current.push(floatingPoint);
                        if (defined(leftClickPosition)) {
                            polygonHierarchy.push(leftClickPosition);
                        }
                        if (mouseMovePosition) {
                            polygonHierarchy.push(mouseMovePosition);
                        }
                    }
                }, ScreenSpaceEventType.LEFT_CLICK);
                createHandlerRef.current.setInputAction(() => {
                    if (polygonHierarchy.length < 4) {
                        alert("Please enter 3 or more coordinates before continuing");
                        return;
                    }
                    polygonHierarchy.pop();
                    viewer.entities.remove(polygonEntity);
                    createHandlerRef.current.destroy();
                    props.setDisabled(false);

                    const finalPolygonEntity = viewer.entities.add({
                        polygon: {
                            material: new ColorMaterialProperty(entityColor),
                            hierarchy: polygonHierarchy,
                            height: minimumAltitude.current,
                            extrudedHeight: maximumAltitude.current
                        }
                    });
                    createdEntities.current = [finalPolygonEntity];
                    handleSetEntitiesEditable(0, viewer, createdEntities.current, true);
                    handleUpdateFinalVolumes();
                }, ScreenSpaceEventType.RIGHT_CLICK);
            }
        }
    };
    const handleFileUpload = (acceptedFiles) => {
        if (acceptedFiles[0].size > 500000) {
            setSnackbar({ children: "Please enter a file less than 500KB", severity: "error" });
            return;
        }
        if (!/(\.kml|\.kmz)$/i.exec(acceptedFiles[0].name.toLowerCase())) {
            setSnackbar({ children: "Please enter a valid file type", severity: "error" });
            return;
        }
        let fileData = new FileReader();
        fileData.onloadend = (e) => {
            const content = e.target.result;
            const kmlParsed = new DOMParser().parseFromString(content, "text/xml");
            const converted = kml(kmlParsed);

            const entity = ConvertFeaturesToVolumes(converted.features, props.start, props.end, user);
            if (!entity.volumes.length) {
                return setSnackbar({ children: "Please enter a file with one or more valid polygons", severity: "error" });
            }
            const name = entity.opName;
            props.setName(name);

            const min_alt_ft = ConvertMetersToFeet(entity.minAlt);
            props.setMinAltitude(min_alt_ft);

            const max_alt_ft = ConvertMetersToFeet(entity.maxAlt);
            props.setMaxAltitude(max_alt_ft);
            props.setName(entity.opName);

            const entities = [];
            const viewer = viewerRef.current.cesiumElement;
            entity.volumes.forEach((volume) => {
                volume.altitude_lower_hae = entity.minAlt;
                volume.altitude_upper_hae = entity.maxAlt;

                const newVolume = createVolumeEntity(volume, userMapSettings.op_opacity);
                if ("polygon" in newVolume) {
                    newVolume.polygon.heightReference = HeightReference.CLAMP_TO_GROUND;
                } else if ("ellipse" in newVolume) {
                    newVolume.ellipse.heightReference = HeightReference.CLAMP_TO_GROUND;
                }
                const newEntity = viewer.entities.add(newVolume);
                entities.push(newEntity);
            });
            createdEntities.current = entities;
            handleSetEntitiesEditable(0, viewer, createdEntities.current, true);
            zoomToEntities(viewer, entities);
            handleUpdateFinalVolumes();
        };
        fileData.readAsText(acceptedFiles[0]);
    };
    const handleAutomaticBufferChange = (baseEntities, bufferedEntities, bufferValue) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            if (bufferValue > 0) {
                baseEntities.forEach((entity, i) => {
                    if (entity.polygon) {
                        const positions = entity.polygon.hierarchy.getValue().positions.map((cartesian) => {
                            const cartographic = Cartographic.fromCartesian(cartesian);
                            return [Math.toDegrees(cartographic.longitude), Math.toDegrees(cartographic.latitude)];
                        });
                        if (positions[0] !== positions[positions.length]) {
                            positions.push(positions[0]);
                        }
                        if (positions.length > 3) {
                            const turfPolygon = polygon([positions]);
                            const geoJson = featureCollection([turfPolygon]);
                            const newGeoJson = buffer(geoJson, bufferValue / 5280.0, { units: "miles" });

                            const degreesArray = [];
                            newGeoJson.features[0].geometry.coordinates[0].forEach((coordinate) => {
                                degreesArray.push(coordinate[0], coordinate[1]);
                            });
                            const cartesian = Cartesian3.fromDegreesArray(degreesArray);
                            bufferedEntities[i].polygon.hierarchy = new PolygonHierarchy(cartesian);
                        }
                    } else if (entity.ellipse) {
                        const position = Cartographic.fromCartesian(entity.position.getValue());
                        const center = [Math.toDegrees(position.longitude), Math.toDegrees(position.latitude)];
                        const radius_ft = ConvertMetersToFeet(entity.ellipse.semiMajorAxis);

                        const feature = circle(center, radius_ft / 5280, {
                            steps: 64,
                            units: "miles"
                        });
                        const geojson = featureCollection([feature]);
                        const newGeoJson = buffer(geojson, bufferValue / 5280.0, {
                            units: "miles"
                        });

                        const degreesArray = [];
                        newGeoJson.features[0].geometry.coordinates[0].forEach((coordinate) => {
                            degreesArray.push(coordinate[0], coordinate[1]);
                        });
                        const cartesian = Cartesian3.fromDegreesArray(degreesArray);
                        bufferedEntities[i].polygon.hierarchy = new PolygonHierarchy(cartesian);
                    }
                });
            }
        }
    };
    const handleSetEntitiesEditable = (type, viewer, entities, createEditPoints = true) => {
        cursorEntities.current.forEach((entity) => {
            viewer.entities.remove(entity);
        });
        viewer.entities.removeById("cursor");
        viewer.scene.requestRender();

        const min = minimumAltitude.current;
        const max = maximumAltitude.current;
        entities.forEach((entity) => {
            if (type === 0 && entity.polygon) {
                const { positions } = entity.polygon.hierarchy.getValue();
                editEntitiesHierarchy.current.set(entity.id, positions);
                entity.polygon.hierarchy = new CallbackProperty(() => {
                    return new PolygonHierarchy(editEntitiesHierarchy.current.get(entity.id));
                }, false);
                if (createEditPoints === true) {
                    positions.forEach((position) => {
                        const editPoint = handleCreateEditPoint(viewer, position, editPoints.current, min, max);
                        editPointPositions.current.set(editPoint.id, position);
                    });
                }
            } else if (type === 1 && entity.ellipse) {
                if (createEditPoints === true) {
                    const editPoint = handleCreateEditPoint(viewer, entity.position.getValue(), editPoints.current, min, max);
                    editPointPositions.current.set(editPoint.id, entity.position.getValue());
                }
                editEntitiesHierarchy.current.set(entity.id, entity.position.getValue());
                entity.position = new CallbackProperty(() => {
                    return editEntitiesHierarchy.current.get(entity.id);
                }, false);
            } else if (type === 2 && entity.polygon) {
                if (createEditPoints === true) {
                    createdWaypointHierarchy.current.forEach((position) => {
                        const editPoint = handleCreateEditPoint(viewer, position, editPoints.current, min, max);
                        editPointPositions.current.set(editPoint.id, position);
                    });
                }
            }
        });
        editHanderRef.current = new ScreenSpaceEventHandler(viewer.scene.canvas);
        editHanderRef.current.setInputAction(({ position }) => {
            if (position.clientX != 0 && position.clientY != 0) {
                const pickedEntities = viewer.scene.drillPick(position);
                const editPoint = pickedEntities.find(({ id }) => id.type === "edit");
                if (editPoint) {
                    viewer.scene.screenSpaceCameraController.enableInputs = false;
                    editPoint.id.position = new CallbackProperty(() => {
                        return editPointPositions.current.get(editPoint.id.id);
                    }, false);
                    editHanderRef.current.setInputAction(({ endPosition }) => {
                        const newPosition = pickPosition(viewer, endPosition);
                        if (defined(newPosition)) {
                            handleMoveEditPoint(type, editPoint.id, newPosition);
                            viewer.scene.requestRender();
                        }
                    }, ScreenSpaceEventType.MOUSE_MOVE);
                    editHanderRef.current.setInputAction(() => {
                        viewer.scene.screenSpaceCameraController.enableInputs = true;
                        editPoint.id.position = editPointPositions.current.get(editPoint.id.id);
                        editHanderRef.current.destroy();
                        handleSetEntitiesEditable(type, viewer, createdEntities.current, false);
                        handleUpdateFinalVolumes();
                        if (props.flightCanBePublished === true && props.setFlightCanBePublished) {
                            props.setFlightCanBePublished(false);
                        }
                        if (props.flightGeometryUpdated === false && props.setFlightGeometryUpdated) {
                            props.setFlightGeometryUpdated(true);
                        }
                    }, ScreenSpaceEventType.LEFT_UP);
                }
            }
        }, ScreenSpaceEventType.LEFT_DOWN);
    };
    const handleMoveEditPoint = (type, point, position) => {
        const old = point.position.getValue();
        if (type === 0) {
            let positions = [];
            const entity = createdEntities.current.find(({ polygon }) => {
                positions = polygon.hierarchy.getValue().positions;
                return positions.find((pos) => pos.equals(old));
            });
            const index = positions.findIndex((position) => {
                return position.equals(old);
            });
            if (entity && index !== -1) {
                editEntitiesHierarchy.current.set(entity.id, [...positions.slice(0, index), position, ...positions.slice(index + 1)]);
            }
        } else if (type === 1) {
            const entity = createdEntities.current[0];
            editEntitiesHierarchy.current.set(entity.id, position);
        } else if (type === 2) {
            const index = createdWaypointHierarchy.current.findIndex((position) => {
                return position.equals(old);
            });
            createdWaypointHierarchy.current = [
                ...createdWaypointHierarchy.current.slice(0, index),
                position,
                ...createdWaypointHierarchy.current.slice(index + 1)
            ];
            const polygonHierarchy = handleGetPolygonHierarchyFromWaypoints(waypointBuffer.current);
            const entity = createdEntities.current[0];
            editEntitiesHierarchy.current.set(entity.id, polygonHierarchy);
        }
        editPointPositions.current.set(point.id, position);
    };
    const handleGetPolygonHierarchyFromWaypoints = (width) => {
        const coordinates = createdWaypointHierarchy.current.map((position) => {
            const cartographic = Cartographic.fromCartesian(position);
            return [Math.toDegrees(cartographic.longitude), Math.toDegrees(cartographic.latitude)];
        });
        const turfLineString = lineString(coordinates);
        const geoJson = featureCollection([turfLineString]);
        const newGeoJson = buffer(geoJson, (width || 0) / 5280.0, { units: "miles" });

        const degreesArray = [];
        newGeoJson.features[0].geometry.coordinates[0].forEach((coordinate) => {
            degreesArray.push(coordinate[0], coordinate[1]);
        });
        return Cartesian3.fromDegreesArray(degreesArray);
    };
    const handleUpdateEntitiesAltitudes = (entities, min, max) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            entities.forEach((entity) => {
                if (entity.polygon) {
                    entity.polygon.height = min;
                    entity.polygon.extrudedHeight = max;
                } else if (entity.ellipse) {
                    entity.ellipse.height = min;
                    entity.ellipse.extrudedHeight = max;
                }
            });
            viewer.scene.requestRender();
        }
    };
    const handleUpdateFinalVolumes = () => {
        const finalVolumes = [];
        createdEntities.current.forEach((entity, i) => {
            if (entity.ellipse && entity.position) {
                const position = entity.position.getValue();
                const cartographic = Cartographic.fromCartesian(position);
                const latitude = Math.toDegrees(cartographic.latitude);
                const longitude = Math.toDegrees(cartographic.longitude);
                finalVolumes.push({ id: i, type: "circle", vertices: [{ lat: latitude, lng: longitude }] });
            } else if (entity.polygon && entity.polygon.hierarchy) {
                const vertices = [];
                const { positions } = entity.polygon.hierarchy.getValue();
                positions.forEach((position) => {
                    const cartographic = Cartographic.fromCartesian(position);
                    const latitude = Math.toDegrees(cartographic.latitude);
                    const longitude = Math.toDegrees(cartographic.longitude);
                    vertices.push({ lat: latitude, lng: longitude });
                });
                finalVolumes.push({ id: i, type: "polygon", vertices: vertices });
            }
        });
        if (finalVolumes.length) {
            props.setFinalVolumes(finalVolumes);
        }
        if (props.setWaypointHierarchy !== undefined && createdWaypointHierarchy.current.length > 0) {
            const wpHierarchy = createdWaypointHierarchy.current.map((position) => {
                const cartographic = Cartographic.fromCartesian(position);
                const lng = Math.toDegrees(cartographic.longitude);
                const lat = Math.toDegrees(cartographic.latitude);
                return { lat: lat, lng: lng };
            });
            props.setWaypointHierarchy(wpHierarchy);
        }
    };
    const handleUpdateAlertEntityColors = (entities) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            if (props.alertColorId !== undefined) {
                const material = getAlertColorFromId(props.alertColorId, colors);
                entities.forEach((entity) => {
                    if (entity.polygon) {
                        entity.polygon.material = material;
                    } else if (entity.ellipse) {
                        entity.ellipse.material = material;
                    }
                });
            }
            viewer.scene.requestRender();
        }
    };
    const handleRemoveEntities = (entities) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            entities.forEach((entity) => {
                viewer.entities.remove(entity);
            });
        }
    };
    const handleRemoveEditFunctionality = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            if (editHanderRef.current && editHanderRef.current.isDestroyed() === false) {
                editHanderRef.current.destroy();
                editHanderRef.current = null;
            }
            const viewer = viewerRef.current.cesiumElement;
            editPoints.current.forEach((entity) => {
                viewer.entities.remove(entity);
            });
        }
    };
    const handleResetDrawingData = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            if (createHandlerRef.current && createHandlerRef.current.isDestroyed() === false) {
                createHandlerRef.current.destroy();
                createHandlerRef.current = null;
            }
            if (editHanderRef.current && editHanderRef.current.isDestroyed() === false) {
                editHanderRef.current.destroy();
                editHanderRef.current = null;
            }
            createdWaypointHierarchy.current = [];

            const viewer = viewerRef.current.cesiumElement;
            viewer.entities.removeById("cursor");
            viewer.scene.requestRender();

            if (props.setMinAltitude) {
                props.setMinAltitude(0);
            }
            if (props.setMaxAltitude) {
                props.setMaxAltitude(0);
            }
            if (props.setWaypointHierarchy) {
                props.setWaypointHierarchy([]);
            }
            if (props.setFinalVolumes) {
                props.setFinalVolumes([]);
            }
            if (props.setAlertVolumes && props.setAlertChangeMethod) {
                props.setAlertVolumes([]);
                props.setAlertChangeMethod("Clear");
            }
            handleRemoveEntities(cursorEntities.current);
            handleRemoveEntities(createdEntities.current);
            handleRemoveEntities(editPoints.current);
            handleRemoveEntities(laancEntities.current);

            createdEntities.current = [];
            cursorEntities.current = [];
            editPoints.current = [];
            laancEntities.current = [];
        }
    };
    const handleSetSelectedEntity = (entity) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            if (props.handleLaancVolumeSelected !== undefined) {
                if (entity !== undefined && viewer.entities.contains(entity)) {
                    props.handleLaancVolumeSelected([entity.name]);
                } else {
                    props.handleLaancVolumeSelected([]);
                }
            } else {
                viewer.selectedEntity = entity;
            }
            if (contextMenuOpen === true) {
                handleCloseContextMenu();
            }
        }
    };
    const handleSelectedEntityChanged = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            if (viewer.selectedEntity === undefined) {
                if (props.handleLaancVolumeSelected !== undefined) {
                    props.handleLaancVolumeSelected([]);
                }
            }
        }
    };
    const handleCloseContextMenu = () => {
        setContextMenuOpen(false);
        setContextMenuAnchorEl(null);
    };
    // should probably make a little more readable at some point
    const handleCreateLayersFromComponent = (component) => {
        const newLayers = [];
        if (component.volumes.airspaces === true) {
            newLayers.push({
                id: 4,
                name: "Airspaces",
                checked: true,
                indeterminate: false,
                subLayers: [
                    { name: "CLASS_A", checked: true, elements: airspacesClassA },
                    { name: "CLASS_B", checked: true, elements: airspacesClassB },
                    { name: "CLASS_C", checked: true, elements: airspacesClassC },
                    { name: "CLASS_D", checked: true, elements: airspacesClassD },
                    { name: "CLASS_E2", checked: true, elements: airspacesClassE2 },
                    { name: "CLASS_E3", checked: true, elements: airspacesClassE3 },
                    { name: "CLASS_E4", checked: true, elements: airspacesClassE4 },
                    { name: "CLASS_E5", checked: true, elements: airspacesClassE5 },
                    { name: "MODE C", checked: true, elements: airspacesModeC }
                ],
                elements: [
                    airspacesClassA,
                    airspacesClassB,
                    airspacesClassC,
                    airspacesClassD,
                    airspacesClassE2,
                    airspacesClassE3,
                    airspacesClassE4,
                    airspacesClassE5,
                    airspacesModeC
                ],
                color: "default"
            });
        }

        const areas = [
            { id: 1, name: "Operations", elements: operationEntities, property: "operations" },
            { id: 2, name: "Constraints", elements: constraintEntities, property: "constraints" },
            { id: 3, name: "Alert Volumes", elements: alertEntities, property: "alerts" },
            { id: 5, name: "Facility Maps", elements: [facilityMaps, facilityMapLabels], property: "facilityMap" },
            { id: 6, name: "Stadiums", elements: [stadiums, stadiumLabels], property: "stadiums" },
            { id: 7, name: "National Security No Fly", elements: noFlyZones, property: "national" },
            { id: 8, name: "Prohibited Airspaces", elements: prohibitedAirspaces, property: "prohibited" },
            { id: 9, name: "Restricted Airspaces", elements: restrictedAirspaces, property: "restricted" },
            { id: 10, name: "Military Operation Areas", elements: militaryOperationAreas, property: "military" },
            { id: 11, name: "Warning Areas", elements: warningAreas, property: "warning" },
            { id: 12, name: "Alert Areas", elements: alertAreas, property: "alert" },
            { id: 13, name: "TFR Areas", elements: tfrAreas, property: "tfr" }
        ];
        areas.forEach(({ id, name, elements, property }) => {
            if (!component.volumes[property]) {
                return;
            }

            let color = "default";
            if (Array.isArray(elements) && elements.length > 0 && elements[0] && elements[0].color) {
                color = elements[0].color;
            } else if (elements && elements.color) {
                color = elements.color;
            }

            newLayers.push({
                id: id,
                name: name,
                checked: true,
                indeterminate: false,
                subLayers: [],
                elements: elements,
                color: color
            });
        });
        setLayers(newLayers);
    };
    const toggleElementsVisibility = (elements, value) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            if (Array.isArray(elements)) {
                elements.forEach((element) => {
                    if (element) {
                        element.show = value;
                    }
                });
            } else {
                if (elements) {
                    elements.show = value;
                }
            }
            const viewer = viewerRef.current.cesiumElement;
            viewer.scene.requestRender();
        }
    };
    const handleUpdateElementsInLayer = (id, newElements, subLayers = null) => {
        setLayers((previous) => {
            return previous.map((layer) => {
                if (layer.id === id) {
                    if (subLayers === null) {
                        return { ...layer, elements: newElements };
                    } else {
                        return {
                            ...layer,
                            checked: getCheckedFromSubLayers(subLayers),
                            indeterminate: getIndeterminateFromSubLayers(subLayers),
                            elements: newElements,
                            subLayers: subLayers
                        };
                    }
                } else {
                    return layer;
                }
            });
        });
    };

    return component !== null ? (
        <Box sx={{ position: "relative", height: "450px" }} ref={viewerContainerRef}>
            {component.type === -1 ? (
                <></>
            ) : (
                <Viewer animation={false} timeline={false} ref={viewerRef} full imageryProviderViewModels={providerViewModels} navigationHelpButton={false} />
            )}

            <Typography ref={cursorLocationRef} variant="caption" sx={{ position: "absolute", right: "35px", bottom: "5px" }} />

            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    bottom: "5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    textShadow: "0 0 10px #000"
                }}
            >
                {props.canEdit === true ? getActiveDrawMessageFromMethod(props.activeDrawMethod) : ""}
            </Typography>
            <MapLayers
                viewerRef={viewerRef}
                viewerContainerRef={viewerContainerRef}
                layers={layers}
                setLayers={setLayers}
                toggleElementsVisibility={toggleElementsVisibility}
            />
            <MapContextMenu
                contextMenuOpen={contextMenuOpen}
                contextMenuAnchorEl={contextMenuAnchorEl}
                contextMenuEntities={contextMenuEntities}
                handleCloseContextMenu={handleCloseContextMenu}
                handleSetSelectedEntity={handleSetSelectedEntity}
            />
            <input
                type="file"
                ref={fileUploadRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e.target.files)}
                onClick={(e) => (e.target.value = "")}
                id="fileUpload"
            />
            <Box
                sx={{
                    position: "absolute",
                    left: "15px",
                    top: "15px",
                    zIndex: 100,
                    background: "#121212"
                }}
            >
                <ToggleButtonGroup orientation="vertical" fullWidth exclusive value={props.activeDrawMethod} onChange={handleActiveDrawMethodChange}>
                    {component.methods.map(({ method, icon }, i) => (
                        <ToggleButton
                            id={method.toLowerCase()}
                            data-testid={method.toLowerCase()}
                            key={i}
                            fullWidth
                            value={method}
                            disabled={props.disabled || props.canEdit === false}
                        >
                            <Tooltip title={method} placement="right">
                                {icon}
                            </Tooltip>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
        </Box>
    ) : (
        <Box sx={{ position: "relative", height: "450px", display: "flex", justifyContent: "center", alignItems: "center" }} ref={viewerContainerRef}>
            <CircularProgress color="inherit" />
        </Box>
    );
};
