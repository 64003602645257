import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BadgeIcon from "@mui/icons-material/Badge";
import HeightIcon from "@mui/icons-material/Height";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ConvertFeetToMeters, getFilteredVolumesFromUserInputs, GetTimezone } from "../../util";
import { MapDrawComponent } from "../../map/mapDrawComponent";

import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../contexts/authContext";
import { useMap } from "../../contexts/mapContext";

const ConstraintCreator = () => {
    const [drawType, setDrawType] = useState("Polygon");
    const [submitting, setSubmitting] = useState(false);
    const [mobileConstraintDrawerOpen, setMobileConstraintDrawerOpen] = useState(false);

    const [constraintName, setConstraintName] = useState("");
    const [constraintNameErrorText, setConstraintNameErrorText] = useState("");

    const constraint_types = [
        { type: "ADVISORY", priority: 0 },
        { type: "TRAINING", priority: 10 },
        { type: "COMMERCIAL", priority: 20 },
        { type: "PUBLIC_SAFETY", priority: 30 },
        { type: "EMERGENCY", priority: 40 },
        { type: "RESTRICTED", priority: 1000 }
    ];
    const [constraintType, setConstraintType] = useState("ADVISORY");
    const [constraintPriority, setConstraintPriority] = useState(0);

    const [constraintAltitudeFt, setConstraintAltitudeFt] = useState(400);
    const [constraintAltitudeErrorText, setConstraintAltitudeErrorText] = useState("");

    const [constraintRadiusFt, setConstraintRadiusFt] = useState(0);
    const [constraintRadiusErrorText, setConstraintRadiusErrorText] = useState("");

    const [constraintStartTime, setConstraintStartTime] = useState(dayjs().add(10, "minutes").set("seconds", 0).set("milliseconds", 0));
    const [constraintStartDateErrorText, setConstraintStartDateErrorText] = useState("");

    const [constraintDurationMin, setConstraintDurationMin] = useState(60);
    const [constraintDurationErrorText, setConstraintDurationErrorText] = useState("");

    const [constraintGeojson, setConstraintGeojson] = useState([]);

    const [filteredFlights, setFilteredFlights] = useState([]);
    const [filteredConstraints, setFilteredConstraints] = useState([]);

    const navigate = useNavigate();
    const isDesktop = useMediaQuery("(min-width:900px)");

    const { user, handleFailedFetch, setSnackbar } = useUserAuth();
    const { publishedOperations, constraints } = useMap();

    //Filter the Flights / Constraints when the dates changes
    useEffect(() => {
        const filtered_flights = getFilteredVolumesFromUserInputs(publishedOperations, constraintStartTime, constraintDurationMin);
        setFilteredFlights(filtered_flights);

        const filtered_constraints = getFilteredVolumesFromUserInputs(constraints, constraintStartTime, constraintDurationMin);
        setFilteredConstraints(filtered_constraints);
    }, [constraintStartTime, constraintDurationMin, publishedOperations, constraints]);

    const handleTypeChange = (selected_constraint_type) => {
        const selected_type = constraint_types.find(({ type }) => {
            return selected_constraint_type === type;
        });
        if (selected_type) {
            setConstraintType(selected_type.type);
            setConstraintPriority(selected_type.priority);
        }
    };
    const handleSubmitButtonClick = () => {
        // validate user inputs
        if (constraintGeojson.length < 1) {
            setSnackbar({ children: "Please create at least one polygon on the map before proceeding.", severity: "error" });
            return;
        }
        if (!constraintName || constraintName.trim().length === 0) {
            setConstraintNameErrorText("Please enter a name.");
            return;
        } else if (constraintNameErrorText.length) {
            setConstraintNameErrorText("");
        }
        const altitude_ft = parseFloat(constraintAltitudeFt);
        if (isNaN(altitude_ft) || altitude_ft < 1) {
            setConstraintAltitudeErrorText("Please enter an altitude of 1 or more feet.");
            return;
        } else if (constraintAltitudeErrorText.length) {
            setConstraintAltitudeErrorText("");
        }
        const radius_ft = parseFloat(constraintRadiusFt);
        if (drawType === "Circle") {
            if (isNaN(radius_ft) || radius_ft < 1) {
                setConstraintRadiusErrorText("Please enter a radius of 1 or more feet.");
                return;
            } else if (constraintRadiusErrorText.length) {
                setConstraintRadiusErrorText("");
            }
        }
        const current_time = dayjs();
        if (!constraintStartTime || constraintStartTime.isValid() === false || current_time.isAfter(constraintStartTime)) {
            setConstraintStartDateErrorText("Please enter a date that is one or more minutes in the future.");
            return;
        } else if (constraintStartDateErrorText.length) {
            setConstraintStartDateErrorText("");
        }
        const duration_min = parseFloat(constraintDurationMin);
        if (isNaN(duration_min) || duration_min < 1) {
            setConstraintDurationErrorText("Please enter a duration of 1 or more minutes.");
            return;
        } else if (constraintDurationErrorText.length) {
            setConstraintDurationErrorText("");
        }
        // submit if all checks pass
        setSubmitting(true);

        const final_volumes = [];
        constraintGeojson.forEach((feature, i) => {
            if (drawType === "Circle") {
                const radius_m = ConvertFeetToMeters(radius_ft);
                final_volumes.push({ id: i, lng: feature[0][0], lat: feature[0][1], radius: radius_m });
            } else {
                const vertices = feature.map(([lng, lat]) => {
                    return { lng: lng, lat: lat };
                });
                final_volumes.push({ id: i, type: "polygon", vertices: vertices });
            }
        });
        const altitude_m = ConvertFeetToMeters(altitude_ft);
        const time_start_iso_str = constraintStartTime.toISOString();
        const time_end_iso_str = constraintStartTime.add(duration_min, "minutes").toISOString();

        const constraint = {
            name: constraintName,
            altitude: altitude_m,
            organization: user.organization,
            organization_id: user.organization_id,
            created_user_id: user.id,
            time_start: time_start_iso_str,
            time_end: time_end_iso_str,
            volumes: final_volumes,
            type: constraintType,
            priority: constraintPriority,
            state: "PLANNING",
            version: 1
        };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("api/constraint/planning", requestOptions)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then(() => {
                setSnackbar({ children: "Constraint successfully created", severity: "success" });
                navigate("/");
            })
            .catch((err) => {
                handleFailedFetch(err);
            });
    };
    return (
        <Box style={{ position: "relative", width: "100%", height: "100%", display: "flex" }}>
            <Collapse in={isDesktop || mobileConstraintDrawerOpen} orientation="horizontal">
                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        width: { xs: "100%", sm: "375px" },
                        overflowY: "auto",
                        flexShrink: 0,
                        height: "100%"
                    }}
                >
                    {isDesktop === false ? (
                        <Button
                            endIcon={<KeyboardArrowRightIcon />}
                            onClick={() => setMobileConstraintDrawerOpen(false)}
                            sx={{ flexDirection: "row", marginLeft: "auto" }}
                            size="small"
                        >
                            Back to map
                        </Button>
                    ) : (
                        <></>
                    )}

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                        <BadgeIcon fontSize="small" />

                        <TextField
                            fullWidth
                            size="small"
                            margin="dense"
                            label="Constraint Name"
                            value={constraintName}
                            onChange={(e) => setConstraintName(e.target.value)}
                            error={constraintNameErrorText.length > 0}
                            helperText={constraintNameErrorText}
                            autoFocus
                            id="nameInput"
                        />
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                        <PriorityHighIcon fontSize="small" />

                        <TextField
                            select
                            fullWidth
                            size="small"
                            margin="dense"
                            label="Constraint Type"
                            value={constraintType}
                            onChange={(e) => handleTypeChange(e.target.value)}
                        >
                            {constraint_types.map(({ type }, i) => (
                                <MenuItem key={i} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>

                    <Typography variant="body1">{`Altitude ${drawType === "Circle" ? "& Radius" : ""}`}</Typography>

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                        <HeightIcon fontSize="small" />

                        <TextField
                            fullWidth
                            size="small"
                            margin="dense"
                            label="Altitude (AGL)"
                            value={constraintAltitudeFt}
                            onChange={(e) => setConstraintAltitudeFt(e.target.value)}
                            error={constraintAltitudeErrorText.length > 0}
                            helperText={constraintAltitudeErrorText}
                            InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                            id="inputAltitude"
                        />
                    </Box>

                    {drawType === "Circle" ? (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                            <PlaceOutlinedIcon fontSize="small" />

                            <TextField
                                fullWidth
                                size="small"
                                margin="dense"
                                label="Radius"
                                value={constraintRadiusFt}
                                onChange={(e) => setConstraintRadiusFt(e.target.value)}
                                error={constraintRadiusErrorText.length > 0}
                                helperText={constraintRadiusErrorText}
                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                id="constraintRadius"
                            />
                        </Box>
                    ) : (
                        <></>
                    )}

                    <Typography variant="body1">Date & Time</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                            <AccessTimeIcon fontSize="small" />

                            <DateTimePicker
                                label={`Start Time (${GetTimezone()}) *`}
                                value={constraintStartTime}
                                onChange={(value) => setConstraintStartTime(value)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        size: "small",
                                        margin: "dense",
                                        error: constraintStartDateErrorText.length > 0,
                                        helperText: constraintStartDateErrorText,
                                        inputProps: { "data-testid": "startTime" }
                                    }
                                }}
                            />
                        </Box>
                    </LocalizationProvider>

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                        <TimelapseIcon fontSize="small" />

                        <TextField
                            fullWidth
                            size="small"
                            margin="dense"
                            label="Duration"
                            value={constraintDurationMin}
                            onChange={(e) => setConstraintDurationMin(e.target.value)}
                            error={constraintDurationErrorText.length > 0}
                            helperText={constraintDurationErrorText}
                            InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment> }}
                            id="durationInput"
                        />
                    </Box>

                    <LoadingButton fullWidth variant="contained" loading={submitting} onClick={handleSubmitButtonClick} id="publish">
                        Submit
                    </LoadingButton>
                    <Button fullWidth variant="text" onClick={() => navigate("/")}>
                        Cancel
                    </Button>
                </Box>
            </Collapse>

            <Box sx={{ flex: 1, position: "relative", overflow: "hidden" }}>
                <MapDrawComponent
                    type="Constraint"
                    publishedFlights={filteredFlights}
                    constraints={filteredConstraints}
                    alerts={[]}
                    changeDrawType={setDrawType}
                    canEdit={true}
                    radius={constraintRadiusFt}
                    setRadius={setConstraintRadiusFt}
                    setVolumeGeojson={setConstraintGeojson}
                    setMinAltitude={() => {}}
                    setMaxAltitude={setConstraintAltitudeFt}
                    setName={setConstraintName}
                    setMobileDrawerOpen={setMobileConstraintDrawerOpen}
                />
                {mobileConstraintDrawerOpen === false && isDesktop === false ? (
                    <Fab size="medium" color="primary" onClick={() => setMobileConstraintDrawerOpen(true)} sx={{ position: "absolute", bottom: 8, left: 8 }}>
                        <KeyboardArrowRightIcon />
                    </Fab>
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
};

export default ConstraintCreator;
