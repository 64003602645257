// HIMS Color Constants
export const DEFAULT_COLOR = "#2f2f2f"; // Dark Grey
export const ONLINE_COLOR = "#5fa052"; // Green
export const FAILURE_COLOR = "#CB4C4E"; // Red
export const DEGRADED_OUTAGE_COLOR = "#CC5500"; // Orange
export const DEGRADED_COLOR = "#E0D268"; // Yellow
export const START_UP_COLOR = "#90caf9"; // Light Blue

// HIMS Latency Band Constants
export const COOP_NOMINAL_VALUE = 1;
export const COOP_LOW_VALUE = 1.5;
export const COOP_MEDIUM_VALUE = 2;
export const NONCOOP_NOMINAL_VALUE = 2;
export const NONCOOP_LOW_VALUE = 3;
export const NONCOOP_MEDIUM_VALUE = 4;

export const getSystemColorFromType = (type) => {
    let statusColor = ONLINE_COLOR;
    if (type == "FAILURE") {
        statusColor = FAILURE_COLOR;
    }
    if (type == "DEGRADED_OUTAGE") {
        statusColor = DEGRADED_OUTAGE_COLOR;
    }
    if (type == "DEGRADED") {
        statusColor = DEGRADED_COLOR;
    }
    return statusColor;
};

export const getVolumeAltitudeFromType = (type) => {
    let altitude = 3;
    if (type == "FAILURE") {
        altitude = 0;
    }
    if (type == "DEGRADED_OUTAGE") {
        altitude = 1;
    }
    if (type == "DEGRADED") {
        altitude = 2;
    }
    return altitude;
};
